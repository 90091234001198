import React, { useState } from "react";
import { injectIntl } from "react-intl";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import { LinearProgress, TextField, Typography } from '@material-ui/core';

/**
 * Propiedades de FWDDialogo
 */
//? Se define objeto exportable para
//? ayudar a construir el dialogo
//? con autocompletado de props.
export const FWDDialogoProps = {
  txtOk: 'Si',
  txtNoOk: 'No',
  arrastrable: false,
  txtTitulo: "",
  txtMensaje: "",
  inputValido: null,
  fnOk: () => { },
  fnNoOk: () => { },
  cargando: false,
  visible: false
}


//? Se copia y pegan las propiedades definidas
//? arriba.
/**
 * Dialogo de sistema FWD. Para guiarte puedes ver el uso en la pantalla de 
 * app/pages/configuraciones/Seguridad.js
 * @param {FWDDialogoProps} param0 
 */
const _FWDDialogo = ({
  txtOk = 'Si',
  txtNoOk = 'No',
  arrastrable = false,
  txtTitulo = "",
  txtMensaje = "",
  inputValido = null,
  fnOk = () => { },
  fnNoOk = () => { },
  cargando = false, 
  visible = false
}) => {


  const [input, setInput] = useState("");

  const PaperComponent = (props) => {
    return (
      <>
        {arrastrable ? (
          <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
          >
            <Paper {...props} />
          </Draggable>
        ) : (
            <Paper {...props} />
          )}
      </>
    );
  };

  return (
    <>
      <Dialog
        open={visible}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: arrastrable ? "move" : "default", textAlign: 'center' }} id="draggable-dialog-title">
          {txtTitulo}
        </DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography align="center" variant="subtitle1" component="div">
            {txtMensaje instanceof Array
              ? txtMensaje.map((x, i) => <p key={i}>{x}</p>)
              : txtMensaje
            }
          </Typography>
          {inputValido
            ?
            <>
              <Typography>Escribe <span style={{ fontStyle: 'italic' }}>{inputValido}</span> para continuar</Typography>
              <TextField disabled={cargando} autoFocus value={input} onChange={e => setInput(e.target.value)} inputProps={{ style: { textAlign: 'center' } }} />
            </>
            :
            <></>
          }
        </DialogContent>
        <DialogActions style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <section>
            <Button disabled={cargando}
              onClick={async () => {
                await fnNoOk()
                setInput('')
              }} color="primary">
              {txtNoOk}
            </Button>
            <Button disabled={cargando || !(inputValido !== null ? inputValido === input : true)}
              onClick={async () => {
                await fnOk()
                setInput('')
              }} color="primary">
              {txtOk}
            </Button>
          </section>
          <LinearProgress style={{ width: cargando ? '100%' : '0%' }} />
        </DialogActions>
      </Dialog>
    </>
  );
};
const FWDDialogo = injectIntl(_FWDDialogo)
export { FWDDialogo }
