import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LogoAzul from 'app/../imagenes/LogoAzul.png';
import { Typography, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        marginRight: '43%',
    },
    rootTable: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    logo: {
        width: 150,
        height: 90,
        marginBottom: theme.spacing(2),
    },
    textoCargando: {
        fontSize: '1rem',
    },
}));

export const LoaderDashboard = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <img src={LogoAzul} alt="Logo" className={classes.logo} />
            <CircularProgress style={{ color: '#1f23be' }} color="secondary" />
        </div>
    );
};

export const LoaderDashboardTables = ({label}) => {
    const classes = useStyles();

    return (
        <div className={classes.rootTable}>
            <img src={LogoAzul} alt="Logo" className={classes.logo} />
            <CircularProgress style={{ color: '#1f23be' }} color="secondary" />
            <Typography style={{ marginTop: 10, color: '#1f23be', fontSize: 13, fontWeight: 'bold' }}>{label ?  label :  "Cargando información, por favor espere..." } </Typography>
        </div>
    );
};

export const LoaderPantallaVacia = ({label}) => {
    const classes = useStyles();

    return (
        <div className={classes.rootTable}>
            <img src={LogoAzul} alt="Logo" className={classes.logo} />
            {/* <CircularProgress style={{ color: '#1f23be' }} color="secondary" /> */}
            <Typography style={{ marginTop: 10, color: '#1f23be', fontSize: 13, fontWeight: 'bold' }}>{label}</Typography>
        </div>
    );
};

export const LogoFwd = () => {
    const classes = useStyles();

    return (
        <div className={classes.rootTable}>
            <img src={LogoAzul} alt="Logo" className={classes.logo} />
            {/* <CircularProgress style={{ color: '#1f23be' }} color="secondary" /> */}
        </div>
    );
};