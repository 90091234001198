import React, { useState } from 'react'
import { injectIntl } from 'react-intl'
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import GpsFixed from '@material-ui/icons/GpsFixed'
import { Paper, Typography, Divider, Link } from '@material-ui/core'
import SanitizedHTML from 'react-sanitized-html';
import RoomIcon from '@material-ui/icons/Room';
import FWDModalServicio from 'app/componentes/FWDModalServicio'

const markerColors = {
    green: '#587D71',
    red: '#902D41',
    blue: '#2E5EAA'
}

const TimelineSeguimiento = (props) => {

    const { seguimiento, multipleServicios = false } = props;
    const [modalServicio, setModalServicio] = useState({ dblPedido: null, open: false })

    return <>
        <FWDModalServicio bitOpen={modalServicio.open} dblPedido={modalServicio.dblPedido}
            fnClose={() => { setModalServicio({ open: false, dblPedido: null }) }}
        />
        <Timeline style={{ display: 'flex', flexDirection: 'column-reverse' }}>
            {seguimiento?.map((evento, indxEvento) => (
                <TimelineItem key={indxEvento}>
                    <TimelineOppositeContent style={{ display: 'none' }} />
                    {!multipleServicios
                        ?
                        <TimelineSeparator>
                            <TimelineConnector style={{ display: evento.intEstatusPedido === 5 ? 'none' : 'block' }} />
                            <TimelineDot style={{
                                background: indxEvento === 0
                                    ? markerColors.green
                                    : evento.intEstatusPedido === 5
                                        ? markerColors.red
                                        : 'inherit'
                            }}>
                                {indxEvento > 0
                                    ? evento.intEstatusPedido !== 5
                                        ? <GpsFixed style={{ color: markerColors.blue }} />
                                        : <RoomIcon />
                                    : <RoomIcon />
                                }
                            </TimelineDot>
                            <TimelineConnector style={{ display: evento.intEstatusPedido !== 5 ? 'none' : 'block' }} />
                        </TimelineSeparator>
                        :
                        <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot style={{ background: 'inherit' }}  >
                                <GpsFixed style={{ color: markerColors.blue }} />
                            </TimelineDot>
                        </TimelineSeparator>
                    }
                    <TimelineContent>
                        <Paper elevation={3} style={{ padding: '10px' }}>
                            <section style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                {evento?.strNumeroServicio
                                    ? <>
                                        <Link className="cursor-hover" variant="h5" component="h1" onClick={() => setModalServicio({ dblPedido: evento.dblPedido, open: true })}>{evento.strNumeroServicio}</Link>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                            <Typography variant="overline" component="h1">{evento.strEstatusPedido}</Typography>
                                            <Typography variant="overline" color="textSecondary">{evento.datFechaHoraAlta}hrs</Typography>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <Typography variant="overline" component="h1">{evento.strEstatusPedido}</Typography>
                                        <Typography variant="overline" color="textSecondary">{evento.datFechaHoraAlta}hrs</Typography>
                                    </>
                                }
                            </section>
                            <Divider />
                            <SanitizedHTML
                                style={{ paddingTop: '10px' }}
                                allowedTags={['strong']}
                                html={evento.strSeguimiento}
                            />
                        </Paper>
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    </>

}

export default injectIntl(TimelineSeguimiento);