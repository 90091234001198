import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { QuickUserTogglerMobile } from "../extras/QuiclUserTogglerMobile";

import Logo from 'app/../imagenes/logo.png'

export function HeaderMobile() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      headerLogo: uiService.getStickyLogo(),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      headerMenuSelfDisplay:
        objectPath.get(uiService.config, "header.menu.self.display") === true,
      headerMobileCssClasses: uiService.getClasses("header_mobile", true),
      headerMobileAttributes: uiService.getAttributes("header_mobile")
    };
  }, [uiService]);

  return (
    <>
      {/*begin::Header Mobile*/}
      <div
        id="kt_header_mobile"
        className={`header-mobile justify-content-space-between align-items-center ${layoutProps.headerMobileCssClasses}`}
        {...layoutProps.headerMobileAttributes}
      >

        {layoutProps.asideDisplay && (
          <>
            {/*begin::Aside Mobile Toggle*/}
            <button className="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
              <span />
            </button>
            {/*end::Aside Mobile Toggle*/}
          </>
        )}

        {/*begin::Logo*/}
        <Link to="">
          <img src={Logo} width={75} height={40} className="cursor-hover" />
        </Link>

        {/*begin::Topbar Mobile Toggle*/}
        {/* <button
            className="btn btn-hover-text-primary p-0 ml-2"
            id="kt_header_mobile_topbar_toggle"
          >
            <span className="svg-icon svg-icon-xl">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
          </button> */}
        <QuickUserTogglerMobile />
        {/*end::Topbar Mobile Toggle*/}

        {/*end::Logo*/}
        {/*begin::Toolbar*/}
        {/* <div className="d-flex align-items-center"> */}

        {/* </div> */}
        {/*end::Toolbar*/}
      </div>
      {/*end::Header Mobile*/}
    </>
  );
}
