import React, { useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import axios from 'axios'
import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, LinearProgress, Paper, CircularProgress } from '@material-ui/core'
import { FWDCabecera, FWDTableHead, FWDOrderHelper } from 'app/componentes/FWDTableHead'
import ScrollContainer from 'react-indiana-drag-scroll'
const Necesidades = ({
    mes,
    anio,
    cliente = ''
}) => {
    const [cargandoModalNecesidad, setCargandoModalNecesidad] = useState(false);
    const [dataNecesidades, setDataNecesidades] = useState([]);
    const [totalReportadas, setTotalReportadas] = useState(0);
    const [totalCubiertas, setTotalCubiertas] = useState(0);

    const get_validarTarifario = async (ordenamiento = []) => {
        try {
            setCargandoModalNecesidad(true);
            let apiURL = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/dashboard/api/necesidades/detallepanel?';
            apiURL += `${cliente}&`;
            apiURL += `varMes=${mes}&`;
            apiURL += `varAnio=${anio}&`;

            const strOrdenamiento = FWDOrderHelper(ordenamiento)

            if (strOrdenamiento.length) apiURL += '&ordenamiento=' + strOrdenamiento

            const response = await axios.get(apiURL);
            console.log('Detalle Necesidades', response);
            if (response.data.totalReportadas >= 0) {
                setTotalReportadas(response.data.totalReportadas);
            }
            if (response.data.totalCubiertas >= 0) {
                setTotalCubiertas(response.data.totalCubiertas);
            }
            if (response.data.necesidades) {
                setDataNecesidades(response.data.necesidades);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setCargandoModalNecesidad(false);
        }
    }

    useEffect(() => {
        get_validarTarifario();
    }, [])

    return <>
        <Grid container alignContent="flex-start" style={{ display: 'initial' }}>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <ScrollContainer className="scroll-container" hideScrollbars={false}>

                        <Table>
                            <FWDTableHead
                                cabeceras={[
                                    new FWDCabecera('solicitados', `Reportadas ${cargandoModalNecesidad ? '(Cargando...)' : `(${totalReportadas})`}`, { width: 155, align: 'center', style: { minWidth: 155 } }),
                                    new FWDCabecera('cubiertos', `Cubiertas ${cargandoModalNecesidad ? '(Cargando...)' : `(${totalCubiertas})`}`, { width: 150, align: 'center', style: { minWidth: 150 } }),
                                    new FWDCabecera('servicio', 'Servicio', { width: 180, style: { minWidth: 180 } }),
                                    new FWDCabecera('vehiculo', 'Vehiculo', { width: 180, style: { minWidth: 180 } }),
                                    new FWDCabecera('origen', 'Origen', { style: { minWidth: 200 } }),
                                    new FWDCabecera('destino', 'Destino', { style: { minWidth: 200 } }),
                                ]}
                                fnOnClick={get_validarTarifario}
                                disabled={cargandoModalNecesidad}
                            />
                            <TableBody>
                                {
                                    (cargandoModalNecesidad)
                                        ?
                                        <TableRow>
                                            <TableCell colSpan="6" align="center"><LinearProgress /></TableCell>
                                        </TableRow>
                                        :
                                        [
                                            (dataNecesidades?.length > 0)
                                                ?
                                                dataNecesidades.map((element) => (
                                                    <TableRow hover={true}>
                                                        <TableCell align="center">{element.SOLICITADOS}</TableCell>
                                                        <TableCell align="center">{element.CUBIERTOS}</TableCell>
                                                        <TableCell>{element.SERVICIOS}</TableCell>
                                                        <TableCell>{element.VEHICULO}</TableCell>
                                                        <TableCell>{element.ORIGEN}</TableCell>
                                                        <TableCell>{element.DESTINO}</TableCell>
                                                    </TableRow>
                                                ))
                                                :
                                                <TableRow>
                                                    <TableCell colSpan="6" style={{ textAlign: 'center' }}>No hay solicitudes&nbsp;</TableCell>
                                                </TableRow>
                                        ]
                                }
                            </TableBody>
                        </Table>
                    </ScrollContainer>
                </TableContainer>
            </Grid>
        </Grid>
    </>
}

export default injectIntl(Necesidades);