import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import locale_es from "date-fns/locale/es";
import {MuiPickersUtilsProvider,KeyboardDatePicker} from '@material-ui/pickers';
import { useSnackbar } from 'notistack';
import { LoaderDashboardTables } from 'app/componentes/dashboard/LoaderDashboard'


import React, { useState, useEffect } from "react"
import axios from 'axios'
import { injectIntl } from "react-intl";
import { useSelector } from "react-redux"
import Loading from 'app/componentes/Loading';

import {
    Paper, Grid, makeStyles, InputAdornment, FormControl, InputLabel, Tooltip,
    Input, IconButton, Checkbox, Button,
    Table, TableContainer, TableHead, TableBody, TableRow, TableCell,
    Tabs, Tab, Typography, Box,
} from '@material-ui/core';


import Skeleton from '@material-ui/lab/Skeleton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SearchIcon from '@material-ui/icons/Search';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ModalFactura from 'app/componentes/ModalFactura'
import moneyFormat from 'app/formatos/Moneda';
import formatDate from 'app/formatos/Fecha';
import { FileIcon, defaultStyles } from 'react-file-icon';

const useStyles = makeStyles((theme) => ({
    input: {
        textAlign: 'left'
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        padding: theme.spacing(2),
        //textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        marginTop: 0,
        marginLeft: 2,
        marginBottom: 0,
        marginRight: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    title: {
        flex: '1 1 100%',
    },
    button: {
        margin: theme.spacing(1),
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ width: '100%' }}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
    

function ConsultarPagos(props) {
    const today = new Date();
    const { intl } = props;
    const { session, selectedClienteComercial } = useSelector(state => state.auth);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const arrayCliente =  session ? session.listaVarClientes.map((cliente) => {return `varCliente=${cliente.strVarClienteOrigen}`;}) : [];
    const classes = useStyles();
    const docStyles = defaultStyles;
    docStyles['xml'] = { type: 'document', glyphColor: '#000000', labelColor: '#000000' };

    const [bitBloquearVista, setBitBloquearVista] = useState(false);
    //Filtros de busqueda
    const [strBusqueda, setStrBusqueda] = useState('');
    const [datFechaInicio, setDatFechaInicio] = useState(new Date(today.getFullYear(), today.getMonth() - 1, 1));
    const [datFechaFin, setDatFechaFin] = useState(new Date());

    const [listaFranquicias, setListaFranquicias] = useState(null);
    const [tabFranquiciaSeleccionada, setTabFranquiciaSeleccionada] = useState(0);
    const [dblFacturaSeleccionada, setDblFacturaSeleccionada] = useState(0);
    const [bitAbrirModalFactura, setBitAbrirModalFactura] = useState(false);
    const [listaFilasSeleccionadas, setListaFilasSeleccionadas] = useState([]);

    const [loading_isOpen, setLoading_isOpen ] = useState(false);


    const comprobarSesionAsync = async () => {
        try {
            axios.get(`${process.env.REACT_APP_AWS_API_BASE_URL}/auth/sesion`);            
        } catch (exception) {
            console.log("[inicializarDatos][exception]", exception);
        }
    }

    const consultarPagos = () => {
        setBitBloquearVista(true);
        axios.get(`${process.env.REACT_APP_AWS_API_BASE_URL}/portal/administracion/pagos/api/listapagos?` + `&varCliente=${selectedClienteComercial}`+ (strBusqueda.trim() ? `&strBusqueda=${strBusqueda.trim()}` : ``) + `&datFechaInicio=${formatDate(datFechaInicio)}&datFechaFin=${formatDate(datFechaFin)}`)
        .then(response => {

            if (response?.response?.data) {
                let responseError = response.response.data;
                if (responseError) {
                    enqueueSnackbar(responseError.error, {
                        autoHideDuration: 7000,
                        preventDuplicate: true,
                        variant: 'error',
                        onClick: () => closeSnackbar()
                    });
                    console.log(responseError);
                }
            }

            var obj_listaFranquicias = [];

            if (response.status === 200) {

                response.data.forEach((pago) => {
                    // Generamos el array de franquicias
                    if (!obj_listaFranquicias[pago.intFranquiciaEmisora]) {
                        obj_listaFranquicias[pago.intFranquiciaEmisora] = {
                            intFranquiciaEmisora: pago.intFranquiciaEmisora,
                            strFranquiciaEmisora: pago.strFranquiciaEmisora,
                            arrayCuentas: []
                        };
                    }

                    //Agrupamos los pagos por cuenta
                    if(!obj_listaFranquicias[pago.intFranquiciaEmisora].arrayCuentas[pago.intCuentaBancaria]) {
                        obj_listaFranquicias[pago.intFranquiciaEmisora].arrayCuentas[pago.intCuentaBancaria] = {
                            intCuentaBancaria: pago.intCuentaBancaria,
                            strCuenta: pago.strCuenta,
                            bitColumnaFormaPagoExtendida: false,
                            arrayPagos: [],
                        }
                    }
                    pago.strMonto = moneyFormat(pago.dblMonto, pago.strMoneda, '$');
                    pago.arrayFacturas = JSON.parse(pago.jsonFacturas);

                    if(pago.strTipoPago.length > 10) {
                        obj_listaFranquicias[pago.intFranquiciaEmisora].arrayCuentas[pago.intCuentaBancaria].bitColumnaFormaPagoExtendida = true;
                    }

                    obj_listaFranquicias[pago.intFranquiciaEmisora].arrayCuentas[pago.intCuentaBancaria].arrayPagos.push(pago);
                });
            }
            
            let aux_listaFranquicias = objectToSimpleArray(obj_listaFranquicias);
            let aux2_listaFranquicias = aux_listaFranquicias.map(franquicia => {
                let arrayCuentas = objectToSimpleArray(franquicia.arrayCuentas);
                franquicia.arrayCuentas = arrayCuentas;
                return franquicia;
            });
            setListaFranquicias(aux2_listaFranquicias.length > 0 ? aux2_listaFranquicias : null);
            setListaFilasSeleccionadas([]);
            setBitBloquearVista(false);

        })
        .catch(error => {
            console.log("[consultarPagos][catch]", error);
            setBitBloquearVista(false);
            if (error) {
                enqueueSnackbar(intl.formatMessage({ id: "ADMINISTRACION.PAGOS.MENSAJE.ERROR_CONSULTA_PAGOS" }), {
                    autoHideDuration: 7000,
                    preventDuplicate: true,
                    variant: 'error',
                    onClick: () => closeSnackbar()
                });
            }
        });
    }

    var handleClick_FilaSeleccionada = (event, dblCobranzaTimbradoSAT) => {
        if (event.target)
            if (event.target.classList.contains("btn-no-seleccionable"))
                return;

        const selectedIndex = listaFilasSeleccionadas.indexOf(dblCobranzaTimbradoSAT);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(listaFilasSeleccionadas, dblCobranzaTimbradoSAT);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(listaFilasSeleccionadas.slice(1));
        } else if (selectedIndex === listaFilasSeleccionadas.length - 1) {
            newSelected = newSelected.concat(listaFilasSeleccionadas.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                listaFilasSeleccionadas.slice(0, selectedIndex),
                listaFilasSeleccionadas.slice(selectedIndex + 1),
            );
        }

        setListaFilasSeleccionadas(newSelected);
    }

    const isSelected = (dblCobranzaTimbradoSAT) => {
        return listaFilasSeleccionadas.indexOf(dblCobranzaTimbradoSAT) !== -1
    };

    const abrirComplementoPago = (dblCobranzaTimbradoSAT, tipo = "pdf") => {
        setLoading_isOpen(true);
        axios.get(`${process.env.REACT_APP_AWS_API_BASE_URL}/portal/administracion/pagos/api/complementos/${tipo}/${dblCobranzaTimbradoSAT}`)
            .then(response => {
                if (response.status === 200) {
                    if (tipo === "pdf") {
                        window.open(response.data, "_blank", 'toolbar=0,scrollbars=1,location=0,statusbar=0,menubar=0,resizable=1,width=750,height=1000');
                    } else {
                        var link = document.createElement("a");
                        link.href = response.data;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }

                } else {
                    enqueueSnackbar(intl.formatMessage({ id: "ADMINISTRACION.PAGOS.MENSAJE.ERROR_AL_DESCARGAR_DOCUMENTO" }), {
                        autoHideDuration: 7000,
                        preventDuplicate: true,
                        variant: 'error',
                        onClick: () => closeSnackbar()
                    });
                }
                setLoading_isOpen(false);
            })
            .catch(error => {
                console.log("[abrirComplementoPago][catch]", error);
                if (error) {
                    enqueueSnackbar(intl.formatMessage({ id: "ADMINISTRACION.PAGOS.MENSAJE.ERROR_AL_DESCARGAR_DOCUMENTO" }), {
                        autoHideDuration: 7000,
                        preventDuplicate: true,
                        variant: 'error',
                        onClick: () => closeSnackbar()
                    });
                }
                setLoading_isOpen(false);
            });
    }

    const handleClick_descargarZipComplementoPago = () => {
        if (!listaFilasSeleccionadas) {
            enqueueSnackbar(intl.formatMessage({ id: "ADMINISTRACION.PAGOS.MENSAJE.DESCARGAR_COMPLEMENTO" }), {
                autoHideDuration: 7000,
                preventDuplicate: true,
                variant: 'warning',
                onClick: () => closeSnackbar()
            });
            return;
        }

        if (listaFilasSeleccionadas.length === 0) {
            enqueueSnackbar(intl.formatMessage({ id: "ADMINISTRACION.PAGOS.MENSAJE.DESCARGAR_COMPLEMENTO" }), {
                autoHideDuration: 7000,
                preventDuplicate: true,
                variant: 'warning',
                onClick: () => closeSnackbar()
            });
            return;
        }

        var arrayFilasSeleccionadas = listaFilasSeleccionadas ? listaFilasSeleccionadas.map((dblCobranzaTimbradoSAT, index) => { return `dblCobranzaTimbradoSAT[]=${dblCobranzaTimbradoSAT}`; }) : [];
        setLoading_isOpen(true);
        axios.get(`${process.env.REACT_APP_AWS_API_BASE_URL}/portal/administracion/pagos/api/complementos/zip?` + arrayFilasSeleccionadas.join('&'))
            .then(response => {
                if (response?.response?.data) {
                    let responseError = response.response.data;
                    if (responseError) {
                        enqueueSnackbar(responseError.error, {
                            autoHideDuration: 7000,
                            preventDuplicate: true,
                            variant: 'error',
                            onClick: () => closeSnackbar()
                        });
                        console.log(responseError);
                    }
                }

                if (response.status === 200) {
                    var link = document.createElement("a");
                    link.href = response.data;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                setLoading_isOpen(false);
            })
            .catch(error => {
                console.log("[handleClick_descargarZipFacturas][catch]", error);
                if (error) {
                    enqueueSnackbar(intl.formatMessage({ id: "ADMINISTRACION.PAGOS.MENSAJE.ERROR_AL_DESCARGAR_DOCUMENTO" }), {
                        autoHideDuration: 7000,
                        preventDuplicate: true,
                        variant: 'error',
                        onClick: () => closeSnackbar()
                    });
                }
                setLoading_isOpen(false);
            });
    }

    const abrirModalFactura = (dblFactura) => {
        setDblFacturaSeleccionada(dblFactura);
        setBitAbrirModalFactura(true);
    }

    const objectToSimpleArray = (obj) => {
        let array = [];
        Object.keys(obj).forEach((itemKey) => {
            if (obj[itemKey]) {
                array.push(obj[itemKey]);
            }
        });
        return array;
    }

    useEffect(() => {  
        comprobarSesionAsync();
        setBitBloquearVista(false);
        consultarPagos();
    },[selectedClienteComercial]);

    return (<>
        <Loading open={loading_isOpen} mensaje="Cargando..."></Loading>
        <ModalFactura open={bitAbrirModalFactura} dblFactura={dblFacturaSeleccionada} onClose={() => {setBitAbrirModalFactura(false); setDblFacturaSeleccionada(0); }}  ></ModalFactura>
        <Grid container direction="column" xl={12} justify="center" style={{ height: '100%', display: 'initial' }} spacing={3}>
            <Grid item component={Paper} elevation={4} container spacing={3} direction="row" justify="space-between" alignItems="center"
                    style={{ padding: '15px', position: 'sticky', top: '70px', marginBottom: '25px', zIndex:'2' }}>
                    
                    <Grid container item md={12} lg={5}>
                        <FormControl style={{ width: '100%' }}>
                            <InputLabel>{intl.formatMessage({ id: "ADMINISTRACION.PAGOS.FILTROS.INPUT_BUSQUEDA" })}</InputLabel>
                            <Input
                                disabled={bitBloquearVista}
                                className={classes.input}
                                type='text'
                                value={strBusqueda}
                                onChange={event => setStrBusqueda(event.target.value)}
                                onKeyUp={event => {
                                    if (event.key.toLowerCase() === 'enter') {
                                        consultarPagos();
                                    }
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        {strBusqueda.length > 0
                                            ?   <IconButton
                                                    aria-label="Limpiar campo"
                                                    onClick={(event) => {
                                                        setStrBusqueda('');
                                                    }}
                                                >
                                                    <HighlightOffIcon />
                                                </IconButton>
                                            : <></>}
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>

                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale_es}>
                        <Grid item md={6} lg={3}>
                            <KeyboardDatePicker
                                disabled={bitBloquearVista}
                                disableToolbar
                                autoOk={true}                                     
                                variant="inline"
                                format="dd/MMM/yyyy"
                                id="date-picker-inicio"
                                style={{width: '100%'}}
                                label={intl.formatMessage({ id: "ADMINISTRACION.ESTADO_CUENTA.FILTROS.FECHA_INICIO" })}
                                value={datFechaInicio}
                                onChange={(date) => {setDatFechaInicio(date)}}
                                onKeyUp={event => {
                                    if (event.key.toLowerCase() === 'enter') {
                                        consultarPagos();
                                    }
                                }}
                                KeyboardButtonProps={{
                                'aria-label': 'Cambiar fecha',
                                }}
                            />
                        </Grid>
                        <Grid item md={6} lg={3}>
                            <KeyboardDatePicker
                                disabled={bitBloquearVista}
                                disableToolbar
                                autoOk={true}                                     
                                variant="inline"
                                format="dd/MMM/yyyy"
                                id="date-picker-fin"
                                style={{width: '100%'}}
                                label={intl.formatMessage({ id: "ADMINISTRACION.ESTADO_CUENTA.FILTROS.FECHA_FIN" })}
                                value={datFechaFin}
                                onChange={(date) => {setDatFechaFin(date)}}
                                onKeyUp={event => {
                                    if (event.key.toLowerCase() === 'enter') {
                                        consultarPagos();
                                    }
                                }}
                                KeyboardButtonProps={{
                                'aria-label': 'Cambiar fecha',
                                }}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>

                    <Grid container item md={12} lg={1}>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<SearchIcon/>}
                            onClick={() => { consultarPagos(); }}
                            style={{ float: 'right' }}>
                            buscar
                        </Button>
                    </Grid>
                </Grid>

            <Grid item component={Paper} container direction="row" justify="space-between" alignItems="center"
                    style={{ padding: '0px', marginBottom: '25px' }}>

                    {bitBloquearVista ?
                        <Grid container item lg={12}>
                            <Paper className={classes.paper} style={{ width: "100%"}}>
                                <Skeleton variant="rect" style={{ width: "100%", height: '30px'}} />
                            </Paper>
                            <Paper className={classes.paper} style={{ width: "100%"}}>
                                <LoaderDashboardTables />
                            </Paper>
                        </Grid>
                        :
                        <Grid container item lg={12}>
                            <Paper square>
                                <Tabs value={tabFranquiciaSeleccionada}
                                    onChange={(event, newValue) => { setTabFranquiciaSeleccionada(newValue); }}
                                    aria-label="Empresa emisora"
                                    indicatorColor="primary"
                                    textColor="primary">
                                    {listaFranquicias ?
                                        listaFranquicias.map((franquicia, indexFranquiciaEmisora) => {
                                            return <Tab key={`key-tab-franquicia-${franquicia.intFranquiciaEmisora}`} label={franquicia.strFranquiciaEmisora} {...a11yProps(indexFranquiciaEmisora)} />;
                                        })
                                        : ''}
                                </Tabs>
                            </Paper>

                            {listaFranquicias ?
                                listaFranquicias.map((franquicia, indexFranquiciaEmisora) => {
                                    return <TabPanel key={`key-panel-franquicia-${franquicia.intFranquiciaEmisora}`} value={tabFranquiciaSeleccionada} index={indexFranquiciaEmisora}>
                                            <Grid container item lg={12}>
                                                <TableContainer component={Paper}>
                                                    <Paper className={classes.paper}>

                                                    <Grid item container style={{ marginBottom: '30px' }}>
                                                            <Grid item xs={12} sm>
                                                            {franquicia.intFranquiciaEmisora == 1 
                                                                ? <Button
                                                                    variant="contained"
                                                                    size="small"
                                                                    color="secondary"
                                                                    startIcon={<CloudDownloadIcon />}
                                                                    onClick={() => { handleClick_descargarZipComplementoPago(); }}
                                                                    style={{ float: 'right' }}>
                                                                    {intl.formatMessage({ id: "ADMINISTRACION.PAGOS.BUTTON.DESCARGAR_COMPLEMENTO" })}
                                                                </Button>
                                                                : <></>
                                                            }
                                                            </Grid>
                                                        </Grid>
                                                    
                                                    {franquicia?.arrayCuentas?.map((cuenta, indexCuenta) => {
                                                        return <Grid item key={`cuenta-${indexCuenta}`} style={{ marginTop:'10px', marginBottom: '20px',  padding: '0px 10px 0px 10px' }}>
                                                                <Typography variant="h6"  style={{ padding: 0}}>
                                                                    {cuenta.strCuenta}
                                                                </Typography>

                                                                <Table size="small">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            { franquicia.intFranquiciaEmisora == 1
                                                                                ? <TableCell align="right" style={{ width: '25px', padding: 0 }} ></TableCell>
                                                                                : <></>}
                                                                            <TableCell align="left" style={{padding: '10px 0px 0px 0px', minWidth: '90px'}}>{intl.formatMessage({ id: "ADMINISTRACION.PAGOS.TABLE.CABECERA.FECHA" })}</TableCell>
                                                                            {arrayCliente.length > 1 ? <TableCell style={{padding: '10px 0px 0px 0px', minWidth: '200px'}}>{intl.formatMessage({ id: "ADMINISTRACION.PAGOS.TABLE.CABECERA.EMPRESA" })}</TableCell> : <></>}
                                                                            <TableCell align="left" style={{padding: '10px 0px 0px 10px'}}>{intl.formatMessage({ id: "ADMINISTRACION.PAGOS.TABLE.CABECERA.FACTURAS" })}</TableCell>
                                                                            <TableCell align="left" style={{padding: '10px 0px 0px 0px', minWidth: cuenta.bitColumnaFormaPagoExtendida ? '180px' : '100px'}}>{intl.formatMessage({ id: "ADMINISTRACION.PAGOS.TABLE.CABECERA.FORMA_PAGO" })}</TableCell>
                                                                            <TableCell align="right" style={{padding: '10px 0px 0px 0px', minWidth: '130px'}}>{intl.formatMessage({ id: "ADMINISTRACION.PAGOS.TABLE.CABECERA.MONTO" })}</TableCell>
                                                                            {franquicia.intFranquiciaEmisora === 1 ? <TableCell align="center" style={{padding: '10px 0px 0px 0px', minWidth: '110px'}}>{intl.formatMessage({ id: "ADMINISTRACION.PAGOS.TABLE.CABECERA.FOLIO" })}</TableCell> : <></>}
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        { 
                                                                            cuenta?.arrayPagos?.map((pago, indexPago) => {
                                                                                
                                                                                const isItemSelected = isSelected(pago.dblCobranzaTimbradoSAT);

                                                                                return <TableRow 
                                                                                    key={`pago-${pago.dblCobranzaTimbradoSAT}`} 
                                                                                    hover
                                                                                    aria-checked={isItemSelected}
                                                                                    tabIndex={-1}
                                                                                    onClick={(event) => handleClick_FilaSeleccionada(event, pago.dblCobranzaTimbradoSAT)}
                                                                                    role="checkbox"
                                                                                    selected={isItemSelected}
                                                                                    style={{verticalAlign: 'top'}}>
                                                                                    { franquicia.intFranquiciaEmisora == 1
                                                                                        ? <TableCell align="right" style={{ padding: 0, width: '25px', margin: 0, paddingRight:'15px' }}>
                                                                                            {pago.dblNumeroCobranza
                                                                                                ? <Checkbox
                                                                                                    checked={isItemSelected}
                                                                                                    inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-${indexPago}` }}
                                                                                                    style={{ width: '25px' }}
                                                                                                />
                                                                                                :<></>
                                                                                            }
                                                                                        </TableCell>
                                                                                        : <></>}
                                                                                    <TableCell align="left" style={{padding: '10px 0px 0px 0px'}}>{pago.datFechaPago}</TableCell>
                                                                                    {arrayCliente.length > 1 ? <TableCell style={{padding: '10px 0px 0px 0px'}}>{pago.strCliente}</TableCell> : <></>}
                                                                                    <TableCell align="left" style={{padding: '10px 0px 0px 10px'}}>{ pago.arrayFacturas
                                                                                        ?pago.arrayFacturas.map((factura) => {
                                                                                            return <button 
                                                                                                className="btn btn-link btn-no-seleccionable" 
                                                                                                key={factura.dblFactura}
                                                                                                onClick={() => {abrirModalFactura(factura.dblFactura);}}
                                                                                                style={{width: (factura.strFolio.length > 15 ? '150px' : '100px'), textAlign:'left', padding:0}}>
                                                                                                    {factura.strFolio}
                                                                                                </button>
                                                                                        })
                                                                                        : <></>
                                                                                    }</TableCell>
                                                                                    <TableCell align="left" style={{padding: '10px 0px 0px 0px'}}>{pago.strTipoPago}</TableCell>
                                                                                    <TableCell align="right" style={{padding: '10px 0px 0px 0px'}}>{pago.strMonto}</TableCell>
                                                                                    {franquicia.intFranquiciaEmisora === 1 
                                                                                        ? <TableCell align="center" style={{padding: '10px 0px 0px 0px'}}>
                                                                                            {pago.dblNumeroCobranza 
                                                                                            ? <Tooltip title={ intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TOOLTIP.VER_PDF" }) + " - " + pago.strSerieSat+pago.dblNumeroCobranza}>
                                                                                                <button className="btn btn-link btn-no-seleccionable" onClick={() => {abrirComplementoPago(pago.dblCobranzaTimbradoSAT, "pdf")}} style={{padding: 0, marginTop: '-10px'}}>
                                                                                                    <div style={{width: '20px', display: 'inline-block'}}> 
                                                                                                        <FileIcon extension={'pdf'} {...docStyles['pdf']}/>
                                                                                                    </div>
                                                                                                </button>
                                                                                            </Tooltip>
                                                                                            : '' }

                                                                                            {pago.dblNumeroCobranza 
                                                                                            ? 
                                                                                            <Tooltip title={ intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TOOLTIP.VER_PDF" }) + " - " + pago.strSerieSat+pago.dblNumeroCobranza}>
                                                                                                <button className="btn btn-link btn-no-seleccionable" onClick={() => {abrirComplementoPago(pago.dblCobranzaTimbradoSAT, "xml")}} style={{padding: 0, paddingLeft: '10px', marginTop: '-10px'}}>
                                                                                                    <div style={{width: '20px', display: 'inline-block'}}> 
                                                                                                        <FileIcon extension={'xml'} {...docStyles['xml']}/>
                                                                                                    </div>
                                                                                                </button>
                                                                                            </Tooltip>
                                                                                            : '' }
                                                                                        </TableCell> : <></> }
                                                                                </TableRow>
                                                                            })
                                                                        }
                                                                    </TableBody>
                                                                </Table>
                                                        </Grid>
                                                    })}
                                                    
                                                    </Paper>
                                                    
                                                </TableContainer>
                                            </Grid>
                                    </TabPanel>
                                }) :
                                <Typography variant="h6" style={{padding:"15px"}}>
                                    {intl.formatMessage({ id: "ADMINISTRACION.PAGOS.MENSAJE.NO_HAY_PAGOS" })}
                                </Typography>
                            }
                        </Grid>
                    }
                </Grid>
            </Grid></>
    );
}



export default injectIntl(ConsultarPagos);
