/**
 * Agrega esta funci�n en event 'onKeyPress' para permitir solo insertar caracteres
 * permitidos en tel�fono
 * @param {*} event 
 */
export const telefono_onKeyPress = event => {
    const validInput = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '#', '+']
    const indexBusqueda = validInput.indexOf(event.key)
    if (indexBusqueda === -1) event.preventDefault()
}

/**
 * Agrega esta funci�n en event 'onKeyPress' para permitir solo insertar caracteres
 * permitidos en campos num�ricos (solo digitos del 0 al 9)
 * @param {*} event 
 */
export const digitos09_onKeyPress = event => {
    const validInput = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    const indexBusqueda = validInput.indexOf(event.key)
    if (indexBusqueda === -1) event.preventDefault()
}
