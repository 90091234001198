import React, { useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import {
    Grid, Button, Paper, TableContainer, Table, TableHead,
    TableBody, TableRow, TableCell, IconButton, Checkbox,
    Typography, FormControlLabel, TextField, LinearProgress
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { useSelector } from 'react-redux';

import FWDModal from 'app/componentes/FWDModal'
import { FWDDialogoProps, FWDDialogo } from 'app/componentes/FWDDialogo'

//**** */
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
//**** */

import * as Icons from '@material-ui/icons/'

const useStyles = makeStyles({
    root: {
        height: 264,
        flexGrow: 1,
        width: '100%'
    },
});

const treeViewColors = {
    active: { color: '#3c8039', bgColor: '#e6f4ea' },
    inactive: { color: '#e3742f', bgColor: '#fcefe3' }
}

const API_URL = process.env.REACT_APP_AWS_API_BASE_URL

function PerfilesUsuario(props) {

    const classes = useStyles();

    const { session } = useSelector(state => state.auth);
    const { userNavigation, userAttributes } = session
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const asideNavigation = userNavigation.filter(x => x?.intModulo !== -1)

    const datosSeguridadFranquicia = userAttributes['custom:franquicias']
    const idGrupoSeguridad = datosSeguridadFranquicia['ID_GRUPO']

    const [modoEdicion, setModoEdicion] = useState(false)
    const [idUsuarioEditar, setIdUsuarioEditar] = useState(null)
    const [openModal, setOpenModal] = useState(false)

    const [nombrePerfilUsuario, setnombrePerfilUsuario] = useState('')
    const [descripcionPerfilUsuario, setdescripcionPerfilUsuario] = useState('')
    const [formularioInvalido, setFormularioInvalido] = useState(true)

    const [permisos, setPermisos] = useState([])
    const [guardandoPerfil, setGuardandoPerfil] = useState(false)

    const [perfilesUsuario, setPerfilesUsuario] = useState([])
    const [cargandoPerfilesUsuario, setCargandoPerfilesUsuario] = useState(true)
    const [cargandoPermisosPerfil, setCargandoPermisosPerfil] = useState(false)

    const [eliminandoPerfil, setEliminandoPerfil] = useState(false)

    const [dialogoEliminarPerfil, setDialogoEliminarPerfil] = useState({
        ...FWDDialogoProps,
        txtTitulo: 'Eliminar perfil de usuario',
        txtMensaje: '¿Estás seguro de eliminar el perfil de usuario?',
        fnNoOk: () => setDialogoEliminarPerfil({ ...dialogoEliminarPerfil, visible: false })
    })

    // Al cerrar modal limpiamos todos los inputs y lista de permisos
    useEffect(() => {
        if (!openModal) {
            setPermisos([])
            setnombrePerfilUsuario('')
            setdescripcionPerfilUsuario('')
            setModoEdicion(false)
        }
    }, [openModal])

    const getColor = (key) => {
        const indexOf = permisos.indexOf(key)
        return indexOf === -1
            ? treeViewColors.inactive
            : treeViewColors.active
    }

    const togglePermiso = (key) => {
        const indexOf = permisos.indexOf(key)
        if (indexOf === -1) {
            // Si es mod, se heredan permisos
            const [tipo, id] = key.split(':')
            if (tipo === 'mod') {
                const moduloSeleccionado = asideNavigation.filter(x => x.intModulo === Number(id))[0]
                const opciones = moduloSeleccionado.opciones.map(x => `opc:${x.intModulo}-${x.intOpcionMenu}`)
                const nuevosPermisos = [...permisos, key, ...opciones]
                setPermisos(nuevosPermisos.filter((x, i) => nuevosPermisos.indexOf(x) === i))
            }
            else {
                setPermisos([...permisos, key])
            }
        }

        else {

            // Si es mod, por herencia se elminan permisos
            const [tipo, id] = key.split(':')
            if (tipo === 'mod') {
                const moduloSeleccionado = asideNavigation.filter(x => x.intModulo === Number(id))[0]
                const opciones = moduloSeleccionado.opciones.map(x => `opc:${x.intModulo}-${x.intOpcionMenu}`)
                let nuevosPermisos = [...permisos]
                nuevosPermisos.splice(indexOf, 1)
                opciones.forEach(x => {
                    const indx = nuevosPermisos.indexOf(x)
                    nuevosPermisos.splice(indx, 1)
                })
                setPermisos(nuevosPermisos)
            }
            else {
                const aux = [...permisos]
                aux.splice(indexOf, 1)
                setPermisos(aux)
            }
        }
    }

    // https://92v9zvrmx3.execute-api.us-east-1.amazonaws.com/dev/admin/v2/perfiles-usuario/crear-perfil
    // https://92v9zvrmx3.execute-api.us-east-1.amazonaws.com/dev/portal/configuracion/seguridad/api/perfiles-usuario
    // https://92v9zvrmx3.execute-api.us-east-1.amazonaws.com/dev/portal/configuracion/seguridad/api/perfiles-usuario
    const fetchGuardarPerfilUsuario = async () => {
        try {
            setGuardandoPerfil(true)
            const payload = {
                nombre: nombrePerfilUsuario,
                descripcion: descripcionPerfilUsuario,
                idGrupoSeguridad,
                permisos,
            }

            const apiURL = !modoEdicion
                ? '/portal/configuracion/seguridad/api/perfiles-usuario'
                : '/portal/configuracion/seguridad/api/perfiles-usuario?intUsuarioPerfil=' + idUsuarioEditar

            if (!modoEdicion) {
                await axios.post(API_URL + apiURL, payload)
                enqueueSnackbar('Perfil creado', {
                    autoHideDuration: 1500,
                    variant: 'success'
                })
            }
            else {
                await axios.put(API_URL + apiURL, payload)
                enqueueSnackbar('Perfil editado', {
                    autoHideDuration: 1500,
                    variant: 'success'
                })
            }
        }
        catch (error) {
            console.log('fetchGuardarPerfilUsuarioError', error)
        }
        finally {
            setGuardandoPerfil(false)
            fetchConsultarPerfilesUsuario()
            setOpenModal(false)
        }
    }

    const fetchConsultarPerfilesUsuario = async () => {
        const apiURL = API_URL + "/portal/configuracion/seguridad/api/perfiles-usuario?ID_GRUPO=" + idGrupoSeguridad
        try {
            setCargandoPerfilesUsuario(true)
            const response = await axios.get(apiURL)
            setPerfilesUsuario(response.data.perfiles)
        }
        catch (error) {
            console.log('fetchConsultarPerfilesUsuarioError', error)
        }
        finally {
            setCargandoPerfilesUsuario(false)
        }
    }

    // https://92v9zvrmx3.execute-api.us-east-1.amazonaws.com/dev/portal/configuracion/seguridad/api/perfiles-usuario/permisos-perfil
    const fetchCargarPermisosPerfilUsuario = async (intUsuarioPerfil) => {
        const apiURL = API_URL + "/portal/configuracion/seguridad/api/perfiles-usuario/permisos-perfil?intUsuarioPerfil=" + intUsuarioPerfil
        try {
            setCargandoPermisosPerfil(true)
            const response = await axios.get(apiURL)
            console.log('permisos perfil ', intUsuarioPerfil, response.data)
            setPermisos(response.data.permisos)
        }
        catch (error) {
            console.log('fetchCargarPermisosPerfilUsuarioError', error)
        }
        finally {
            setCargandoPermisosPerfil(false)
        }
    }

    // https://92v9zvrmx3.execute-api.us-east-1.amazonaws.com/dev/portal/configuracion/seguridad/api/perfiles-usuario
    const fetchEliminarPerfilUsuario = async (intUsuarioPerfil) => {
        const apiURL = API_URL + '/portal/configuracion/seguridad/api/perfiles-usuario?intUsuarioPerfil=' + intUsuarioPerfil
        try {
            setEliminandoPerfil(true)
            setDialogoEliminarPerfil({ ...dialogoEliminarPerfil, cargando: true })
            await axios.delete(apiURL)
            enqueueSnackbar('Perfil eliminado', {
                autoHideDuration: 1500,
                variant: 'success'
            })
        }
        catch (error) {
            console.log('fetchEliminarPerfilUsuarioError', error)
            if (error?.response?.status === 409) {
                enqueueSnackbar('No es posible eliminar perfil de usuario. Existen usuarios asignados a dicho perfil.', {
                    variant: 'error'
                })
            }
        }
        finally {
            fetchConsultarPerfilesUsuario()
            setEliminandoPerfil(false)
            setDialogoEliminarPerfil({ ...dialogoEliminarPerfil, visible: false, cargando: false })
        }
    }

    useEffect(() => {
        fetchConsultarPerfilesUsuario()
    }, [])

    useEffect(() => {
        setFormularioInvalido(!(nombrePerfilUsuario.length && descripcionPerfilUsuario.length))
    }, [nombrePerfilUsuario, descripcionPerfilUsuario])


    return <Grid container>
        <FWDDialogo {...dialogoEliminarPerfil} />
        <FWDModal
            Titulo={() => <Typography variant="h5">Nuevo perfil de usuario</Typography>}
            open={openModal}
            fnCloseModal={() => setOpenModal(false)}
            height="75vh"
            width="420px"
        >
            <section style={{ width: 420, minWidth: 420, display: 'flex', flexDirection: 'column', height: "calc(75vh - 50px)" }}>
                <div style={{ padding: 15 }}>
                    <TextField
                        label="Nombre"
                        style={{ width: '100%', marginBottom: 5 }}
                        value={nombrePerfilUsuario}
                        onChange={e => setnombrePerfilUsuario(e.target.value)}
                        disabled={cargandoPermisosPerfil}
                    />
                    <TextField
                        label="Descripción"
                        style={{ width: '100%' }}
                        multiline
                        rowsMax={2}
                        value={descripcionPerfilUsuario}
                        onChange={e => setdescripcionPerfilUsuario(e.target.value)}
                        disabled={cargandoPermisosPerfil}
                    />
                </div>
                <div style={{ visibility: cargandoPermisosPerfil ? 'visible' : 'hidden' }}>
                    <LinearProgress width="100%" />
                </div>
                <div style={{ overflowY: 'scroll', flexGrow: 1 }}>
                    <TreeView
                        className={classes.root}
                        defaultCollapseIcon={<ArrowDropDownIcon />}
                        defaultExpandIcon={<ArrowRightIcon />}
                        defaultEndIcon={<div style={{ width: 24 }} />}
                    >
                        {asideNavigation?.map(mod => (
                            <StyledTreeItem
                                key={`mod:${mod.intModulo}`}
                                nodeId={`mod:${mod.intModulo}`}
                                color={getColor(`mod:${mod.intModulo}`).color}
                                bgColor={getColor(`mod:${mod.intModulo}`).bgColor}
                                labelText={mod.strModulo}
                                labelIcon={mod.strIconoPortal}
                                onCheckboxClick={(e) => {
                                    e.stopPropagation()
                                    togglePermiso(`mod:${mod.intModulo}`)
                                }}
                                checkboxChecked={permisos.indexOf(`mod:${mod.intModulo}`) !== -1}
                            >
                                { mod.opciones?.map(opc => (
                                    <StyledTreeItem
                                        key={`opc:${opc.intModulo}-${opc.intOpcionMenu}`}
                                        nodeId={`opc:${opc.intModulo}-${opc.intOpcionMenu}`}
                                        color={getColor(`opc:${opc.intModulo}-${opc.intOpcionMenu}`).color}
                                        bgColor={getColor(`opc:${opc.intModulo}-${opc.intOpcionMenu}`).bgColor}
                                        labelText={opc.strOpcionMenuPortal}
                                        onCheckboxClick={(e) => {
                                            e.stopPropagation()
                                            if (permisos.indexOf(`mod:${opc.intModulo}`) !== -1)
                                                togglePermiso(`opc:${opc.intModulo}-${opc.intOpcionMenu}`)
                                        }}
                                        checkboxChecked={permisos.indexOf(`opc:${opc.intModulo}-${opc.intOpcionMenu}`) !== -1}
                                        checkboxDisabled={permisos.indexOf(`mod:${opc.intModulo}`) === -1}
                                    ></StyledTreeItem>
                                ))}
                            </StyledTreeItem>
                        ))}
                    </TreeView>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: 15 }}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        style={{ width: 180 }}
                        onClick={() => setOpenModal(false)}
                        disabled={guardandoPerfil}
                    >Cancelar</Button>
                    {!modoEdicion
                        ?
                        <Button
                            variant="outlined"
                            color="primary"
                            style={{ width: 180 }}
                            onClick={fetchGuardarPerfilUsuario}
                            disabled={guardandoPerfil || formularioInvalido}
                        >Crear</Button>
                        :
                        <Button
                            variant="outlined"
                            color="primary"
                            style={{ width: 180 }}
                            onClick={fetchGuardarPerfilUsuario}
                            disabled={guardandoPerfil || cargandoPermisosPerfil || formularioInvalido}
                        >Guardar</Button>
                    }
                </div>
            </section>
        </FWDModal>
        <Grid item xs={12}>
            <Paper elevation={2} style={{ padding: 15 }}>
                <section>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell>Descripci&oacute;n</TableCell>
                                    <TableCell align="right">Fecha de creaci&oacute;n</TableCell>
                                    <TableCell align="right" width={150}>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            color="secondary"
                                            startIcon={<GroupAddIcon />}
                                            onClick={() => setOpenModal(true)}
                                        >Nuevo Perfil</Button>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!cargandoPerfilesUsuario
                                    ? perfilesUsuario?.map((x, i) => (
                                        <TableRow key={i}>
                                            <TableCell component="td">
                                                {x.strNombrePerfil}
                                            </TableCell>
                                            <TableCell component="td">
                                                {x.strDescripcion}
                                            </TableCell>
                                            <TableCell align="right">
                                                {x.datFechaCreacion}
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => {
                                                        fetchCargarPermisosPerfilUsuario(x.intUsuarioPerfil)
                                                        setModoEdicion(true,
                                                            setIdUsuarioEditar(x.intUsuarioPerfil,
                                                                setnombrePerfilUsuario(x.strNombrePerfil,
                                                                    setdescripcionPerfilUsuario(x.strDescripcion,
                                                                        setOpenModal(true)
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    }}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    disabled={eliminandoPerfil}
                                                    onClick={() => {
                                                        setDialogoEliminarPerfil({
                                                            ...dialogoEliminarPerfil,
                                                            fnOk: () => fetchEliminarPerfilUsuario(x.intUsuarioPerfil),
                                                            visible: true
                                                        })
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                    : <TableRow>
                                        <TableCell colSpan={4}>
                                            <section style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                <Typography variant="overline">Cargando</Typography>
                                            </section>
                                            <LinearProgress width="100%" />
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </section>
            </Paper>
        </Grid>
    </Grid>

}


const IconoPortal = (props) => {
    const { strIconoPortal, className } = props;
    // console.log('>>',strIconoPortal);
    if (typeof strIconoPortal === 'string') return React.createElement(Icons[strIconoPortal], {
        className
    })
    else return <>&nbsp;&middot;&nbsp;&nbsp;</>
}

function StyledTreeItem(props) {
    const { labelText, labelIcon, labelInfo, color, bgColor, onCheckboxClick, checkboxChecked, checkboxDisabled, ...other } = props;

    return (
        <TreeItem
            style={{ background: bgColor }}
            label={
                <div style={{ display: 'flex', justifyContent: 'space-between', background: bgColor }}>
                    <section style={{ display: 'flex', alignItems: 'center', color }}>
                        <IconoPortal strIconoPortal={labelIcon} />
                        <Typography variant="body2" style={{ marginLeft: 5 }}>
                            {labelText}
                        </Typography>
                        <Typography variant="caption" color="inherit">
                            {labelInfo}
                        </Typography>
                    </section>
                    <FormControlLabel
                        onClick={onCheckboxClick}
                        checked={checkboxChecked}
                        disabled={checkboxDisabled}
                        control={<Checkbox
                            icon={<CheckBoxOutlineBlankIcon />}
                            checkedIcon={<CheckBoxIcon />}
                            disabled={checkboxDisabled}
                        />}
                    />
                </div>
            }
            {...other}
        />
    );
}


export default injectIntl(PerfilesUsuario)