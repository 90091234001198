/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { injectIntl } from 'react-intl'
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux"
import { DropdownCustomToggler, DropdownMenu1 } from "app/../_metronic/_partials/dropdowns";
import { Card, CardHeader, CardContent, Divider, Link, Tooltip, MenuItem } from '@material-ui/core'
import { FormControl, Input, InputLabel, InputAdornment, IconButton, Select } from '@material-ui/core'
import { CircularProgress } from '@material-ui/core'
import SanitizedHTML from 'react-sanitized-html';
import FWDModalServicio from 'app/componentes/FWDModalServicio'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SearchIcon from '@material-ui/icons/Search';
import axios from 'axios'
import Skeleton from '@material-ui/lab/Skeleton';
import { LoaderDashboard, LoaderDashboardTables } from 'app/componentes/dashboard/LoaderDashboard'

function TimelineSeguimientos({ height = 500, arrayCliente = [], fechaDashboard, clienteComercial }) {
    const [bitFetchSeguimientos, setbitFetchSeguimientos] = useState(true)
    const [bitVerMasSeguimientos, setBitVerMasSeguimientos] = useState(false)
    const [modalServicio, setModalServicio] = useState({ dblPedido: null, open: false })
    // Paleta estatus seguimientos
    const paletaEstatusSeguimientos = [
        {
            id: 1,
            estatus: 'Confirmado',
            color: '#F94144'
        },
        {
            id: 2,
            estatus: 'En transito a origen',
            color: '#F3722C'
        },
        {
            id: 3,
            estatus: 'En origen',
            color: '#F8961E'
        },
        {
            id: 4,
            estatus: 'Cargando',
            color: '#F9844A'
        },
        {
            id: 7,
            estatus: 'En transito a destino',
            color: '#F9C74F'
        },
        {
            id: 11,
            estatus: 'Pendiente de cruce',
            color: '#577590'
        },
        {
            id: 12,
            estatus: 'En cruce',
            color: '#277DA1'
        },
        {
            id: 8,
            estatus: 'En destino',
            color: '#90BE6D'
        },
        {
            id: 9,
            estatus: 'Descargando',
            color: '#43AA8B'
        },
        {
            id: 5,
            estatus: 'Entregado',
            color: '#00e396'
        },
        {
            id: 10,
            estatus: 'Incidencia',
            color: '#4D908E'
        },
    ]

    const dateTimeHoy = new Date();
    const datFechaHoy = `${dateTimeHoy.getDate()}/${dateTimeHoy.getMonth() + 1}/${dateTimeHoy.getFullYear()}`;
    const { session, selectedClienteComercial } = useSelector(state => state.auth);

    const [limitSeguimientos, setLimitSeguimientos] = useState(20)
    const [txtBusquedaTimelineSeguimientos, setTxtBusquedaTimelineSeguimientos] = useState('')
    const [selectEstatus, setSelectEstatus] = useState(/*paletaEstatusSeguimientos.map(x => x.id)*/[])
    const [seguimientos, setSeguimientos] = useState([])

    const fetchSeguimientosAction = (addLimit = 0) => {
        return new Promise(async (resolve, reject) => {
            try {
                let apiURL = `${process.env.REACT_APP_AWS_API_BASE_URL}/portal/dashboard/api/seguimientos?`
                apiURL += `varCliente=${clienteComercial}`
                apiURL += `&limit=${limitSeguimientos + addLimit}`
                if (selectEstatus.length)
                    apiURL += "&" + selectEstatus.map(x => "estatus=" + x).join('&')
                if (txtBusquedaTimelineSeguimientos.length)
                    apiURL += '&txt=' + txtBusquedaTimelineSeguimientos
                const response = await axios.get(apiURL)
                setSeguimientos(response?.data?.seguimientos)
                setLimitSeguimientos(limitSeguimientos + addLimit)
                resolve()
            }
            catch (error) {
                reject(error)
            }
        })
    }
    const fetchSeguimientos = async () => {
        try {
            setbitFetchSeguimientos(true)
            await fetchSeguimientosAction()
        }
        catch (error) {
            console.log('ERROR | fetchSeguimientos ', error);
        }
        finally {
            setbitFetchSeguimientos(false)
        }
    }

    const verMasSeguimientos = async () => {
        try {
            setBitVerMasSeguimientos(true)
            await fetchSeguimientosAction(15)
        }
        catch (error) {
            console.log('ERROR | fetchSeguimientos ', error);
        }
        finally {
            setBitVerMasSeguimientos(false)
        }
    }

    useEffect(() => {
        // BUG: App crashea... se incluye bloque try/catch para encontrar bug.
        // Nota 1: BUG que mataba app no se ha vuelto a presentar...
        // Nota 2: No eliminar bloque try/catch... 
        try {
            fetchSeguimientos()
        }
        catch (error) { console.log('TMSGMNTOSERR', error) }
    }, [clienteComercial])


    const RenderSeguimientoDummy = () => (
        <div className="timeline-item align-items-start">
            <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">

            </div>

            <div className="timeline-badge">
                <Tooltip>
                    <i className={`fa fa-genderless icon-xl`}></i>
                </Tooltip>
            </div>

            <div className="timeline-content d-flex">
                <Link className="cursor-hover"
                    variant="subtitle2"
                    component="p"
                    color="secondary"
                    style={{ paddingTop: 2 }}
                >

                </Link>
                <span style={{ marginLeft: 5, width: '90%' }}>
                    <Skeleton width="100%" animation="wave" />
                </span>
            </div>
        </div>
    )

    return (
        <>
            <FWDModalServicio bitOpen={modalServicio.open} dblPedido={modalServicio.dblPedido}
                fnClose={() => { setModalServicio({ open: false, dblPedido: null }) }}
            />
            {/* <div className={`card card-custom card-stretch gutter-b`}> */}
            <Card>
                <CardHeader title="Historial de seguimientos" />
                <CardContent>

                    <div className="card-body pt-4" style={{ display: 'flex', width: '100%' }}>
                        <FormControl style={{ flexGrow: 1 }}>
                            <InputLabel disabled={false}>No. servicio&nbsp;/&nbsp;Referencia</InputLabel>
                            <Input
                                disabled={bitFetchSeguimientos}
                                type='text'
                                value={txtBusquedaTimelineSeguimientos}
                                onChange={event => setTxtBusquedaTimelineSeguimientos(event.target.value)}
                                onKeyUp={e => {
                                    if (e.key === 'Enter')
                                        setLimitSeguimientos(20, fetchSeguimientos())
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        {txtBusquedaTimelineSeguimientos.length > 0
                                            ? <IconButton
                                                disabled={false}
                                                aria-label="search services"
                                                onClick={(event) => {
                                                    setTxtBusquedaTimelineSeguimientos('')
                                                }}
                                            >
                                                <HighlightOffIcon />
                                            </IconButton>
                                            : <></>}
                                        <IconButton aria-label="search" style={{ width: '50px' }}
                                            disabled={bitFetchSeguimientos}
                                            onClick={e => setLimitSeguimientos(20, fetchSeguimientos())}
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <FormControl style={{ flexBasis: 140, flexShrink: 5 }}>
                            <InputLabel htmlFor="age-native-simple">Estatus</InputLabel>
                            <Select
                                disabled={bitFetchSeguimientos}
                                multiple
                                value={selectEstatus}
                                onChange={event => setSelectEstatus(event.target.value)}
                                input={<Input />}
                                MenuProps={{
                                    getContentAnchorEl: () => null,
                                }}
                                renderValue={(selected) => {
                                    const txt = selected.length === paletaEstatusSeguimientos.length
                                        ? "Todos"
                                        : selected.length === 1
                                            ? selected.length.toString() + " seleccionado"
                                            : selected.length.toString() + " seleccionados"
                                    return txt
                                }}
                                onClose={e => setLimitSeguimientos(20, fetchSeguimientos())}
                            >
                                <MenuItem disabled>
                                    <section style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ marginRight: 5 }} >
                                            <Tooltip title="cerrar">
                                                <i className={`fa fa-info-circle`} color="info"></i>
                                            </Tooltip>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <em>Para cerrar haz click afuera</em>
                                            <em>o presiona ESC</em>
                                        </div>
                                    </section>
                                </MenuItem>
                                {
                                    paletaEstatusSeguimientos?.map(x => (
                                        <MenuItem key={x.id} value={x.id}>
                                            <section style={{ display: 'flex', alignItems: 'center' }}>
                                                <Tooltip title={x.estatus}>
                                                    <i className={`fa fa-genderless icon-xl`} style={{ color: x.color }}></i>
                                                </Tooltip>
                                                <span style={{ marginLeft: 5 }}>{x.estatus}</span>
                                            </section>
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </div>

                    {/* Body */}
                    <div className="card-body pt-4" style={{ height: height, overflowY: 'scroll' }}>
                        <div className="timeline timeline-6 mt-3">
                            {
                                bitFetchSeguimientos
                                    ? <>
                                        <LoaderDashboardTables/>
                                    </>
                                    :
                                    seguimientos?.map((seguimiento, indexDate) => (
                                        <div key={`timeline-span-${indexDate}`}>

                                        {
                                            seguimiento 
                                            ?
                                                <div className="timeline-item align-items-start" style={{display: 'block', marginBottom: '0px', marginTop: '1.7rem'}}>
                                                    <span  style={{ marginLeft: 60, fontWeight: 'bold', display: 'block' }}>{datFechaHoy === seguimiento[0].datFechaAlta ? 'Hoy' : seguimiento[0].datFechaAlta}</span>
                                                    <Divider style={{display: 'block'}}/>
                                                </div>
                                            :
                                            <></>
                                        }

                                        { 
                                            seguimiento?.map((s, i) => (

                                                <div className="timeline-item align-items-start" key={`timeline-${i}`}>
                                                    <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                                                        {s.datHoraAlta}
                                                    </div>

                                                    <div className="timeline-badge">
                                                        <Tooltip title={s.strEstatusPedido || "Desconocido"}>
                                                            <i className={`fa fa-genderless icon-xl`} style={{ color: paletaEstatusSeguimientos.find(x => x.id === s.intEstatusPedido)?.color }}></i>
                                                        </Tooltip>
                                                    </div>

                                                    <div className="timeline-content d-flex">
                                                        <Link className="cursor-hover"
                                                            variant="subtitle2"
                                                            component="p"
                                                            color="secondary"
                                                            style={{ paddingTop: 2 }}
                                                            onClick={() => setModalServicio({ dblPedido: s.dblPedido, open: true })}>
                                                            {s.strNoReferencia
                                                                ? <>{s.strNumeroServicio}&nbsp;/&nbsp;{s.strNoReferencia}</>
                                                                : <>{s.strNumeroServicio}</>
                                                            }
                                                        </Link>
                                                        <span style={{ marginLeft: 5 }}>
                                                            <SanitizedHTML
                                                                allowedTags={['strong']}
                                                                html={s.strSeguimiento}
                                                            />
                                                        </span>
                                                        {/* <span className="font-weight-bolder text-dark-75 pl-3 font-size-lg">{seguimiento.strNumeroServicio}</span> {seguimiento.strSeguimiento} */}
                                                    </div>
                                                </div>

                                            ))
                                        }

                                        </div>

                                    ))
                            }

                            <div className="timeline-item align-items-start">
                                <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">

                                </div>

                                <div className="timeline-badge">
                                    {bitVerMasSeguimientos
                                        ? <i className="fa fa-genderless icon-xl animation-pulse animation-shadow-circle" style={{ color: '#28a745' }}></i>
                                        : <i className="fa fa-genderless icon-xl"></i>}

                                </div>

                                <div className="font-weight-mormal font-size-lg timeline-content text-muted pl-3 mt-5">
                                    <Link className={`cursor-hover ${bitVerMasSeguimientos ? 'disabled-link' : ''}`} 
                                        variant="overline" 
                                        component="p"
                                        onClick={() => {
                                            verMasSeguimientos()
                                        }}>ver mas</Link>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* </div> */}
                </CardContent>
            </Card>
        </>
    );
}

export default injectIntl(TimelineSeguimientos)