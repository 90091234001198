/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";

export function QuickUserTogglerMobile() {
  const { user } = useSelector(state => state.auth);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
    };
  }, [uiService]);

  // console.log(user);

  return (<>
    {layoutProps.offcanvas && (<OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id="quick-user-tooltip">Perfil</Tooltip>}
    >
      <div className="topbar-item">
        <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
          id="kt_quick_user_mobile_toggle">
          <>

            {/* <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span> */}
            {/* <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
              {user.attributes ? user.attributes['email'] : ''}
            </span> */}
            <span className="svg-icon svg-icon-xl">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
          </>
        </div>
      </div>
    </OverlayTrigger>)
    }

    {/* { !layoutProps.offcanvas && (<UserProfileDropdown />)} */}
  </>
  );
}
