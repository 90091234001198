// Imports de ley
import axios from 'axios'
import { injectIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import React, { useEffect, useState, useReducer, useContext, useLayoutEffect } from 'react'

//Imports a componentes creados
import AutocompleteSolicitudes from 'app/componentes/Solicitudes/AutocompleteSolicitudes'
import CrearDireccionClienteModal from 'app/componentes/servicios/CrearDireccionClienteModal';
//Imports a necesidades
import {
  Grid,
  Card,
  Typography,
  CardContent,
  Input,
  FormControl,
  InputLabel,
  Paper,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  LinearProgress,
  InputAdornment,
  IconButton,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Zoom,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  FormLabel,
  TextField,
  StepContent,
  CircularProgress,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide
} from '@material-ui/core'
import { SH_formatCurrency, SH_FindInString } from 'app/helpers/stringHelper'
import { markerColors } from 'app/helpers/UIHelper'
import { debounce } from 'lodash'
import Skeleton from '@material-ui/lab/Skeleton'
import FWDModal from 'app/componentes/FWDModal'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useSnackbar } from "notistack";

//Iconos
import RoomIcon from '@material-ui/icons/Room'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import SearchIcon from '@material-ui/icons/Search'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import SettingsIcon from '@material-ui/icons/Settings'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import ViewModuleIcon from '@material-ui/icons/ViewModule'
import ViewListIcon from '@material-ui/icons/ViewList'
import Check from '@material-ui/icons/Check';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import WarningIcon from '@material-ui/icons/Warning';
import RefreshIcon from '@material-ui/icons/Refresh';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ScheduleIcon from '@material-ui/icons/Schedule';
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const _TarjetaFormularioSolicitudes = ({
  intl,
  cargandoPagina = false,
  setCargandoPagina = null,
  listaVarClientes = [],
  formularioSolicitud = {},
  setFormularioSolicitud = null,
  lstTipoProducto = [],
  lstUnidadMedida = [],
  setModalActivo = null,
  setGuardarDireccion = null,
  guardarDireccion = false,
  modalActivo = false,
  session = [],
  nombreComercial
}) => {
  const [indexError, setIndexError] = useState(-1);
  const [mensajeError, setMensajeError] = useState('');
  //Alerts Dialogs
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  //Catalogos
  const [lstDireccionesOrigen, setDireccionesOrigen] = useState([]);
  const [disabledDireccionesOrigen, setDisabledDireccionesOrigen] = useState(false);

  const [lstDireccionesDestino, setDireccionesDestino] = useState([]);
  const [disabledDireccionesDestino, setDisabledDireccionesDestino] = useState(false);

  const [lstDireccionesCliente, setDireccionesCliente] = useState([]); //Direcciones para los fletes de reparto y recolección

  const lstHoras = [
    '00:00', '00:30',
    '01:00', '01:30',
    '02:00', '02:30',
    '03:00', '03:30',
    '04:00', '04:30',
    '05:00', '05:30',
    '06:00', '06:30',
    '07:00', '07:30',
    '08:00', '08:30',
    '09:00', '09:30',
    '10:00', '10:30',
    '11:00', '11:30',
    '12:00', '12:30',
    '13:00', '13:30',
    '14:00', '14:30',
    '15:00', '15:30',
    '16:00', '16:30',
    '17:00', '17:30',
    '18:00', '18:30',
    '19:00', '19:30',
    '20:00', '20:30',
    '21:00', '21:30',
    '22:00', '22:30',
    '23:00', '23:30'
  ];

  const dateTimeHoy = new Date();
  const datFechaHoy = `${dateTimeHoy.getFullYear()}-${(dateTimeHoy.getMonth() < 10) ? '0' + (dateTimeHoy.getMonth() + 1) : dateTimeHoy.getMonth() + 1}-${dateTimeHoy.getDate()}`;
  // console.log(datFechaHoy);

  //Values forms
  //Extras
  const [varInfoMoneda, setInfoMoneda] = useState('');
  const [varInfoTipoProducto, setInfoTipoProducto] = useState('');
  const [varInfoUnidadMedida, setInfoUnidadMedida] = useState('');
  const [varMaxFechaDescarga, setMaxFechaDescarga] = useState('');
  const [varMaxFechaStop, setMaxFechaStop] = useState('');

  //Step 1
  const [varFormOrigen, setValueFormOrigen] = useState('');
  const [idFormOrigen, setIdFormOrigen] = useState(null);
  const [requiredOrigen, setRequiredOrigen] = useState(true);

  const [varFormDestino, setValueFormDestino] = useState('');
  const [idFormDestino, setIdFormDestino] = useState(null);
  const [requiredDestino, setRequiredDestino] = useState(true);

  const [varFormVehiculo, setValueFormVehiculo] = useState('');
  const [idFormVehiculo, setIdFormVehiculo] = useState(null);
  const [requiredVehiculo, setRequiredVehiculo] = useState(true);

  //step 2
  const [varFormCantidadSolicitar, setValueFormCantidadSolicitar] = useState(1);
  const [requiredCantidadSolicitar, setRequiredCantidadSolicitar] = useState(false);

  const [varFormReferencia, setValueFormReferencia] = useState('');
  // const [requiredReferencia, setRequiredReferencia] = useState(false);

  const [varFormHazmat, setValueFormHazmat] = useState(false);
  // const [requiredHazmat, setRequiredHazmat] = useState(false);

  const [varFormNoUN, setValueFormNoUN] = useState('');
  // const [requiredNoUN, setRequiredNoUN] = useState(false);

  const [varFormCheckTrasbordo, setValueFormCheckTrasbordo] = useState(false);
  // const [requiredCheckTrasbordo, setRequiredCheckTrasbordo] = useState(false);

  const [varFormTipoProducto, setValueFormTipoProducto] = useState('');
  const [requiredTipoProducto, setRequiredTipoProducto] = useState(true);

  const [varFormDescProducto, setValueFormDescProducto] = useState('');
  const [requiredDescProducto, setRequiredDescProducto] = useState(true);

  const [varFormPeso, setValueFormPeso] = useState('');
  const [requiredPeso, setRequiredPeso] = useState(true);

  const [varFormUnidadMedida, setValueFormUnidadMedida] = useState('');
  const [requiredUnidadMedida, setRequiredUnidadMedida] = useState(true);

  const [varFormNotas, setValueFormNotas] = useState('');
  // const [requiredNotas, setRequiredNotas] = useState(true);

  const [varFormCarga, setValueFormCarga] = useState('1');
  // const [requiredCarga, setRequiredCarga] = useState(true);

  const [varFormDescarga, setValueFormDescarga] = useState('1');
  // const [requiredDescarga, setRequiredDescarga] = useState(true);

  //Step 3
  const [varFormDireccionOrigen, setValueFormDireccionOrigen] = useState('');
  const [requiredDireccionOrigen, setRequiredDireccionOrigen] = useState(true);

  const [varFormFechaOrigen, setValueFormFechaOrigen] = useState('');
  const [requiredFechaOrigen, setRequiredFechaOrigen] = useState(true);

  const [varFormHoraOrigen, setValueFormHoraOrigen] = useState('');
  const [requiredHoraOrigen, setRequiredHoraOrigen] = useState(false);

  const [varFormDireccionDestino, setValueFormDireccionDestino] = useState('');
  const [requiredDireccionDestino, setRequiredDireccionDestino] = useState(false);

  const [varFormFechaDestino, setValueFormFechaDestino] = useState('');
  const [requiredFechaDestino, setRequiredFechaDestino] = useState(false);

  const [varFormHoraDestino, setValueFormHoraDestino] = useState('');
  const [requiredHoraDestino, setRequiredHoraDestino] = useState(false);

  const [varFormPuntoIntermedio, setValueFormPuntoIntermedio] = useState('26');
  const [varInfoPuntoIntermedio, setInfoPuntoIntermedio] = useState('Reparto');

  //Values forms

  // Steps
  //Styles
  const useQontoStepIconStyles = makeStyles({
    root: {
      color: '#eaeaf0',
      display: 'flex',
      height: 22,
      alignItems: 'center',
    },
    active: {
      color: '#3783e6',
    },
    circle: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
    completed: {
      color: '#1bc5bd',
      zIndex: 1,
      fontSize: 18,
    },
    error: {
      color: '#f44336',
      zIndex: 1,
      fontSize: 18,
    },
  });

  function QontoStepIcon(props, index) {
    const classes = useQontoStepIconStyles();
    const { active, completed, error } = props;

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        {
          completed
            ?
            <Check className={classes.completed} key={index} />
            :
            [
              error
                ?
                <WarningIcon className={classes.error} key={index} />
                :
                <div className={classes.circle} key={index} />
            ]
        }
      </div>
    );
  }

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  useEffect(() => {
    if (mensajeError !== '' && activeStep === 0) {
      activarAlerts();
    }
  }, [mensajeError]);

  //Events
  const handleNext = () => {
    // console.log('JSON Solicitud >> ',formularioSolicitud);
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    switch (activeStep) {
      case 0:
        get_validarTarifario();
        break;
      case 2:
        set_solicitudNecesidades();
        //Reiniciar datos y step
        //setActiveStep(0);
        //Su nombre lo dice todo :v
        // fn_limpiarFormulario();
        break;
      default:
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  //Contents
  function getSteps() {
    return [
      intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.PRIMER_PASO' }),
      intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.SEGUNDO_PASO' }),
      intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.TERCERO_PASO' })
    ];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.DLG.PRIMER_PASO' });
      case 1:
        return intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.DLG.SEGUNDO_PASO' });
      case 2:
        return intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.DLG.TERCERO_PASO' });
      default:
        return 'Unknown step';
    }
  }
  // Steps

  //Axios
  const get_validarTarifario = async () => {
    try {
      setCargandoPagina(true);
      let cliente = nombreComercial;//Falta traer del campo cliente >> solo si existe mas de una franquicia dentro del grupo
      let apiURL = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/servicios/solicitudes/api/validartarifario?';
      apiURL += `varCliente=${cliente}&`;
      apiURL += `varOrigen=${idFormOrigen}&`;
      apiURL += `varDestino=${idFormDestino}&`;
      apiURL += `varVehiculo=${idFormVehiculo}`;
      // console.log('>>',apiURL);
      if ((varFormOrigen).trim() !== '' && (varFormDestino).trim() !== '' && (varFormVehiculo).trim() !== '') {
        const response = await axios.get(apiURL);
        // console.log(response.data);
        if (response.data.tarifario) {
          //Guardar datos a insertar a la base de datos
          let solicitudTemp = { ...formularioSolicitud };
          solicitudTemp.varMoneda = response.data.tarifario.ID_MONEDA;
          solicitudTemp.varTarifa = response.data.tarifario.TARIFA;
          setFormularioSolicitud(solicitudTemp);
          //Guardar datos a insertar a la base de datos
          setInfoMoneda(response.data.tarifario.MONEDA);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setIndexError(-1);
          setMensajeError('');
        } else {
          // console.log('No hay tarifas para esta ruta y vehiculo');
          // setActiveStep((prevActiveStep) => prevActiveStep - 1);
          setIndexError(activeStep);
          setMensajeError(intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.MSG.ERROR_VALIDACION_TARIFARIO_RUTA' }));

        }

      } else {
        console.log('Nada');
      }
    } catch (err) {
      console.log(err);
    } finally {
      setCargandoPagina(false);
      activarAlerts();
    }
  }

  function activarAlerts() {
    console.log("activarAlerts: ", mensajeError, activeStep)
    if(activeStep === 0 && mensajeError !== ''){
      handleClickOpen()
    }
  }

  const get_direcciones = async (tipo, ciudad, selectAutocomplete = false) => {
    try {
      setCargandoPagina(true);
      let cliente = (listaVarClientes.length == 1) ? listaVarClientes[0] : '';//Falta traer del campo cliente >> solo si existe mas de una franquicia dentro del grupo
      let apiURL = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/servicios/solicitudes/api/catalogos?';
      apiURL += `strCatalogo=direccionentrega&`;
      apiURL += `strFiltro1=${cliente}&`;
      apiURL += `strFiltro2=${ciudad}&`;
      // console.log(apiURL);
      const response = await axios.get(apiURL);
      // console.log(response.data);
      switch (tipo) {
        case 'origen':
          // setDisabledDireccionesOrigen( (response.data.catalogos.length === 1) );
          setDireccionesOrigen(response.data.catalogos);
          if (response.data.catalogos.length === 1) {
            setValueFormDireccionOrigen(response.data.catalogos[0].VALUE_ID);
            // Guardar datos a insertar a la base de datos
            let solicitudTemp = { ...formularioSolicitud };
            if (selectAutocomplete) {
              solicitudTemp.varOrigen = ciudad;
            }
            solicitudTemp.varDireccionOrigen = (response.data.catalogos[0].VALUE_ID != '') ? response.data.catalogos[0].VALUE_ID : null;
            setFormularioSolicitud(solicitudTemp);
          }
          setRequiredDireccionOrigen((response.data.catalogos.length === 0 || (!varFormDireccionOrigen && response.data.catalogos.length > 1)));
          if (response.data.catalogos.length === 0) {
            setDireccionesOrigen([{
              VALUE_ID: '',
              VALUE_TEXT: intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.ERROR_DIRECCIONES_ENTREGA' })
            }]);
            setValueFormDireccionOrigen('');
            // Guardar datos a insertar a la base de datos
            let solicitudTemp = { ...formularioSolicitud };
            if (selectAutocomplete) {
              solicitudTemp.varOrigen = ciudad;
            }
            solicitudTemp.varDireccionOrigen = null;
            setFormularioSolicitud(solicitudTemp);
          }
          break;
        case 'destino':
          // setDisabledDireccionesDestino( (response.data.catalogos.length === 1) );
          setDireccionesDestino(response.data.catalogos);
          if (response.data.catalogos.length === 1) {
            setValueFormDireccionDestino(response.data.catalogos[0].VALUE_ID);
            // Guardar datos a insertar a la base de datos
            let solicitudTemp = { ...formularioSolicitud };
            if (selectAutocomplete) {
              solicitudTemp.varDestino = ciudad;
            }
            solicitudTemp.varDireccionDestino = (response.data.catalogos[0].VALUE_ID != '') ? response.data.catalogos[0].VALUE_ID : null;
            setFormularioSolicitud(solicitudTemp);
          }
          // setRequiredDireccionDestino( (response.data.catalogos.length === 0 || (!varFormDireccionDestino && response.data.catalogos.length > 1)) );
          if (response.data.catalogos.length === 0) {
            setDireccionesDestino([{
              VALUE_ID: '',
              VALUE_TEXT: intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.ERROR_DIRECCIONES_ENTREGA' })
            }]);
            setValueFormDireccionDestino('');
            // Guardar datos a insertar a la base de datos
            let solicitudTemp = { ...formularioSolicitud };
            if (selectAutocomplete) {
              solicitudTemp.varDestino = ciudad;
            }
            solicitudTemp.varDireccionDestino = null;
            setFormularioSolicitud(solicitudTemp);
          }
          break;
        default:
          //Todas las direcciones del cliente
          setDireccionesCliente(response.data.catalogos);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setCargandoPagina(false);
    }
  }

  const set_solicitudNecesidades = async () => {
    try {
      setCargandoPagina(true);
      const payload = formularioSolicitud;
      let cliente = (listaVarClientes.length == 1) ? listaVarClientes[0] : '';//Falta traer del campo cliente >> solo si existe mas de una franquicia dentro del grupo
      let apiURL = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/servicios/solicitudes/api/registrarsolicitud?';
      apiURL += `varCliente=${cliente}&`;
      const response = await axios.post(apiURL, payload);
      set_notificacionSolicitudNecesidad();
      // console.log(response);
    } catch (err) {
      console.log(err);
      setCargandoPagina(false);
      enqueueSnackbar(intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.MSG.ERROR_NUEVASOLICITUDREPORTADA' }),
        {
          preventDuplicate: true,
          autoHideDuration: 2500,
          variant: "error",
        }
      );
    } finally {
      // setCargandoPagina(false);
    }
  }

  const set_notificacionSolicitudNecesidad = async () => {
    try {
      // setCargandoPagina(true);
      let cliente = (listaVarClientes.length == 1) ? listaVarClientes[0] : '';//Falta traer del campo cliente >> solo si existe mas de una franquicia dentro del grupo
      let apiURL = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/servicios/solicitudes/api/notificacionnecesidad?';
      apiURL += `hdnCliente=${cliente}&`;
      apiURL += `hdnProceso=solicitud-necesidad&`;
      const response = await axios.get(apiURL);
      enqueueSnackbar(intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.MSG.NUEVASOLICITUDREPORTADA' }),
        {
          preventDuplicate: true,
          autoHideDuration: 3500,
          variant: "success",
        }
      );
      // console.log('Notificacion >> ',response);
      //Su nombre lo dice todo :v
      fn_limpiarFormulario();
      setActiveStep(0);
      setTimeout(() => {
        get_predeterminados(null, true);
      }, 1500);
    } catch (err) {
      console.log(err);
      enqueueSnackbar(intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.MSG.ERROR_NOTIFICACION_NUEVASOLICITUDREPORTADA' }),
        {
          preventDuplicate: true,
          autoHideDuration: 3500,
          variant: "error",
        }
      );
    } finally {
      setCargandoPagina(false);
    }
  }

  const get_predeterminados = async (session = null, bitNuevoFormulario = false) => {
    try {
      setCargandoPagina(true);
      let cliente = (listaVarClientes.length == 1) ? listaVarClientes[0] : '';//Falta traer del campo cliente >> solo si existe mas de una franquicia dentro del grupo
      let apiURL = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/servicios/solicitudes/api/catalogos?';
      apiURL += 'strCatalogo=predeterminados&';
      apiURL += `strFiltro1=${cliente}&`;
      const response = await axios.get(apiURL);
      //Asignar datos predeterminados
      // console.log(response.data.catalogos[0]);
      if (response.data.catalogos.length) {
        const predeterminado = response.data.catalogos[0];
        let solicitudTemp = bitNuevoFormulario ? fn_obtenerNuevoFormulario() : { ...formularioSolicitud };
        if (predeterminado.TIPO_PRODUCTO) {
          setRequiredTipoProducto(false);
          setValueFormTipoProducto(predeterminado.TIPO_PRODUCTO);
          solicitudTemp.varTipoProducto = predeterminado.TIPO_PRODUCTO;
        }
        if (predeterminado.PRODUCTO) {
          setValueFormDescProducto(predeterminado.PRODUCTO);
          setRequiredDescProducto(false);
          solicitudTemp.varProducto = predeterminado.PRODUCTO;
        }
        if (predeterminado.PESO) {
          setValueFormPeso(predeterminado.PESO);
          setRequiredPeso(false);
          solicitudTemp.varPeso = predeterminado.PESO;
        }
        if (predeterminado.UNIDAD_MEDIDA) {
          setValueFormUnidadMedida(predeterminado.UNIDAD_MEDIDA);
          setRequiredUnidadMedida(false);
          solicitudTemp.varUnidadMedida = predeterminado.UNIDAD_MEDIDA;
        }
        setFormularioSolicitud(solicitudTemp);
      }
      // setDatosPredeterminados(response.data.catalogos[2][0]);
    } catch (err) {
      console.log(err);
    } finally {
      setCargandoPagina(false);
    }
  }
  //Axios

  //Funciones chidas
  const fn_limpiarFormulario = () => {
    //JSON Formulario
    setFormularioSolicitud(fn_obtenerNuevoFormulario());

    //Extras
    setInfoMoneda('');
    setInfoTipoProducto('');
    setInfoUnidadMedida('');

    //Step 1
    setValueFormOrigen('');
    setIdFormOrigen(null);
    setRequiredOrigen(true);

    setValueFormDestino('');
    setIdFormDestino(null);
    setRequiredDestino(true);

    setValueFormVehiculo('');
    setIdFormVehiculo(null);
    setRequiredVehiculo(true);

    //step 2
    setValueFormCantidadSolicitar(1);
    setRequiredCantidadSolicitar(false);

    setValueFormHazmat(false);

    setValueFormNoUN('');

    setValueFormCheckTrasbordo(false);

    setValueFormTipoProducto('');
    setRequiredTipoProducto(true);

    setValueFormDescProducto('');
    setRequiredDescProducto(true);

    setValueFormPeso('');
    setRequiredPeso(true);

    setValueFormUnidadMedida('');
    setRequiredUnidadMedida(true);

    setValueFormNotas('');

    setValueFormCarga('1');

    setValueFormDescarga('1');

    //Step 3
    setValueFormDireccionOrigen('');
    setRequiredDireccionOrigen(true);

    setValueFormFechaOrigen('');
    setRequiredFechaOrigen(true);

    setValueFormHoraOrigen('');
    setRequiredHoraOrigen(false);

    setValueFormDireccionDestino('');
    setRequiredDireccionDestino(false);

    setValueFormFechaDestino('');
    setRequiredFechaDestino(false);

    setValueFormHoraDestino('');
    setRequiredHoraDestino(false);

    setValueFormPuntoIntermedio('26');
    setInfoPuntoIntermedio('Reparto');
  }

  const fn_obtenerNuevoFormulario = () => {
    return {
      //Step 1
      varOrigen: null,
      varDestino: null,
      varVehiculo: null,
      varUsuario: session.strUsuario,
      varTarifa: null,
      varMoneda: null,
      //Step 2
      varCantidad: '1',
      varHazmat: 0,
      varHazmatUN: null,
      varTrasbordo: 0,
      varTipoProducto: null,
      varProducto: null,
      varPeso: null,
      varUnidadMedida: null,
      varNota: null,
      varCarga: "1",
      varDescarga: "1",
      varReferencia: null,
      //step 3
      varDireccionOrigen: null,
      varFechaOrigen: null,
      varHoraOrigen: null,
      varDireccionDestino: null,
      varFechaDestino: null,
      varHoraDestino: null,
      varIntermedios: [
        //Fletes de reparto - recoleccion
        /* Estructura para los datos correspondientes
          {
            varFleteID : null,
            varFleteText : null,
            varDireccionID : null,
            varFechaIntermedio : null,
            varHoraIntermedio : null,
            varRequired : true
          }
        */
      ]
    }
  }

  const validCodes = (key, erasable = false) => {
    const keys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    if (erasable) keys.push('Backspace')
    return keys.indexOf(key) !== -1
  }
  //Funciones chidas

  //useEffect
  useEffect(() => {
    if (guardarDireccion) {
      // console.log('Actualizar direcciones >>> ',guardarDireccion);
      get_direcciones('origen', idFormOrigen);
      get_direcciones('destino', idFormDestino);
      get_direcciones('', '');
      setGuardarDireccion(false);
    }
  }, [modalActivo]);

  useEffect(() => {
    if (listaVarClientes.length) {
      get_predeterminados();
    }
  }, [listaVarClientes]);

  return <>
    <Grid item md={12}>
      <Paper style={{ marginBottom: '10px', padding: '10px' }} elevation={3}>
        <section style={{ display: 'flex', alignItems: 'center' }}>
          <section style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'flex-end' }}>
            <Grid container spacing={1}>
              <Grid item md={12} key={'Steps'}>
                <Stepper activeStep={activeStep}>
                  {steps.map((label, indexLabel) => (
                    <Step key={label}>
                      <StepLabel StepIconComponent={QontoStepIcon} style={{ fontWeight: '700' }} index={label} error={indexLabel === indexError}>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                {
                  (activeStep === 1)
                    ?
                    <Grid item md={12} key={'ruta-' + activeStep}>
                      <Grid container spacing={1} justify="center">
                        <Grid item md={9}>
                          {
                            (varFormOrigen && varFormDestino && varFormVehiculo && formularioSolicitud.varTarifa && varInfoMoneda)
                              ?
                              <Paper style={{ marginBottom: '10px', padding: '0' }} elevation={0}>
                                <CheckCircleIcon style={{ color: '#1bc5bd' }} /> De <b>{varFormOrigen}</b> a <b>{varFormDestino}</b> en <b>{varFormVehiculo}</b> a <b>{SH_formatCurrency(formularioSolicitud.varTarifa)} {varInfoMoneda} </b>
                              </Paper>
                              :
                              <CircularProgress style={{ width: '15px', height: '15px' }} />
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                    : [
                      (activeStep === 2)
                        ?
                        <Grid item md={12} key={'detalle-' + activeStep}>
                          <Grid container spacing={1} justify="center">
                            <Grid item md={9}>
                              {
                                (varFormTipoProducto && varFormDescProducto && varFormPeso && varFormUnidadMedida && varFormCantidadSolicitar)
                                  ?
                                  <div>
                                    <Paper style={{ marginBottom: '10px', padding: '0' }} elevation={0}>
                                      {
                                        (varFormReferencia.trim() != '')
                                          ?
                                          <p>
                                            <CheckCircleIcon style={{ color: '#1bc5bd' }} /> No. Referencia: <b>{varFormReferencia}</b>
                                          </p>
                                          :
                                          <></>
                                      }
                                      <p>
                                        <CheckCircleIcon style={{ color: '#1bc5bd' }} /> Se solicita <b>{varFormCantidadSolicitar}</b> {(varFormCantidadSolicitar == 1) ? 'servicio' : 'servicios'} <b>{(varFormHazmat && varFormCheckTrasbordo) ? 'con trasbordo y hazmat' : ''} {(varFormHazmat && !varFormCheckTrasbordo) ? 'con hazmat' : ''} {(!varFormHazmat && varFormCheckTrasbordo) ? 'con trasbordo' : ''} {(varFormNoUN) ? '# UN - ' + varFormNoUN : ''}</b>
                                      </p>
                                      <p><CheckCircleIcon style={{ color: '#1bc5bd' }} /> Para transportar <b>{varInfoTipoProducto}</b> - <b>{varFormDescProducto}</b> de <b>{varFormPeso} {varInfoUnidadMedida}</b></p>
                                      <p><CheckCircleIcon style={{ color: '#1bc5bd' }} /> Con carga <b>{(varFormCarga == "1") ? 'en vivo' : 'drop & hook'}</b> y descarga <b>{(varFormDescarga == "1") ? 'en vivo' : 'drop & hook'}</b></p>
                                      {
                                        (varFormNotas.trim() != '')
                                          ?
                                          <div>
                                            <p><CheckCircleIcon style={{ color: '#1bc5bd' }} /> Notas: </p>
                                            <p style={{ paddingLeft: '25px' }}><b>{varFormNotas}</b></p>
                                          </div>
                                          :
                                          <></>
                                      }
                                      <p><CheckCircleIcon style={{ color: '#1bc5bd' }} /> De <b>{varFormOrigen}</b> a <b>{varFormDestino}</b> en <b>{varFormVehiculo}</b> a <b>{SH_formatCurrency(formularioSolicitud.varTarifa)} {varInfoMoneda} </b></p>
                                    </Paper>
                                  </div>
                                  :
                                  <CircularProgress style={{ width: '15px', height: '15px' }} />
                              }
                            </Grid>
                          </Grid>
                        </Grid>
                        :
                        <span key={'nada-' + activeStep}></span>
                    ]
                }
                {
                  (activeStep === 0)
                    ?
                    <Grid item md={12} key={activeStep}>
                      <Grid container spacing={1} justify="center">
                        <Grid item md={3}>
                          <AutocompleteSolicitudes
                            lblAutocomplete="origen"
                            valueForm={varFormOrigen}
                            setValueForm={setValueFormOrigen}
                            requiredForm={requiredOrigen}
                            setRequiredForm={setRequiredOrigen}
                            cargandoPagina={cargandoPagina}
                            setIdForm={setIdFormOrigen}
                            getDirecciones={get_direcciones}
                            setFormSolicitud={setFormularioSolicitud}
                            formSolicitud={formularioSolicitud}
                            setErrorStep={setIndexError}
                            setMensajeError={setMensajeError}
                          ></AutocompleteSolicitudes>
                        </Grid>
                        <Grid item md={3}>
                          <AutocompleteSolicitudes
                            lblAutocomplete="destino"
                            valueForm={varFormDestino}
                            setValueForm={setValueFormDestino}
                            requiredForm={requiredDestino}
                            setRequiredForm={setRequiredDestino}
                            cargandoPagina={cargandoPagina}
                            setIdForm={setIdFormDestino}
                            getDirecciones={get_direcciones}
                            setFormSolicitud={setFormularioSolicitud}
                            formSolicitud={formularioSolicitud}
                            setErrorStep={setIndexError}
                            setMensajeError={setMensajeError}
                          ></AutocompleteSolicitudes>
                        </Grid>
                        <Grid item md={3}>
                          <AutocompleteSolicitudes
                            lblAutocomplete="vehiculo"
                            valueForm={varFormVehiculo}
                            setValueForm={setValueFormVehiculo}
                            requiredForm={requiredVehiculo}
                            setRequiredForm={setRequiredVehiculo}
                            cargandoPagina={cargandoPagina}
                            setIdForm={setIdFormVehiculo}
                            setFormSolicitud={setFormularioSolicitud}
                            formSolicitud={formularioSolicitud}
                            setErrorStep={setIndexError}
                            setMensajeError={setMensajeError}
                          ></AutocompleteSolicitudes>
                        </Grid>
                      </Grid>
                    </Grid>
                    :
                    [
                      (activeStep === 1)
                        ?
                        <Grid item md={12} key={activeStep}>
                          <Grid container spacing={1} justify="center">
                            {/* Cantidad - No. referencia - Hazmat (# UN) - Trasbordos */}
                            <Grid item md={9}>
                              <Grid container spacing={1} justify="flex-start">
                                <Grid item md={2}>
                                  <FormControl style={{ width: '70%', paddingRight: '5px' }} onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}>
                                    <TextField
                                      type='number'
                                      disabled={cargandoPagina}
                                      value={varFormCantidadSolicitar}
                                      error={requiredCantidadSolicitar}
                                      label={intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.FORM_CANTIDAD' })}
                                      onKeyDown={(e) => {
                                        if (!validCodes(e.key, true)) e.preventDefault()
                                      }}
                                      InputProps={{
                                        startAdornment: <InputAdornment position="start">Cant.</InputAdornment>,
                                      }}
                                      onChange={
                                        (event) => {
                                          setRequiredCantidadSolicitar(((event.target.value).trim() == '' || event.target.value <= 0))
                                          setValueFormCantidadSolicitar((parseFloat(event.target.value) > 30) ? 30 : parseFloat(event.target.value));
                                          // Guardar datos a insertar a la base de datos
                                          let solicitudTemp = { ...formularioSolicitud };
                                          solicitudTemp.varCantidad = ((event.target.value).trim() != '') ? event.target.value : null;
                                          setFormularioSolicitud(solicitudTemp);
                                        }
                                      }
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item md={2} style={{ display: 'flex' }}>
                                  <FormControl style={{ width: '80%', paddingRight: '5px' }} onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}>
                                    <InputLabel>{intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.FORM_REFERENCIA' })}</InputLabel>
                                    <Input
                                      type='text'
                                      disabled={cargandoPagina}
                                      value={varFormReferencia}
                                      onChange={
                                        (event) => {
                                          setValueFormReferencia(event.target.value);
                                          // Guardar datos a insertar a la base de datos
                                          let solicitudTemp = { ...formularioSolicitud };
                                          solicitudTemp.varReferencia = ((event.target.value).trim() != '') ? event.target.value : null;
                                          setFormularioSolicitud(solicitudTemp);
                                        }
                                      }
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item md={1} style={{ display: 'flex', justifyContent: 'center' }}>
                                  <FormControlLabel
                                    style={{ width: '35%', margin: '0', alignItems: 'flex-end', justifyContent: 'flex-end' }}
                                    control={
                                      <Checkbox checked={varFormCheckTrasbordo}
                                        name="checkTrasbordo"
                                        style={{ height: '0' }}
                                        disabled={cargandoPagina}
                                        onChange={(event) => {
                                          setValueFormCheckTrasbordo(event.target.checked);
                                          // Guardar datos a insertar a la base de datos
                                          let solicitudTemp = { ...formularioSolicitud };
                                          solicitudTemp.varTrasbordo = (event.target.checked) ? 1 : 0;
                                          setFormularioSolicitud(solicitudTemp);
                                        }}
                                      />
                                    }
                                    label={intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.FORM_TRASBORDO' })}
                                    labelPlacement="end"
                                  />
                                </Grid>
                                <Grid item md={3} style={{ display: 'flex' }}>
                                  <FormControlLabel
                                    style={{ width: '35%', margin: '0', alignItems: 'flex-end' }}
                                    control={
                                      <Checkbox checked={varFormHazmat}
                                        name="checkHazmat"
                                        style={{ height: '0', width: '0', marginRight: '10px' }}
                                        disabled={cargandoPagina}
                                        onChange={(event) => {
                                          setValueFormHazmat(event.target.checked);
                                          setValueFormNoUN('');
                                          // Guardar datos a insertar a la base de datos
                                          let solicitudTemp = { ...formularioSolicitud };
                                          solicitudTemp.varHazmat = (event.target.checked) ? 1 : 0;
                                          solicitudTemp.varHazmatUN = null;
                                          setFormularioSolicitud(solicitudTemp);
                                        }}
                                      />
                                    }
                                    label={intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.FORM_HAZMAT' })}
                                    labelPlacement="end"
                                  />
                                  {
                                    (varFormHazmat)
                                      ?
                                      <FormControl style={{ width: '65%', paddingRight: '5px' }} onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}>
                                        <InputLabel>{intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.FORM_NUM_UN' })}</InputLabel>
                                        <Input
                                          type='number'
                                          disabled={cargandoPagina}
                                          value={varFormNoUN}
                                          onKeyDown={(e) => {
                                            if (!validCodes(e.key, true)) e.preventDefault()
                                            if (e.target.value.length > 3) e.preventDefault()
                                          }}
                                          onChange={
                                            (event) => {
                                              setValueFormNoUN(event.target.value);
                                              // Guardar datos a insertar a la base de datos
                                              let solicitudTemp = { ...formularioSolicitud };
                                              solicitudTemp.varHazmatUN = ((event.target.value).trim() != '') ? event.target.value : null;
                                              setFormularioSolicitud(solicitudTemp);
                                            }
                                          }
                                        />
                                      </FormControl>
                                      :
                                      <></>
                                  }
                                </Grid>
                              </Grid>
                            </Grid>
                            {/* Tipo producto - descripcion producto - peso y unidad de medida */}
                            <Grid item md={12} style={{ marginTop: '15px' }}>
                              <Grid container spacing={1} justify="center">
                                <Grid item md={3}>
                                  <FormControl style={{ width: '100%', paddingRight: '5px' }} onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}>
                                    <InputLabel>{intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.FORM_TIPO_PRODUCTO' })}</InputLabel>
                                    <Select
                                      disabled={cargandoPagina}
                                      value={varFormTipoProducto}
                                      error={requiredTipoProducto}
                                      onChange={
                                        (event, child) => {
                                          // console.log('Evento select >> ', event, child);
                                          setInfoTipoProducto(child.props.children);
                                          setValueFormTipoProducto(event.target.value);
                                          setRequiredTipoProducto(false);
                                          // Guardar datos a insertar a la base de datos
                                          let solicitudTemp = { ...formularioSolicitud };
                                          solicitudTemp.varTipoProducto = (event.target.value != '') ? event.target.value : null;
                                          setFormularioSolicitud(solicitudTemp);
                                        }
                                      }
                                    >
                                      {
                                        lstTipoProducto.map((element, index) => (
                                          <MenuItem key={index} value={element.VALUE_ID}>{element.VALUE_TEXT}</MenuItem>
                                        ))
                                      }
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item md={3}>
                                  <FormControl style={{ width: '100%', paddingRight: '5px' }} onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}>
                                    <InputLabel>{intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.FORM_DESCRIPCION_PRODUCTO' })}</InputLabel>
                                    <Input
                                      type='text'
                                      disabled={cargandoPagina}
                                      value={varFormDescProducto}
                                      error={requiredDescProducto}
                                      onChange={
                                        (event) => {
                                          setValueFormDescProducto(event.target.value);
                                          setRequiredDescProducto(((event.target.value).trim() == ''));
                                          // Guardar datos a insertar a la base de datos
                                          let solicitudTemp = { ...formularioSolicitud };
                                          solicitudTemp.varProducto = ((event.target.value).trim() != '') ? event.target.value : null;
                                          setFormularioSolicitud(solicitudTemp);
                                        }
                                      }
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item md={3}>
                                  <FormControl style={{ width: '30%', paddingRight: '5px' }} onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}>
                                    <InputLabel>{intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.FORM_PESO' })}</InputLabel>
                                    <Input
                                      type='number'
                                      disabled={cargandoPagina}
                                      value={varFormPeso}
                                      error={requiredPeso}
                                      onKeyDown={(e) => {
                                        if (!validCodes(e.key, true)) e.preventDefault()
                                      }}
                                      onChange={
                                        (event) => {
                                          setValueFormPeso(event.target.value);
                                          setRequiredPeso(((event.target.value).trim() == '' || event.target.value <= 0));
                                          // Guardar datos a insertar a la base de datos
                                          let solicitudTemp = { ...formularioSolicitud };
                                          solicitudTemp.varPeso = ((event.target.value).trim() != '') ? event.target.value : null;
                                          setFormularioSolicitud(solicitudTemp);
                                        }
                                      }
                                    />
                                  </FormControl>
                                  <FormControl style={{ width: '70%', paddingRight: '5px' }} onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}>
                                    <InputLabel>{intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.FORM_UNIDAD_MEDIDA' })}</InputLabel>
                                    <Select
                                      disabled={cargandoPagina}
                                      value={varFormUnidadMedida}
                                      error={requiredUnidadMedida}
                                      onChange={
                                        (event, child) => {
                                          // console.log('Evento select >> ', event, child);
                                          setInfoUnidadMedida(child.props.children);
                                          setValueFormUnidadMedida(event.target.value);
                                          setRequiredUnidadMedida(false);
                                          // Guardar datos a insertar a la base de datos
                                          let solicitudTemp = { ...formularioSolicitud };
                                          solicitudTemp.varUnidadMedida = (event.target.value != '') ? event.target.value : null;
                                          setFormularioSolicitud(solicitudTemp);
                                        }
                                      }
                                    >
                                      {
                                        lstUnidadMedida.map((element, index) => (
                                          <MenuItem key={index} value={element.VALUE_ID}>{element.VALUE_TEXT}</MenuItem>
                                        ))
                                      }
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                            {/* Notas */}
                            <Grid item md={9} style={{ marginTop: '10px' }}>
                              <Grid container spacing={1} justify="flex-start">
                                <Grid item md={12}>
                                  <FormControl style={{ width: '100%', paddingRight: '5px' }} onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}>
                                    <TextField
                                      label={intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.FORM_NOTAS' })}
                                      multiline
                                      rowsMax={6}
                                      value={varFormNotas}
                                      disabled={cargandoPagina}
                                      onChange={(event) => {
                                        setValueFormNotas(event.target.value);
                                        // Guardar datos a insertar a la base de datos
                                        let solicitudTemp = { ...formularioSolicitud };
                                        solicitudTemp.varNota = ((event.target.value).trim() != '') ? event.target.value : null;
                                        setFormularioSolicitud(solicitudTemp);
                                      }}
                                      placeholder={intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.FORM_PLACEHOLDER_NOTAS' })}
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                            {/* Carga > Descarga */}
                            <Grid item md={9} style={{ marginTop: '30px' }}>
                              <Grid container spacing={1} justify="center">
                                <Grid item md={4}>
                                  <FormControl component="fieldset" style={{ textAlign: 'center' }}>
                                    <FormLabel component="legend">{intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.FORM_CARGA' })}</FormLabel>
                                    <RadioGroup
                                      name="carga"
                                      value={varFormCarga}
                                      style={{ display: 'inline', width: '' }}
                                      disabled={cargandoPagina}
                                      onChange={(event, value) => {
                                        // console.log(props, event);
                                        setValueFormCarga(value);
                                        // Guardar datos a insertar a la base de datos
                                        let solicitudTemp = { ...formularioSolicitud };
                                        solicitudTemp.varCarga = ((value).trim() != '') ? value : null;
                                        setFormularioSolicitud(solicitudTemp);
                                      }}
                                    >
                                      <FormControlLabel value="1" control={<Radio />} label="En vivo" />
                                      <FormControlLabel value="4" control={<Radio />} label="Drop & Hook" />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                                <Grid item md={4}>
                                  <FormControl component="fieldset" style={{ textAlign: 'center' }}>
                                    <FormLabel component="legend">{intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.FORM_DESCARGA' })}</FormLabel>
                                    <RadioGroup
                                      name="descarga"
                                      value={varFormDescarga}
                                      disabled={cargandoPagina}
                                      style={{ display: 'inline', width: '' }}
                                      onChange={(event, value) => {
                                        // console.log(event);
                                        setValueFormDescarga(value);
                                        // Guardar datos a insertar a la base de datos
                                        let solicitudTemp = { ...formularioSolicitud };
                                        solicitudTemp.varDescarga = ((value).trim() != '') ? value : null;
                                        setFormularioSolicitud(solicitudTemp);
                                      }}
                                    >
                                      <FormControlLabel value="1" control={<Radio />} label="En vivo" />
                                      <FormControlLabel value="4" control={<Radio />} label="Drop & Hook" />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        :
                        [
                          (activeStep === 2)
                            ?
                            <Grid item md={12} key={activeStep}>
                              <Grid container spacing={1} justify="center">
                                {/* Datos de viaje Origen */}
                                <Grid item md={6}>
                                  <Grid container spacing={1} justify="flex-end">
                                    <Grid item md={9}>
                                      <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                                        <FormControl style={{ width: '100%', paddingRight: '5px' }} onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}>
                                          <InputLabel>{intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.FORM_CITA_ORIGEN' })}</InputLabel>
                                          <Select
                                            disabled={cargandoPagina /*|| disabledDireccionesOrigen*/}
                                            value={varFormDireccionOrigen}
                                            error={requiredDireccionOrigen}
                                            onChange={
                                              (event, child) => {
                                                // console.log('Evento select >> ', event, child);
                                                setValueFormDireccionOrigen(event.target.value);
                                                setRequiredDireccionOrigen(false);
                                                let solicitudTemp = { ...formularioSolicitud };
                                                solicitudTemp.varDireccionOrigen = event.target.value;
                                                setFormularioSolicitud(solicitudTemp);
                                              }
                                            }
                                          >
                                            {
                                              lstDireccionesOrigen.map((element, index) => (
                                                <MenuItem value={element.VALUE_ID} key={'Carga-' + index}>{element.VALUE_TEXT}</MenuItem>
                                              ))
                                            }
                                          </Select>
                                        </FormControl>
                                        <Tooltip TransitionComponent={Zoom} title={intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.TOOLTIP_REFRESH_ORIGEN' })} disableFocusListener disableTouchListener placement="top">
                                          <IconButton
                                            aria-label="refresh"
                                            color="secondary"
                                            style={{ height: '0', width: '0' }}
                                            // disabled={cargandoPagina}
                                            onClick={() => {
                                              // get_direcciones('origen', idFormOrigen);
                                              setModalActivo(true);
                                            }}
                                          >
                                            <AddLocationIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </div>
                                    </Grid>
                                    <Grid item md={5}>
                                      <FormControl style={{ width: '100%', paddingRight: '5px' }} onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}>
                                        <TextField
                                          id="date"
                                          label={intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.FORM_FECHA_ORIGEN' })}
                                          type="date"
                                          // defaultValue="2017-05-24"
                                          inputProps={{
                                            min: datFechaHoy
                                          }}
                                          value={varFormFechaOrigen}
                                          error={requiredFechaOrigen}
                                          disabled={cargandoPagina}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          onChange={(event) => {
                                            setValueFormFechaOrigen(event.target.value);
                                            setRequiredFechaOrigen(((event.target.value).trim() == ''));
                                            let solicitudTemp = { ...formularioSolicitud };
                                            solicitudTemp.varFechaOrigen = event.target.value;
                                            setFormularioSolicitud(solicitudTemp);
                                          }}
                                        />
                                      </FormControl>
                                    </Grid>
                                    <Grid item md={4}>
                                      <FormControl style={{ width: '100%', paddingRight: '5px' }} onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}>
                                        <InputLabel>{intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.FORM_HORA_ORIGEN' })}</InputLabel>
                                        <Select
                                          value={varFormHoraOrigen}
                                          error={requiredHoraOrigen}
                                          disabled={cargandoPagina}
                                          onChange={(event) => {
                                            setValueFormHoraOrigen(event.target.value);
                                            let solicitudTemp = { ...formularioSolicitud };
                                            solicitudTemp.varHoraOrigen = event.target.value;
                                            setFormularioSolicitud(solicitudTemp);
                                            // setRequiredHoraOrigen( ((event.target.value).trim() == '') );
                                          }}
                                          startAdornment={
                                            <InputAdornment position="start">
                                              <ScheduleIcon />
                                            </InputAdornment>
                                          }
                                        >
                                          <MenuItem value="" key={'HoraOrigen-100'}>Niguno</MenuItem>
                                          {
                                            lstHoras.map((element, index) => (
                                              <MenuItem value={element} key={'HoraOrigen-' + index}>{element}</MenuItem>
                                            ))
                                          }
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                {/* Datos de viaje Destino */}
                                <Grid item md={6}>
                                  <Grid container spacing={1} justify="center">
                                    <Grid item md={9}>
                                      <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                                        <FormControl style={{ width: '100%', paddingRight: '5px' }} onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}>
                                          <InputLabel>{intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.FORM_CITA_DESTINO' })}</InputLabel>
                                          <Select
                                            disabled={cargandoPagina || disabledDireccionesDestino}
                                            value={varFormDireccionDestino}
                                            error={requiredDireccionDestino}
                                            onChange={
                                              (event, child) => {
                                                // console.log('Evento select >> ', event, child);
                                                setValueFormDireccionDestino(event.target.value);
                                                // setRequiredDireccionDestino(false);
                                                let solicitudTemp = { ...formularioSolicitud };
                                                solicitudTemp.varDireccionDestino = event.target.value;
                                                setFormularioSolicitud(solicitudTemp);
                                              }
                                            }
                                          >
                                            <MenuItem value="" key={'Descarga-1000'}>Niguno</MenuItem>
                                            {
                                              lstDireccionesDestino.map((element, index) => (
                                                <MenuItem value={element.VALUE_ID} key={'Descarga-' + index}>{element.VALUE_TEXT}</MenuItem>
                                              ))
                                            }
                                          </Select>
                                        </FormControl>
                                        <Tooltip TransitionComponent={Zoom} title={intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.TOOLTIP_REFRESH_DESTINO' })} disableFocusListener disableTouchListener placement="top">
                                          <IconButton
                                            aria-label="refresh"
                                            color="secondary"
                                            style={{ height: '0', width: '0' }}
                                            // disabled={cargandoPagina}
                                            onClick={() => {
                                              // get_direcciones('destino', idFormDestino);
                                              setModalActivo(true);
                                            }}
                                          >
                                            <AddLocationIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </div>
                                    </Grid>
                                    <Grid item md={5}>
                                      <FormControl style={{ width: '100%', paddingRight: '5px' }} onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}>
                                        <TextField
                                          id="date"
                                          label={intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.FORM_FECHA_DESTINO' })}
                                          type="date"
                                          value={varFormFechaDestino}
                                          error={requiredFechaDestino}
                                          disabled={cargandoPagina}
                                          // defaultValue="2017-05-24"
                                          inputProps={{
                                            min: datFechaHoy,
                                            max: varMaxFechaDescarga
                                          }}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          onChange={(event) => {
                                            setValueFormFechaDestino(event.target.value);
                                            // setRequiredFechaDestino( ((event.target.value).trim() == '') );
                                            let solicitudTemp = { ...formularioSolicitud };
                                            solicitudTemp.varFechaDestino = event.target.value;
                                            formularioSolicitud.varIntermedios.map((element, index) => {
                                              solicitudTemp.varIntermedios[index].varFechaIntermedio = "";
                                            });
                                            setFormularioSolicitud(solicitudTemp);
                                            if ((event.target.value).trim() == '') {
                                              setMaxFechaStop('');
                                            } else {
                                              const dateTimeHoy = new Date(event.target.value);
                                              const fechaRegistrada = `${dateTimeHoy.getFullYear()}-${(dateTimeHoy.getMonth() < 10) ? '0' + (dateTimeHoy.getMonth() + 1) : dateTimeHoy.getMonth() + 1}-${dateTimeHoy.getDate()}`;
                                              setMaxFechaStop(fechaRegistrada);
                                            }
                                          }}
                                        />
                                      </FormControl>
                                    </Grid>
                                    <Grid item md={4}>
                                      <FormControl style={{ width: '100%', paddingRight: '5px' }} onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}>
                                        <InputLabel>{intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.FORM_HORA_DESTINO' })}</InputLabel>
                                        <Select
                                          value={varFormHoraDestino}
                                          error={requiredHoraDestino}
                                          disabled={cargandoPagina}
                                          onChange={(event) => {
                                            setValueFormHoraDestino(event.target.value);
                                            // setRequiredHoraDestino( ((event.target.value).trim() == '') );
                                            let solicitudTemp = { ...formularioSolicitud };
                                            solicitudTemp.varHoraDestino = event.target.value;
                                            setFormularioSolicitud(solicitudTemp);
                                          }}
                                          startAdornment={
                                            <InputAdornment position="start">
                                              <ScheduleIcon />
                                            </InputAdornment>
                                          }
                                        >
                                          <MenuItem value="" key={'HoraDestino-100'}>Niguno</MenuItem>
                                          {
                                            lstHoras.map((element, index) => (
                                              <MenuItem value={element} key={'HoraDestino-' + index}>{element}</MenuItem>
                                            ))
                                          }
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                {/* Select Flete de reparto - recolección */}
                                <Grid item md={12} style={{ marginTop: '20px' }}>
                                  <Grid container spacing={1} justify={'center'}>
                                    <Grid item md={2}>
                                      <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                                        <FormControl style={{ width: '100%', paddingRight: '5px' }} onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}>
                                          <InputLabel>{intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.FORM_PUNTO_INTERMEDIO' })}</InputLabel>
                                          <Select
                                            disabled={cargandoPagina}
                                            value={varFormPuntoIntermedio}
                                            onChange={
                                              (event, child) => {
                                                // console.log('Evento select >> ', event, child);
                                                setValueFormPuntoIntermedio(event.target.value);
                                                setInfoPuntoIntermedio(child.props.children);
                                              }
                                            }
                                          >
                                            <MenuItem value='26'>Reparto</MenuItem>
                                            <MenuItem value='27'>Recolección</MenuItem>
                                          </Select>
                                        </FormControl>
                                        <Tooltip TransitionComponent={Zoom} title={intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.TOOLTIP_PUNTO_INTERMEDIO' })} disableFocusListener disableTouchListener placement="top">
                                          <IconButton
                                            aria-label="add"
                                            color="secondary"
                                            style={{ height: '0', width: '0' }}
                                            // disabled={cargandoPagina}
                                            onClick={() => {
                                              get_direcciones('', '');
                                              let solicitudTemp = { ...formularioSolicitud };
                                              solicitudTemp.varIntermedios.push({
                                                varFleteID: varFormPuntoIntermedio,
                                                varFleteText: varInfoPuntoIntermedio?.toLowerCase(),
                                                varDireccionID: '',
                                                varFechaIntermedio: '',
                                                varHoraIntermedio: '',
                                                varRequired: true
                                              });
                                              setFormularioSolicitud(solicitudTemp);
                                            }}
                                          >
                                            <AddCircleIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </div>
                                    </Grid>
                                    <Grid item md={7}></Grid>
                                  </Grid>
                                </Grid>
                                {/* Fletes de reparto - recolección Array */}
                                {
                                  formularioSolicitud.varIntermedios.map((element, index) => (
                                    <Grid item md={12} key={'Intermedio-' + index}>
                                      <Grid container spacing={1} justify={'center'}>
                                        {/* Dirección */}
                                        <Grid item md={3}>
                                          <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                                            <FormControl style={{ width: '100%', paddingRight: '5px' }} onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}>
                                              <InputLabel>{intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.FORM_CITA_FLETE' }) + ' ' + element.varFleteText + ' ' + (index + 1)}</InputLabel>
                                              <Select
                                                disabled={cargandoPagina}
                                                error={element.varRequired}
                                                value={element?.varDireccionID}
                                                onChange={
                                                  (event, child) => {
                                                    // console.log('Evento select >> ', event, child);
                                                    let solicitudTemp = { ...formularioSolicitud };
                                                    solicitudTemp.varIntermedios[index].varRequired = false;
                                                    solicitudTemp.varIntermedios[index].varDireccionID = event.target.value;
                                                    setFormularioSolicitud(solicitudTemp);
                                                  }
                                                }
                                              >
                                                {
                                                  lstDireccionesCliente.map((elementDireccion, indexDireccion) => (
                                                    <MenuItem value={elementDireccion.VALUE_ID} key={'DireccionCliente-' + indexDireccion + '-' + index}>{elementDireccion.VALUE_TEXT}</MenuItem>
                                                  ))
                                                }
                                              </Select>
                                            </FormControl>
                                            <Tooltip TransitionComponent={Zoom} title={intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.TOOLTIP_REFRESH_FLETE' })} disableFocusListener disableTouchListener placement="top">
                                              <IconButton
                                                aria-label="refresh"
                                                color="secondary"
                                                style={{ height: '0', width: '0' }}
                                                // disabled={cargandoPagina}
                                                onClick={() => {
                                                  // get_direcciones('origen', idFormOrigen);
                                                  setModalActivo(true);
                                                }}
                                              >
                                                <AddLocationIcon />
                                              </IconButton>
                                            </Tooltip>
                                          </div>
                                        </Grid>
                                        {/* Fecha */}
                                        <Grid item md={2}>
                                          <FormControl style={{ width: '100%', paddingRight: '5px' }} onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}>
                                            <TextField
                                              id="date"
                                              label={intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.FORM_FECHA_FLETE' }) + ' ' + element.varFleteText}
                                              type="date"
                                              inputProps={{
                                                min: datFechaHoy,
                                                max: varMaxFechaStop
                                              }}
                                              disabled={cargandoPagina}
                                              value={element?.varFechaIntermedio}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              onChange={(event) => {
                                                let solicitudTemp = { ...formularioSolicitud };
                                                solicitudTemp.varIntermedios[index].varFechaIntermedio = event.target.value;
                                                setFormularioSolicitud(solicitudTemp);
                                                // console.log(solicitudTemp);
                                              }}
                                            />
                                          </FormControl>
                                        </Grid>
                                        {/* Hora */}
                                        <Grid item md={2}>
                                          <FormControl style={{ width: '100%', paddingRight: '5px' }} onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}>
                                            <InputLabel>{intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.FORM_HORA_FLETE' }) + ' ' + element.varFleteText}</InputLabel>
                                            <Select
                                              disabled={cargandoPagina}
                                              value={element?.varHoraIntermedio}
                                              onChange={(event) => {
                                                let solicitudTemp = { ...formularioSolicitud };
                                                solicitudTemp.varIntermedios[index].varHoraIntermedio = event.target.value;
                                                setFormularioSolicitud(solicitudTemp);
                                              }}
                                              startAdornment={
                                                <InputAdornment position="start">
                                                  <ScheduleIcon />
                                                </InputAdornment>
                                              }
                                            >
                                              <MenuItem value="" key={'HoraFlete-1000-' + index}>Ninguno</MenuItem>
                                              {
                                                lstHoras.map((elementHoras, indexHoras) => (
                                                  <MenuItem value={elementHoras} key={'HoraFlete-' + indexHoras + '-' + index}>{elementHoras}</MenuItem>
                                                ))
                                              }
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                        {/* Eliminar */}
                                        <Grid item md={1} style={{ display: 'flex', alignItems: 'center' }}>
                                          <Tooltip TransitionComponent={Zoom} title={intl.formatMessage({ id: 'SERVICIOS.SOLICITUDES.TXT.TOOLTIP_DELETE_FLETE' }) + ' ' + element.varFleteText + ' ' + (index + 1)} disableFocusListener disableTouchListener placement="top">
                                            <IconButton
                                              aria-label="delete"
                                              color="secondary"
                                              style={{ height: '0', width: '0' }}
                                              // disabled={cargandoPagina}
                                              onClick={() => {
                                                let solicitudTemp = { ...formularioSolicitud };
                                                solicitudTemp.varIntermedios.splice(index, 1);
                                                setFormularioSolicitud(solicitudTemp);
                                              }}
                                            >
                                              <RemoveCircleIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  ))
                                }
                              </Grid>
                            </Grid>
                            :
                            <span key={'nadaContent-' + activeStep}></span>
                        ]
                    ]
                }
              </Grid>
              <Grid item md={12} style={{ padding: '4px 15px', display: 'flex', justifyContent: 'center', marginTop: '35px' }} key={'Acciones'}>
                {
                  (activeStep === steps.length)
                    ?
                    <IconButton
                      color="secondary"
                      onClick={handleReset}
                    >
                      <Typography>
                        <ThumbUpIcon />
                      </Typography>
                    </IconButton >
                    :
                    <div style={{ textAlign: 'center' }}>
                      {/* <Typography style={{fontWeight: '700', color: '#3783e6'}}>{getStepContent(activeStep)}</Typography> */}
                      <div>
                        <Button disabled={activeStep === 0 || cargandoPagina} onClick={handleBack}>
                          Atras
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={handleNext}
                          disabled={
                            (cargandoPagina) ||
                            (activeStep === indexError) ||
                            ((requiredOrigen || requiredDestino || requiredVehiculo) && activeStep === 0) ||
                            ((requiredCantidadSolicitar || requiredTipoProducto || requiredDescProducto || requiredPeso || requiredUnidadMedida) && activeStep === 1) ||
                            ((requiredDireccionOrigen || requiredFechaOrigen || requiredHoraOrigen || requiredDireccionDestino || requiredFechaDestino || requiredHoraDestino) && activeStep === 2)
                          }
                        >
                          {activeStep === steps.length - 1 ? 'Solicitar servicio' : 'Continuar'}
                        </Button>
                      </div>
                    </div>
                }
              </Grid>
              <Grid item md={12} key={'Mensajes'}>
                {
                  (!cargandoPagina) ?  <></> : <LinearProgress />
                }
                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogTitle id="alert-dialog-slide-title"><InfoIcon/> {"Aviso Importante: "}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" style={{fontSize: 15}}>
                      {mensajeError}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>

                    <Button onClick={handleClose} variant="contained" style={{borderRadius: 10, backgroundColor: '#1f23bc', color: 'white'}} component={Link} to="/servicios/crear-solicitud">
                      Aceptar
                    </Button>

                  </DialogActions>
                </Dialog>
              </Grid>
            </Grid>
          </section>
        </section>
      </Paper>
    </Grid>
  </>
}

const TarjetaFormularioSolicitudes = injectIntl(_TarjetaFormularioSolicitudes);

const Solicitudes = (props) => {
  const { intl } = props;
  const { session, selectedClienteComercial } = useSelector(state => state.auth);
  const [cargandoPagina, setCargandoPagina] = useState(true);
  const [modalActivo, setModalActivo] = useState(false);
  const [guardarDireccion, setGuardarDireccion] = useState(false);
  const [listaVarClientes, setListaVarClientes] = useState([]);
  const [lstTipoProducto, setTipoProducto] = useState([]);
  const [lstUnidadMedida, setUnidadMedida] = useState([]);
  const [formularioSolicitud, setFormularioSolicitud] = useState({
    //Step 1
    varOrigen: null,
    varDestino: null,
    varVehiculo: null,
    varUsuario: session.strUsuario,
    varTarifa: null,
    varMoneda: null,
    //Step 2
    varCantidad: '1',
    varHazmat: 0,
    varHazmatUN: null,
    varTrasbordo: 0,
    varTipoProducto: null,
    varProducto: null,
    varPeso: null,
    varUnidadMedida: null,
    varNota: null,
    varCarga: "1",
    varDescarga: "1",
    varReferencia: null,
    //step 3
    varDireccionOrigen: null,
    varFechaOrigen: null,
    varHoraOrigen: null,
    varDireccionDestino: null,
    varFechaDestino: null,
    varHoraDestino: null,
    varIntermedios: [
      //Fletes de reparto - recoleccion
      /* Estructura para los datos correspondientes
        {
          varFleteID : null,
          varFleteText : null,
          varDireccionID : null,
          varFechaIntermedio : null,
          varHoraIntermedio : null,
          varRequired : true
        }
      */
    ]
  });

  const get_catalogos = async () => {
    try {
      setCargandoPagina(true);
      let apiURL = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/servicios/solicitudes/api/catalogos?';
      apiURL += 'strCatalogo=tipoproductoservicio&';
      apiURL += 'strCatalogo=unidadmedidaservicio&';
      const response = await axios.get(apiURL);
      setTipoProducto(response.data.catalogos[0]);
      setUnidadMedida(response.data.catalogos[1]);

    } catch (err) {
      console.log(err);
    } finally {
      setCargandoPagina(false);
    }
  }

  useEffect(() => {
    // console.log('Hola >> ',session.strUsuario);
    const _sessionVarClientes = session.listaVarClientes;
    const _listaVarClientes = _sessionVarClientes.map(x => x.strVarClienteOrigen);
    setListaVarClientes(_listaVarClientes);
    get_catalogos();
  }, [])

  return <>
    <FWDModal
      open={modalActivo}
      fnCloseModal={() => {
        setModalActivo(false);
      }}
      Titulo={() => <Typography variant="h5">Agregar dirección</Typography>}
      width="65%"
      height="50%"
    >
      <CrearDireccionClienteModal
        varCliente={listaVarClientes[0]}
        setModalAbierto={setModalActivo}
        setGuardarDireccion={setGuardarDireccion}
      />
    </FWDModal>
    <Grid container spacing={2}>
      <Grid item md={12}>
        <TarjetaFormularioSolicitudes
          cargandoPagina={cargandoPagina}
          setCargandoPagina={setCargandoPagina}
          formularioSolicitud={formularioSolicitud}
          setFormularioSolicitud={setFormularioSolicitud}
          listaVarClientes={listaVarClientes}
          lstTipoProducto={lstTipoProducto}
          lstUnidadMedida={lstUnidadMedida}
          modalActivo={modalActivo}
          setModalActivo={setModalActivo}
          setGuardarDireccion={setGuardarDireccion}
          guardarDireccion={guardarDireccion}
          session={session}
          nombreComercial={selectedClienteComercial}
        ></TarjetaFormularioSolicitudes>
      </Grid>
    </Grid>
  </>
}

export default injectIntl(Solicitudes)