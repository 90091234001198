import React, { useState, useEffect, useRef } from 'react'
import { injectIntl } from 'react-intl'
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import { LinearProgress, TextField, Typography } from '@material-ui/core';
import { SvgIcon } from '@material-ui/core'
import { useSnackbar } from "notistack";
import axios from 'axios'
import qrcode from 'qrcode'
import HelpIcon from '@material-ui/icons/Help';
// https://92v9zvrmx3.execute-api.us-east-1.amazonaws.com/dev/auth/validar-otp

const BASE_URL = process.env.REACT_APP_AWS_API_BASE_URL;
const XAPI_KEY = process.env.REACT_APP_AWS_API_X_API_KEY;

function FWDDialogoGoogleAuthenticatorQR(props) {

    const { open = false, metadata, email } = props
    const [loading, setLoading] = useState(false)
    const [qr, setQr] = useState()
    const [verificado, setVerificado] = useState(false)

    const inputStyle = { width: '1.5rem' }

    const [txt0, setTxt0] = useState('')
    const [txt1, setTxt1] = useState('')
    const [txt2, setTxt2] = useState('')
    const [txt3, setTxt3] = useState('')
    const [txt4, setTxt4] = useState('')
    const [txt5, setTxt5] = useState('')

    const input0 = useRef()
    const input1 = useRef()
    const input2 = useRef()
    const input3 = useRef()
    const input4 = useRef()
    const input5 = useRef()

    const { enqueueSnackbar } = useSnackbar()

    const validCodes = (key, erasable = false) => {
        const keys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
        if (erasable) keys.push('Backspace')
        return keys.indexOf(key) !== -1
    }

    const getQRCode = (otp_url) => {
        return new Promise((resolve, reject) => {
            qrcode.toDataURL(otp_url, (qrerr, qrdata) => {
                if (qrerr) reject(qrerr)
                else resolve(qrdata)
            })
        })
    }

    const setQR = async () => {
        try {
            const codigoQR = await getQRCode(metadata?.strHashOtpUrl)
            setQr(codigoQR)
        }
        catch (error) {
            console.log('setQRError ', error)
        }
    }

    const fetchValidarOtp = async (codigo) => {
        try {
            setLoading(true)
            let response = await fetch(BASE_URL + '/auth/validar-otp', {
                method: 'post',
                headers: { 'x-api-key': XAPI_KEY },
                body: JSON.stringify({
                    strHashGoogleAuthenticator: metadata?.strHashGoogleAuthenticator,
                    codigo: codigo,
                    email: email
                })
            })
            response = await response.json()
            if (response.verificado) {
                setVerificado(true)
            }
            else {
                setTxt0('')
                setTxt1('')
                setTxt2('')
                setTxt3('')
                setTxt4('')
                setTxt5('')
                setLoading(false)
                input0.current.focus()
                enqueueSnackbar("Codigo incorrecto",
                    {
                        preventDuplicate: true,
                        autoHideDuration: 2500,
                        variant: "warning",
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                    }
                );
            }
        }
        catch (error) {
            console.log('fetchValidarOtpError ', error)
        }

    }

    useEffect(() => {
        if (open) {
            console.clear()
            console.log(props)
            setQR()
        }
    }, [open])

    // useEffect(() => {
    //     if (codigo.length === 6) {
    //         fetchValidarOtp()
    //     }
    // }, [codigo])

    return <>
        <Dialog
            open={open}
            PaperComponent={Paper}
            aria-labelledby="dialog-gauth-qr"
        >
            <DialogContent>
                <div style={{ display: 'flex', width: 550 }}>
                    <img src={qr} width="250px" height="250px" />
                    <section style={{ width: '100%', padding: 15, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        {
                            !verificado
                                ?
                                <>
                                    <div style={{ display: 'flex' }}>
                                        <section>
                                            <Typography variant="h6">Se ha detectado factor</Typography>
                                            <Typography variant="h6">de doble autenticación</Typography>
                                            <Typography variant="subtitle2" style={{ marginTop: 4 }}>Para poder acceder a tu cuenta realiza los siguientes pasos</Typography>
                                        </section>
                                        <HelpIcon
                                            className="cursor-hover"
                                            style={{ marginLeft: 5, marginTop: 4 }}
                                            onClick={() => { }}
                                        />
                                    </div>
                                    <div style={{ marginTop: 8 }}>
                                        <Typography variant="body2">Descarga la aplicación <b><a href="#">Google Authenticator</a></b> en tu celular</Typography>
                                        <Typography variant="body2">Da click <b>escanear código QR</b></Typography>
                                        <Typography variant="body2">Escanea el código de la izquierda</Typography>
                                        <Typography variant="body2">Inserta los 6 dígitos de tu auntenticador</Typography>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <section style={{ display: 'flex', justifyContent: 'space-between', width: '100%', margin: '10px 0' }}>
                                            <TextField inputRef={input0} value={txt0} autoFocus color="primary" style={inputStyle} inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                                                disabled={loading}
                                                onKeyDown={(e) => {
                                                    if (!validCodes(e.key)) e.preventDefault()
                                                }}
                                                onInput={(e) => {
                                                    setTxt0(e.target.value)
                                                    input1.current.focus()
                                                }}
                                                onFocus={e => e.target.select()}
                                                onClick={_ => {

                                                    setTxt0('')
                                                    input0.current.focus()
                                                }}
                                            />
                                            <TextField inputRef={input1} value={txt1} color="primary" style={inputStyle} inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                                                disabled={loading}
                                                onKeyDown={(e) => {
                                                    if (!validCodes(e.key)) e.preventDefault()
                                                    if (e.key === 'Backspace') {
                                                        setTxt0('')
                                                        input0.current.focus()
                                                    }
                                                }}
                                                onInput={(e) => {
                                                    setTxt1(e.target.value)
                                                    input2.current.focus()
                                                }}
                                                onFocus={e => e.target.select()}
                                                onClick={_ => {
                                                    setTxt1('')
                                                    input1.current.focus()
                                                }}
                                            />
                                            <TextField inputRef={input2} value={txt2} color="primary" style={inputStyle} inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                                                disabled={loading}
                                                onKeyDown={(e) => {
                                                    if (!validCodes(e.key)) e.preventDefault()
                                                    if (e.key === 'Backspace') {
                                                        setTxt1('')
                                                        input1.current.focus()
                                                    }
                                                }}
                                                onInput={(e) => {
                                                    setTxt2(e.target.value)
                                                    input3.current.focus()
                                                }}
                                                onFocus={e => e.target.select()}
                                                onClick={_ => {
                                                    setTxt2('')
                                                    input2.current.focus()
                                                }}
                                            />
                                            <TextField inputRef={input3} value={txt3} color="primary" style={inputStyle} inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                                                disabled={loading}
                                                onKeyDown={(e) => {
                                                    if (!validCodes(e.key)) e.preventDefault()
                                                    if (e.key === 'Backspace') {
                                                        setTxt2('')
                                                        input2.current.focus()
                                                    }
                                                }}
                                                onInput={(e) => {
                                                    setTxt3(e.target.value)
                                                    input4.current.focus()
                                                }}
                                                onFocus={e => e.target.select()}
                                                onClick={_ => {
                                                    setTxt3('')
                                                    input3.current.focus()
                                                }}
                                            />
                                            <TextField inputRef={input4} value={txt4} color="primary" style={inputStyle} inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                                                disabled={loading}
                                                onKeyDown={(e) => {
                                                    if (!validCodes(e.key)) e.preventDefault()
                                                    if (e.key === 'Backspace') {
                                                        setTxt3('')
                                                        input3.current.focus()
                                                    }
                                                }}
                                                onInput={(e) => {
                                                    setTxt4(e.target.value)
                                                    input5.current.focus()
                                                }}
                                                onFocus={e => e.target.select()}
                                                onClick={_ => {
                                                    setTxt4('')
                                                    input4.current.focus()
                                                }}
                                            />
                                            <TextField inputRef={input5} value={txt5} color="primary" style={inputStyle} inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                                                disabled={loading}
                                                onKeyDown={(e) => {
                                                    if (!validCodes(e.key)) e.preventDefault()
                                                    if (e.key === 'Backspace') {
                                                        setTxt4('')
                                                        input4.current.focus()
                                                    }
                                                }}
                                                onInput={(e) => {
                                                    const codigo = `${txt0}${txt1}${txt2}${txt3}${txt4}${e.target.value}`
                                                    setTxt5(e.target.value, fetchValidarOtp(codigo))
                                                }}
                                                onFocus={e => e.target.select()}
                                                onClick={_ => {
                                                    setTxt5('')
                                                    input5.current.focus()
                                                }}
                                            />
                                        </section>
                                        <LinearProgress style={{ width: '100%', visibility: loading ? 'visible' : 'hidden' }} />
                                        {/* {loading ? <LinearProgress style={{ width: '100%' }} /> : <></>} */}
                                    </div>
                                </>
                                :
                                <>
                                    <div>
                                        <Typography variant="h6">¡Todo listo!</Typography>
                                        <Typography variant="subtitle2">No cierres tu <b>Google Authenticator</b>. Se te volverá a pedir que inicies sesión.</Typography>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Button
                                            style={{ marginBottom: 5 }}
                                            variant="outlined" color="primary"
                                            onClick={() => window.location.replace('/auth/login?gauth=1&email=' + email)}
                                        >Entendido</Button>
                                        <LinearProgress style={{ width: '100%' }} />
                                    </div>
                                </>
                        }
                    </section>
                </div>
            </DialogContent>
        </Dialog>


    </>

}

export default injectIntl(FWDDialogoGoogleAuthenticatorQR)