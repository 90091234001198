import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { TextField, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';


const fetchAutocompleteAPI = async (catalogName, additionalFilter) => {

  let api_url = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/servicios/solicitudes/api/catalogos';
  api_url += `?strCatalogo=${catalogName}&strFiltro1=${additionalFilter}`;
  try {
    const response = await axios.get(api_url);
    const lstResponse = response.data.catalogos;
    return lstResponse;
  }
  catch (error) {
    console.log('error', error);
  }
}

export const SelectoresCustomer = ({ label, catalogName, additionalFilter, selectedValue, setSelectedValue, onItemSelected, error }) => {
  const dispatch = useDispatch();

  const { user, session, selectedClienteComercial } = useSelector(state => state.auth);
  const [options, setOptions] = useState([]);
  const [errorEmpty, setErrorEmpty] = useState(true);
  const [clienteComercial, setClientecomercial] = useState(selectedClienteComercial);
  const clientes = session.listaVarClientes;


  const handleSelectChange = (event) => {
    const value = event.target.value;
    const selectedOption = value;

    if (selectedOption) {
      setSelectedValue(value); // Actualiza el valor en el componente padre
      setErrorEmpty(value === '');

      // Llama a la función onItemSelected y pasa el VALUE_TEXT
      onItemSelected(selectedOption.VALUE_TEXT);
    } else {
      setSelectedValue(''); // Asegúrate de borrar el valor si no se selecciona ninguna opción
      setErrorEmpty(value === '');
      onItemSelected(''); // Llama a la función onItemSelected con un valor vacío
    }
  };

  return (
    <FormControl style={{ width: '100%' }} error={error}>
      <InputLabel>{label}</InputLabel>
      <Select
        style={{ marginLeft: 0, paddingLeft: 0, textAlign: 'left', textAlign: 'left' }}
        value={selectedValue}
        onChange={handleSelectChange}
      >
        <MenuItem style={{ color: '#8c8e9a' }} value="">
          <em>Seleccionar</em>
        </MenuItem>
        {clientes.map((option, index) => (
          <MenuItem key={index} value={option.strVarClienteOrigen}>
            {option.strFranquicia}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const SelectoresCatalogos = ({ label, catalogName, additionalFilter, selectedValue, setSelectedValue, onItemSelected, error }) => {
  const [options, setOptions] = useState([]);
  const [errorEmpty, setErrorEmpty] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchAutocompleteAPI(catalogName, additionalFilter);
        setOptions(response);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [catalogName, additionalFilter]);

  const handleSelectChange = (event) => {
    const value = event.target.value;
    const selectedOption = options.find((option) => option.VALUE_ID === value);

    if (selectedOption) {
      setSelectedValue(value); // Actualiza el valor en el componente padre
      setErrorEmpty(value === '');

      // Llama a la función onItemSelected y pasa el VALUE_TEXT
      onItemSelected(selectedOption.VALUE_TEXT);
    } else {
      setSelectedValue(''); // Asegúrate de borrar el valor si no se selecciona ninguna opción
      setErrorEmpty(value === '');
      onItemSelected(''); // Llama a la función onItemSelected con un valor vacío
    }
  };

  return (
    <FormControl style={{ width: '100%' }} error={error}>
      <InputLabel>{label}</InputLabel>
      <Select
        style={{ marginLeft: 0, paddingLeft: 0, textAlign: 'left', textAlign: 'left' }}
        value={selectedValue}
        onChange={handleSelectChange}
      >
        <MenuItem style={{ color: '#8c8e9a' }} value="">
          <em>Seleccionar</em>
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.VALUE_ID} value={option.VALUE_ID}>
            {option.VALUE_TEXT}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const SelectoresRutaCatalogos = ({ label, catalogName, additionalFilter, selectedValue, setSelectedValue, onItemSelected, error }) => {
  const [options, setOptions] = useState([]);
  const [errorEmpty, setErrorEmpty] = useState(true);

  const [selectedLocalValue, setSelectedLocalValue] = useState(selectedValue);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchAutocompleteAPI(catalogName, additionalFilter);
        setOptions(response);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [catalogName, additionalFilter]);

  useEffect(() => {
    setSelectedLocalValue(selectedValue); // Actualiza el valor local cuando cambia la prop
  }, [selectedValue]);

  const handleSelectChange = (event) => {
    const value = event.target.value;
    const selectedOption = options.find((option) => option.VALUE_ID === value);

    if (selectedOption) {
      setSelectedValue(value); // Actualiza el valor en el componente padre
      setSelectedLocalValue(value);
      setErrorEmpty(false);

      // Llama a la función onItemSelected y pasa el VALUE_TEXT
      onItemSelected(selectedOption.VALUE_TEXT);
    } else {
      setSelectedValue(''); // Asegúrate de borrar el valor si no se selecciona ninguna opción
      setSelectedLocalValue('');
      setErrorEmpty(true);
      onItemSelected(''); // Llama a la función onItemSelected con un valor vacío
    }
  };

  return (
    <FormControl style={{ width: '100%' }} error={errorEmpty}>
      <InputLabel>{label}</InputLabel>
      <Select
        style={{ marginLeft: 0, paddingLeft: 0, textAlign: 'left' }}
        value={selectedLocalValue} // Usa selectedLocalValue
        onChange={handleSelectChange}
      >
        <MenuItem style={{ color: '#8c8e9a' }} value="">
          <em>Seleccionar</em>
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.VALUE_ID} value={option.VALUE_ID}>
            {option.VALUE_TEXT}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const SelectorEjecutivo = ({ label, catalogName, additionalFilter, selectedValue, setSelectedValue, onItemSelected }) => {
  const [options, setOptions] = useState([]);
  const [errorEmpty, setErrorEmpty] = useState(true);
  const { session, selectedClienteComercial } = useSelector(state => state.auth);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchAutocompleteAPI(catalogName, additionalFilter);
        setOptions(response);

        // Verificar si solo hay una opción y el usuario no ha seleccionado nada
        if (response.length === 1 && (!selectedValue || selectedValue === '')) {
          setSelectedValue(response[0].VALUE_ID);
        } else if (response.length > 1 && (!selectedValue || selectedValue === '')) {
          // Establecer un valor aleatorio
          const randomIndex = Math.floor(Math.random() * response.length);
          setSelectedValue(response[randomIndex].VALUE_ID);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [catalogName, additionalFilter, selectedValue, setSelectedValue, selectedClienteComercial]);

  const handleSelectChange = (event) => {
    const value = event.target.value;
    const selectedOption = options.find((option) => option.VALUE_ID === value);

    if (selectedOption) {
      setSelectedValue(value); // Actualiza el valor en el componente padre
      setErrorEmpty(value === '');

      // Llama a la función onItemSelected y pasa el VALUE_TEXT
      onItemSelected(selectedOption.VALUE_TEXT);
    } else {
      setSelectedValue(''); // Asegúrate de borrar el valor si no se selecciona ninguna opción
      setErrorEmpty(value === '');
      onItemSelected(''); // Llama a la función onItemSelected con un valor vacío
    }
  };

  return (
    <FormControl style={{ width: '100%' }}>
      <InputLabel>{label}</InputLabel>
      <Select
        style={{ marginLeft: 0, paddingLeft: 0, textAlign: 'left' }}
        value={selectedValue}
        onChange={handleSelectChange}
      >
        <MenuItem style={{ color: '#8c8e9a' }} value="">
          <em>Seleccionar</em>
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.VALUE_ID} value={option.VALUE_ID}>
            {option.VALUE_TEXT}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};


export const SelectVehiculosCatalogos = ({ label, catalogName, additionalFilter, selectedValue, setSelectedValue, onItemSelected, error }) => {
  const [options, setOptions] = useState([]);
  const [errorEmpty, setErrorEmpty] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchAutocompleteAPI(catalogName, additionalFilter);
        setOptions(response);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [catalogName, additionalFilter]);

  const handleSelectChange = (event) => {
    const value = event.target.value;
    const selectedOption = options.find((option) => option.VALUE_ID === value);

    if (selectedOption) {
      setSelectedValue(value); // Actualiza el valor en el componente padre
      setErrorEmpty(value === '');

      // Llama a la función onItemSelected y pasa el VALUE_TEXT
      onItemSelected(selectedOption.VALUE_TEXT);
    } else {
      setSelectedValue(''); // Asegúrate de borrar el valor si no se selecciona ninguna opción
      setErrorEmpty(value === '');
      onItemSelected(''); // Llama a la función onItemSelected con un valor vacío
    }
  };

  return (
    <Autocomplete
        options={options}
        getOptionLabel={(option) => option.VALUE_TEXT}
        onChange={handleSelectChange} // Maneja el evento de selección
        renderInput={(params) => <TextField {...params} label={label} />}
      />
  );
};