import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    title: {
        fontSize: 20,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    previewChip: {
        minWidth: 160,
        maxWidth: 210
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    buttonIconSave: {
        color: '#37ad52'
    },
    buttonIconEdit: {
        color: '#ffa800'
    },
    buttonIconNew: {
        color: '#1f23be'
    },
    buttonIconDelete: {
        color: 'red'
    },
    buttonSave: {
        color: '#ffffff',
        backgroundColor: '#2abb71',
        margin: 2,
        width: 'auto',
        '&:hover': {
            backgroundColor: '#1a814c',
        },
    },
    buttonWarning: {
        color: '#ffa800',
        borderColor: "#ffa800",
        margin: 2,
        width: 'auto',
        '&:hover': {
            color: '#ffffff',
            backgroundColor: '#ffa800',
        },
    },
    buttonDelete: {
        color: 'red',
        borderColor: "red",
        margin: 2,
        width: 'auto',
        '&:hover': {
            color: '#ffffff',
            backgroundColor: 'red',
        },
    },
    select: {
        marginRight: theme.spacing(2),
    },
    tooltip: {
        width: 500,
    },
    dialogModal: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: 'white',
    },
    cardFilters: {
        padding: theme.spacing(3, 2),
    },
    cardTable: {
        marginTop: 15,
    },
    //Modals Header Estilos//
    modalHeaderServicios: {
        backgroundColor: '#3f4254',
        color: 'white'
    },
    //Modals Header Estilos//
}));


export default useStyles;
