import React, { useState, useReducer, useRef, useLayoutEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { detect } from 'detect-browser'
import { Auth } from 'aws-amplify'


import {
  FWDDialogoGoogleAuthenticator,
  FWDDialogoGoogleAuthenticatorContext,
  FWDDialogoGoogleAuthenticatorActions,
  FWDDialogoGoogleAuthenticatorReductor,
  FWDDialogoGoogleAuthenticatorState
} from 'app/componentes/FWDDialogoGoogleAuthenticator'


import FWDDialogoGoogleAuthenticatorQR from 'app/componentes/FWDDialogoGoogleAuthenticatorQR'

function Login(props) {
  const history = useHistory();
  const { intl } = props;
  const [loading, setLoading] = useState(false);

  const queryParams = new URLSearchParams(props.location.search)

  const initialValues = {
    email: queryParams.get('email') || '',
    password: "",
  };

  const GAUTH = queryParams.get('gauth') || false

  const userInputRef = useRef()
  const passInputRef = useRef()

  useLayoutEffect(() => {
    if (GAUTH)
      passInputRef.current.focus()
    else
      userInputRef.current.focus()
  }, [])

  const [dialogoProps, setDialogoProps] = useState({ fnOK: () => { } })
  const [dataDialogo, dispatchDataDialogo] = useReducer(FWDDialogoGoogleAuthenticatorReductor, FWDDialogoGoogleAuthenticatorState)

  const [dialogoQRProps, setDialogoQRProps] = useState({ open: false, metadata: null, fnOK: () => { } })

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: '_FORMATO_EMAIL' }))
      // .min(3, "Minimum 3 symbols")
      // .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "_CAMPO_REQUERIDO",
        })
      ),
    password: Yup.string()
      // .min(3, "Minimum 3 symbols")
      // .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "_CAMPO_REQUERIDO",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };



  const postData = async (values, { setStatus, setSubmitting }) => {
    try {

      const ping_data = await fetch(`${process.env.REACT_APP_AWS_API_BASE_URL}/auth/ping`, {
        method: 'post',
        headers: { 'x-api-key': process.env.REACT_APP_AWS_API_X_API_KEY },
        body: JSON.stringify({ correoElectronico: values.email })
      });

      if (ping_data.status !== 200) throw { codigo: 'PING_FALLO', ping_data }

      const ipPublica = await ping_data.text()
      const detectNavegador = detect()


      const login_data = await Auth.signIn({
        username: values.email,
        password: values.password,
        validationData: {
          // foo: 'bar',
          ipPublica,
          navegador: detectNavegador?.name,
          version: detectNavegador?.version,
          os: detectNavegador?.os,
          codigo: values.codigo
        }
      });

      dispatchDataDialogo({ id: FWDDialogoGoogleAuthenticatorActions.validado })

      if ('challengeName' in login_data) {

        switch (login_data.challengeName) {

          case 'NEW_PASSWORD_REQUIRED':
            history.push({
              pathname: '/auth/registration',
              state: { user: values.email, pass: values.password }
            });
            break;
        }

      }

      const jwt = login_data.signInUserSession.accessToken.jwtToken;
      props.login(jwt);
      // disableLoading();
    }
    catch (error) {
      disableLoading();
      setSubmitting(false);
      if ('code' in error) {

        dispatchDataDialogo({ type: FWDDialogoGoogleAuthenticatorActions.resetCodigo })

        switch (error.code) {

          case 'UserNotFoundException':
            setStatus(intl.formatMessage({ id: "AUTH.LOGIN.ERROR.USUARIO_NO_ENCONTRADO" }))
            break;

          case 'NotAuthorizedException':
            if (error.message === 'Incorrect username or password.')
              setStatus(intl.formatMessage({ id: "AUTH.LOGIN.ERROR.CREDENCIALES_INCORRECTAS" }))
            else
              setStatus(intl.formatMessage({ id: "AUTH.LOGIN.ERROR.USUARIO_INACTIVO" }))
            break;

          case 'UserLambdaValidationException':
            const msg = error.message.slice(0, -1).split(' ');
            const inner_error = JSON.parse(msg[4])['error']['codigo'];

            if (inner_error === "DOMINIO_INVALIDO" || inner_error === "LISTA_DOMINIOS_VACIA")
              setStatus(intl.formatMessage({ id: "AUTH.LOGIN.ERROR.DOMINIO_INVALIDO" }))


            else if (inner_error === 'USR_OTPAUTH_INCOMPLETE') {
              const metadata = JSON.parse(msg[4])['error']['metadata']
              setDialogoQRProps({
                metadata, open: true, email: values.email, fnOk: (codigo) => {
                  postData({
                    email: values.email,
                    password: values.password,
                    codigo
                  }, { setStatus, setSubmitting })
                }
              })
            }

            else if (inner_error === "USR_OTPAUTH_ERROR") {
              // dispatchDataDialogo({ type: FWDDialogoGoogleAuthenticatorActions.estadoInvalido })
              setDialogoProps({
                fnOk: (codigo) => {
                  dispatchDataDialogo({ type: FWDDialogoGoogleAuthenticatorActions.cargando })
                  postData({
                    email: values.email,
                    password: values.password,
                    codigo
                  }, { setStatus, setSubmitting })
                }
              })
              dispatchDataDialogo({ type: FWDDialogoGoogleAuthenticatorActions.mostrarDialogo })
            }

            else if (inner_error === 'USR_OTPAUTH_INVALID') {
              dispatchDataDialogo({ type: FWDDialogoGoogleAuthenticatorActions.estadoInvalido })
              dispatchDataDialogo({ type: FWDDialogoGoogleAuthenticatorActions.resetCodigo })
              setDialogoProps({
                fnOk: (codigo) => {
                  dispatchDataDialogo({ type: FWDDialogoGoogleAuthenticatorActions.cargando })
                  postData({
                    email: values.email,
                    password: values.password,
                    codigo
                  }, { setStatus, setSubmitting })
                }
              })
              dispatchDataDialogo({ type: FWDDialogoGoogleAuthenticatorActions.mostrarDialogo })
            }

            break;

          default:
            setStatus(intl.formatMessage({ id: '_ERROR_INESPERADO' }));
            break;

        }
      }

      if ('codigo' in error) {
        switch (error.codigo) {

          case 'PING_FALLO':

            // if(error.ping_data.status === 400) 
            // nota: se asume que no existir� una respuesta 400 debido 
            //       que el formulario valida que se env�e un correo electr�nico

            if (error.ping_data.status === 404)
              setStatus(intl.formatMessage({ id: "AUTH.LOGIN.ERROR.USUARIO_NO_ENCONTRADO" }))

            else if (error.ping_data.status === 403)
              setStatus(intl.formatMessage({ id: "AUTH.LOGIN.ERROR.IP_INVALIDA" }))

            else
              setStatus(intl.formatMessage({ id: '_ERROR_INESPERADO' }));

            break;

          default:
            setStatus(intl.formatMessage({ id: '_ERROR_INESPERADO' }));
            break;

        }
      }
    }

    // finally {
    //   resolve()
    // }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading();
      postData(values, { setStatus, setSubmitting })
    },
  });

  return (
    <FWDDialogoGoogleAuthenticatorContext.Provider value={{ data: dataDialogo, dispatchData: dispatchDataDialogo }}>
      <div className="login-form login-signin" id="kt_login_signin_form">
        <FWDDialogoGoogleAuthenticator {...dialogoProps} />
        <FWDDialogoGoogleAuthenticatorQR {...dialogoQRProps} />
        {/*begin::Form*/}
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          {formik.status ? (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible" style={{ height: '65px' }}>
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          ) : (
              <div className="mb-10 alert alert-custom alert-light-info alert-dismissible" style={{ height: '65px', visibility: 'hidden' }}>
                <div className="alert-text ">
                </div>
              </div>
            )}

          <div className="form-group fv-plugins-icon-container">
            <input
              ref={userInputRef}
              placeholder={intl.formatMessage({ id: 'AUTH.LOGIN.TXT.CORREO_ELECTRONICO' })}
              type="email"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "email"
              )}`}
              name="email"
              {...formik.getFieldProps("email")}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group fv-plugins-icon-container">
            <input
              ref={passInputRef}
              placeholder={intl.formatMessage({ id: 'AUTH.LOGIN.TXT.CONTRASENA' })}
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "password"
              )}`}
              name="password"
              {...formik.getFieldProps("password")}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            {/* <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.LOGIN.TXT.OLVIDE_CONTRASENA" />
          </Link> */}
            <button
              id="kt_login_signin_submit"
              type="submit"
              disabled={loading}
              className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
            >
              <span><FormattedMessage id="AUTH.LOGIN.TXT.INICIAR_SESION" /></span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
        </form>
        {/*end::Form*/}
      </div>
    </FWDDialogoGoogleAuthenticatorContext.Provider>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
