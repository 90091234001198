import React, { useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import {Select, MenuItem } from '@material-ui/core';

const arrayMesesDefaultState = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre"
];

function SelectorMes(props) {

    const {intl, onChange, strFechaInicio, value, disabled} = props;

    const [strFechaInicioState, setStrFechaInicioState] = useState(null);
    const [arrayMeses, setArrayMeses] = useState(arrayMesesDefaultState);

    today = new Date();
    let today = new Date(Date.now());

    useEffect(() => {
        setStrFechaInicioState(strFechaInicio);
    }, [strFechaInicio, strFechaInicioState])


    const handleOnChangeSelector = (event) => {
        if(onChange) {
            onChange(event.target.value);
        }
    }
    return <Select
        disabled={disabled}
        value={arrayMeses ? (Number(value))  : ""}
        onChange={handleOnChangeSelector}>
            {
                arrayMeses?.map((mes, index) => <MenuItem key={index} value={index+1}>{mes}</MenuItem>)
            }
        </Select>
}

export default injectIntl(SelectorMes);