import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Grid, Paper, Switch, Typography, Divider, Chip, Button, IconButton, CircularProgress } from '@material-ui/core'
import ChipInput from 'material-ui-chip-input'
import { SH_validarDominio, SH_validarIP } from 'app/helpers/stringHelper'
import { useSnackbar } from 'notistack'
import publicIp from 'public-ip'
import axios from 'axios'
import { FWDDialogo, FWDDialogoProps } from 'app/componentes/FWDDialogo';
import RefreshIcon from '@material-ui/icons/Refresh';
import CrudUsuarios from 'app/componentes/configuraciones/CrudUsuarios'

const getBaseURL = () => process.env.REACT_APP_AWS_API_BASE_URL + "/portal/configuracion/seguridad/api";

const Seguridad = ({ intl }) => {
    // Control de grupos franquicia
    const [franquicias, setFranquicias] = useState({})

    //  Control de dialogo
    const [dialogoVisible, setDialogoVisible] = useState(false);
    const [dialogoCargando, setDialogoCargando] = useState(false);
    const [dialogoProps, setDialogoProps] = useState(FWDDialogoProps);

    // control de peticions http
    const [cargandoDominios, setCargandoDominios] = useState(false)
    const [cargandoIPs, setCargandoIPs] = useState(false);

    // Datos de configuracion
    const [validarDominios, setValidarDominios] = useState(false);
    const [validarIps, setValidarIps] = useState(false);
    const [dominios, setDominios] = useState([])
    const [ips, setIps] = useState([])

    // Datos de sesion
    const [myPublicIp, setMyPublicIp] = useState({ v4: null, v6: null });
    const [myEmailDomain, setMyEmailDomain] = useState();

    const { session, selectedClienteComercial } = useSelector(state => state.auth);


    const fetchConfiguracionesFranquicia = async (varCliente, omitir = null) => {
        try {

            if (omitir !== 'dominios') setCargandoDominios(true);
            if (omitir !== 'ips') setCargandoIPs(true);

            const apiUrl = getBaseURL() + "/franquicias/" + selectedClienteComercial + "/configuraciones";
            const { data } = await axios.get(apiUrl);
            const { DOMINIOS, IPs, VERIFICA } = data.configuraciones;

            if (omitir !== 'dominios') {
                setValidarDominios(!!VERIFICA.VERIFICA_DOMINIOS);
                setDominios(DOMINIOS.map(x => x.DOMINIO))
            }

            if (omitir !== 'ips') {
                setValidarIps(!!VERIFICA.VERIFICA_IPS);
                setIps(IPs.map(x => x.IP))
            }

        }
        catch (error) {
            console.log('ERROR | fetchConfiguracionesFranquicia', error)
        }
        finally {
            setCargandoDominios(false)
            setCargandoIPs(false)
        }
    }

    const fetchConfiguracionesGrupoFranquicias = async (idGrupoFranquicia, omitir) => {
        try {

            if (omitir !== 'dominios') setCargandoDominios(true);
            if (omitir !== 'ips') setCargandoIPs(true);

            const apiUrl = getBaseURL() + "/gruposfranquicias/" + idGrupoFranquicia + "/configuraciones";
            const { data } = await axios.get(apiUrl);
            const { DOMINIOS, IPs, VERIFICA } = data.configuraciones;

            if (omitir !== 'dominios') {
                setValidarDominios(!!VERIFICA.VERIFICA_DOMINIOS);
                setDominios(DOMINIOS.map(x => x.DOMINIO))
            }

            if (omitir !== 'ips') {
                setValidarIps(!!VERIFICA.VERIFICA_IPS);
                setIps(IPs.map(x => x.IP))
            }

        }
        catch (error) {
            console.log('ERROR | fetchConfiguracionesGrupoFranquicias', error);
        }
        finally {
            setCargandoDominios(false)
            setCargandoIPs(false)
        }
    }

    /**
     * Coordinador de accion fetch segun ID_GRUPO en session. 
     * @param {Grupo franquicia} ID_GRUPO 
     * @param {Sse puede omitir setear 'dominios' o 'ips'} omitir 
     */
    const coordinaFethDataDominiosIPs = (omitir = null) => {
        //  Obtenemos datos de Franquicia y GrupoFranquicia
        const { ID_GRUPO } = session['userAttributes']['custom:franquicias'];
        const listaClientes = session['listaVarClientes'];
        const cliente = listaClientes[0];
        // const varCliente = cliente['strVarClienteOrigen'];
        const varCliente = selectedClienteComercial;

        if (ID_GRUPO) {
            fetchConfiguracionesGrupoFranquicias(ID_GRUPO, omitir)
            setFranquicias({ tipo: 'grupo', idGrupo: ID_GRUPO, varCliente: varCliente })
        }
        else {
            fetchConfiguracionesFranquicia(varCliente, omitir)
            setFranquicias({ tipo: 'franquicia', varCliente: varCliente, idGrupo: null })
        }
    }

    useEffect(() => {

        // Obtenemos IPV4 ó IPV6 pública de la sesión activa.
        const getPublicIpV4 = async () => {
            try {
                const v4 = await publicIp.v4();
                setMyPublicIp({ ...myPublicIp, v4 });
            }
            catch (error) {
                console.log("error getIPv4 ", error);
            }
        }
        const getPublicIpV6 = async () => {
            try {
                const v6 = await publicIp.v6();
                setMyPublicIp({ ...myPublicIp, v6 });
            }
            catch (error) {
                console.log("error getIPv6 ", error);
            }
        }
        getPublicIpV4();
        getPublicIpV6();


        //  Coordinamos la accion fetch correcta y seteamos datos de franquicias
        coordinaFethDataDominiosIPs()


        // Obtenemos dominio del usuario actual (sesion actual)
        const username = session['username'];
        setMyEmailDomain(username?.split('@')[1]);
    }, [])

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const putConfiguracionesFranquicia = async (resolve, reject) => {
        const { tipo, idGrupo, varCliente } = franquicias;

        const configuraciones = {
            verificaDominios: validarDominios,
            verificaIPs: validarIps,
            dominios,
            IPs: ips,
            grupoAccion: 'asignar', // parámetro se requiere para asignar a grupo (si es que existe...)
            grupoValor: tipo === 'grupo' ? idGrupo : null //parámetro se requiere cuando hay un grupo... null si no lo hay (evitar 500)
        }
        try {
            if (varCliente === null) throw { codigo: 'VAR CLIENTE NULO' }

            setDialogoCargando(true);
            const apiUrl = getBaseURL() + "/franquicias/" + varCliente + "/configuraciones";
            const response = await axios.put(apiUrl, { configuraciones })
            enqueueSnackbar(intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.MSG.CONFIGURACION_ACTUALIZADA' }), {
                variant: "success",
                autoHideDuration: 1500
            })
            resolve()
        }
        catch (error) {
            console.log('ERROR | putConfiguracionesFranquicia', error);
            enqueueSnackbar(intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.ERROR.CONFIGURACION_ACTUALIZADA' }), {
                variant: "error",
                onClick: () => closeSnackbar()
            })
            reject()
        }
        finally {
            setDialogoVisible(false);
            setDialogoCargando(false);
        }
    }

    //  Dialogo para dominios
    const setupDialogoDominiosGuardar = () => {
        const myDomainIndx = dominios.indexOf(myEmailDomain)
        const alertaDominio = myDomainIndx === -1 && validarDominios;

        setDialogoProps({
            ...dialogoProps,
            txtTitulo: intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.TITULO_DIALOGO' }),
            txtMensaje: alertaDominio
                ?
                [
                    intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.MSG.DOMINIO_NO_DETECTADO' }),
                    intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.MSG.SEGURO_GUARDAR_CAMBIOS_DOMINIOS' })
                ]
                : intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.MSG.SEGURO_GUARDAR_CAMBIOS_DOMINIOS' }),
            inputValido: alertaDominio
                ? intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.TEXTO_CONFIRMACION' })
                : null,
            fnOk: () => new Promise(putConfiguracionesFranquicia),
            fnNoOk: () => new Promise((resolve, reject) => {
                setDialogoVisible(false);
                resolve()
            })
        })

        setDialogoVisible(true)
    }

    //  Dialogo para IPs
    const setupDialogoIPSGuardar = () => {
        const myIPIndx = ips.indexOf(myPublicIp);
        const alertaIP = myIPIndx === -1 && validarIps;

        setDialogoProps({
            ...dialogoProps,
            txtTitulo: intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.TITULO_DIALOGO' }),
            txtMensaje: alertaIP
                ?
                [
                    intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.MSG.IP_NO_DETECTADA' }),
                    intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.MSG.SEGURO_GUARDAR_CAMBIOS_IPS' })
                ]
                : intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.MSG.SEGURO_GUARDAR_CAMBIOS_IPS' }),
            inputValido: alertaIP
                ? intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.TEXTO_CONFIRMACION' })
                : null,
            fnOk: () => new Promise(putConfiguracionesFranquicia),
            fnNoOk: () => new Promise((resolve) => {
                setDialogoVisible(false);
                resolve()
            })
        })

        setDialogoVisible(true)
    }

    return <Grid container alignContent="flex-start">

        <FWDDialogo {...dialogoProps} visible={dialogoVisible} cargando={dialogoCargando} />
        <Grid item xs={12}>
            <Paper elevation={3} style={{ flexGrow: 1, passing: '10px', display: 'flex' }}>
                <CrudUsuarios franquicias={franquicias} />
            </Paper>
        </Grid>
        <Grid item xs={12}>
            <Paper elevation={3} style={{ flexGrow: 1, marginTop: 20, padding: '10px', display: 'flex', flexDirection: 'column' }}>
                <section style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="subtitle1">{intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.TITULO_VALIDAR_DOMINIOS' })}</Typography>
                    <Switch
                        disabled={cargandoDominios}
                        checked={validarDominios}
                        onChange={(e) => {
                            const bitDominios = e.target.checked;
                            setValidarDominios(bitDominios)
                            if (bitDominios && dominios?.length === 0) {
                                setValidarDominios(!bitDominios)
                                enqueueSnackbar(intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.MSG.LISTA_DOMINIOS_VACIA_BIT_0' }), {
                                    variant: 'warning',
                                    autoHideDuration: 2500,
                                    preventDuplicate: true
                                })
                            }
                        }}
                        color="secondary" />
                </section>
                <Typography variant="caption">{intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.SUBTITULO_VALIDAR_DOMINIOS' })}</Typography>
                <Divider />
                <ChipInput
                    disabled={cargandoDominios}
                    style={{ marginTop: 20 }}
                    value={dominios}
                    readOnly={false}
                    alwaysShowPlaceholder={true}
                    placeholder={dominios?.length === 0
                        ? intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.PLACEHOLDER_INGRESA_DOMINIO' }, { dominio: myEmailDomain })
                        : intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.PLACEHOLDER_INGRESA_DOMINIO_2' })
                    }
                    onAdd={dominio => {
                        if (dominios?.length === 0)
                            setValidarDominios(true)
                        setDominios([...dominios, dominio])
                    }}
                    onDelete={(_, indx) => {
                        const aux = [...dominios];
                        aux.splice(indx, 1);
                        setDominios(aux)
                        if (aux.length === 0)
                            setValidarDominios(false)
                    }}
                    onBeforeAdd={(dominio) => {
                        const status = SH_validarDominio(dominio);
                        if (!status) enqueueSnackbar(intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.MSG.VERIFICA_DOMINIO_BIEN_ESCRITO' }),
                            {
                                preventDuplicate: true,
                                autoHideDuration: 2500,
                                variant: 'warning'
                            })
                        return status;
                    }}
                    chipRenderer={({ value, isDisabled, className, handleClick, handleDelete }, key) => (
                        <Chip
                            key={key}
                            label={`@${value}`}
                            disabled={isDisabled}
                            className={className}
                            clickable={false}
                            onClick={handleClick}
                            onDelete={handleDelete} color={validarDominios ? 'secondary' : 'default'} />
                    )}
                />
                <section style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: 20, height: 50 }}>
                    {cargandoDominios
                        ?
                        <CircularProgress size={15} style={{ marginRight: 10 }} color="secondary" />
                        :
                        <IconButton aria-label="refresh"
                            color="secondary"
                            onClick={() => coordinaFethDataDominiosIPs('ips')}
                            disabled={cargandoDominios}
                        >
                            <RefreshIcon />
                        </IconButton>

                    }
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={setupDialogoDominiosGuardar}
                        disabled={cargandoDominios}
                    >{intl.formatMessage({ id: '_GUARDAR' })}</Button>
                </section>
            </Paper>
            <Paper elevation={3} style={{ flexGrow: 1, marginTop: 20, padding: '10px', display: 'flex', flexDirection: 'column' }}>
                <section style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="subtitle1">{intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.TITULO_VALIDAR_IPS' })}</Typography>
                    <Switch
                        disabled={cargandoIPs}
                        checked={validarIps}
                        onChange={(e) => {
                            const bitIP = e.target.checked;
                            setValidarIps(bitIP)
                            if (bitIP && ips?.length === 0) {
                                setValidarIps(!bitIP);
                                enqueueSnackbar(intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.MSG.LISTA_IPS_VACIA_BIT_0' }), {
                                    variant: 'warning',
                                    preventDuplicate: true,
                                    autoHideDuration: 2500
                                })
                            }
                        }}
                        color="secondary" />
                </section>
                <Typography variant="caption">{intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.SUBTITULO_VALIDAR_IPS' })}</Typography>
                <Divider />
                <ChipInput
                    disabled={cargandoIPs}
                    style={{ marginTop: 20 }}
                    value={ips}
                    readOnly={false}
                    alwaysShowPlaceholder={true}
                    placeholder={ips?.length === 0
                        ? intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.PLACEHOLDER_INGRESA_IP' }, {
                            ip: `"${myPublicIp.v4}" ${myPublicIp.v6 ? `o "${myPublicIp.v6}"` : ''}`
                        })
                        : intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.PLACEHOLDER_INGRESA_IP_2' })}
                    onAdd={ip => {
                        if (ips?.length === 0)
                            setValidarIps(true)
                        setIps([...ips, ip])
                    }}
                    onDelete={(_, indx) => {
                        const aux = [...ips];
                        aux.splice(indx, 1);
                        setIps(aux)
                        if (aux.length === 0)
                            setValidarIps(false)
                    }}
                    onBeforeAdd={ip => {
                        const status = SH_validarIP(ip);
                        if (!status) enqueueSnackbar(intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.MSG.VERIFICA_IP_BIEN_ESCRITA' }),
                            {
                                preventDuplicate: true,
                                autoHideDuration: 2500,
                                variant: 'warning'
                            })
                        return status;
                    }}
                    chipRenderer={({ value, isDisabled, className, handleClick, handleDelete }, key) => (
                        <Chip
                            key={key}
                            label={value}
                            disabled={isDisabled}
                            className={className}
                            clickable={false}
                            onClick={handleClick}
                            onDelete={handleDelete} color={validarIps ? 'secondary' : 'default'} />
                    )}
                />
                <section style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: 20, height: 50 }}>
                    {cargandoIPs
                        ?
                        <CircularProgress size={15} style={{ marginRight: 10 }} color="secondary" />
                        :

                        <IconButton aria-label="refresh"
                            color="secondary"
                            onClick={() => coordinaFethDataDominiosIPs('dominios')}
                            disabled={cargandoIPs}
                        >
                            <RefreshIcon />
                        </IconButton>
                    }
                    <Button
                        variant="outlined"
                        color="secondary"
                        disabled={cargandoIPs}
                        onClick={setupDialogoIPSGuardar}
                    >Guardar</Button>
                </section>
            </Paper>
        </Grid>
    </Grid>

}


export default injectIntl(Seguridad)