import Currency from 'currency.js'
/**
 * Busca una subcadena dentro de otra cadena sin importar caracteres especiales, acentos, etc.
 * @param {*} subcadena 
 * @param {*} filtro 
 */
export const SH_FindInString = (cadena, subcadena) => {
    return cadena?.toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(
            subcadena?.toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
        )
}

/**
 * Validar que dominio ej. "hms.mx" est� bien formado.
 * @param {*} dominio 
 */
export const SH_validarDominio = (dominio) => {
    const regex_dominio = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g;
    let dest = dominio.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/\s/g, '')
        .toLowerCase();
    return regex_dominio.exec(dest);
}

/**
 * Validar que la IPV4 e IPV6 ej. "192.168.0.1" o "fe80::200:f8ff:fe21:67cf" est� bien formada.
 * @param {*} ip 
 */
export const SH_validarIP = (ip) => {
    const regex_ipv4 = /(^((25[0-5]|(2[0-4]|1[0-9]|[1-9]|)[0-9]|\*)(\.(?!$)|$)){4}$)|(^(\*)$)|(^(\*)(\.(?!$)){4}$)/;
    const regex_ipv6 = /(?:^|(?<=\s))(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))(?=\s|$)/
    let dest = ip.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/\s/g, '')
        .toUpperCase();
    return regex_ipv4.exec(dest) || regex_ipv6.exec(dest);
}

/**
 * Validar que correo es v�lido
 * @param {*} correo 
 */
export const SH_validarCorreoElectronico = (correo) => {
    const regex_correo = /\S+@\S+\.\S+/;
    return regex_correo.exec(correo);
}

/**
 * Imprimir formato numerico en moneda - especificando el simbolo 
 * @param {*} numerico 
 * @param {*} config 
 */
export const SH_formatCurrency = (numerico, options) => {
    let config = {};
    if(typeof options == 'string'){
        config = {
            symbol : options
        }
    } else if(typeof options == 'object'){
        config = options;
    } else {
        config = {
            symbol : '$ '
        }
    }
    let currencyJS = Currency(numerico, config).format();
    return currencyJS;
}
/**
 * Buscar formato fecha
 * @param {*} numerico 
 * @param {*} config 
 */
export const SH_FindInStringDate = (cadena, subcadena) => {
    console.log(cadena);
    return cadena
}