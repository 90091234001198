import React, {useState} from 'react'
import { injectIntl } from 'react-intl'
import { Autocomplete } from '@material-ui/lab'
import { debounce } from 'lodash'
import { 
  TextField, 
  Typography, 
  LinearProgress
} from '@material-ui/core'
import axios from 'axios'

const AutocompleteTarifario = ({ 
  intl, 
  lblAutocomplete = 'ORIGEN', 
  disabledBusquedaAvanzada = false,
  disabledFiltro = false,
  setDisableFiltro = null,
  valueFiltro = '',
  setValueFiltro = null,
  filtrosAvanzados = [],
  setFiltrosAvanzados = null
  }) => {
  // const lstResponse = [];
  const [cargandoAutocomplete, setCargandoAutocomplete] = useState(false);
  const [contentResponse, setContentResponse] = useState([]);

  const fetchAutocompleteAPI = async (busqueda) => {
    setCargandoAutocomplete(true);
    busqueda = busqueda.trim();
    
    let api_url = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/servicios/tarifario/api/autocompletecatalogos';
    api_url += '?strCatalogo='+lblAutocomplete?.toLowerCase()?.trim()+'&strBusqueda=' + busqueda || '';
    try {
      const response = await axios.get(api_url);
      const lstResponse = response.data.catalogo;
      setContentResponse(lstResponse);
    }
    catch (error) {
      console.log('error', error);
    }
    finally {
      setCargandoAutocomplete(false);
    }
  }

  const debounceFetchAutocompleteAPI = debounce(fetchAutocompleteAPI, 750);

  return <>
    <Autocomplete
      autoComplete={false}
      disabled={disabledBusquedaAvanzada}
      disabled={disabledFiltro}
      label={intl.formatMessage({ id: `AUTOCOMPLETE.TXT.${lblAutocomplete?.toUpperCase()}` })}
      // style={{ width: '50%' }}
      loading={cargandoAutocomplete}
      clearOnEscape={true}
      disableClearable={true}
      inputValue={valueFiltro}
      options={contentResponse}
      getOptionLabel={(catalogo) => catalogo.TITLE}
      noOptionsText={intl.formatMessage({ id: 'AUTOCOMPLETE.TXT.SIN_OPCIONES' })}
      loadingText={
        <section style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="overline">
                {intl.formatMessage({ id: '_CARGANDO' })}
            </Typography>
          <LinearProgress />
        </section>
      }
      renderInput={(params) => <TextField {...params}
          label={intl.formatMessage({ id: `AUTOCOMPLETE.TXT.${lblAutocomplete?.toUpperCase()}` })}
          onFocus={event => event.target.autocomplete = 'new-password'} // Hack para evitar autofill de Chrome
          onClick={event => {
            event.target.autocomplete = 'new-password';
            event.preventDefault();
            event.stopPropagation();
          }}
          onChange={(event) => {
            event.preventDefault();
            event.stopPropagation();
            debounceFetchAutocompleteAPI(event.target.value);
            setValueFiltro(event.target.value);
          }}
        />
      }
      onChange={
        (event, value) => {
          // console.log('Selecciono', event, value);
          if(value){
            let valorOrigen = (lblAutocomplete?.toLowerCase() == 'origen') ? value.ID : '';
            let valorDestino = (lblAutocomplete?.toLowerCase() == 'destino') ? value.ID : '';
            let valorVehiculo = (lblAutocomplete?.toLowerCase() == 'vehiculo') ? value.ID : '';
            setFiltrosAvanzados([...filtrosAvanzados, {
              label: value.TITLE,
              background: '',
              color: '',
              params: lblAutocomplete?.toLowerCase(),
              varMoneda: '',
              varOrigen: valorOrigen,
              varDestino: valorDestino,
              varVehiculo: valorVehiculo
            }]);
            setValueFiltro(value.TITLE);
            setDisableFiltro(true);
            setContentResponse([]);
          }
        }
      }
    />
  </>
}

export default injectIntl(AutocompleteTarifario);