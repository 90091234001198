import React, { useContext, useState } from 'react'
import { injectIntl } from 'react-intl'
import { Grid, Typography, LinearProgress } from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { FileIcon, defaultStyles } from 'react-file-icon';
import axios from 'axios';

function DocumentoServicio({ documento, indx, fnOnClick = null, cargando = false }) {

    if (!documento) return <></>;

    const { archivo, fecha, tipoDocumento, descripcionTipoDocumento, nombreDocumento, folio } = documento;

    // const tipo_indx = `${tipoDocumento}-${indx}`;

    let nombreDocumentoFinal = archivo;
    const extension = tipoDocumento.toUpperCase() === 'FA' ? 'pdf' : nombreDocumentoFinal.split('.').pop();

    // Verificamos si es un documento 'otros' (empiezan con "T"). De ser así les asignamos el nombre de documento
    nombreDocumentoFinal = tipoDocumento.toUpperCase().startsWith('T') 
        ? nombreDocumento 
        // Si es una factura le asignamos el nombre factura-xxxx (tipo-folio)
        : tipoDocumento.toUpperCase() === 'FA'
            ? descripcionTipoDocumento + ' ' + archivo
            : descripcionTipoDocumento


    const docStyles = defaultStyles;

    // PDF tonalidad rojo en Glyph
    docStyles.pdf.glyphColor = docStyles.pdf.labelColor;

    // Escala de verdes para imagenes
    docStyles.jpeg.glyphColor = "#1B998B";
    docStyles.jpeg.labelColor = "#1B998B";

    docStyles.jpg.glyphColor = "#1B998B";
    docStyles.jpg.labelColor = "#1B998B";

    docStyles.bmp.glyphColor = '#52E0D0';
    docStyles.bmp.labelColor = '#52E0D0';

    docStyles.png.glyphColor = '#126862';
    docStyles.png.labelColor = '#126862';

    // Negro para XML (Estilo XMl no existe por default, se a�ade a objeto)
    docStyles['xml'] = { type: 'document', glyphColor: '#000000', labelColor: '#000000' };

    return <>
        <Grid item container xs={12} className={cargando ? "cursor-default" : "cursor-hover"}
            style={{ display: 'flex', marginTop: '15px' }}
            onClick={() => { if (fnOnClick) { fnOnClick() } }}
        >
            <Grid item>
                <div style={{ height: '30px', width: '30px' }}>
                    <FileIcon extension={extension} {...docStyles[extension]} />
                </div>
            </Grid>
            <Grid item style={{ display: 'flex', flexDirection: 'column', paddingLeft: '15px', flexGrow: '1', justifyContent: 'center' }}>
                <Grid item style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}> {nombreDocumentoFinal} </Typography>
                    <Typography variant="body1" style={{ fontStyle: 'italic' }}> {fecha} </Typography>
                </Grid>
                <LinearProgress style={{ visibility: cargando ? 'visible' : 'hidden' }} />
            </Grid>
        </Grid>
    </>

}

export default injectIntl(DocumentoServicio);