/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useEffect } from "react";
import { Nav, Tab, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import axios from 'axios';
// import { injectIntl } from "react-intl";
import { useSelector } from "react-redux";
import './UserNotificationsDropdown.css';
import FWDModalServicio from 'app/componentes/FWDModalServicio';

import { Grid, CircularProgress, makeStyles, Typography} from '@material-ui/core';

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const useStylesFacebook = makeStyles((theme) => ({
  root: {
      position: 'relative',
  },
  bottom: {
      color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
      color: '#1a90ff',
      animationDuration: '550ms',
      position: 'absolute',
      left: 0,
  },
  circle: {
      strokeLinecap: 'round',
  },
}));

function Loading(props) {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </div>
  );
}

export function UserNotificationsDropdown() {

  const [contadorNuevasNotificaciones, set_contadorNuevasNotificaciones] = useState(0);
  const [arrayCategoriaNotificacion, set_arrayCategoriaNotificacion] = useState([{intCategoriaNotificacion: 0, strCategoriaNotificacion: ''}]);
  const [indexCategoriaSeleccionada, set_indexCategoriaSeleccionada] = useState(0);
  const [objNotificacionesPorCategoria, set_objNotificacionesPorCategoria] = useState(null);
  const [bitCargando, set_bitCargando] = useState(false);
  const [modalServicio, setModalServicio] = useState({ dblPedido: null, open: false });

  const { session } = useSelector(state => state.auth);

  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");
  const colores = {success: '#43a047', danger: '#d32f2f', warning: '#ff9800', info: '#2196f3'};
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.notifications.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  useEffect(() => {
  }, []);

  var actualizarListaNotificaciones = (intCategoriaNotificacion = null) => {
    set_bitCargando(true);
    axios.get(`${process.env.REACT_APP_AWS_API_BASE_URL}/portal/notificaciones/api/listar?strEmail=${session.username}`)
            .then(response => {
                if (response?.response?.data) {
                    let responseError = response.response.data;
                    if (responseError) {
                        alert(responseError.error);
                        console.log(responseError);
                    }
                }

                if (response.status === 200) {
                  let notificacionesAgrupadas = {};

                  response.data[0].forEach((notificacion) => {
                    if(!notificacionesAgrupadas.hasOwnProperty(notificacion.intCategoriaNotificacion)) {
                      notificacionesAgrupadas[notificacion.intCategoriaNotificacion] = {arrayNotificaciones: [], intCategoriaNotificacion: notificacion.intCategoriaNotificacion};
                    }

                    notificacionesAgrupadas[notificacion.intCategoriaNotificacion].arrayNotificaciones.push(notificacion);
                  });
                  set_objNotificacionesPorCategoria(notificacionesAgrupadas);
                  set_contadorNuevasNotificaciones(response.data[1]);
                  set_arrayCategoriaNotificacion(response.data[2]);
                  set_bitCargando(false);
                }
                  set_bitCargando(false);
            })
            .catch(error => {
                console.log("[actualizarListaNotificaciones][catch]", error);
                if (error) {
                    alert("Error inesperado al consultar notificaciones");
                }
              set_bitCargando(false);
            });
  };

  var marcarNotificacionComoVisto = (intCategoriaNotificacion, intNotificacion) => {

    if(objNotificacionesPorCategoria[intCategoriaNotificacion]?.arrayNotificaciones) {
      let objNotificacionesPorCategoria_aux = {...objNotificacionesPorCategoria};
      let length = objNotificacionesPorCategoria_aux[intCategoriaNotificacion]?.arrayNotificaciones?.length;
      
      for(let index = 0; index < length; index++) {
        if(objNotificacionesPorCategoria_aux[intCategoriaNotificacion]?.arrayNotificaciones[index]?.intNotificacion === intNotificacion) {
          objNotificacionesPorCategoria_aux[intCategoriaNotificacion].arrayNotificaciones[index].bitVisto = 1;
          break;
        }
      }

      set_objNotificacionesPorCategoria(objNotificacionesPorCategoria_aux);
    }

    //Por default restamos una notificacion
    //Pero la sobreescribimos cuando nos responda el servicio
    set_contadorNuevasNotificaciones(contadorNuevasNotificaciones > 0 ? contadorNuevasNotificaciones - 1 : 0);

    axios.post(`${process.env.REACT_APP_AWS_API_BASE_URL}/portal/notificaciones/api/marcar-visto?strEmail=${session.username}&intNotificacion=${intNotificacion}`)
    .then(response => {
      if (response.status === 200) {
        set_contadorNuevasNotificaciones(response.data.total);
      }
    })
    .catch(error => {
      console.log("[marcarNotificacionComoVisto][catch]", error);
    });
  }


  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
            id="kt_quick_notifications_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/bell-.svg")} />
            </span>
            <span className="pulse-ring"></span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown drop="down" onToggle={(isOpen) => {if(isOpen) {actualizarListaNotificaciones();} else {set_indexCategoriaSeleccionada(0); set_objNotificacionesPorCategoria(null);}}}>
          <FWDModalServicio bitOpen={modalServicio.open} dblPedido={modalServicio.dblPedido} fnClose={() => { setModalServicio({ open: false, dblPedido: null }) }}/>
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="kt_quick_notifications_toggle"
          
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-notification-tooltip">
                  Notificaciones
                </Tooltip>
              }
            >
              <div
                className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
                id="kt_quick_notifications_toggle"
              >
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Code/bell-.svg")}
                  />
                </span>
                <span className="pulse-ring"></span>
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-xl" >
            <form>
              {/** Head */}
              <div
                className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
                style={{ backgroundImage: `url(${bgImage})` }}
              >
                <h4 className="d-flex flex-center rounded-top">
                  <span className="text-white">Notificaciones</span>
                  {contadorNuevasNotificaciones > 0 ?
                  <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
                    {contadorNuevasNotificaciones} {contadorNuevasNotificaciones > 1 ? 'Nuevas' : 'Nueva'}
                  </span>
                  : <></> }
                </h4>

                <Tab.Container defaultActiveKey={indexCategoriaSeleccionada}>
                  <Nav
                    as="ul"
                    className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
                    onSelect={(_key) => set_indexCategoriaSeleccionada(_key)}
                  >
                    {arrayCategoriaNotificacion?.map((categoria, indexCategoria) => {
                      return <Nav.Item className="nav-item" as="li" key={`tab-item-${indexCategoria}`}>
                        <Nav.Link
                          eventKey={indexCategoria}
                          className={`nav-link show ${
                            indexCategoriaSeleccionada === indexCategoria ? "active" : ""
                          }`}
                        >
                          {categoria.strCategoriaNotificacion}
                        </Nav.Link>
                      </Nav.Item>
                    })}
                  </Nav>

                  <Tab.Content className="tab-content">
                    {arrayCategoriaNotificacion?.map((categoria, indexCategoria) => {
                      return <Tab.Pane eventKey={indexCategoria} style={{padding: '10px 10px 5px 10px'}} key={`tab-content-${indexCategoria}`}>
                        <PerfectScrollbar
                          options={perfectScrollbarOptions}
                          className="scroll pr-7 mr-n7"
                          style={{ maxHeight: "300px", position: "relative" }}
                        >
                          <Grid container direction='column' justify='center' alignItems="center" style={{border: 0}}>

                            {bitCargando 
                              ? <Grid item xs={12}>
                                <Loading></Loading>
                              </Grid>
                              : <Grid item container direction='column'>
                                { objNotificacionesPorCategoria ?
                                    objNotificacionesPorCategoria[categoria.intCategoriaNotificacion] 
                                      ? objNotificacionesPorCategoria[categoria.intCategoriaNotificacion]?.arrayNotificaciones?.map((notificacion, index) => {
                                          return <Grid item container 
                                            key={`notificacion-${notificacion.intNotificacion}`} 
                                            className={`mb-2 card-notificacion ${!notificacion.bitVisto ? 'noleido' : ''}`}
                                            style={{marginBottom: '10px', cursor: 'pointer', padding: '0px 5px 0px 5px'}}
                                            onClick={(event) => { eval(notificacion.strAccion); marcarNotificacionComoVisto(categoria.intCategoriaNotificacion, notificacion.intNotificacion);}}>
                                              <Grid 
                                                  item xs={1} 
                                                  style={{
                                                      alignItems: 'center', 
                                                      verticalAlign: 'middle', 
                                                      width: '100%',
                                                      position: 'relative',
                                                      margin: '3px 0px 2px 0px',
                                                  }} >
                                                    <Grid style={{
                                                        width: '30px',
                                                        height: '30px',
                                                        position: 'absolute',
                                                        backgroundColor: notificacion.strColor,
                                                        top: 0, bottom: 0, left: 0, right: 0, margin: 'auto',
                                                        borderRadius: '4px',
                                                    }}>
                                                      <img alt="logo" style={{ width: '100%', height: 'auto', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}} src='https://fwd.myhmsapps.com/FWD/Modulos/Seguridad/Catalogos/Logos/logoFWD.png' />
                                                    </Grid>
                                              </Grid>
                                              <Grid item container direction='column' xs={11} style={{textAlign: 'left', paddingLeft: '10px'}}>
                                                  <Grid item>
                                                      <span style={{float: 'left'}} className="text-dark-75 font-size-lg mb-0">{notificacion.strTitulo}</span>
                                                      <span style={{float: 'right', fontSize: '.6rem', margin: 0, padding: 0, lineHeight: '21px'}}>{notificacion.datFechaAlta}</span>
                                                  </Grid>
                                                  <Grid item>
                                                      <span className="text-dark-50 m-0 font-weight-normal" dangerouslySetInnerHTML={{__html: notificacion.strNotificacion}}>
                                                      </span>
                                                  </Grid>
                                              </Grid>
                                            </Grid>
                                        })
                                      : (objNotificacionesPorCategoria[categoria.intCategoriaNotificacion]?.arrayNotificaciones?.length > 0 
                                        ? <></>
                                        : 'No se encontraron notificaciones')
                                  : <></>
                                }
                              </Grid> }
                          </Grid>
                          <></>
                        </PerfectScrollbar>
                      </Tab.Pane>
                    })}
                    
                  </Tab.Content>
                </Tab.Container>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
