import React, { useState, useEffect } from "react"
import axios from 'axios'
import { injectIntl } from "react-intl";
import { useSelector } from "react-redux";
import FWDModal from 'app/componentes/FWDModal';
import Loading from 'app/componentes/Loading';
import { FileIcon, defaultStyles } from 'react-file-icon';

import {
    Paper, Grid, makeStyles, withStyles, Tooltip,
    Table, TableContainer, TableHead, TableBody, TableRow, TableCell,
    Tabs, Tab, Typography, Box, CircularProgress, LinearProgress
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import DescriptionIcon from '@material-ui/icons/Description';
import moneyFormat from 'app/formatos/Moneda';


const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
    },
}))(LinearProgress);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (<>
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    </>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    input: {
        textAlign: 'left'
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        padding: theme.spacing(2),
        //textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        marginTop: 0,
        marginLeft: 2,
        marginBottom: 0,
        marginRight: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    title: {
        flex: '1 1 100%',
    },
    button: {
        margin: theme.spacing(1),
    },
}));

function ModalFactura(props) {
    const { intl, onClose } = props;
    const { session, selectedClienteComercial } = useSelector(state => state.auth);
    const arrayCliente = session ? session.listaVarClientes.map((cliente) => { return `varCliente=${cliente.strVarClienteOrigen}`; }) : [];

    const classes = useStyles();
    const docStyles = defaultStyles;
    docStyles['xml'] = { type: 'document', glyphColor: '#000000', labelColor: '#000000' };

    const [bitCargandoModal, setBitCargandoModal] = useState(true);
    const [bitModalAbierto, setBitModalAbierto] = useState(props?.open || false);
    const [tabOpcionSeleccionada, setTabOpcionSeleccionada] = useState(0);

    const [dblFactura, setDblFactura] = useState(0);
    const [infoFactura, setInfoFactura] = useState(null);
    const [listaPagos, setListaPagos] = useState(null);
    const [listaServicios, setListaServicios] = useState(null);
    const [listaAcusesDigitales, setListaAcusesDigitales] = useState(null);
    const [listaAcusesFisicos, setListaAcusesFisicos] = useState(null);
    const [listaNotasDeCredito, setListaNotasDeCredito] = useState(null);
    const [loading_isOpen, setLoading_isOpen] = useState(false);

    useEffect(() => {
        setDblFactura(props.dblFactura);
        setBitCargandoModal(true);
        if (dblFactura === props.dblFactura && dblFactura !== 0) {
            consultarInfoFactura(props.dblFactura);
        }
    }, [dblFactura, props.dblFactura, selectedClienteComercial]);

    useEffect(() => {
        setBitModalAbierto(props.open);
    }, [bitModalAbierto, props.open]);

    const consultarInfoFactura = (dblFactura) => {
        console.log("[consultarInfoFactura]", dblFactura);
        axios.get(`${process.env.REACT_APP_AWS_API_BASE_URL}/portal/administracion/pagos/api/facturas/${dblFactura}?` + `varCliente=${selectedClienteComercial}`)
            .then(response => {
                if (response?.response?.data) {
                    let responseError = response.response.data;
                    if (responseError) {
                        alert(responseError.error);
                        console.log(responseError);
                    }
                }

                if (response.status === 200) {
                    console.log("[consultarInfoFactura][response.data]", response.data);
                    setInfoFactura(response.data[0][0] || null);
                    setListaPagos(response.data[1] ? (response.data[1].length > 0 ? response.data[1] : null) : null);
                    setListaServicios(response.data[2] ? (response.data[2].length > 0 ? response.data[2] : null) : null);
                    setListaNotasDeCredito(response.data[3] ? (response.data[3].length > 0 ? response.data[3] : null) : null);
                    setListaAcusesDigitales(response.data[4] ? (response.data[4][0]?.length > 0 ? response.data[4][0] : null) : null);
                    setListaAcusesFisicos(response.data[4] ? (response.data[4][1]?.length > 0 ? response.data[4][1] : null) : null);

                    console.log(response.data);
                }
                setBitCargandoModal(false);
            })
            .catch(error => {
                console.log("[consultarInfoFactura][catch]", error);
                if (error) {
                    alert("Error inesperado al consultar factura");
                }
                setBitCargandoModal(false);
            });
    }

    const abrirComplementoPago = (dblCobranzaTimbradoSAT, tipo = "pdf") => {
        console.log("[abrirComplementoPago]", dblCobranzaTimbradoSAT);
        setLoading_isOpen(true);
        axios.get(`${process.env.REACT_APP_AWS_API_BASE_URL}/portal/administracion/pagos/api/complementos/${tipo}/${dblCobranzaTimbradoSAT}`)
            .then(response => {
                console.log(response);
                if (response.status === 200) {
                    if (tipo === "pdf") {
                        window.open(response.data, "_blank", 'toolbar=0,scrollbars=1,location=0,statusbar=0,menubar=0,resizable=1,width=750,height=1000');
                    } else {
                        var link = document.createElement("a");
                        link.href = response.data;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                } else {
                    alert("Ha surgido un error inesperado al solicitar complemento de pago");
                }
                setLoading_isOpen(false);
            })
            .catch(error => {
                console.log("[abrirComplementoPago][catch]", error);
                if (error) {
                    alert("Ha surgido un error inesperado al solicitar complemento de pago");
                }
                setLoading_isOpen(false);
            });
    }

    const abrirAcuseDigital = (strDocumento) => {
        console.log("[abrirAcuseDigital]", strDocumento);
        setLoading_isOpen(true);

        axios.get(`${process.env.REACT_APP_AWS_API_BASE_URL}/portal/administracion/facturas/acuse-digital/${strDocumento}`)
            .then(response => {
                console.log(response);
                if (response.status === 200) {
                    window.open(response.data, "_blank", 'toolbar=0,scrollbars=1,location=0,statusbar=0,menubar=0,resizable=1,width=750,height=1000');
                } else {
                    alert("Ha surgido un error inesperado al solicitar acuse digital");
                }
                setLoading_isOpen(false);
            })
            .catch(error => {
                console.log("[abrirComplementoPago][catch]", error);
                if (error) {
                    alert("Ha surgido un error inesperado al solicitar acuse digital");
                }
                setLoading_isOpen(false);
            });
    }

    var abrirDocumentoFactura = (dblFactura, tipo = "pdf") => {
        console.log("[handleClick_abrirDocumentoFactura]", dblFactura);
        setLoading_isOpen(true);
        axios.get(`${process.env.REACT_APP_AWS_API_BASE_URL}/portal/administracion/estado-de-cuenta/api/documentos/facturas-${tipo}/${dblFactura}`)
            .then(response => {
                console.log(response);
                if (response.status === 200) {
                    if (tipo === "pdf") {
                        window.open(response.data, "_blank", 'toolbar=0,scrollbars=1,location=0,statusbar=0,menubar=0,resizable=1,width=750,height=1000');
                    } else {
                        var link = document.createElement("a");
                        link.href = response.data;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }

                } else {
                    alert("Ha surgido un error inesperado al solicitar el estado de cuenta");
                }
                setLoading_isOpen(false);
            })
            .catch(error => {
                console.log("[abrirDocumentoFactura][catch]", error);
                if (error) {
                    alert(intl.formatMessage({ id: "ADMINISTRACION.ESTADO_CUENTA.MENSAJE.ERROR_AL_DESCARGAR_DOCUMENTO" }));
                }
                setLoading_isOpen(false);
            });
    }

    return (<>
        <FWDModal
            open={bitModalAbierto}
            Titulo={() => <Grid style={{ width: '100%' }}>
                {bitCargandoModal
                    ? <Grid item container justify="space-between">
                        <Typography variant="h5">Cargando detalle de factura ...</Typography>
                    </Grid>
                    : <Grid item container justify="space-between">
                        <Typography variant="h6">Detalle de factura <strong>{infoFactura?.strFolio} {arrayCliente.length > 1 ? (' / ' + infoFactura?.strCliente) : ''}</strong> </Typography>
                        <Typography variant="h6">Estatus:  <strong>{infoFactura?.strEstatusFactura}</strong></Typography>

                    </Grid>
                }
            </Grid>}
            fnCloseModal={() => {
                if (onClose) {
                    setTabOpcionSeleccionada(0);
                    setInfoFactura(null);
                    setListaPagos(null);
                    setListaServicios(null);
                    onClose();
                }
            }}>
            <Loading open={loading_isOpen} mensaje="Cargando..."></Loading>
            {
                bitCargandoModal
                    ? <Grid container spacing={3} style={{ padding: '5px 15px 5px 15px' }}>
                        <Grid container item spacing={3}>
                            <Grid item container xs={5} style={{ justifyContent: 'center', alignItems: 'center' }}>
                                <CircularProgress />
                            </Grid>
                            <Grid item container xs={7} style={{ padding: '5px 0px 5px 5px' }}>
                                <Skeleton variant="rect" style={{ width: "100%", height: '100%' }} />
                            </Grid>
                        </Grid>

                    </Grid>
                    : <Grid container spacing={3} style={{ overflow: "auto", height: 'calc(80vh - 45px)', margin: '1px 1px 2px 0px' }}>
                        <Grid item container xs={5}>
                            <Grid item container>
                                {infoFactura ?
                                    <Paper className={classes.paper}>

                                        <Grid item container style={{ padding: '15px' }} xs={12} lg={12} spacing={3}>
                                            <Grid item container>
                                                <Grid item container xs={12} style={{ textAlign: "left", paddingBottom: '0' }}>
                                                    <Typography variant="h6" style={{ height: '30px' }} >
                                                        Factura <strong>{infoFactura?.strFolio}</strong>
                                                    </Typography>
                                                </Grid>
                                                <Grid item container xs={12} spacing={3}>
                                                    <Tooltip title={intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TOOLTIP.VER_PDF" }) + " - " + infoFactura?.strFolio}>
                                                        <button className="btn btn-link" onClick={() => { abrirDocumentoFactura(dblFactura, 'pdf') }}>
                                                            <div style={{ width: '30px', display: 'inline-block' }}>
                                                                <FileIcon extension={'pdf'} {...docStyles['pdf']} />
                                                            </div>
                                                        </button>
                                                    </Tooltip>
                                                    {infoFactura?.intFranquiciaEmisora === 1
                                                        ? <Tooltip title={intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TOOLTIP.DESCARGAR_XML" }) + " - " + infoFactura?.strFolio}>
                                                            <button className="btn btn-link" style={{ paddingLeft: 0 }} onClick={() => { abrirDocumentoFactura(dblFactura, 'xml') }}>
                                                                <div style={{ width: '30px', display: 'inline-block' }}>
                                                                    <FileIcon extension={'xml'} {...docStyles['xml']} />
                                                                </div>
                                                            </button>
                                                        </Tooltip>
                                                        : <></>}
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                        <Grid item container style={{ padding: '15px' }} xs={12} lg={12}>
                                            <Grid item container spacing={3}>
                                                <Grid item xs={5}><Typography variant="subtitle1">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.GENERALES.ITEM.FECHA_FACTURA" })}</Typography></Grid>
                                                <Grid item xs={7} style={{ textAlign: "left" }}><strong>{infoFactura?.datFechaFactura}</strong></Grid>
                                            </Grid>
                                            <Grid item container spacing={3}>
                                                <Grid item xs={5}><Typography variant="subtitle1">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.GENERALES.ITEM.FECHA_VENCIMIENTO" })}</Typography></Grid>
                                                <Grid item xs={7} style={{ textAlign: "left" }}><strong>{infoFactura?.datFechaVencimiento}</strong></Grid>
                                            </Grid>
                                            <Grid item container spacing={3}>
                                                <Grid item xs={5}><Typography variant="subtitle1">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.GENERALES.ITEM.FECHA_PAGO" })}</Typography></Grid>
                                                <Grid item xs={7} style={{ textAlign: "left" }}>
                                                    <strong>{infoFactura.datFechaPago ? infoFactura.datFechaPago : '--'}</strong>


                                                    {infoFactura.dblPendiente <= 0
                                                        ? <span style={{ color: infoFactura.diasParaCobro >= 0 ? 'green' : 'red' }}> ({infoFactura.diasParaCobro >= 0 ? infoFactura.diasParaCobro : (Number(infoFactura.diasParaCobro) * -1)}
                                                            {infoFactura.diasParaCobro >= 0
                                                                ? intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.GENERALES.ITEM.DIAS_ANTES_DE_PAGO" })
                                                                : intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.GENERALES.ITEM.DIAS_DESPUES_DE_PAGO" })})</span>
                                                        : ''}

                                                </Grid>
                                            </Grid>
                                            {infoFactura.dblPendiente > 0 ?
                                                <Grid item container spacing={3}>
                                                    <Grid item xs={5}>
                                                        <Typography variant="subtitle1">
                                                            {infoFactura.diasParaCobro >= 0
                                                                ? intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.GENERALES.ITEM.DIAS_PARA_VENCIMIENTO" })
                                                                : intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.GENERALES.ITEM.DIAS_DESDE_VENCIMIENTO" })}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={7} style={{ textAlign: "left", color: (infoFactura.diasParaCobro <= 0) ? 'red' : '' }}>
                                                        <strong>{infoFactura.diasParaCobro >= 0 ? infoFactura.diasParaCobro : (Number(infoFactura.diasParaCobro) * -1)}</strong>
                                                        {intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.GENERALES.ITEM.DIAS" })}
                                                    </Grid>
                                                </Grid>
                                                : ''}
                                        </Grid>
                                        <Grid item container style={{ padding: '15px' }} xs={12}>
                                            <Grid item container xs={12}>

                                                {/* Totales factura*/}
                                                <Grid item container spacing={3}>
                                                    <Grid item xs={5}><Typography variant="subtitle1">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.GENERALES.ITEM.SUBTOTAL" })}</Typography></Grid>
                                                    <Grid item xs={7} style={{ textAlign: "right" }}>{moneyFormat(infoFactura.dblSubtotal, infoFactura.strMoneda, "$")}</Grid>
                                                </Grid>
                                                {infoFactura.dblDescuento > 0 ?
                                                    <Grid item container spacing={3}>
                                                        <Grid item xs={5}><Typography variant="subtitle1">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.GENERALES.ITEM.DESCUENTO" })}</Typography></Grid>
                                                        <Grid item xs={7} style={{ textAlign: "right" }}>{moneyFormat(infoFactura.dblDescuento, infoFactura.strMoneda, "$")}</Grid>
                                                    </Grid>
                                                    : ''}
                                                {infoFactura.intFranquiciaEmisora === 1 ?
                                                    <Grid item container spacing={3}>
                                                        <Grid item xs={5}><Typography variant="subtitle1">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.GENERALES.ITEM.IVA" })}</Typography></Grid>
                                                        <Grid item xs={7} style={{ textAlign: "right" }}>{moneyFormat(infoFactura.dblIVA, infoFactura.strMoneda, "$")}</Grid>
                                                    </Grid>
                                                    : ''}
                                                {infoFactura.intFranquiciaEmisora === 1 && infoFactura.dblRetIVA > 0 ?
                                                    <Grid item container spacing={3}>
                                                        <Grid item xs={5}><Typography variant="subtitle1">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.GENERALES.ITEM.RET_IVA" })}</Typography></Grid>
                                                        <Grid item xs={7} style={{ textAlign: "right" }}>{moneyFormat(infoFactura.dblRetIVA, infoFactura.strMoneda, "$")}</Grid>
                                                    </Grid>
                                                    : ''}
                                                <Grid item container spacing={3}>
                                                    <Grid item xs={5}><Typography variant="h6">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.GENERALES.ITEM.TOTAL" })}</Typography></Grid>
                                                    <Grid item xs={7} style={{ textAlign: "right" }}><Typography variant="h6">{moneyFormat(infoFactura.dblTotal, infoFactura.strMoneda, "$")}</Typography></Grid>
                                                </Grid>

                                                {/* Totales abonados*/}
                                                <Grid item container spacing={3} style={{ paddingTop: '20px' }}>
                                                    <Grid item xs={5}><Typography variant="subtitle1">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.GENERALES.ITEM.TOTAL.ABONADO" })}</Typography></Grid>
                                                    <Grid item xs={7} style={{ textAlign: "right" }}>{moneyFormat(infoFactura.dblCobrado, infoFactura.strMoneda, "$")}</Grid>
                                                </Grid>
                                                {infoFactura.dblTotalNC > 0 ?
                                                    <Grid item container spacing={3}>
                                                        <Grid item xs={5}><Typography variant="subtitle1">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.GENERALES.ITEM.TOTAL.NOTA_DE_CREDITO" })}</Typography></Grid>
                                                        <Grid item xs={7} style={{ textAlign: "right" }}>{moneyFormat(infoFactura.dblTotalNC, infoFactura.strMoneda, "$")}</Grid>
                                                    </Grid>
                                                    : ''}
                                                <Grid item container spacing={3}>
                                                    <Grid item xs={5}><Typography variant="h6">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.GENERALES.ITEM.TOTAL.POR_PAGAR" })}</Typography></Grid>
                                                    <Grid item xs={7} style={{ textAlign: "right" }}><Typography variant="h6">{moneyFormat(infoFactura.dblPendiente, infoFactura?.strMoneda, "$")}</Typography></Grid>
                                                </Grid>

                                                <Grid item container xs={12} style={{ justifyContent: 'center', alignItems: 'center', paddingBottom: '20px', paddingTop: '20px' }}>
                                                    <Grid item xs={12}>
                                                        <BorderLinearProgress style={{ width: '100%', height: '5px' }} variant="determinate" value={((infoFactura.dblCobrado + infoFactura.dblTotalNC) * 100) / infoFactura?.dblTotal} />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="subtitle1">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.GENERALES.ITEM.PROGRESO_PAGO" })} <strong>{(((infoFactura?.dblCobrado + infoFactura.dblTotalNC) * 100) / infoFactura?.dblTotal).toFixed(2)} %</strong></Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Paper>
                                    : <Grid item container style={{ padding: '15px' }} xs={12} lg={12}>{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.GENERALES.MENSAJE.SIN_INFORMACION" })}</Grid>}
                            </Grid>

                        </Grid>
                        <Grid container item xs={7} style={{ padding: 0 }}>
                            <Paper square style={{ width: '100%', height: '70px' }}>
                                <Tabs
                                    variant="fullWidth"
                                    value={tabOpcionSeleccionada}
                                    onChange={(event, tabSeleccionado) => { setTabOpcionSeleccionada(tabSeleccionado); }}
                                    indicatorColor="secondary"
                                    textColor="secondary"
                                    aria-label="Tabs"
                                    style={{ width: '100%', height: '70px' }}
                                >
                                    <Tab style={{ backgroundColor: 'white' }} icon={<AccountBalanceWalletIcon />} label="Pagos" {...a11yProps(0)} />
                                    <Tab style={{ backgroundColor: 'white' }} icon={<LocalShippingIcon />} label="Servicios" {...a11yProps(1)} />
                                    <Tab style={{ backgroundColor: 'white' }} icon={<DescriptionIcon />} label="Acuses" {...a11yProps(2)} />
                                </Tabs>
                            </Paper>
                            <Paper square style={{ height: 'calc(100% - 70px)', width: '100%' }}>
                                {/* Pagos */}
                                <TabPanel value={tabOpcionSeleccionada} index={0} style={{ height: '100%', width: '100%' }} spacing={3}>
                                    <Grid container item lg={12}>
                                        <TableContainer component={Paper}>
                                            <Typography variant="subtitle1" style={{ padding: '15px' }}>{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.PAGOS.TITULO" })}</Typography>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="left">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.PAGOS.FECHA" })}</TableCell>
                                                        <TableCell align="left">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.PAGOS.CUENTA" })}</TableCell>
                                                        <TableCell align="left">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.PAGOS.FORMA_PAGO" })}</TableCell>
                                                        <TableCell align="left">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.PAGOS.REFERENCIA" })}</TableCell>
                                                        <TableCell align="right">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.PAGOS.MONTO" })}</TableCell>
                                                        {infoFactura?.intFranquiciaEmisora === 1
                                                            ? <TableCell align="center">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.PAGOS.COMPLEMENTO" })}</TableCell>
                                                            : <></>
                                                        }
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {listaPagos ?
                                                        listaPagos.map((pago, indexPago) => {
                                                            return <TableRow key={`pago-factura-${indexPago}`}>
                                                                <TableCell align="left">{pago.datFechaPago}</TableCell>
                                                                <TableCell align="left">{pago.strCuenta}</TableCell>
                                                                <TableCell align="left">{pago.strTipoPago}</TableCell>
                                                                <TableCell align="left">{pago.strReferenciaBancaria}</TableCell>
                                                                <TableCell align="right">{moneyFormat(pago.dblMonto, pago.strMoneda, "$")}</TableCell>
                                                                {infoFactura?.intFranquiciaEmisora === 1
                                                                    ? (pago.dblCobranzaTimbradoSAT
                                                                        ? <TableCell align="center" style={{ minWidth: '150px' }}>
                                                                            {pago.dblNumeroCobranza
                                                                                ? <Tooltip title={intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TOOLTIP.VER_PDF" }) + " - " + pago.strSerieSat + pago.dblNumeroCobranza}>
                                                                                    <button
                                                                                        className="btn btn-link" onClick={() => { abrirComplementoPago(pago.dblCobranzaTimbradoSAT, "pdf") }}
                                                                                        style={{ padding: 0 }}>
                                                                                        <div style={{ width: '20px', display: 'inline-block' }}>
                                                                                            <FileIcon extension={'pdf'} {...docStyles['pdf']} />
                                                                                        </div>
                                                                                    </button>
                                                                                </Tooltip>
                                                                                : ''}

                                                                            {pago.dblNumeroCobranza
                                                                                ? <Tooltip title={intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TOOLTIP.DESCARGAR_XML" }) + " - " + pago.strSerieSat + pago.dblNumeroCobranza}>
                                                                                    <button
                                                                                        className="btn btn-link" onClick={() => { abrirComplementoPago(pago.dblCobranzaTimbradoSAT, "xml") }}
                                                                                        style={{ padding: 0, paddingLeft: '10px' }}>
                                                                                        <div style={{ width: '20px', display: 'inline-block' }}>
                                                                                            <FileIcon extension={'xml'} {...docStyles['xml']} />
                                                                                        </div>
                                                                                    </button>
                                                                                </Tooltip>
                                                                                : ''}
                                                                        </TableCell>
                                                                        : <TableCell align="center" style={{ minWidth: '150px' }}></TableCell>)
                                                                    : <></>}
                                                            </TableRow>
                                                        })
                                                        : <TableRow><TableCell colSpan={6}>{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.PAGOS.MENSAJE.NO_HAY_PAGOS" })}</TableCell></TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                    <br />
                                    <br />
                                    {listaNotasDeCredito ?
                                        <Grid container item lg={12} >
                                            <TableContainer component={Paper}>
                                                <Typography variant="subtitle1" style={{ padding: '15px' }}>{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.NOTA_DE_CREDITO.TITULO" })}</Typography>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="left">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.NOTA_DE_CREDITO.FOLIO" })}</TableCell>
                                                            <TableCell align="left">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.NOTA_DE_CREDITO.FECHA" })}</TableCell>
                                                            <TableCell align="left">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.NOTA_DE_CREDITO.DESCRIPCION" })}</TableCell>
                                                            <TableCell align="left">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.NOTA_DE_CREDITO.ESTATUS" })}</TableCell>
                                                            <TableCell align="right">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.NOTA_DE_CREDITO.MONTO" })}</TableCell>
                                                            <TableCell align="center">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.NOTA_DE_CREDITO.COMPROBANTE" })}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {listaNotasDeCredito ?
                                                            listaNotasDeCredito.map((ndc, indexNdc) => {
                                                                return <TableRow key={`ndc-factura-${indexNdc}`}>
                                                                    <TableCell align="left">{ndc.strSerie + ndc.intFolio}</TableCell>
                                                                    <TableCell align="left">{ndc.datFecha}</TableCell>
                                                                    <TableCell align="left">{ndc.strTipoNotaCreditoCliente}</TableCell>
                                                                    <TableCell align="left">{ndc.strEstatus}</TableCell>
                                                                    <TableCell align="right">{moneyFormat(ndc.dblMonto, ndc.strMoneda, "$")}</TableCell>
                                                                    <TableCell align="center" style={{ minWidth: '150px' }}></TableCell>
                                                                </TableRow>
                                                            })
                                                            : <TableRow><TableCell colSpan={6}>{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.NOTA_DE_CREDITO.MENSAJE.NO_HAY_NDC" })}</TableCell></TableRow>
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        : <></>}
                                </TabPanel>
                                <TabPanel value={tabOpcionSeleccionada} index={1} style={{ height: '100%', width: '100%' }}>
                                    {/* Servicios */}
                                    <Grid container item lg={12}>
                                        <TableContainer component={Paper}>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="left">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.SERVICIOS.NO_SERVICIO" })}</TableCell>
                                                        <TableCell align="left">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.SERVICIOS.DESCRIPCION" })}</TableCell>
                                                        <TableCell align="right" style={{ padding: 0 }}>{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.SERVICIOS.SUBTOTAL" })}</TableCell>
                                                        <TableCell align="right" style={{ padding: 0 }}>{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.SERVICIOS.IVA" })}</TableCell>
                                                        <TableCell align="right" style={{ padding: 0 }}>{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.SERVICIOS.RET_IVA" })}</TableCell>
                                                        <TableCell align="right" style={{ padding: '0px 10px 0px 15px' }}>{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.SERVICIOS.TOTAL" })}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {listaServicios ?
                                                        listaServicios.map((servicio, indexPago) => {
                                                            return <TableRow key={`servicio-factura-${indexPago}`}>
                                                                <TableCell align="left">{servicio.strNumeroServicio}</TableCell>
                                                                <TableCell align="left" style={{ minWidth: '150px' }}>{servicio.strDescripcion}</TableCell>
                                                                <TableCell align="right" style={{ padding: 0, minWidth: '100px' }}>{moneyFormat(servicio.dblSubtotal, servicio.strMoneda, "$")}</TableCell>
                                                                <TableCell align="right" style={{ padding: 0, minWidth: '100px' }}>{moneyFormat(servicio.dblIVA, servicio.strMoneda, "$")}</TableCell>
                                                                <TableCell align="right" style={{ padding: 0, minWidth: '100px' }}>{moneyFormat(servicio.dblRetIVA, servicio.strMoneda, "$")}</TableCell>
                                                                <TableCell align="right" style={{ padding: '0px 10px 0px 15px', minWidth: '125px' }}>{moneyFormat(servicio.dblTotal, servicio.strMoneda, "$")}</TableCell>
                                                            </TableRow>
                                                        })
                                                        : <TableRow><TableCell colSpan={7}>{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.SERVICIOS.MENSAJE.NO_HAY_SERVICIO" })}</TableCell></TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={tabOpcionSeleccionada} index={2} style={{ height: '100%', width: '100%' }}>
                                    {/* Acuses */}
                                    {/* Acuses Digitales */}
                                    {listaAcusesDigitales
                                        ? <Grid container item lg={12}>
                                            <TableContainer component={Paper}>
                                                <Typography variant="subtitle1" style={{ padding: '15px' }}>{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.ACUSE_DIGITAL.TITULO" })}</Typography>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="left">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.ACUSE_DIGITAL.NO_SERVICIO" })}</TableCell>
                                                            <TableCell align="left">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.ACUSE_DIGITAL.FECHA_RECEPCION" })}</TableCell>
                                                            <TableCell align="center">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.ACUSE_DIGITAL.ACUSE" })}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {listaAcusesDigitales ?
                                                            listaAcusesDigitales.map((acuseDigital, indexAcuseDigital) => {
                                                                return <TableRow key={`acuse-digital-${indexAcuseDigital}`}>
                                                                    <TableCell align="left">{acuseDigital.strNumeroServicio}</TableCell>
                                                                    <TableCell align="left">{acuseDigital.datFechaHoraRecepcion}</TableCell>
                                                                    <TableCell align="center">
                                                                        <Tooltip title="Descargar">
                                                                            <button className="btn btn-link" style={{ padding: 0, marginTop: '-5px' }} onClick={() => { abrirAcuseDigital(acuseDigital.strAcuseDigital) }}>
                                                                                <div style={{ width: '20px', display: 'inline-block' }}>
                                                                                    <FileIcon extension={'pdf'} {...docStyles['pdf']} />
                                                                                </div>
                                                                            </button>
                                                                        </Tooltip>
                                                                    </TableCell>
                                                                </TableRow>
                                                            })
                                                            : <TableRow><TableCell colSpan={6}>{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.ACUSES.MENSAJE.NO_HAY_ACUSES" })}</TableCell></TableRow>
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        : <Typography variant="subtitle1" style={{ padding: '15px' }}>{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.ACUSES.MENSAJE.NO_HAY_ACUSES" })}</Typography>
                                    }

                                    <br />
                                    <br />

                                    {/* Acuses Fisicos */}
                                    {listaAcusesFisicos
                                        ? <Grid container item lg={12}>
                                            <TableContainer component={Paper}>
                                                <Typography variant="subtitle1" style={{ padding: '15px' }}>{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.ACUSE_FISICO.TITULO" })}</Typography>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="left">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.ACUSE_FISICO.NO_SERVICIO" })}</TableCell>
                                                            <TableCell align="left">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.ACUSE_FISICO.FECHA_ENVIO" })}</TableCell>
                                                            <TableCell align="left">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.ACUSE_FISICO.FECHA_RECEPCION" })}</TableCell>
                                                            <TableCell align="left">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.ACUSE_FISICO.PAQUETERIA" })}</TableCell>
                                                            <TableCell align="left">{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.ACUSE_FISICO.NO_GUIA" })}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {listaAcusesFisicos ?
                                                            listaAcusesFisicos.map((acuseFisico, indexAcuseFisico) => {
                                                                return <TableRow key={`acuse-fisico-${indexAcuseFisico}`}>
                                                                    <TableCell align="left">{acuseFisico.strNumeroServicio}</TableCell>
                                                                    <TableCell align="left">{acuseFisico.datFechaEnvio}</TableCell>
                                                                    <TableCell align="left">{acuseFisico.datFechaHoraRecepcion}</TableCell>
                                                                    <TableCell align="left">{acuseFisico.strPaqueteria}</TableCell>
                                                                    <TableCell align="left">
                                                                        <a className="btn btn-link" target="_blank" href={acuseFisico.strWebsiteGuia.replace("###", acuseFisico.strGuia)}>{acuseFisico.strGuia}</a>
                                                                    </TableCell>
                                                                </TableRow>
                                                            })
                                                            : <TableRow><TableCell colSpan={6}>{intl.formatMessage({ id: "ADMINISTRACION.MODAL_FACTURA.TABLA.ACUSES.MENSAJE.NO_HAY_ACUSES" })}</TableCell></TableRow>
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        : ''
                                    }
                                </TabPanel>
                            </Paper>
                        </Grid>
                    </Grid>
            }
        </FWDModal>
    </>
    );
}



export default injectIntl(ModalFactura);
