// Imports de ley
import { injectIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import axios from 'axios'

//Imports a componentes creados
import { AutocompleteCatalogos, AutocompleteRutaCatalogos, AutocompleteVehiculosCatalogos } from 'app/componentes/Solicitudes/AutocompleteCatalogos';
import { SelectoresCustomer, SelectoresCatalogos, SelectoresRutaCatalogos, SelectorEjecutivo } from 'app/componentes/Solicitudes/SelectoresCatalogos';
import NumberFormatMasks from 'app/componentes/uiFwdElements/InputsMasks';

//Imports a necesidades
import {
  Grid,
  Card,
  Typography,
  CardContent,
  FormControl,
  Paper,
  Tooltip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Fab,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  TextField,
  Divider,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Chip
} from '@material-ui/core'

import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/core/styles';
import { DropzoneArea } from 'material-ui-dropzone'; //Subir archivos
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Alert, AlertTitle } from '@material-ui/lab';

//Iconos
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import BlockIcon from '@material-ui/icons/Block';
import AddBoxIcon from '@material-ui/icons/AddBox';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionIcon from '@material-ui/icons/Description';
import SendIcon from '@material-ui/icons/Send';

//Estilos
import useStyles from 'app/componentes/servicios/serviciosEstilos/serviciosEstilos';
import { id } from 'date-fns/locale';

const _FormSolicitudCotizacion = ({ session = [] }) => {

  const classes = useStyles();
  const history = useHistory();
  const { selectedClienteComercial } = useSelector(state => state.auth);

  //============== Estados importantes

  const _sessionVarClientes = session.listaVarClientes; //Variable que almacena los datos de los clientes y usuario

  //Estados que hacen un funcionamiento de acuerdo al momento usado

  const [formItems, setFormItems] = useState([]); //Almacena los objetos nuevos y los actualizados
  const [formItemsOnly, setFormItemsOnly] = useState([]); //Almacena los objetos nuevos y los actualizados

  const [editableItemIndex, setEditableItemIndex] = useState(-1); //Estado que establece que elemento(index) es el que se actualizará
  const [editedFormItems, setEditedFormItems] = useState([

  ]);

  const [editRegister, setEditRegister] = useState(false);//Establece que el index es igual al elemento que se va a actualizar

  //Estados Generales que guardan a nivel global los datos de la cotizacion
  const [idClienteCotizacion, setIdClienteCotizacion] = useState(_sessionVarClientes.length == 1 ? _sessionVarClientes[0].strVarClienteOrigen : '');
  const [idTipoMercancia, setIdTipoMercancia] = useState('');
  const [descMercancia, setDescMercancia] = useState('');
  const [idEjecutivo, setIdEjecutivo] = useState('');
  const [ejecutivoNombre, setEjecutivoNombre] = useState(''); // Este es un ejemplo para el nombre del ejecutivo

  //Estados que almacenan los valores para el guardado de un nuevo objeto en el arreglo formItems
  const [objOrigen, setObjOrigen] = useState({});
  const [objDestino, setObjDestino] = useState({});
  const [objVehiculo, setObjVehiculo] = useState({});

  const [strTipoMercancia, setStrTipoMercancia] = useState('');
  const [strEjectivo, setStrEjecutivo] = useState('');

  const [strTipoServicio, setStrTipoServicio] = useState('');
  const [strVehiculo, setStrVehiculo] = useState('');
  const [strMoneda, setStrMoneda] = useState('');
  const [strUniMedida, setStrUniMedida] = useState('');
  const [strTipoFrecuencia, setStrTipoFrecuencia] = useState('');

  const [idOrigen, setIdOrigen] = useState({});
  const [idDestino, setIdDestino] = useState({});
  const [idVehiculo, setIdVehiculo] = useState({});
  const [idTipoServicio, setIdTipoServicio] = useState('');
  const [idMoneda, setIdMoneda] = useState('');
  const [idUniMedida, setIdUniMedida] = useState('');
  const [idTipoFrecuencia, setIdTipoFrecuencia] = useState('');

  const [idTipoServicioRuta, setIdTipoServicioRuta] = useState('');
  const [idMonedaRuta, setIdMonedaRuta] = useState('');
  const [idUniMedidaRuta, setIdUniMedidaRuta] = useState('');
  const [idTipoFrecuenciaRuta, setIdTipoFrecuenciaRuta] = useState('');

  const [intTarget, setIntTarget] = useState('');
  const [intTargetRuta, setIntTargetRuta] = useState('');
  const [intPeso, setIntPeso] = useState('');
  const [intPesoRuta, setIntPesoRuta] = useState('');
  const [intFrecuencia, setIntFrecuencia] = useState('');

  const [intFrecuenciaFormat, setIntFrecuenciaFormat] = useState('');

  const [notasRuta, setNotasRuta] = useState('');
  const [numeroRuta, setNumeroRuta] = useState(1); //AutoincrementarRuta
  const [hazmat, setHazmat] = useState([]); //Arreglo que guarda los elementos en hazmat
  const [NewHazmat, setNewHazmat] = useState(''); //Estado que guarda el nuevo elemento que se agregara al arreglo hazmat
  const [NewHazmatRuta, setNewHazmatRuta] = useState(''); //Estado que guarda el nuevo elemento que se agregara al arreglo hazmat

  const [radioButtonHazmanShow, setRadioButtonHazmanShow] = useState("0"); //Estado que establece el valor del radio button al seleccionar el Tipo de Carga
  const [radioButtonHazmanShowRuta, setRadioButtonHazmanShowRuta] = useState("0"); //Estado que establece el valor del radio button al seleccionar el Tipo de Carga
  const [selectValorAutocompleteOrigen, setSelectValorAutocompleteOrigen] = useState(null);
  const [selectValorAutocompleteDestino, setSelectValorAutocompleteDestino] = useState(null);
  const [selectValorAutocompleteVehiculo, setSelectValorAutocompleteVehiculo] = useState(null);
  const [vehiculoCleanAutoComplete, setVehiculoCleanAutoComplete] = useState(false);

  const [cleanAutocompletes, setCleanAutocompletes] = useState(false);

  //Abrir cuadros de dialogos
  const [openModal, setOpenModal] = useState(false)
  const [openAlertas, setOpenAlertas] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleClickAlertas = () => {
    setOpenAlertas(true);
  };

  const handleCloseAlertas = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlertas(false);
  };

  const [openModalsRuta, setOpenModalsRuta] = useState(formItems.map(() => false)); //Abre el modal con los valores del elemento dinamico creado

  const [openDialogGuardarCotizacion, setOpenDialogGuardarCotizacion] = useState(false)
  const [openDialogLimpiarForm, setOpenDialogLimpiarForm] = useState(false);
  const [openDialogEliminarRuta, setOpenDialogEliminarRuta] = useState(false);

  //Variable que guarda datos de usuario

  const sessionClientName = session.strUsuario;
  const sessionClientEmail = session.strEmail;


  //Abrir PopOvers de informacion
  const [openDialogValidarForm, setOpenDialogValidarForm] = useState(false); //Estado que valida el formulario

  const [camposVacios, setCamposVacios] = useState([]);

  const [selectedTexts, setSelectedTexts] = useState({
    strTipoServicio: '',
    strMoneda: '',
    strUniMedida: '',
    strTipoFrecuencia: '',
  });

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  //Dialogo de guardar solicitud nueva

  //================= INICIO --- Funciones importantes para la actualizacion y guardado del formulario  -- INICIO ======================//

  //Funcion que abre modal para actualizar una ruta
  const handleOpenModalRuta = (index) => {
    const updatedModals = [...openModalsRuta];
    updatedModals[index] = true;
    setOpenModalsRuta(updatedModals);
  };

  //Funcion que cierra modal para actualizar una ruta
  const handleCloseModalRuta = (index) => {
    const updatedModals = [...openModalsRuta];
    updatedModals[index] = false;
    setOpenModalsRuta(updatedModals);
  };

  //Funcion qque actualiza el valor del radio button
  const handleRadioButonHazman = (event) => {
    setRadioButtonHazmanShow(event.target.value);
  };

  //Funcion para testear la data guardada en los Estados
  const TestData = () => {
    const payload = {
      idTipoMercancia: idTipoMercancia,
      strDescMercancia: descMercancia,
      idCliente: idClienteCotizacion,
      idEjecutivo: idEjecutivo,
      idUsuario: session.strUsuario,
      strNombreUsuario: sessionClientName,
      strUsuario: session.username,
      idUsuarioEmail: sessionClientEmail,
      rutas: formItems
    }
    const newItem = {
      idOrigen: idOrigen.ID,
      idDestino: idDestino.ID,
      idVehiculo: idVehiculo.VALUE_ID,
      idTipoServicio: idTipoServicio,
      idMoneda: idMoneda,
      idUniMedida: idUniMedida,
      idTipoFrecuencia: idTipoFrecuencia,
      intTarget: intTarget,
      intFrecuencia: intFrecuencia,
      intPeso: intPeso,
      strOrigen: idOrigen.TITLE,
      strDestino: idDestino.TITLE,
      strVehiculo: idVehiculo.VALUE_TEXT,
      strTipoServicio: strTipoServicio,
      strMoneda: strMoneda,
      strUniMedida: strUniMedida,
      strTipoFrecuencia: strTipoFrecuencia,
      notasRuta: notasRuta,
      radioButtonHazmanShow: radioButtonHazmanShow,
      radioButtonHazmanShowRuta: radioButtonHazmanShow,
      hazmat: hazmat, // Un arreglo vacío
    };

    // Agrega el nuevo elemento al arreglo formItems
    setFormItemsOnly([...formItemsOnly, newItem]);

    console.log("Payload: ", payload);
    console.log("new item", formItemsOnly);
  };

  //Funciones para limpiar formulario nueva ruta
  const handleLimpiarFormulario = async () => {
    await limpiarFormulario();
    // Se ejecutará después de que limpiarFormulario haya terminado
    setCleanAutocompletes(false);
    setOpenDialogLimpiarForm(false);
  }

  const limpiarFormulario = () => {
    setCleanAutocompletes(true);
    setIdOrigen(null);
    setIdDestino(null);
    setIdVehiculo(null);
    setIdTipoServicio('')
    setIdMoneda('')
    setIdUniMedida('')
    setIdTipoFrecuencia('')
    setIntTarget('')
    setIntPeso('')
    setIntFrecuencia('')
    setIntFrecuenciaFormat('')
    setNotasRuta('')
    setRadioButtonHazmanShow('0')
    setObjOrigen({})
    setObjDestino({})
    setObjVehiculo({})
    setStrTipoMercancia('')
    setStrEjecutivo('')
    setStrTipoServicio('')
    setStrMoneda('')
    setStrUniMedida('')
    setStrTipoFrecuencia('')
    setHazmat([])
    setNewHazmat('')
    setNewHazmatRuta('')
    return new Promise((resolve) => {
      resolve();
    });
  };

  //Funcion que valida que campos estan vacios
  const handlerValidarCamposVacios = () => {

    const titulosCampos = {
      idClienteCotizacion: 'Cotizar a',
      idTipoMercancia: 'Tipo de Mercancia',
      descMercancia: 'Descripción de Mercancia',
      idOrigen: 'Origen',
      idDestino: 'Destino',
      idVehiculo: 'Vehiculo',
      idTipoServicio: 'Tipo de Servicio',
      intTarget: 'Target',
      idMoneda: 'Moneda',
      intPeso: 'Peso',
      idUniMedida: 'Unidad de Medida',
      intFrecuencia: 'Frecuencia',
      idTipoFrecuencia: 'Tipo de Frecuencia',
      notasRuta: 'Notas',
      radioButtonHazmanShow: 'Tipo de Carga',
    };

    const camposRequeridos = {
      idClienteCotizacion: idClienteCotizacion,
      idTipoMercancia: idTipoMercancia,
      descMercancia: descMercancia,
      idOrigen: idOrigen?.ID,
      idDestino: idDestino?.ID,
      idVehiculo: idVehiculo?.VALUE_TEXT,
      intTarget: intTarget,
      idMoneda: idMoneda,
    };

    const camposVacios = [];

    for (const campo in camposRequeridos) {
      if (!camposRequeridos[campo]) {
        camposVacios.push(titulosCampos[campo]);
      }
    }

    if (camposVacios.length > 0) {
      setCamposVacios(camposVacios);
      setOpenDialogValidarForm(true);
    } else {
      handleAgregarItem();
    }
  };

  //Funcion para agregar un nuevo objeto al arreglo formItems
  const handleAgregarItem = () => {

    // Valores iniciales para el nuevo elemento
    const nuevoTitulo = `Ruta No. ${numeroRuta}`;

    const newItem = {
      ruta: nuevoTitulo,
      idOrigen: idOrigen.ID,
      idDestino: idDestino.ID,
      idVehiculo: idVehiculo.VALUE_ID,
      idTipoServicio: idTipoServicio,
      idMoneda: idMoneda,
      idUniMedida: idUniMedida,
      idTipoFrecuencia: idTipoFrecuencia,
      intTarget: intTarget,
      intFrecuencia: intFrecuencia,
      intPeso: intPeso,
      strOrigen: idOrigen.TITLE,
      strDestino: idDestino.TITLE,
      strVehiculo: idVehiculo.VALUE_TEXT,
      strTipoServicio: strTipoServicio,
      strMoneda: strMoneda,
      strUniMedida: strUniMedida,
      strTipoFrecuencia: strTipoFrecuencia,
      notasRuta: notasRuta,
      radioButtonHazmanShow: radioButtonHazmanShow,
      radioButtonHazmanShowRuta: radioButtonHazmanShow,
      hazmat: hazmat, // Un arreglo vacío
    };

    // Agrega el nuevo elemento al arreglo formItems
    setEditableItemIndex(formItems.length); // Establece el elemento como editable
    setFormItems([...formItems, newItem]);

    setNumeroRuta(numeroRuta + 1);

    // Limpia los campos después de agregar el elemento
    setRadioButtonHazmanShow('0'); // Restablece el valor predeterminado a Regular
    setRadioButtonHazmanShowRuta('0'); // Restablece el valor predeterminado a Regular

    setHazmat([]); // Borra el arreglo hazmat
    handleLimpiarFormulario(); //Limpia el formulario
  };

  //Funcion para Editar un Elemento en el arreglo formItems
  const handleEditClick = (index) => {

    setEditableItemIndex(index);
    setEditRegister(true);

    //Selectores
    setIdTipoServicioRuta(formItems[index].idTipoServicio);
    setIdMonedaRuta(formItems[index].idMoneda);
    setIdUniMedidaRuta(formItems[index].idUniMedida);
    setIdTipoFrecuenciaRuta(formItems[index].idTipoFrecuencia);


    setEditedFormItems((prevEditedFormItems) => {
      const updatedItems = [...prevEditedFormItems];
      updatedItems[index] = { ...formItems[index] };

      return updatedItems;
    });

    handleOpenModalRuta(index);
    // Marcar el elemento como editado en el estado

  };

  //Funcione para Guardar la actualizacion de propiedades del Elemento(Hazmat) en el arreglo formItems

  const handleSaveClickHazmatRutas = (index) => {
    setFormItems((prevFormItems) =>
      prevFormItems.map((item, i) => (i === index ? editedFormItems[i] : item))
    );
  };

  const handleSaveClick = (index) => {
    // Obtiene el elemento editado

    setFormItems((prevFormItems) =>
      prevFormItems.map((item, i) => (i === index ? editedFormItems[i] : item))
    );

    setFormItems((prevFormItems) => {
      const updatedItems = [...prevFormItems];

      updatedItems[index] = {
        ...prevFormItems[index],
        idOrigen: updatedItems[index].idOrigen != objOrigen.ID && objOrigen.ID != undefined ? objOrigen.ID : updatedItems[index].idOrigen,
        strOrigen: updatedItems[index].idOrigen != objOrigen.ID && objOrigen.ID != undefined ? objOrigen.TITLE : updatedItems[index].strOrigen,
        idDestino: updatedItems[index].idDestino != objDestino.ID && objDestino.ID != undefined ? objDestino.ID : updatedItems[index].idDestino,
        strDestino: updatedItems[index].idDestino != objDestino.ID && objDestino.ID != undefined ? objDestino.TITLE : updatedItems[index].strDestino,
        idVehiculo: updatedItems[index].idVehiculo != objVehiculo.ID && objVehiculo.ID != undefined ? objVehiculo.ID : updatedItems[index].idVehiculo,
        strVehiculo: updatedItems[index].idVehiculo != objVehiculo.ID && objVehiculo.ID != undefined ? objVehiculo.TITLE : updatedItems[index].strVehiculo,
        idTipoServicio: idTipoServicio != '' ? idTipoServicio : updatedItems[index].idTipoServicio,
        strTipoServicio: strTipoServicio != '' ? strTipoServicio : updatedItems[index].strTipoServicio,
        idMoneda: idMoneda != '' ? idMoneda : updatedItems[index].idMoneda,
        strMoneda: strMoneda != '' ? strMoneda : updatedItems[index].strMoneda,
        idUniMedida: idUniMedida != '' ? idUniMedida : updatedItems[index].idUniMedida,
        strUniMedida: strUniMedida != '' ? strUniMedida : updatedItems[index].strUniMedida,
        idTipoFrecuencia: idTipoFrecuencia != '' ? idTipoFrecuencia : updatedItems[index].idTipoFrecuencia,
        strTipoFrecuencia: strTipoFrecuencia != '' ? strTipoFrecuencia : updatedItems[index].strTipoFrecuencia,
        intFrecuenciaFormat: intFrecuenciaFormat != '' ? intFrecuenciaFormat : updatedItems[index].intFrecuencia,
        intTarget: intTargetRuta != '' ? intTargetRuta : updatedItems[index].intTarget,
        intPeso: intPesoRuta != '' ? intPesoRuta : updatedItems[index].intPeso,
        intFrecuencia: intFrecuencia != '' ? intFrecuencia : updatedItems[index].intFrecuencia,
        notasRuta: notasRuta != '' ? notasRuta : updatedItems[index].notasRuta,
        radioButtonHazmanShow: radioButtonHazmanShow
      };

      return updatedItems;
    });

    setEditRegister(false);
    setEditableItemIndex(-1);
    handleCloseModalRuta(index);
  };

  //Funcion para Agregar datos adentro del arreglo hazmat
  const handleAddHazmat = () => {
    if (NewHazmat) {
      setHazmat([...hazmat, NewHazmat]);
    }
    setNewHazmat('');
  };

  //Funcion para eliminar un elemento dentro del arreglo hazmat
  const handleDeleteHazMat = (index) => {
    const updatedHazmat = [...hazmat];
    updatedHazmat.splice(index, 1);
    setHazmat(updatedHazmat);
  };

  const handleAddHazmatRuta = (index) => {
    if (NewHazmatRuta) {
      const updatedHazmat = [...editedFormItems[index].hazmat, NewHazmatRuta];
      setEditedFormItems((prevEditedFormItems) => {
        const updatedItems = [...prevEditedFormItems];
        updatedItems[index].hazmat = updatedHazmat;
        return updatedItems;
      });
      setNewHazmatRuta('');
      handleSaveClickHazmatRutas(index);

    }
  };

  // Función para eliminar un hazmat al arreglo ya existente
  const handleDeleteHazRuta = (index, hazIndex) => {
    setEditedFormItems((prevEditedFormItems) => {
      const updatedItems = [...prevEditedFormItems];
      updatedItems[index].hazmat.splice(hazIndex, 1);
      return updatedItems;
    });
  };

  const handleEditHazmatChange = (index, hazIndex, newValue) => {
    setEditedFormItems((prevEditedFormItems) => {
      const updatedItems = [...prevEditedFormItems];
      updatedItems[index].hazmat[hazIndex] = newValue;
      return updatedItems;
    });
  };

  //================= FIN -- Funciones importantes para la actualizacion y guardado del formulario -- FIN ======================//


  //=========INICIO Guardado de datos===============//

  // Función para guardar los datos en una API


  const saveDataToAPI = async () => {
    // console.log('Enviar >> ', data);

    const idCliente = idClienteCotizacion;

    const payload = {
      idTipoMercancia: idTipoMercancia,
      strDescMercancia: descMercancia,
      idCliente: idCliente,
      idEjecutivo: idEjecutivo,
      idUsuario: session.strUsuario,
      strNombreUsuario: sessionClientName,
      strUsuario: session.username,
      idUsuarioEmail: sessionClientEmail,
      rutas: formItems
    }

    console.log("envio de payload: ", payload);

    ///portal/servicios/solicitudes/api/registrardirecciones
    let api_url = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/servicios/solicitudes/api/registrarcotizacion?';
    api_url += `idCliente=${idCliente}&`;

    try {
      const response = await axios.put(api_url, payload);
      //Reedirigir a consulta de cotizaciones
      if (response.status == 200) {
        setShowSuccessMessage(true);
        setFormItems([]); //Vaciar arreglo formItems
        history.push('/servicios/consultar-cotizaciones');
      }
    }
    catch (error) {
      console.log('ERROR | Guardar Cotizacion ', error);
    }
  }

  // Función para manejar el guardado de datos
  const handleGuardarDatos = async () => {
    await saveDataToAPI();
  };

  //=========FIN Guardado de datos===============//

  //Funciones para guardar Guardar valores de los selects / Autocompletes
  const handleEliminarRuta = (index) => {
    const updatedItems = [...formItems];
    updatedItems.splice(index, 1); // Elimina el elemento en el índice especificado
    setFormItems(updatedItems);
    setEditedFormItems(updatedItems);
    setOpenDialogEliminarRuta(false)
  };


  const handleInputChange = (index, field, value) => {
    // Verificar si el elemento editado existe antes de modificarlo
    if (editedFormItems[index]) {
      setEditedFormItems((prevEditedFormItems) => {
        const updatedItem = { ...prevEditedFormItems[index], [field]: value };
        const updatedEditedFormItems = [...prevEditedFormItems];
        updatedEditedFormItems[index] = updatedItem;
        return updatedEditedFormItems;
      });
    }
  };


  //Actualiza Registros ya agregados al FormITems para autocompletes
  //Funciones valores autocompletes
  const autocompleteOrigen = (item) => {
    setIdOrigen(item);
  };
  const autocompleteDestino = (item) => {
    setIdDestino(item);
  };
  const autocompleteVehiculo = (item) => {
    console.log("setIdVehiculo: ", idVehiculo);
    setIdVehiculo(item);
  };
  //Funcion para guardar el valor del autocomplete origen dinamico
  const handleOrigenChangeEdit = (selectedOrigen) => {
    setObjOrigen(selectedOrigen);
    setSelectValorAutocompleteOrigen(selectedOrigen)
  };

  //Funcion para guardar el valor del autocomplete destino dinamico
  const handleDestinoChangeEdit = (selectedDestino) => {
    setObjDestino(selectedDestino);
    setSelectValorAutocompleteDestino(selectedDestino)
  };

  //Funcion para guardar el valor del autocomplete vehiculo dinamico
  const handleVehiculoChangeEdit = (selectedVehiculo) => {
    setObjVehiculo(selectedVehiculo);
    setSelectValorAutocompleteVehiculo(selectedVehiculo)
  };

  //Funcion para guardar el valor tipo servicio origen dinamico
  const handleTipoServicioEdit = (selectTipoServicio) => {
    setStrTipoServicio(selectTipoServicio);
  };

  const handleVehiculo = (selectVehiculo) => {
    setStrVehiculo(selectVehiculo);
  };

  //Funcion para guardar el valor de moneda dinamico
  const handleMonedaEdit = (selectedMoneda) => {
    setStrMoneda(selectedMoneda);
  };

  //Funcion para guardar el valor de unidad medida dinamico
  const handleUniMedidaEdit = (selectedUniMedida) => {
    setStrUniMedida(selectedUniMedida);
  };

  //Funcion para guardar el valor del frecuencia dinamico
  const handleTipoFrecuenciaEdit = (selectedTipoFrecuencia) => {
    setStrTipoFrecuencia(selectedTipoFrecuencia);
  };

  const handleTipoMercanciaEdit = (selectTipoMercancia) => {
    setStrTipoMercancia(selectTipoMercancia);
  };

  const handleEjecutivoEdit = (selectedItem) => {
    // Aquí puedes hacer algo con el nombre del ejecutivo si es necesario
    setEjecutivoNombre(selectedItem);
  };


  //Funcion para guardar el valor del radio button dinamico
  const handleRadioCargaChangeRuta = (value, index) => {

    setRadioButtonHazmanShowRuta(value)
    handleSaveClickHazmatRutas(index);
    const newValue = value;
    // Actualiza el registro recién agregado en el estado editedFormItems
    setEditedFormItems((prevEditedFormItems) => {
      const lastIndex = prevEditedFormItems.length - 1;
      return prevEditedFormItems.map((item, index) => {
        if (index === lastIndex) {
          return { ...item, radioButtonHazmanShow: newValue, radioButtonHazmanShowRuta: newValue };
        }
        return item;
      });
    });
  };

  //Formateo de numeros a miles Target
  const handleIntTargetChange = (index, targetName, event) => {
    const inputText = event?.target?.value;
    if (targetName != null) {
      setIntTargetRuta(event);
    } else {
      setIntTarget(event);
    }
  };

  //Formateo de numeros a miles Peso
  const handleIntPesoChange = (index, targetName, event) => {
    if (targetName != null) {
      setIntPesoRuta(event);
    } else {
      setIntPeso(event);
    }
  };

  //Formateo de numeros Frecuencia
  const handleIntFrecuenciaChange = (event) => {
    const inputValue = event.target.value;
    // Valida si el valor es un número y tiene una longitud de 3
    if (/^\d{0,3}$/.test(inputValue)) {
      setIntFrecuencia(inputValue);
    }
  };

  //Funcion para guardar el valor de los selectores dinamicos
  const updateSelectedText = (catalogName, textValue) => {
    setSelectedTexts((prevTexts) => ({
      ...prevTexts,
      [catalogName]: textValue,
    }));
  };

  const handleSelectedValueChange = (newValue, index, property) => {
    // Actualiza el valor seleccionado en el elemento editado
    updateSelectedText(newValue, property);

    const updatedEditedItems = [...editedFormItems];

    updatedEditedItems[index][property] = newValue;
    setEditedFormItems(updatedEditedItems);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'array' });

        const sheetName = workbook.SheetNames[0]; // Supongamos que estamos interesados en la primera hoja.
        const worksheet = workbook.Sheets[sheetName];

        // Convierte los datos de la hoja de cálculo en un objeto JSON
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        // Ahora `jsonData` contiene los datos del archivo Excel en formato JSON
        console.log(jsonData);
      };

      reader.readAsArrayBuffer(file);
    }
  };

  return <>

    <Grid container spacing={1}>
      <Grid item md={12}>
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item md={6}>
                <Typography gutterBottom variant="h5" component="h2">
                  Solicitar Cotización
                </Typography>
              </Grid>
              <Grid item md={6}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {/* <Button space={1} style={{ margin: 2, width: 100 }} variant="contained" color="secondary" onClick={() => TestData()}>Payload</Button> */}
                  <Button startIcon={<ArrowBackIcon />} space={1} style={{ margin: 2, width: 100 }} variant="contained" color="secondary" component={Link} to="/servicios/consultar-cotizaciones">Regresar</Button>
                  {/* <Button startIcon={<CloudUploadIcon />} space={1} style={{ margin: 3, width: 120 }} variant="contained" color="default" onClick={() => setOpenModal(true)}>Documentos</Button> */}
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={1} style={{ marginTop: 10 }}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <Typography gutterBottom variant="h5" component="h5">
                        Datos de la Cotización
                      </Typography>
                    </Grid>
                    <Grid item md={3}>
                      {(_sessionVarClientes.length != 1) ? (
                        <SelectoresCustomer
                          error={idClienteCotizacion == '' ? true : false}
                          label="Cotizar a"
                          catalogName="clienteCotizacion"
                          additionalFilter={''}
                          selectedValue={idClienteCotizacion}
                          setSelectedValue={setIdClienteCotizacion}
                          onItemSelected={handleTipoMercanciaEdit}
                        />
                      ) : (
                        <div style={{textAlign: 'center', marginLeft: '30%' }}>
                          <Typography style={{ color: '#7d7d7d', marginTop: 1, fontSize: 10, textAlign: 'left' }}>Cotizar a</Typography>
                          <Typography style={{ color: 'black', fontSize: 14, textAlign: 'left' }}>{_sessionVarClientes[0].strFranquicia}</Typography>
                        </div>
                      )}
                    </Grid>
                    <Grid item md={2}>
                      <SelectoresCatalogos
                        error={idTipoMercancia == '' ? true : false}
                        label="Tipo de Mercancía"
                        catalogName="tipoproductoservicio"
                        additionalFilter={''}
                        selectedValue={idTipoMercancia}
                        setSelectedValue={setIdTipoMercancia}
                        onItemSelected={handleTipoMercanciaEdit}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <TextField
                        error={descMercancia == '' ? true : false}
                        autoComplete="off"
                        label={'Descripción Mercancía'}
                        type='string'
                        style={{ width: '100%' }}
                        value={descMercancia}
                        onChange={(event) => setDescMercancia(event.target.value)}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <SelectorEjecutivo
                        label="Ejecutivo"
                        catalogName="ejecutivocotizacion"
                        additionalFilter={_sessionVarClientes[0]?.strVarClienteOrigen}
                        selectedValue={idEjecutivo}
                        setSelectedValue={setIdEjecutivo}
                        onItemSelected={handleEjecutivoEdit}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: 10 }}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Grid container spacing={1}>
                    <Grid item md={12}>
                      <Typography gutterBottom variant="h5" component="h5">
                        Datos de la Ruta
                      </Typography>
                    </Grid>
                    <Grid item md={4}>
                      <AutocompleteCatalogos
                        catalogName="origen"
                        label="Origen"
                        cleanAutocomplete={cleanAutocompletes}
                        onItemSelected={autocompleteOrigen}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <AutocompleteCatalogos
                        catalogName="destino"
                        label="Destino"
                        cleanAutocomplete={cleanAutocompletes}
                        onItemSelected={autocompleteDestino}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <AutocompleteVehiculosCatalogos
                        catalogName="vehiculoservicio"
                        label="Tipo de Vehiculo"
                        cleanAutocomplete={cleanAutocompletes}
                        onItemSelected={autocompleteVehiculo}
                      />
                    </Grid>
                    <Grid item md={3} style={{ marginTop: 4 }}>
                      <SelectoresCatalogos
                        label="Tipo de Servicio"
                        catalogName="tiposervicio"
                        filter1={''}
                        selectedValue={idTipoServicio}
                        setSelectedValue={setIdTipoServicio}
                        onItemSelected={(selectedItem) => handleTipoServicioEdit(selectedItem)}
                      />
                    </Grid>
                    <Grid item md={1} style={{ marginTop: 4 }}>
                      <NumberFormatMasks
                        error={intTarget == '' ? true : false}
                        label={'Target'}
                        prefix={'$'}
                        value={intTarget}
                        onChange={(e) => handleIntTargetChange(null, null, e)}
                      />
                    </Grid>
                    <Grid item md={2} style={{ marginTop: 4 }}>
                      <SelectoresCatalogos
                        error={idMoneda == '' ? true : false}
                        label="Moneda"
                        catalogName="monedas"
                        filter1={''}
                        selectedValue={idMoneda}
                        setSelectedValue={setIdMoneda}
                        onItemSelected={(selectedItem) => handleMonedaEdit(selectedItem)}
                      />
                    </Grid>
                    <Grid item md={1} style={{ marginTop: 4 }}>
                      <NumberFormatMasks
                        label={'Peso'}
                        prefix={''}
                        value={intPeso}
                        onChange={(e) => handleIntPesoChange(null, null, e)}
                      />
                    </Grid>
                    <Grid item md={2} style={{ marginTop: 4 }}>
                      <SelectoresCatalogos
                        label="Unidad Medida"
                        catalogName="unidadmedidacotizacion"
                        filter1={''}
                        error={false}
                        selectedValue={idUniMedida} // Valor inicial
                        setSelectedValue={(newValue) => setIdUniMedida(newValue)}
                        onItemSelected={(selectedItem) => handleUniMedidaEdit(selectedItem)}
                      />
                    </Grid>
                    <Grid item md={1} style={{ marginTop: 4 }}>
                      <TextField
                        autoComplete="off"
                        label={'Frecuencia'}
                        type='text'
                        style={{ width: '100%' }}
                        value={intFrecuencia}
                        onChange={handleIntFrecuenciaChange}
                        inputProps={{
                          maxLength: 3, // Limita la longitud a 3 caracteres
                        }}
                      />
                    </Grid>
                    <Grid item md={2} style={{ marginTop: 4 }}>
                      <SelectoresCatalogos
                        label="Tipo de Frecuencia"
                        catalogName="frecuenciacotizacion"
                        filter1={''}
                        error={false}
                        selectedValue={idTipoFrecuencia}
                        setSelectedValue={setIdTipoFrecuencia}
                        onItemSelected={(selectedItem) => handleTipoFrecuenciaEdit(selectedItem)}
                      />
                    </Grid>
                    <Grid item md={7} style={{ marginTop: 4 }}>
                      <TextField
                        autoComplete="off"
                        id="outlined-multiline-flexible"
                        label="Notas"
                        multiline
                        rowsMax="8"
                        style={{ width: '100%' }}
                        value={notasRuta}
                        onChange={(event) => setNotasRuta(event.target.value)}
                      />
                    </Grid>
                    <Grid item md={3} style={{ marginTop: 4 }}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Tipo de Carga</FormLabel>
                        <RadioGroup row aria-label="carga" name="cargaRadio" defaultValue="0" value={radioButtonHazmanShow} onChange={handleRadioButonHazman}>
                          <FormControlLabel value="0" control={<Radio color="primary" />} label="Regular" />
                          <FormControlLabel value="1" control={<Radio color="primary" />} label="Hazmat" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item md={2} style={{ marginTop: 4 }}>
                      {radioButtonHazmanShow == "1" && ( // Muestra el siguiente elemento solo si cargaTipo es "1"
                        <Paper className={classes.paper}>
                          <Grid container spacing={2}>
                            <Grid item md={8}>
                              <TextField
                                autoComplete="off"
                                id="outlined-multiline-flexible"
                                label="UN"
                                style={{ width: '100%' }}
                                value={NewHazmat}
                                inputProps={{ maxLength: 4 }}
                                onChange={(e) => setNewHazmat(e.target.value)}
                              />
                            </Grid>
                            <Grid item md={4}>
                              <Fab size="small" color="secondary" aria-label="Add" className={classes.margin} disabled={hazmat.length >= 5} onClick={() => handleAddHazmat()}>
                                <AddIcon />
                              </Fab>
                            </Grid>
                            {hazmat.map((haz, index) => (
                              <>
                                <Grid item md={8}>
                                  UN-<strong>{haz}</strong>
                                </Grid>
                                <Grid item md={4}>
                                  <IconButton style={{ marginTop: '-15px' }} aria-label="Eliminar" onClick={() => handleDeleteHazMat(index)}>
                                    <DeleteIcon style={{ color: 'red' }} />
                                  </IconButton>
                                </Grid>
                              </>
                            ))}

                          </Grid>
                        </Paper>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Divider style={{ marginTop: 30 }} />
            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 15 }}>
              <Grid space={11} style={{ margin: 1 }}>
                <div style={{ padding: '6px' }}>
                  <Chip style={{ backgroundColor: '#f5000082', color: 'white' }} label={formItems.length == 10 ? 'El limite máximo de rutas por solicitud es de 10*' : 'Campos Obligatorios*'} />
                </div>
              </Grid>
              <Grid space={4} style={{ margin: 1 }}>
                <Button startIcon={<BlockIcon />} space={4} variant="outlined" className={classes.buttonWarning} disabled={idOrigen?.ID != null || idDestino?.ID != null || idVehiculo?.VALUE_ID != null || intTarget != '' || idMoneda != '' ? false : true} onClick={() => setOpenDialogLimpiarForm(true)}>Limpiar Campos</Button>
                {/* <Button startIcon={<DescriptionIcon />} space={1} style={{ margin: 2, width: 'auto', color: '#098d68' }} variant="outlined" color="primary">
                  <input type="file" onChange={handleFileUpload} />
                </Button> */}
                <Button startIcon={<AddBoxIcon />} space={4} disabled={formItems.length == 10} style={{ margin: 2, width: 'auto', marginLeft: 8 }} variant="outlined" color="secondary" onClick={handlerValidarCamposVacios}>Agregar Nueva Ruta</Button>
                <Button startIcon={<SendIcon />} space={4} className={classes.buttonSave} style={{ marginLeft: 8 }} variant="contained" onClick={() => setOpenDialogGuardarCotizacion(true)}>Enviar Cotización</Button>
              </Grid>
            </div>
          </CardContent>
        </Card>
      </Grid>

      {/* FORMULARIO DE ACTUALIZACION*/}
      {formItems.map((item, index) => (
        <>
          {/* Inicio Modal de actualizacion de ruta */}
          <Dialog onClose={handleCloseModalRuta} aria-labelledby="customized-dialog-title" open={openModalsRuta[index]}>
            <MuiDialogTitle style={{ backgroundColor: '#3f4254', color: 'white' }} id="customized-dialog-title" onClose={handleCloseModalRuta}>
              <Typography style={{ fontWeight: 600 }} variant="h5" component="h5">{item.ruta} - {item.strTipoServicio}</Typography>
              <IconButton aria-label="close" className={classes.closeButton} onClick={() => handleCloseModalRuta(index)}>
                <CloseIcon />
              </IconButton>
            </MuiDialogTitle>
            <DialogContent dividers>
              <Paper elevation={2} >
                <Grid container>
                  <Card>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item md={6}>
                          <AutocompleteRutaCatalogos
                            catalogName="origen"
                            label="Origen"
                            inputValueAutocomplete={item.strOrigen} // Usa el valor seleccionado o el valor del item existente
                            onItemSelected={(selectedItem) => handleOrigenChangeEdit(selectedItem)}
                            selectedValue={selectValorAutocompleteOrigen}
                            setSelectedValue={(value) => setSelectValorAutocompleteOrigen(value)}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <AutocompleteRutaCatalogos
                            error={selectValorAutocompleteDestino === '' ? true : false}
                            catalogName="destino"
                            label="Destino"
                            inputValueAutocomplete={item.strDestino}
                            onItemSelected={(selectedItem) => handleDestinoChangeEdit(selectedItem)}
                            selectedValue={selectValorAutocompleteDestino}
                            setSelectedValue={(value) => setSelectValorAutocompleteDestino(value)}
                          />

                        </Grid>

                        <Grid item md={6}>
                          <AutocompleteRutaCatalogos
                            error={selectValorAutocompleteVehiculo === '' ? true : false}
                            catalogName="vehiculo"
                            label="Vehiculo"
                            inputValueAutocomplete={item.strVehiculo}
                            onItemSelected={(selectedItem) => handleVehiculoChangeEdit(selectedItem)}
                            selectedValue={selectValorAutocompleteVehiculo}
                            setSelectedValue={(value) => setSelectValorAutocompleteVehiculo(value)}
                          />
                        </Grid>
                        <Grid item md={6}>

                          <SelectoresRutaCatalogos
                            error={false}
                            label="Tipo de Servicio"
                            catalogName="tiposervicio"
                            selectedValue={idTipoServicioRuta} // Asegúrate de que item.idTipoServicio sea el valor correcto
                            setSelectedValue={(newValue) => handleSelectedValueChange(newValue, index, 'idTipoServicio')}
                            editedFormItems={editedFormItems}
                            onItemSelected={(selectedItem) => handleTipoServicioEdit(selectedItem)}
                          />
                        </Grid>

                        <Grid item md={6}>
                          <NumberFormatMasks
                            error={editedFormItems[index]?.intTarget == '' ? true : false}
                            label={'Target'}
                            prefix={'$'}
                            value={editedFormItems[index]?.intTarget}
                            onChange={(e) => handleIntTargetChange(index, 'intTarget', e)}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <SelectoresRutaCatalogos
                            error={editedFormItems[index]?.idMoneda == '' ? true : false}
                            label="Moneda"
                            catalogName="monedas"
                            selectedValue={idMonedaRuta}
                            setSelectedValue={(newValue) => handleSelectedValueChange(newValue, index, 'idMoneda')}
                            editedFormItems={editedFormItems}
                            onItemSelected={(selectedItem) => handleMonedaEdit(selectedItem)}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <NumberFormatMasks
                            label={'Peso'}
                            prefix={''}
                            value={editedFormItems[index]?.intPeso || ''}
                            onChange={(e) => handleIntPesoChange(index, 'intPeso', e)}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <SelectoresRutaCatalogos
                            label="Unidad de Medida"
                            catalogName="unidadmedidacotizacion"
                            selectedValue={idUniMedidaRuta}
                            setSelectedValue={(newValue) => handleSelectedValueChange(newValue, index, 'idUniMedida')}
                            editedFormItems={editedFormItems}
                            onItemSelected={(selectedItem) => handleUniMedidaEdit(selectedItem)}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <TextField
                            autoComplete="off"
                            type='text'
                            label="Cantidad Frecuencia"
                            style={{ width: '100%' }}
                            value={editedFormItems[index]?.intFrecuencia || ''}
                            onChange={(e) => handleInputChange(index, 'intFrecuencia', e.target.value)}
                            inputProps={{
                              maxLength: 3, // Limita la longitud a 3 caracteres
                            }}

                          />
                        </Grid>
                        <Grid item md={6}>
                          <SelectoresRutaCatalogos
                            label="Frecuencia"
                            catalogName="frecuenciacotizacion"
                            selectedValue={idTipoFrecuenciaRuta}
                            setSelectedValue={(newValue) => handleSelectedValueChange(newValue, index, 'idTipoFrecuencia')}
                            editedFormItems={editedFormItems}
                            onItemSelected={(selectedItem) => handleTipoFrecuenciaEdit(selectedItem)}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <TextField
                            autoComplete="off"
                            id="outlined-multiline-flexible"
                            label="Notas"
                            multiline
                            rowsMax="8"
                            style={{ width: '100%' }}
                            value={editedFormItems[index]?.notasRuta || ''}
                            onChange={(e) => handleInputChange(index, 'notasRuta', e.target.value)}

                          />
                        </Grid>
                        <Grid item md={6}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">Tipo de Carga</FormLabel>
                            <RadioGroup
                              row
                              aria-label="carga"
                              name="cargaRadio"
                              value={editedFormItems[index]?.radioButtonHazmanShowRuta} // Usar el valor de editedFormItems
                              onChange={(e) => handleRadioCargaChangeRuta(e.target.value, index)}
                            >
                              <FormControlLabel value="0" control={<Radio color="primary" />} label="Regular" />
                              <FormControlLabel value="1" control={<Radio color="primary" />} label="Hazmat" />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid item md={6}></Grid>
                        <Grid item md={6}>
                          {editedFormItems[index]?.radioButtonHazmanShow == 1 ?
                            (
                              <Paper className={classes.paper}>
                                <Grid container spacing={2}>
                                  {item.hazmat.length == 5 ? (
                                    <Grid item md={12}>
                                      <div style={{ padding: '16px' }}>
                                        <span style={{ color: '#d90404', fontSize: '15px', fontWeight: 'bold' }}>El límite es de 5 Registros*</span>
                                      </div>
                                    </Grid>
                                  ) : (<>
                                    <Grid item md={8}>
                                      <TextField
                                        autoComplete="off"
                                        id="outlined-multiline-flexible"
                                        label="UN"
                                        style={{ width: '100%' }}
                                        value={NewHazmatRuta}
                                        inputProps={{ maxLength: 4 }}
                                        onChange={(e) => setNewHazmatRuta(e.target.value)}
                                      />
                                    </Grid>
                                    <Grid item md={4}>
                                      <Fab
                                        size="small"
                                        color="secondary"
                                        aria-label="Add"
                                        className={classes.margin}
                                        onClick={() => handleAddHazmatRuta(index)}
                                        disabled={item.hazmat.length >= 5}
                                      >
                                        <AddIcon />
                                      </Fab>
                                    </Grid>
                                  </>)}

                                  {item.hazmat.map((haz, hazIndex) => (
                                    <Grid container spacing={2} key={hazIndex}>

                                      <Grid item md={8}>
                                        <TextField
                                          autoComplete="off"
                                          value={editedFormItems[index]?.hazmat?.[hazIndex] || ''}
                                          inputProps={{ maxLength: 4 }} // Limitar a 4 caracteres
                                          onChange={(e) => handleEditHazmatChange(index, hazIndex, e.target.value)}
                                        />
                                      </Grid>
                                      <Grid item md={4}>
                                        <IconButton style={{ marginTop: '-15px' }} aria-label="Eliminar" onClick={() => handleDeleteHazRuta(index, hazIndex)}>
                                          <DeleteIcon style={{ color: 'red' }} />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Grid>
                              </Paper>
                            ) : (
                              <></>
                            )}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Paper>
            </DialogContent>
            <DialogActions>
              <Grid space={12} style={{ margin: 1 }}>
                <Button startIcon={<BlockIcon />} space={1} variant="outlined" onClick={() => handleCloseModalRuta(index)}>Cancelar</Button>
                <Button startIcon={<SaveIcon />} space={1} style={{ margin: 2, width: 'auto' }} disabled={editedFormItems[index]?.intTarget == '' || editedFormItems[index]?.idMoneda == '' ? true : false} variant="outlined" color="primary" onClick={() => handleSaveClick(index)}>Actualizar Ruta</Button>
              </Grid>
            </DialogActions>
          </Dialog >
          {/* Fin Modal de Actualizacion de ruta */}
          <Grid item md={12}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item md={12}>
                        <Typography gutterBottom style={{ fontWeight: 600 }} variant="h5" component="h5">
                          {item.ruta}
                        </Typography>
                      </Grid>
                      <div style={{ display: 'contents', justifyContent: 'flex-end', padding: 5, alignItems: 'flex-end', textAlign: 'right' }}>
                        <Grid item md={12}>
                          <Grid container>
                            <Grid item md={12} style={{ margin: 1 }}>
                              <Button startIcon={<EditIcon />} variant="outlined" className={classes.buttonWarning} onClick={() => handleEditClick(index)}>
                                Editar Ruta
                              </Button>
                              <Button startIcon={<DeleteIcon />} variant="outlined" className={classes.buttonDelete} onClick={() => handleEliminarRuta(index)}>
                                Eliminar Ruta
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                      <Grid item md={12}>
                        <TableContainer component={Paper}>
                          <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                              <TableRow>
                                <TableCell style={{ width: '25%' }}>Origen</TableCell>
                                <TableCell style={{ width: '20%' }}>Destino</TableCell>
                                <TableCell style={{ width: '20%' }}>Vehiculo</TableCell>
                                <TableCell style={{ width: '20%' }}>Tipo Servicio</TableCell>
                                <TableCell style={{ width: '30%' }}>Target</TableCell>
                                <TableCell>Moneda</TableCell>
                                <TableCell style={{ width: '15%' }}>Peso</TableCell>
                                <TableCell>Uni.Medida</TableCell>
                                <TableCell>Frecuencia</TableCell>
                                <TableCell>Tipo de Frecuencia</TableCell>
                                <TableCell>Tipo de Carga</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  {item.strOrigen}
                                </TableCell>
                                <TableCell>
                                  {item.strDestino}
                                </TableCell>
                                <TableCell>
                                  {item.strVehiculo}
                                </TableCell>
                                <TableCell>
                                  {item.strTipoServicio}
                                </TableCell>
                                <TableCell>
                                  {'$ ' + Number(item.intTarget).toLocaleString()}
                                </TableCell>
                                <TableCell>
                                  {item.strMoneda}
                                </TableCell>
                                <TableCell>
                                  {Number(item.intPeso).toLocaleString()}
                                </TableCell>
                                <TableCell>
                                  {item.strUniMedida}
                                </TableCell>
                                <TableCell>
                                  {item.intFrecuencia}
                                </TableCell>
                                <TableCell>
                                  {item.strTipoFrecuencia}
                                </TableCell>
                                <TableCell>
                                  {item.radioButtonHazmanShowRuta == '1' ? (
                                    <Tooltip title={item.hazmat.length != 0 ? (
                                      // <ul> {item.hazmat.map((item, index) => ( <li key={index}>{item}</li> ))} </ul>
                                      <List>
                                        {item.hazmat.map((item, index) => (
                                          <ListItem key={index}>
                                            <ListItemText
                                              primary={'UN: ' + item}
                                            />
                                          </ListItem>
                                        ))}
                                      </List>
                                    ) : 'No hay datos'} placement="top">
                                      <Button>Hazmat <VisibilityIcon /></Button>
                                    </Tooltip>) : 'Regular'}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </>
      ))}
    </Grid >
    {/* Inicio Modal de Documentos */}
    <Dialog onClose={() => setOpenModal(false)} aria-labelledby="customized-dialog-title" open={openModal}>
      <MuiDialogTitle style={{ backgroundColor: '#3f4254', color: 'white' }} id="customized-dialog-title" onClose={() => setOpenModal(false)}>
        <Typography variant="h6">Documentos</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={() => setOpenModal(false)}>
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <DialogContent dividers>
        <Paper elevation={2} >
          <Grid container spacing={1}>
            <Grid item md={12}>
              <Card>
                <CardContent>
                  <DropzoneArea
                    showPreviews={true}
                    showPreviewsInDropzone={false}
                    useChipsForPreview
                    previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                    previewChipProps={{ classes: { root: classes.previewChip } }}
                    previewText="Seleccionar Archivos"
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={12}>
              <Card>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h5">
                    Documentos
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Paper>
      </DialogContent>
    </Dialog >
    {/* Fin Modal de Documentos */}
    {/* Inicio Alerta de Confirmaciones */}

    {/* Alerta para ver campos vacios */}
    <Dialog open={openDialogValidarForm} onClose={() => setOpenDialogValidarForm(false)}>
      <DialogTitle>Alerta!</DialogTitle>
      <DialogContent>
        <p>Por favor, completa la información en los siguientes campos:</p>
        <ul>
          {camposVacios.map((campo) => (
            <li key={campo} style={{ color: '#d90404' }}>{campo}</li>
          ))}
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialogValidarForm(false)}>Cerrar</Button>
      </DialogActions>
    </Dialog>
    {/* Limpiar Formulario */}
    <Dialog
      key={`dialog-limpiar`}
      open={openDialogLimpiarForm}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Aviso:"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          ¿Deseas limpiar la información del formulario?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="default" variant="outlined" onClick={() => setOpenDialogLimpiarForm(false)}>
          Cancelar
        </Button>
        <Button color="primary" variant="outlined" autoFocus onClick={handleLimpiarFormulario}>
          Limpiar
        </Button>
      </DialogActions>
    </Dialog>

    {/* Guardar Cotizacion */}
    <Dialog
      key={`dialog-Guardar`}
      open={openDialogGuardarCotizacion}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Aviso:"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {formItems.length != 0 ? '¿Esta seguro de enviar los datos de cotizacion?' : 'Debes Agregar al menos una ruta!'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="default" variant="outlined" onClick={() => setOpenDialogGuardarCotizacion(false)}>
          Cerrar
        </Button>
        {formItems.length != 0 ? (<Button startIcon={<SendIcon />} color="primary" variant="outlined" autoFocus onClick={handleGuardarDatos}>
          Enviar
        </Button>) : ''}

      </DialogActions>
    </Dialog>
    {/* <Snackbar open={openAlertas} autoHideDuration={6000} onClose={handleCloseAlertas}>
      <Alert onClose={handleCloseAlertas} severity="success">
        This is a success message!
      </Alert>
    </Snackbar> */}
    {/* Fin de Alertas de Confirmaciones */}
    {showSuccessMessage == 1 ? (<Snackbar open={true} autoHideDuration={6000}>
      <Alert severity="success">
        La solicitud fue registrada correctamente!
      </Alert>
    </Snackbar>) : (<></>)}

  </>
}

const FormSolicitudCotizacion = injectIntl(_FormSolicitudCotizacion);

const CrearSolicitudes = (props) => {
  const { intl } = props;
  const { session } = useSelector(state => state.auth);
  const [cargandoPagina, setCargandoPagina] = useState(true);
  const [listaVarClientes, setListaVarClientes] = useState([]);

  useEffect(() => {
    const _sessionVarClientes = session.listaVarClientes;
    const _listaVarClientes = _sessionVarClientes.map(x => x.strVarClienteOrigen);
    setListaVarClientes(_listaVarClientes);
  }, [])

  return <>

    <Grid container spacing={2}>
      <Grid item md={12}>
        <FormSolicitudCotizacion
          cargandoPagina={cargandoPagina}
          setCargandoPagina={setCargandoPagina}
          listaVarClientes={listaVarClientes}
          session={session}
        ></FormSolicitudCotizacion>
      </Grid>
    </Grid>
  </>
}

export default injectIntl(CrearSolicitudes)