import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import locale_es from "date-fns/locale/es";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { useSnackbar } from 'notistack';


import React, { useState, useEffect } from "react"
import axios from 'axios'
import { injectIntl } from "react-intl";
import { useSelector } from "react-redux"
import "react-datepicker/dist/react-datepicker.css";
import './ConsultarEstadoCuenta.css'
import Loading from 'app/componentes/Loading';
import FWDModalServicio from 'app/componentes/FWDModalServicio';
import moneyFormat from 'app/formatos/Moneda';
import { LoaderDashboardTables } from 'app/componentes/dashboard/LoaderDashboard'

import {
    Paper, Grid, makeStyles, InputAdornment, FormControl, InputLabel, Button,
    Input, IconButton, Chip, MenuItem, useTheme, Select, Checkbox,
    Table, TableContainer, TableHead, TableBody, TableRow, TableCell,
    AppBar, Tabs, Tab, Typography, Box, LinearProgress
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SearchIcon from '@material-ui/icons/Search';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ModalFactura from 'app/componentes/ModalFactura'

import formatDate from 'app/formatos/Fecha';

import { FWDCabecera, FWDTableHead, FWDOrderHelper } from 'app/componentes/FWDTableHead'
import ScrollContainer from 'react-indiana-drag-scroll'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ width: '100%' }}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        padding: theme.spacing(2),
        //textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        marginTop: 0,
        marginLeft: 2,
        marginBottom: 0,
        marginRight: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    title: {
        flex: '1 1 100%',
    },
    button: {
        margin: theme.spacing(1),
    },
}));

const useInputStyles = makeStyles(theme => ({
    input: {
        textAlign: 'left'
    }
}))

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function ConsultarEstadoCuenta(props) {
    const today = new Date();
    const { intl } = props;
    const { session, selectedClienteComercial } = useSelector(state => state.auth);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [loading_isOpen, setLoading_isOpen] = useState(false);
    const [modalServicio, setModalServicio] = useState({ dblPedido: null, open: false })

    const [datFechaInicio, setDatFechaInicio] = useState(new Date(today.getFullYear(), today.getMonth() - 1, 1));
    const [datFechaFin, setDatFechaFin] = useState(new Date());

    const [listaFranquiciaEmisora, setListaFranquiciaEmisora] = useState(null);
    const [listaFacturasPorFranquiciaEmisora, setListaFacturasPorFranquiciaEmisora] = useState(null);

    const [listaFacturas, setListaFacturas] = useState(null);
    const [franquiciaEmisoraSeleccionada, setFranquiciaEmisoraSeleccionada] = useState(null);

    const [listaFacturasSeleccionadas, setListaFacturasSeleccionadas] = useState([]);

    const [bitAbrirModalFactura, setBitAbrirModalFactura] = useState(false);
    const [dblFacturaSeleccionada, setDblFacturaSeleccionada] = useState(0);


    const [bitBloquearVista, setBitBloquearVista] = useState(false);
    const [strBusqueda, setStrBusqueda] = useState('');
    const [indexTabSeleccionado, setIndexTabSeleccionado] = useState(0);

    const [resetOrdenamiento, setResetOrdenamiento] = useState(null)

    const classes = useStyles();
    const theme = useTheme();

    var arrayStringEstatusFactura = ["Pagada", "Por pagar", "Parcialmente pagada"];
    const [arrayEstatusFacturaSeleccionados, setArrayEstatusFacturaSeleccionados] = useState(arrayStringEstatusFactura);

    var arrayCliente = session ? session.listaVarClientes.map((cliente) => { return `varCliente=${cliente.strVarClienteOrigen}`; }) : [];

    useEffect(() => {
        //Actualizamos la lista de facturas en pantalla cada vez que seleccionan un tab de empresa emisora
        setListaFacturas(listaFacturasPorFranquiciaEmisora != null ? listaFacturasPorFranquiciaEmisora[indexTabSeleccionado].arrayFacturas : null);
        setFranquiciaEmisoraSeleccionada(listaFranquiciaEmisora != null ? listaFranquiciaEmisora[indexTabSeleccionado] : null);
    }, [indexTabSeleccionado]);

    useEffect(() => {
        //Actualizamos la lista de facturas en pantalla cada vez que se descargan nuevas facturas
        setListaFacturas(listaFacturasPorFranquiciaEmisora != null ? listaFacturasPorFranquiciaEmisora[indexTabSeleccionado].arrayFacturas : null);
        setFranquiciaEmisoraSeleccionada(listaFranquiciaEmisora != null ? listaFranquiciaEmisora[indexTabSeleccionado] : null);
    }, [listaFacturasPorFranquiciaEmisora]);

    var handleResize = () => {
        var datFechaInicio = document.getElementById("datFechaInicio");
        var datFechaFin = document.getElementById("datFechaFin");
        var contenedorFechas = document.getElementById("contenedor-rango-fecha");

        var inputWidth = contenedorFechas ? ((contenedorFechas.offsetWidth - 161) / 2) : 0;

        if (datFechaInicio && inputWidth > 0) {
            datFechaInicio.style.width = inputWidth + 'px';
        }

        if (datFechaFin && contenedorFechas && inputWidth > 0) {
            datFechaFin.style.width = inputWidth + 'px';
        }

        var cmbEstatusFactura = document.getElementById("cmbEstatusFactura");
        var contenedorCmbEstatusFactura = document.getElementById("contenedor-cmbEstatusFactura");
        if (cmbEstatusFactura && contenedorCmbEstatusFactura) {
            cmbEstatusFactura.childNodes[0].style.width = (contenedorCmbEstatusFactura.offsetWidth - 120) + "px";
        }
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    var objetToSimpleArray = (obj) => {
        let array = [];
        Object.keys(obj).forEach((itemKey) => {
            if (obj[itemKey]) {
                array.push(obj[itemKey]);
            }
        });
        return array;
    }

    var consultarEstadosDeCuenta = (ordenamiento = []) => {
        setBitBloquearVista(true);
        let arrayVarEstatusFactura = arrayEstatusFacturaSeleccionados.map(estatusFactura => { return `varEstatusFactura=${estatusFactura}`; });
        let apiURL = `${process.env.REACT_APP_AWS_API_BASE_URL}/portal/administracion/estado-de-cuenta/api/consultar?` + `&varCliente=${selectedClienteComercial}` + (strBusqueda ? `&strBusqueda=${strBusqueda}` : ``) + `&` + arrayVarEstatusFactura.join('&') + `&datFechaInicio=${formatDate(datFechaInicio)}&datFechaFin=${formatDate(datFechaFin)}`

        const strOrdenamiento = FWDOrderHelper(ordenamiento)
        if (strOrdenamiento.length) apiURL += '&ordenamiento=' + strOrdenamiento

        axios.get(apiURL)
            .then(response => {
                if (response.hasOwnProperty('response')) {
                    if (response.response.hasOwnProperty('data')) {
                        let responseError = response.response.data;
                        if (responseError) {
                            enqueueSnackbar(responseError.error, {
                                autoHideDuration: 7000,
                                preventDuplicate: true,
                                variant: 'error',
                                onClick: () => closeSnackbar()
                            })
                            console.log(responseError);
                        }
                    }
                }

                if (response.status === 200) {
                    let listaFacturas = response.data[0];
                    let obj_listaFranquiciaEmisora = {};

                    // Creamos un objeto por cada franquicia encontrada
                    let obj_listaFacturasPorFranquiciaEmisora = {};
                    listaFacturas.forEach(factura => {
                        //Generamos la lista de franquicias
                        if (!obj_listaFranquiciaEmisora[factura.intFranquiciaEmisora]) {
                            obj_listaFranquiciaEmisora[factura.intFranquiciaEmisora] = {
                                intFranquiciaEmisora: factura.intFranquiciaEmisora,
                                strFranquiciaEmisora: factura.strFranquiciaEmisora,
                                bitColumnaEstatusExtendida: false,
                                bitColumnaFechaVencimientoExtendida: false,
                            };
                        }

                        //Formateamos los datos de la factura
                        factura.arrayServicios = JSON.parse(factura.jsonPedido);
                        factura.strFechaFactura = factura.datFechaFactura;
                        factura.strFechaVencimiento = factura.datFechaVencimiento;
                        factura.strTotal = moneyFormat(factura.dblTotal, factura.strMoneda, '$');
                        factura.strCobrado = moneyFormat(factura.dblCobrado, factura.strMoneda, '$');
                        factura.strPendiente = moneyFormat(factura.dblPendiente, factura.strMoneda, '$');

                        // Hacemos validaciones para ajustar los anchos de las columnas
                        if (factura.strEstatusFactura === "Parcialmente pagada") {
                            obj_listaFranquiciaEmisora[factura.intFranquiciaEmisora].bitColumnaEstatusExtendida = true;
                        }

                        if (factura.intDiasVencidos < 0 && factura.dblPendiente > 0) {
                            obj_listaFranquiciaEmisora[factura.intFranquiciaEmisora].bitColumnaFechaVencimientoExtendida = true;
                        }

                        //Agremaos agrupamos la factura
                        if (!obj_listaFacturasPorFranquiciaEmisora[factura.intFranquiciaEmisora]) {
                            obj_listaFacturasPorFranquiciaEmisora[factura.intFranquiciaEmisora] = {
                                intFranquiciaEmisora: factura.intFranquiciaEmisora,
                                strFranquiciaEmisora: factura.strFranquiciaEmisora,
                                arrayFacturas: [factura],
                            };
                        } else {
                            obj_listaFacturasPorFranquiciaEmisora[factura.intFranquiciaEmisora].arrayFacturas.push(factura);
                        }
                    });
                    let franquicias = objetToSimpleArray(obj_listaFranquiciaEmisora);
                    let facturas = objetToSimpleArray(obj_listaFacturasPorFranquiciaEmisora);
                    setListaFranquiciaEmisora(franquicias.length > 0 ? franquicias : null);
                    setListaFacturasPorFranquiciaEmisora(facturas.length > 0 ? facturas : null);
                } else {
                    setListaFranquiciaEmisora(null);
                    setListaFacturasPorFranquiciaEmisora(null);
                }
                setBitBloquearVista(false);
                setListaFacturasSeleccionadas([]);
            })
            .catch(error => {
                setBitBloquearVista(false);
                console.log("[consultarEstadosDeCuenta][catch]", error);
                if (error) {
                    enqueueSnackbar(intl.formatMessage({ id: "ADMINISTRACION.ESTADO_CUENTA.MENSAJE.ERROR_CONSULTAR_ESTADOS_CUENTA" }), {
                        autoHideDuration: 7000,
                        preventDuplicate: true,
                        variant: 'error',
                        onClick: () => closeSnackbar()
                    });
                }
            });
    }

    let inicializarDatos = async () => {
        try {
            axios.get(`${process.env.REACT_APP_AWS_API_BASE_URL}/auth/sesion`);
        } catch (exception) {
            console.log("[inicializarDatos][exception]", exception);
        }
    }

    const isSelected = (dblFactura) => {
        return listaFacturasSeleccionadas.indexOf(dblFactura) !== -1
    };

    var handleClick_FacturaSeleccionada = (event, dblFactura) => {
        if (event.target)
            if (event.target.classList.contains("btn-no-seleccionable"))
                return;

        const selectedIndex = listaFacturasSeleccionadas.indexOf(dblFactura);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(listaFacturasSeleccionadas, dblFactura);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(listaFacturasSeleccionadas.slice(1));
        } else if (selectedIndex === listaFacturasSeleccionadas.length - 1) {
            newSelected = newSelected.concat(listaFacturasSeleccionadas.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                listaFacturasSeleccionadas.slice(0, selectedIndex),
                listaFacturasSeleccionadas.slice(selectedIndex + 1),
            );
        }

        setListaFacturasSeleccionadas(newSelected);
    }

    const handleClick_descargarZipFacturas = () => {

        if (!listaFacturasSeleccionadas) {
            enqueueSnackbar(intl.formatMessage({ id: "ADMINISTRACION.ESTADO_CUENTA.MENSAJE.VALIDACION.SELECCIONAR_FACTURA" }), {
                autoHideDuration: 7000,
                preventDuplicate: true,
                variant: 'warning',
                onClick: () => closeSnackbar()
            });
            return;
        }

        if (listaFacturasSeleccionadas.length === 0) {
            enqueueSnackbar(intl.formatMessage({ id: "ADMINISTRACION.ESTADO_CUENTA.MENSAJE.VALIDACION.SELECCIONAR_FACTURA" }), {
                autoHideDuration: 7000,
                preventDuplicate: true,
                variant: 'warning',
                onClick: () => closeSnackbar()
            });
            return;
        }

        var arrayFacturasSeleccionadas = listaFacturasSeleccionadas ? listaFacturasSeleccionadas.map((dblFactura, index) => { return `dblFactura[]=${dblFactura}`; }) : [];
        setLoading_isOpen(true);
        axios.get(`${process.env.REACT_APP_AWS_API_BASE_URL}/portal/administracion/estado-de-cuenta/api/documentos/facturas/zip?` + arrayFacturasSeleccionadas.join('&'))
            .then(response => {
                if (response?.response?.data) {
                    let responseError = response.response.data;
                    if (responseError) {
                        enqueueSnackbar(responseError.error, {
                            autoHideDuration: 7000,
                            preventDuplicate: true,
                            variant: 'error',
                            onClick: () => closeSnackbar()
                        });
                        console.log(responseError);
                    }
                }

                if (response.status === 200) {
                    var link = document.createElement("a");
                    link.href = response.data;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                setLoading_isOpen(false);
            })
            .catch(error => {
                console.log("[handleClick_descargarZipFacturas][catch]", error);
                if (error) {
                    enqueueSnackbar(intl.formatMessage({ id: "ADMINISTRACION.ESTADO_CUENTA.MENSAJE.ERROR_AL_DESCARGAR_DOCUMENTO" }), {
                        autoHideDuration: 7000,
                        preventDuplicate: true,
                        variant: 'error',
                        onClick: () => closeSnackbar()
                    });
                }
                setLoading_isOpen(false);
            });
    }

    const handleClick_descargarZipEstadoDeCuenta = () => {
        var arrayCliente_aux = session ? session.listaVarClientes.map((cliente) => { return `varCliente[]=${cliente.strVarClienteOrigen}`; }) : [];

        setLoading_isOpen(true);
        axios.get(`${process.env.REACT_APP_AWS_API_BASE_URL}/portal/administracion/estado-de-cuenta/api/documentos/estado-cuenta?` + arrayCliente_aux.join('&'))
            .then(response => {

                if (response?.response?.data) {
                    let responseError = response.response.data;
                    if (responseError) {
                        enqueueSnackbar(responseError.error, {
                            autoHideDuration: 7000,
                            preventDuplicate: true,
                            variant: 'error',
                            onClick: () => closeSnackbar()
                        });

                        console.log(responseError);
                    }
                }

                if (response.status === 200) {
                    if (arrayCliente_aux.length === 1) {
                        window.open(response.data, "_blank", 'toolbar=0,scrollbars=1,location=0,statusbar=0,menubar=0,resizable=1,width=750,height=1000');
                    } else {
                        var link = document.createElement("a");
                        link.href = response.data;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
                setLoading_isOpen(false);
            })
            .catch(error => {
                console.log("[handleClick_descargarZipEstadoDeCuenta][catch]", error);
                if (error) {
                    enqueueSnackbar(intl.formatMessage({ id: "ADMINISTRACION.ESTADO_CUENTA.MENSAJE.ERROR_AL_DESCARGAR_DOCUMENTO" }), {
                        autoHideDuration: 7000,
                        preventDuplicate: true,
                        variant: 'error',
                        onClick: () => closeSnackbar()
                    });
                }
                setLoading_isOpen(false);
            });
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        // Recalculamos el tama�o de los input fechas por primera vez
        handleResize();
        inicializarDatos();
        consultarEstadosDeCuenta();
    }, []);

    const CargandoTableRow = () => (
        <TableRow>
            <TableCell colSpan={9}>
                <LoaderDashboardTables />
            </TableCell>
        </TableRow>
    )

    return <section style={{ width: '100%' }}>
        <Loading open={loading_isOpen} mensaje="Cargando..."></Loading>
        <ModalFactura open={bitAbrirModalFactura} dblFactura={dblFacturaSeleccionada} onClose={() => { setBitAbrirModalFactura(false); setDblFacturaSeleccionada(0); }}  ></ModalFactura>
        <FWDModalServicio bitOpen={modalServicio.open} dblPedido={modalServicio.dblPedido} fnClose={() => { setModalServicio({ open: false, dblPedido: null }) }} />
        <Grid container direction="column" xl={12} justify="center" style={{ height: '100%', display: 'initial' }} spacing={3}>
            <Grid item component={Paper} elevation={4} container spacing={3} direction="row" justify="space-between" alignItems="center"
                style={{ padding: '15px', position: 'sticky', top: '70px', marginBottom: '25px', zIndex: '2' }}>

                <Grid container item md={12} lg={3}>
                    <FormControl style={{ width: '100%', overflow: 'hidden' }}>
                        <InputLabel>{intl.formatMessage({ id: "ADMINISTRACION.ESTADO_CUENTA.FILTROS.INPUT_BUSQUEDA" })}</InputLabel>
                        <Input
                            disabled={bitBloquearVista}
                            classes={useInputStyles()}
                            type='text'
                            value={strBusqueda}
                            onChange={event => setStrBusqueda(event.target.value)}
                            onKeyUp={event => {
                                if (event.key.toLowerCase() === 'enter') {
                                    setResetOrdenamiento(Date.now())
                                    consultarEstadosDeCuenta();
                                }
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    {strBusqueda.length > 0
                                        ? <IconButton
                                            aria-label="buscando facturas"
                                            onClick={(event) => {
                                                setStrBusqueda('');
                                            }}
                                        >
                                            <HighlightOffIcon />
                                        </IconButton>
                                        : <></>}
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>

                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale_es}>
                    <Grid item md={4} lg={2}>
                        <KeyboardDatePicker
                            disabled={bitBloquearVista}
                            autoOk={true}
                            disableToolbar
                            variant="inline"
                            format="dd/MMM/yyyy"
                            style={{ width: '100%' }}
                            id="date-picker-inicio"
                            label={intl.formatMessage({ id: "ADMINISTRACION.ESTADO_CUENTA.FILTROS.FECHA_INICIO" })}
                            value={datFechaInicio}
                            onChange={(date) => { setDatFechaInicio(date) }}
                            onKeyUp={event => {
                                if (event.key.toLowerCase() === 'enter') {
                                    consultarEstadosDeCuenta();
                                }
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'Cambiar fecha',
                            }}
                        />
                    </Grid>
                    <Grid item md={4} lg={2}>
                        <KeyboardDatePicker
                            disabled={bitBloquearVista}
                            autoOk={true}
                            disableToolbar
                            variant="inline"
                            format="dd/MMM/yyyy"
                            style={{ width: '100%' }}
                            id="date-picker-fin"
                            label={intl.formatMessage({ id: "ADMINISTRACION.ESTADO_CUENTA.FILTROS.FECHA_FIN" })}
                            value={datFechaFin}
                            onChange={(date) => { setDatFechaFin(date) }}
                            onKeyUp={event => {
                                if (event.key.toLowerCase() === 'enter') {
                                    setResetOrdenamiento(Date.now())
                                    consultarEstadosDeCuenta();
                                }
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'Cambiar fecha',
                            }}
                        />
                    </Grid>
                </MuiPickersUtilsProvider>

                <Grid item md={4} lg={4}>
                    <FormControl className={classes.formControl} style={{ width: 'calc(100% - 10px)', maxWidth: 'none' }}>
                        <InputLabel id="demo-mutiple-chip-label">{intl.formatMessage({ id: "ADMINISTRACION.ESTADO_CUENTA.FILTROS.ESTATUS_FACTURA" })}</InputLabel>
                        <Select
                            disabled={bitBloquearVista}
                            labelId="demo-mutiple-chip-label"
                            id="cmb-status-factura"
                            multiple
                            value={arrayEstatusFacturaSeleccionados}
                            onChange={(event => { setArrayEstatusFacturaSeleccionados(event.target.value) })}
                            input={<Input id="select-multiple-chip" />}
                            style={{ padding: 0 }}
                            renderValue={(selected) => (
                                <div className={classes.chips}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} className={classes.chip} />
                                    ))}
                                </div>
                            )}
                            MenuProps={MenuProps}
                        >
                            {arrayStringEstatusFactura.map((name) => (
                                <MenuItem key={name} value={name} style={{ fontWeight: theme.typography.fontWeightMedium }}>
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid container item lg={1} justify="flex-end">
                    <Button
                        disabled={bitBloquearVista}
                        variant="contained"
                        color="secondary"
                        startIcon={<SearchIcon />}
                        onClick={() => {
                            setResetOrdenamiento(Date.now())
                            consultarEstadosDeCuenta();
                        }}
                        style={{ float: 'right' }}>
                        buscar
                    </Button>
                </Grid>
            </Grid>

            <Grid item component={Paper} container direction="row" justify="space-between" alignItems="center"
                style={{ padding: '0px', marginBottom: '25px' }}>
                <Grid container item lg={12}>
                    <Paper square>
                        <Tabs value={indexTabSeleccionado}
                            onChange={(event, newValue) => { 
                                console.log(newValue); 
                                setResetOrdenamiento(Date.now())
                                setIndexTabSeleccionado(newValue); 
                            }}
                            aria-label="Empresa emisora"
                            indicatorColor="primary"
                            textColor="primary">
                            {listaFranquiciaEmisora?.map((franquicia, indexFranquiciaEmisora) => {
                                return <Tab key={`key-tab-franquicia-${franquicia.intFranquiciaEmisora}`} label={franquicia.strFranquiciaEmisora} {...a11yProps(indexFranquiciaEmisora)} />;
                            })}
                        </Tabs>
                    </Paper>

                    <TabPanel value={indexTabSeleccionado} index={indexTabSeleccionado}>
                        <Grid container item lg={12}>
                            <TableContainer component={Paper}>
                                <Paper className={classes.paper}>
                                    <Grid item style={{ marginTop: '10px', padding: '0px 10px 0px 10px' }}>

                                        <Grid item container style={{ marginBottom: '30px' }}>
                                            <Grid item xs={12} sm>
                                                <Typography /*className={classes.title}*/ variant="h6" id="tableTitle" /*component="div"*/>
                                                    {intl.formatMessage({ id: "ADMINISTRACION.ESTADO_CUENTA.TITULO.FACTURA" })}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    size="small"
                                                    startIcon={<CloudDownloadIcon />}
                                                    onClick={() => { handleClick_descargarZipEstadoDeCuenta(); }}
                                                    style={{ marginRight: 0, marginLeft: '15px', float: 'right' }}
                                                    disabled={bitBloquearVista || listaFacturas == null}
                                                >
                                                    {intl.formatMessage({ id: "ADMINISTRACION.ESTADO_CUENTA.BOTONES.DESCARGAR_ESTADO_DE_CUENTA" })}
                                                </Button>

                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    startIcon={<CloudDownloadIcon />}
                                                    onClick={() => { handleClick_descargarZipFacturas(); }}
                                                    style={{ float: 'right' }}
                                                    disabled={bitBloquearVista || listaFacturas == null}
                                                >
                                                    {intl.formatMessage({ id: "ADMINISTRACION.ESTADO_CUENTA.BOTONES.DESCARGAR_FACTURA" })}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <ScrollContainer className="scroll-container" hideScrollbars={false}>

                                            <Table size="small">
                                                <FWDTableHead
                                                    cabeceras={[
                                                        new FWDCabecera('', '', null, false),
                                                        new FWDCabecera('noFactura', intl.formatMessage({ id: "ADMINISTRACION.ESTADO_CUENTA.TABLE.CABECERA.FOLIO_FACTURA" }), { align: 'left', style: { padding: '0px 0px 0px 10px', minWidth: '100px', } }),
                                                        new FWDCabecera('', intl.formatMessage({ id: "ADMINISTRACION.ESTADO_CUENTA.TABLE.CABECERA.NO_SERVICIO" }), { style: { padding: 0 } }, false),
                                                        ...[arrayCliente.length > 1 ? new FWDCabecera('empresa', intl.formatMessage({ id: "ADMINISTRACION.ESTADO_CUENTA.TABLE.CABECERA.EMPRESA" })) : null].filter(x => x !== null),
                                                        new FWDCabecera('fechaFactura', intl.formatMessage({ id: "ADMINISTRACION.ESTADO_CUENTA.TABLE.CABECERA.FECHA" }), { style: { padding: 0, minWidth: "75px", maxWidth: '100px' } }),
                                                        new FWDCabecera('fechaVencimiento', intl.formatMessage({ id: "ADMINISTRACION.ESTADO_CUENTA.TABLE.CABECERA.FECHA_VENCIMIENTO" }), { style: { padding: 0, minWidth: franquiciaEmisoraSeleccionada?.bitColumnaFechaVencimientoExtendida ? "130px" : "75px", maxWidth: '150px' } }),
                                                        new FWDCabecera('estatus', intl.formatMessage({ id: "ADMINISTRACION.ESTADO_CUENTA.TABLE.CABECERA.ESTATUS" }), { style: { padding: 0, minWidth: franquiciaEmisoraSeleccionada?.bitColumnaEstatusExtendida ? "140px" : "75px", maxWidth: '175px' } }),
                                                        new FWDCabecera('total', intl.formatMessage({ id: "ADMINISTRACION.ESTADO_CUENTA.TABLE.CABECERA.TOTAL" }), { align: 'right', style: { padding: 0, minWidth: '130px', maxWidth: '130px' } }, indexTabSeleccionado === 1),
                                                        new FWDCabecera('cobrado', intl.formatMessage({ id: "ADMINISTRACION.ESTADO_CUENTA.TABLE.CABECERA.ABONADO" }), { align: 'right', style: { padding: 0, minWidth: '130px', maxWidth: '130px' } }, indexTabSeleccionado === 1),
                                                        new FWDCabecera('pendiente', intl.formatMessage({ id: "ADMINISTRACION.ESTADO_CUENTA.TABLE.CABECERA.PENDIENTE" }), { align: 'right', style: { padding: 0, minWidth: '130px', maxWidth: '130px' } }, indexTabSeleccionado === 1)
                                                    ]}
                                                    fnOnClick={consultarEstadosDeCuenta}
                                                    disabled={bitBloquearVista}
                                                    reset={resetOrdenamiento}
                                                />
                                                <TableBody>
                                                    {bitBloquearVista
                                                        ? <CargandoTableRow />
                                                        : <></>}
                                                    {
                                                        listaFacturas?.map((factura, index) => {
                                                            const isItemSelected = isSelected(factura.dblFactura);
                                                            const labelId = `enhanced-table-checkbox-${index}`;

                                                            if(bitBloquearVista) return <CargandoTableRow />
                                                            else return <TableRow
                                                                key={factura.dblFactura}
                                                                hover
                                                                aria-checked={isItemSelected}
                                                                tabIndex={-1}
                                                                onClick={(event) => handleClick_FacturaSeleccionada(event, factura.dblFactura)}
                                                                role="checkbox"
                                                                selected={isItemSelected}
                                                                style={{ verticalAlign: 'top' }}
                                                                key={index}
                                                            >
                                                                <TableCell align="right" style={{ padding: 0, width: '25px', margin: 0 }}>
                                                                    <Checkbox
                                                                        checked={isItemSelected}
                                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                                        style={{ width: '25px' }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell id={labelId}
                                                                    align="left"
                                                                    style={{ padding: '10px 0px 0px 10px' }}>
                                                                    <button
                                                                        style={{ padding: 0, maxWidth: '150px', textAlign: 'left' }}
                                                                        className="btn btn-link btn-no-seleccionable" onClick={() => {
                                                                            setDblFacturaSeleccionada(factura.dblFactura);
                                                                            setBitAbrirModalFactura(true);
                                                                        }}>{factura.strFolio}</button>
                                                                </TableCell>
                                                                <TableCell style={{ padding: '10px 0px 0px 0px' }}>
                                                                    {factura.arrayServicios
                                                                        ? factura.arrayServicios.map((servicio) => {
                                                                            return <button
                                                                                key={servicio.dblPedido}
                                                                                className="btn btn-link btn-no-seleccionable"
                                                                                style={{ padding: 0, maxWidth: '250px', textAlign: 'left' }}
                                                                                onClick={() => { setModalServicio({ dblPedido: servicio.dblPedido, open: true }); }}>
                                                                                {servicio.strNumeroServicio}
                                                                            </button>
                                                                        })
                                                                        : ''}
                                                                </TableCell>
                                                                {arrayCliente.length > 1 ? <TableCell style={{ padding: '10px 0px 0px 0px' }}>{factura.strCliente}</TableCell> : <></>}
                                                                <TableCell style={{ padding: '10px 0px 0px 0px' }}>{factura.strFechaFactura}</TableCell>
                                                                <TableCell style={{ padding: '10px 0px 0px 0px' }}>{factura.strFechaVencimiento} {factura.intDiasVencidos < 0 && factura.dblPendiente > 0 ? (<>{factura.intDiasVencidos} d&iacute;as</>) : ''}</TableCell>
                                                                <TableCell style={{ padding: '10px 0px 0px 0px' }}><span className={`label label-lg label-light-${factura.strColor} label-inline`}>{factura.strEstatusFactura}</span></TableCell>
                                                                <TableCell align="right" style={{ padding: '10px 0px 0px 0px' }}>{factura.strTotal}</TableCell>
                                                                <TableCell align="right" style={{ padding: '10px 0px 0px 0px' }}>{factura.strCobrado}</TableCell>
                                                                <TableCell align="right" style={{ padding: '10px 0px 0px 0px' }}>{factura.strPendiente}</TableCell>
                                                            </TableRow>
                                                        })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </ScrollContainer>
                                    </Grid>
                                </Paper >
                            </TableContainer>
                        </Grid>
                    </TabPanel>
                    {bitBloquearVista && listaFacturas == null ?
                        <Typography variant="h6" style={{ padding: "15px", marginLeft: 15 }}>
                            Cargando
                            </Typography>
                        : <></>
                    }
                    {!bitBloquearVista && listaFacturas == null ?
                        <Typography variant="h6" style={{ padding: "15px", marginLeft: 15 }}>
                            {intl.formatMessage({ id: "ADMINISTRACION.ESTADO_CUENTA.MENSAJE.NO_HAY_FACTURAS" })}
                        </Typography>
                        : <></>
                    }
                </Grid>
                {/* : <></>
                 } */}
            </Grid>
        </Grid >
    </section >
}

export default injectIntl(ConsultarEstadoCuenta);