import React, { useEffect, useState, useRef } from 'react'
import { injectIntl } from 'react-intl'
import axios from 'axios'
import { Grid, Paper, TextField, Button } from '@material-ui/core'
import BlankProfilePic from 'app/../imagenes/blank-profile-picture.png'
import { useSnackbar } from 'notistack'
import fileToBase64 from 'app/helpers/fileToBase64'

// https://92v9zvrmx3.execute-api.us-east-1.amazonaws.com/dev/portal/perfil-usuario/api/consultar-datos
// https://92v9zvrmx3.execute-api.us-east-1.amazonaws.com/dev/portal/perfil-usuario/api/actualizar-datos
// https://92v9zvrmx3.execute-api.us-east-1.amazonaws.com/dev/portal/perfil-usuario/api/subir-imagen-perfil?bucket=portalcliente-imagenesperfil
const BASE_URL = process.env.REACT_APP_AWS_API_BASE_URL;
const IMG_WIDTH = 120
const IMG_HEIGHT = 120

function EditarInformacion(props) {

    const [cargandoDatosUsuario, setCargandoDatosUsuario] = useState(true)
    const [datosUsuario, setDatosUsuario] = useState({})
    const [datosUsuarioValidos, setDatosUsuariosValidos] = useState(true)
    const [validarTextField, setValidarTextField] = useState({ nombre: true, apPaterno: true })
    const [imagenCambio, setImagenCambio] = useState(false)

    console.log(datosUsuario);
    
    const imgInputRef = useRef()

    const { enqueueSnackbar } = useSnackbar()

    const fetchConsultarDatosPerfil = async () => {

        try {
            setCargandoDatosUsuario(true)
            const { data } = await axios.get(BASE_URL + '/portal/perfil-usuario/api/consultar-datos')
            setDatosUsuario(data.datosUsuario)
        }
        catch (error) {
            console.log('errorFetchConsultarDatosPerfil ', error)
            enqueueSnackbar('Error al consultar datos de usuario', {
                variant: 'error',
                persist: false
            })
        }
        finally {
            setCargandoDatosUsuario(false)
        }

    }

    const fetchActualizarDatosPerfil = async () => {
        try {
            const payload = datosUsuario
            setCargandoDatosUsuario(true)

            // if (imagenCambio) {
            //     const file = imgInputRef.current.files[0]
            //     const base64File = await fileToBase64(file)
            //     console.clear()
            //     console.log('payload!!' , file, base64File)

            //     const result = await axios.post(BASE_URL + '/portal/perfil-usuario/api/subir-imagen-perfil?bucket=portalcliente-imagenesperfil', base64File, {
            //         headers: {
            //             "fileName": "imagenperfil-demo.jpg"
            //         }
            //     })

            //     console.clear()
            //     console.log(file, result)
            // }

            await axios.post(BASE_URL + '/portal/perfil-usuario/api/actualizar-datos', payload)
            enqueueSnackbar('Los datos han sido actualizados', {
                variant: 'success',
                persist: false,
                autoHideDuration: 1500
            })
        }
        catch (error) {
            console.log('errorFetchActualizarDatosPerfil ', error)
            enqueueSnackbar('Error al actualizar datos de usuario', {
                variant: 'error',
                persist: false
            })
        }
        finally {
            setCargandoDatosUsuario(false)
        }
    }

    useEffect(() => {
        fetchConsultarDatosPerfil()
    }, [])

    useEffect(() => {
        const nombreValido = datosUsuario?.strNombre?.length > 0 || false
        const apPaternoValido = datosUsuario?.strApellidoPaterno?.length > 0 || false

        setValidarTextField({ ...validarTextField, nombre: nombreValido })
        setValidarTextField({ ...validarTextField, apPaterno: apPaternoValido })

        const validos = apPaternoValido && nombreValido

        setDatosUsuariosValidos(validos)
    }, [JSON.stringify(datosUsuario)])

    return <Grid container>
        <Grid item xs={0} md={2} lg={3} />
        <Grid item xs={12} md={8} lg={6} component={Paper} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '15px 25px' }}>
            <img
                className="cursor-hover"
                src={datosUsuario?.strRutaArchivo || BlankProfilePic}
                style={{ width: IMG_WIDTH, height: IMG_HEIGHT, objectFit: 'cover', borderRadius: '50%' }}
                onClick={() => imgInputRef.current.click()}
            />
            <input
                style={{ display: 'none' }}
                ref={imgInputRef}
                type="file"
                accept="image/*"
                onChange={e => {
                    const file = e.target.files[0]
                    const tmpUrl = URL.createObjectURL(file)
                    setDatosUsuario({ ...datosUsuario, strRutaArchivo: tmpUrl })
                    setImagenCambio(true)
                }}
            />
            <section style={{ display: 'flex', flexDirection: 'column', maxWidth: 500, width: '100%', marginTop: 15 }}>
                <TextField
                    label="Nombre"
                    style={{ marginTop: 5 }}
                    value={datosUsuario?.strNombre || ''}
                    onChange={e => setDatosUsuario({ ...datosUsuario, strNombre: e.target.value })}
                    error={!validarTextField.nombre && !cargandoDatosUsuario}
                    disabled={cargandoDatosUsuario}
                />
                <TextField
                    label="Apellido paterno"
                    style={{ marginTop: 5 }}
                    value={datosUsuario?.strApellidoPaterno || ''}
                    onChange={e => setDatosUsuario({ ...datosUsuario, strApellidoPaterno: e.target.value })}
                    error={!validarTextField.apPaterno && !cargandoDatosUsuario}
                    disabled={cargandoDatosUsuario}
                />
                <TextField
                    label="Apellido materno"
                    style={{ marginTop: 5 }}
                    value={datosUsuario?.strApellidoMaterno || ''}
                    onChange={e => setDatosUsuario({ ...datosUsuario, strApellidoMaterno: e.target.value })}
                    disabled={cargandoDatosUsuario}
                />
                <TextField
                    label="Alias"
                    style={{ marginTop: 5 }}
                    value={datosUsuario?.strNickname || ''}
                    onChange={e => setDatosUsuario({ ...datosUsuario, strNickname: e.target.value })}
                    disabled={cargandoDatosUsuario}
                />
                <div style={{ display: 'flex', marginTop: 5 }}>
                    <TextField
                        label="Teléfono"
                        style={{ flexGrow: 1 }}
                        value={datosUsuario?.strTelefono || ''}
                        onChange={e => setDatosUsuario({ ...datosUsuario, strTelefono: e.target.value })}
                        disabled={cargandoDatosUsuario}
                    />
                    <TextField
                        label="Extensión"
                        style={{ width: 85 }}
                        value={datosUsuario?.strExtension || ''}
                        onChange={e => setDatosUsuario({ ...datosUsuario, strExtension: e.target.value })}
                        disabled={cargandoDatosUsuario}
                    />
                </div>
                <TextField
                    label="Número celular"
                    style={{ marginTop: 5 }}
                    value={datosUsuario?.strNumeroCelular || ''}
                    onChange={e => setDatosUsuario({ ...datosUsuario, strNumeroCelular: e.target.value })}
                    disabled={cargandoDatosUsuario}
                />

                <Button style={{ marginTop: 25 }}
                    color="secondary"
                    variant="contained"
                    disabled={cargandoDatosUsuario || !datosUsuarioValidos}
                    onClick={() => fetchActualizarDatosPerfil()}
                >Actualizar</Button>
            </section>
        </Grid>
        <Grid item xs={0} md={2} lg={3} />
    </Grid>

}

export default injectIntl(EditarInformacion)


