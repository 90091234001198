import React from 'react';
import { injectIntl } from "react-intl";
import { Modal, Paper, Grid, IconButton, ClickAwayListener } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import loadTheme from '../helpers/loadTheme';

function FWDModal(props) {
    const { open, elevation = 2, Titulo = null, fnOnRendered, fnOnClose, fnCloseModal, width = '85vw', height = '80vh', persistent = false, overflow = 'hidden' } = props;

    const uiTheme = loadTheme();

    const modalPaperStyle = { display: 'flex', flexDirection: 'column', justifyContent: 'stretch', height, width, outline: 'none', overflow };
    const modalHeaderStyle = { padding: '0 10px', background: uiTheme.secondary, color: uiTheme.white, height: '50px', alignItems: 'center' };
    const modalChildrenContainerStyle = { flexGrow: '1' };
    const modalChildrenSectionStyle = { display: 'flex', flexDirection: 'row', width: '100%' };

    return (
        <Modal
            open={open}
            onRendered={fnOnRendered}
            onClose={fnOnClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            onEscapeKeyDown={fnCloseModal}
        >
            <ClickAwayListener onClickAway={() => { if (!persistent) fnCloseModal() }}>
                <Paper elevation={elevation} style={modalPaperStyle}>
                    <Grid container justify="space-between" style={modalHeaderStyle}>
                        {Titulo ? (
                            <Grid item container style={{ width: 'calc(100% - 50px)' }}>
                                {Titulo}
                            </Grid>
                        ) : null}
                        <IconButton style={{ color: uiTheme.primary, width: '50px' }} aria-label="cerrar modal" onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            fnCloseModal();
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Grid container style={modalChildrenContainerStyle}>
                        <section style={modalChildrenSectionStyle}>
                            {props.children}
                        </section>
                    </Grid>
                </Paper>
            </ClickAwayListener>
        </Modal>
    );
}

export default injectIntl(FWDModal);
