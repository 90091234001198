
import React, { useState, useEffect } from "react"
import axios from 'axios'
import { injectIntl } from "react-intl";
import { useSelector } from "react-redux"

import {
    Paper, Grid, makeStyles, InputAdornment, FormControl, InputLabel, Button,
    Input, IconButton, Chip, MenuItem, useTheme, Select, Checkbox,
    Table, TableContainer, TableHead, TableBody, TableRow, TableCell,
    AppBar, Tabs, Tab, Typography, Box,
} from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SearchIcon from '@material-ui/icons/Search';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const estilosNotificaciones = makeStyles((theme) => ({
    success: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: '#fff!important',
        backgroundColor: '#43a047!important',
        minHeight:'60px', 
        display: 'flex', 
        borderRadius: '4px',
        overflow: 'hidden',
    },
    danger: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: '#fff!important',
        backgroundColor: '#d32f2f!important',
        minHeight:'60px', 
        display: 'flex', 
        borderRadius: '4px',
        overflow: 'hidden',
    },
    info: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: '#fff!important',
        backgroundColor: '#2196f3!important',
        minHeight:'60px', 
        display: 'flex', 
        borderRadius: '4px',
        overflow: 'hidden',
    },
    warning: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: '#fff!important',
        backgroundColor: '#ff9800!important',
        minHeight:'60px', 
        display: 'flex', 
        borderRadius: '4px',
        overflow: 'hidden',
    },
}));


function CajonNotificaciones(props) {
    const estilos = estilosNotificaciones();
    return (
        <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}
            style={{minHeight: '150px', overflow:'auto' , width: '100%', padding: 0, margin: 0}}>
            <Grid item xs={12} style={{paddingLeft: 0, paddingRight: 0, height: '30px'}}>
                <Typography variant='h6'>Notificaciones</Typography>
            </Grid>
            <Grid item xs={12} container direction='column' style={{paddingLeft: 0, paddingRight: 0, height: 'calc(100vh - 60vh)'}}>
                <Grid item xs={12}>
                    <Paper className={estilos.success}>
                        <Grid container style={{minHeight: '100%'}}>
                            <Grid 
                                item xs={1} 
                                style={{
                                    alignItems: 'center', 
                                    verticalAlign: 'middle', 
                                    width: '100%',
                                    minHeight: 'calc(100% - 4px)', 
                                    position: 'relative'
                                }}>
                                <img alt="logo" style={{ width: '100%', height: 'auto', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}} src='https://fwd.myhmsapps.com/FWD/Modulos/Seguridad/Catalogos/Logos/logoFWD.png' />
                            </Grid>
                            <Grid item container direction='column' xs={10} style={{textAlign: 'left', paddingLeft: '10px'}}>
                                <Grid item>
                                    <Typography variant="subtitle1">Titulo
                                        <Typography variant='subtitle1' style={{float: 'right'}}>date</Typography>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle2" dangerouslySetInnerHTML={{__html: '<strong>Test</strong> test'}}>
                                        
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default injectIntl(CajonNotificaciones);