import React, { createContext, useContext, useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import { LinearProgress, TextField, Typography } from '@material-ui/core';
import { SvgIcon } from '@material-ui/core'
import { useSnackbar } from "notistack";


export const FWDDialogoGoogleAuthenticatorContext = createContext()
export const FWDDialogoGoogleAuthenticatorState = {
  visible: false,
  arrastrable: false,
  timewatch: null,
  error: false,
  done: false,
  cargando: false
}

export const FWDDialogoGoogleAuthenticatorActions = {
  mostrarDialogo: 'mostrarDialogo',
  resetCodigo: 'resetCodigo',
  estadoInvalido: 'estadoInvalido',
  estadoValido: 'estadoValido',
  validado: 'validado',
  cargando: 'cargando'
}

export const FWDDialogoGoogleAuthenticatorReductor = (state, action) => {

  switch (action.type) {

    case FWDDialogoGoogleAuthenticatorActions.mostrarDialogo:
      return { ...state, visible: true }

    case FWDDialogoGoogleAuthenticatorActions.resetCodigo:
      return { ...state, timewatch: Date.now() }

    case FWDDialogoGoogleAuthenticatorActions.estadoInvalido:
      return { ...state, error: true }

    case FWDDialogoGoogleAuthenticatorActions.estadoValido:
      return { ...state, error: false }

    case FWDDialogoGoogleAuthenticatorActions.validado:
      return { ...state, done: true }

    case FWDDialogoGoogleAuthenticatorActions.cargando:
      return { ...state, cargando: Date.now() }
  }

}


//? Se copia y pegan las propiedades definidas
//? arriba.
/**
 * Dialogo de sistema FWD. Para guiarte puedes ver el uso en la pantalla de 
 * app/pages/configuraciones/Seguridad.js
 * @param {FWDDialogoGoogleAuthenticatorProps} param0 
 */
const _FWDDialogoGoogleAuthenticator = ({ fnOk = () => { } }) => {

  const { data, dispatchData } = useContext(FWDDialogoGoogleAuthenticatorContext)
  const { enqueueSnackbar } = useSnackbar();

  const PaperComponent = (props) => {
    return (
      <>
        {data?.arrastrable || false ? (
          <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
          >
            <Paper {...props} />
          </Draggable>
        ) : (
            <Paper {...props} />
          )}
      </>
    );
  };

  const iconStyle = {
    maxWidth: '250px',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '25px'
  }
  const inputStyle = { width: '1.5rem' }

  const [inputActual, setInputActual] = useState(0)
  const [codigo, setCodigo] = useState(new Array(6))
  const [cargando, setCargando] = useState(false)
  const [codigoValido, setCodigoValido] = useState(false)
  // const [styleSvg, setStyleSvg] = useState({})

  useEffect(() => {
    setInputActual(0)
    setCodigo(new Array(0))
    setCargando(false)
  }, [data?.timewatch])

  useEffect(() => {
      setCargando(true)
  }, [data?.cargando])

  useEffect(() => {
    const valido = codigo.every(x => x.length === 1) && codigo.length === 6
    setCodigoValido(valido)
  }, [JSON.stringify(codigo)])

  // useEffect(() => {
  //   if (data?.done) {
  //     setStyleSvg({
  //       filter: 'invert(46%) sepia(77%) saturate(431%) hue-rotate(81deg) brightness(98%) contrast(96%)'
  //     })
  //   }
  // }, [data?.done])

  useEffect(() => {

    if (data?.error) {
      dispatchData({ id: FWDDialogoGoogleAuthenticatorActions.estadoValido })
      enqueueSnackbar("Codigo incorrecto",
        {
          preventDuplicate: true,
          autoHideDuration: 2500,
          variant: "warning",
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        }
      );
    }

  }, [data?.error])

  const updateCodigo = (indx, val) => {
    const cod = codigo
    cod[indx] = val
    setCodigo(cod)
  }

  const validCodes = (key, erasable = false) => {
    const keys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    if (erasable) keys.push('Backspace')
    return keys.indexOf(key) !== -1
  }

  return <>
    <Dialog
      open={data?.visible || false}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-gauth"
    >

      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'stretch' }}>
          <section style={iconStyle}>
            <img src="/svgs/GoogleAuthenticatorSvg.svg" width="100px" height="auto" />
          </section>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>Ingresa los 6 dígitos de tu Google Authenticator</Typography>
          <section style={{ display: 'flex', justifyContent: 'space-between', width: '100%', margin: '10px 0' }}>
            <TextField value={codigo[0]} autoFocus={inputActual === 0} color="primary" style={inputStyle} inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
              disabled={cargando}
              onKeyDown={(e) => {
                if (!validCodes(e.key)) e.preventDefault()
              }}
              onInput={(e) => {
                updateCodigo(0, e.target.value)
                setInputActual(1)
              }}
              onFocus={e => e.target.select()}
              onClick={_ => {

                setInputActual(0, updateCodigo(0, ''))
              }}
            />
            <TextField value={codigo[1]} autoFocus={inputActual === 1} color="primary" style={inputStyle} inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
              disabled={cargando}
              onKeyDown={(e) => {
                if (!validCodes(e.key)) e.preventDefault()
                if (e.key === 'Backspace') {
                  updateCodigo(0, '')
                  setInputActual(0)
                }
              }}
              onInput={(e) => {
                updateCodigo(1, e.target.value)
                setInputActual(2)
              }}
              onFocus={e => e.target.select()}
              onClick={_ => {
                setInputActual(1, updateCodigo(1, ''))
              }}
            />
            <TextField value={codigo[2]} autoFocus={inputActual === 2} color="primary" style={inputStyle} inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
              disabled={cargando}
              onKeyDown={(e) => {
                if (!validCodes(e.key)) e.preventDefault()
                if (e.key === 'Backspace') {
                  updateCodigo(1, '')
                  setInputActual(1)
                }
              }}
              onInput={(e) => {
                updateCodigo(2, e.target.value)
                setInputActual(3)
              }}
              onFocus={e => e.target.select()}
              onClick={_ => {
                setInputActual(2, updateCodigo(2, ''))
              }}
            />
            <TextField value={codigo[3]} autoFocus={inputActual === 3} color="primary" style={inputStyle} inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
              disabled={cargando}
              onKeyDown={(e) => {
                if (!validCodes(e.key)) e.preventDefault()
                if (e.key === 'Backspace') {
                  updateCodigo(2, '')
                  setInputActual(2)
                }
              }}
              onInput={(e) => {
                updateCodigo(3, e.target.value)
                setInputActual(4)
              }}
              onFocus={e => e.target.select()}
              onClick={_ => {
                setInputActual(3, updateCodigo(3, ''))
              }}
            />
            <TextField value={codigo[4]} autoFocus={inputActual === 4} color="primary" style={inputStyle} inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
              disabled={cargando}
              onKeyDown={(e) => {
                if (!validCodes(e.key)) e.preventDefault()
                if (e.key === 'Backspace') {
                  updateCodigo(3, '')
                  setInputActual(3)
                }
              }}
              onInput={(e) => {
                updateCodigo(4, e.target.value)
                setInputActual(5)
              }}
              onFocus={e => e.target.select()}
              onClick={_ => {
                setInputActual(4, updateCodigo(4, ''))
              }}
            />
            <TextField value={codigo[5]} autoFocus={inputActual === 5} color="primary" style={inputStyle} inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
              disabled={cargando}
              onKeyDown={(e) => {
                if (!validCodes(e.key)) e.preventDefault()
                if (e.key === 'Backspace') {
                  updateCodigo(4, '')
                  setInputActual(4)
                }
              }}
              onInput={(e) => {
                updateCodigo(5, e.target.value)
                setInputActual(null)
                const code = `${codigo[0]}${codigo[1]}${codigo[2]}${codigo[3]}${codigo[4]}${e.target.value}`
                fnOk(code)
              }}
              onFocus={e => e.target.select()}
              onClick={_ => {
                setInputActual(5, updateCodigo(5, ''))
              }}
            />
          </section>
          <LinearProgress style={{ width: '100%', visibility: cargando ? 'visible' : 'hidden' }} />
        </div>
      </DialogContent>
    </Dialog>
  </>
};
const FWDDialogoGoogleAuthenticator = injectIntl(_FWDDialogoGoogleAuthenticator)
export { FWDDialogoGoogleAuthenticator }
