import React, { useState, useEffect, useRef } from 'react'
import { injectIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { Grid, TableContainer, Table, TableHead, TableBody, TableCell, TableRow, IconButton, LinearProgress, Typography } from '@material-ui/core'
import { FormControl, Input, InputLabel, InputAdornment, Select, Button, Checkbox, CircularProgress } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import EmailIcon from '@material-ui/icons/Email';
import PersonIcon from '@material-ui/icons/Person';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import PhoneIcon from '@material-ui/icons/Phone';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import FWDModal from 'app/componentes/FWDModal'
import { SH_validarCorreoElectronico } from 'app/helpers/stringHelper'
import { useSnackbar } from 'notistack'
import { FWDDialogo, FWDDialogoProps } from 'app/componentes/FWDDialogo'
import { telefono_onKeyPress } from 'app/helpers/inputEventListeners'
import qrcode from 'qrcode'
import Popover from '@material-ui/core/Popover';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';

const getQRCode = (otp_url) => {
    return new Promise((resolve, reject) => {
        qrcode.toDataURL(otp_url, (qrerr, qrdata) => {
            if (qrerr) reject(qrerr)
            else resolve(qrdata)
        })
    })
}

const _Usuario = ({ intl, visible,
    fnCerrar = () => { }, fnSuccess = () => { },
    evento, perfilesUsuario, varCliente, usuarioEditar = null,
    timestamp = Date.now() }) => {

    const [errorCorreo, setErrorCorreo] = useState(true)
    const [errorNombre, setErrorNombre] = useState(true)
    const [errorAPPat, setErrorAPPat] = useState(true)

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    let edoInicialUsuario = {
        correoElectronico: '',
        alias: null,
        nombre: '',
        apellidoPaterno: '',
        apellidoMaterno: null,
        telefono: null,
        extension: null,
        celular: null,
        status: 'activo',
        rol: 'usuario',
        matricula: null,
        perfil: null
    };

    const [usuario, setUsuario] = useState(edoInicialUsuario)
    const [idUsuarioEditar, setIdUsuarioEditar] = useState(null)
    const [guardandoCambios, setGuardandoCambios] = useState(false);



    //? Watcher de usuarioEditar
    useEffect(() => {
        if (usuarioEditar !== null) {
            edoInicialUsuario.correoElectronico = usuarioEditar.EMAIL;
            edoInicialUsuario.nombre = usuarioEditar.NOMBRE;
            edoInicialUsuario.apellidoPaterno = usuarioEditar.AP_PATERNO;
            edoInicialUsuario.apellidoMaterno = usuarioEditar.AP_MATERNO;
            edoInicialUsuario.telefono = usuarioEditar.TELEFONO;
            edoInicialUsuario.extension = usuarioEditar.EXTENSION;
            edoInicialUsuario.celular = usuarioEditar.CELULAR;
            edoInicialUsuario.status = usuarioEditar.STATUS;
            edoInicialUsuario.rol = usuarioEditar.ROL;
            edoInicialUsuario.matricula = usuarioEditar.MATRICULA;
            edoInicialUsuario.perfil = usuarioEditar.ID_PERFIL;
            edoInicialUsuario.strHashOtpUrl = usuarioEditar.strHashOtpUrl
        }
        setUsuario(edoInicialUsuario);
        setIdUsuarioEditar(usuarioEditar?.ID_USUARIO)
    }, [timestamp])


    //? Watcher de correo electrónico
    useEffect(() => {
        setErrorCorreo(!SH_validarCorreoElectronico(usuario.correoElectronico))
    }, [usuario.correoElectronico])

    //? Watcher Nombre requerido
    useEffect(() => {
        setErrorNombre(usuario.nombre.length === 0)
    }, [usuario.nombre])

    //? Watcher Apellido paterno requerido
    useEffect(() => {
        setErrorAPPat(usuario.apellidoPaterno.length === 0)
    }, [usuario.apellidoPaterno])


    const getUsuariosURL = () => process.env.REACT_APP_AWS_API_BASE_URL + `/portal/configuracion/seguridad/api/franquicias/${varCliente}/usuarios/`
    const guardarCambiosUsuario = async () => {
        let method = 'POST';
        let apiUrl = getUsuariosURL();
        try {
            setGuardandoCambios(true);
            if (evento?.toLowerCase() === 'crear') {
                method = 'POST';

                //* Ajustamos el perfil de usuario, en caso de no haberse seleccionado
                //* Ninguno del selector se crea usuario con el primer perfil (TODOS LOS PERMISOS)
                usuario.perfil = usuario.perfil || perfilesUsuario.length > 0
                    ? perfilesUsuario[0].intUsuarioPerfil
                    : null
            }
            if (evento?.toLowerCase() === 'editar') {
                method = 'PUT'
                apiUrl += idUsuarioEditar
            }

            await axios({ method, url: apiUrl, data: usuario })

            fnSuccess()
            fnCerrar()
            enqueueSnackbar(intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.MSG.USUARIO_CREADO' }), {
                autoHideDuration: 1500,
                variant: 'success'
            })
        }
        catch (error) {
            if (error.response) {
                switch (error.response.status) {
                    case 409:
                        enqueueSnackbar(
                            intl.formatMessage({
                                id: 'CONFIGURACIONES.SEGURIDAD.MSG.CONFLICTO_CORREO_ELECTRONICO', values: {
                                    correo: usuario.correoElectronico
                                }
                            }),
                            {
                                autoHideDuration: 2500,
                                variant: 'warning'
                            })
                        break;

                    default:
                        enqueueSnackbar(intl.formatMessage({ id: '_ERROR_SERVIDOR' }), {
                            variant: 'error',
                            onClick: () => closeSnackbar()
                        })
                        break;
                }
            }
            else {
                enqueueSnackbar(intl.formatMessage({ id: '_ERROR_INESPERADO' }), {
                    variant: 'error',
                    onClick: () => closeSnackbar()
                })
            }
        }
        finally {
            setGuardandoCambios(false);
        }
    }

    return <>
        <FWDModal
            open={visible}
            fnCloseModal={() => {
                fnCerrar()
                setUsuario(edoInicialUsuario)
            }}
            Titulo={() => <Typography variant="h5">{`${evento} usuario`}</Typography>}
            width="450"
            height="auto"
        >
            <Grid style={{ height: 'auto', width: '100%', display: 'flex', alignItems: 'center' }}>
                <section style={{ padding: 10, overflowY: 'scroll', display: 'flex', flexDirection: 'column', width: 450 }}>
                    <FormControl>
                        <InputLabel htmlFor="input-with-icon-adornment">{intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.CORREO_ELECTRONICO' })}</InputLabel>
                        <Input
                            value={usuario.correoElectronico}
                            onChange={e => setUsuario({ ...usuario, correoElectronico: e.target.value })}
                            onFocus={event => event.target.autocomplete = 'new-password'} // Hack para evitar autofill de Chrome
                            error={errorCorreo}
                            startAdornment={
                                <InputAdornment position="start">
                                    <EmailIcon />
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl style={{ marginTop: 10 }}>
                        <InputLabel htmlFor="input-with-icon-adornment">{intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.NOMBRE' })}</InputLabel>
                        <Input
                            value={usuario.nombre}
                            onChange={e => setUsuario({ ...usuario, nombre: e.target.value })}
                            onFocus={event => event.target.autocomplete = 'new-password'} // Hack para evitar autofill de Chrome
                            error={errorNombre}
                            startAdornment={
                                <InputAdornment position="start">
                                    <PersonIcon />
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl style={{ marginTop: 10 }}>
                        <InputLabel htmlFor="input-with-icon-adornment">{intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.AP_PATERNO' })}</InputLabel>
                        <Input
                            value={usuario.apellidoPaterno}
                            onChange={e => setUsuario({ ...usuario, apellidoPaterno: e.target.value })}
                            onFocus={event => event.target.autocomplete = 'new-password'} // Hack para evitar autofill de Chrome
                            error={errorAPPat}
                            startAdornment={
                                <InputAdornment position="start">
                                    <PersonIcon />
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl style={{ marginTop: 10 }}>
                        <InputLabel htmlFor="input-with-icon-adornment">{intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.AP_MATERNO' })}</InputLabel>
                        <Input
                            value={usuario.apellidoMaterno}
                            onChange={e => setUsuario({ ...usuario, apellidoMaterno: e.target.value })}
                            onFocus={event => event.target.autocomplete = 'new-password'} // Hack para evitar autofill de Chrome
                            startAdornment={
                                <InputAdornment position="start">
                                    <PersonIcon />
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl style={{ marginTop: 10 }}>
                        <InputLabel htmlFor="input-with-icon-adornment">{intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.MATRICULA' })}</InputLabel>
                        <Input
                            value={usuario.matricula}
                            onChange={e => setUsuario({ ...usuario, matricula: e.target.value })}
                            onFocus={event => event.target.autocomplete = 'new-password'} // Hack para evitar autofill de Chrome
                            startAdornment={
                                <InputAdornment position="start">
                                    <PermIdentityIcon />
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <Grid item style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <FormControl style={{ marginTop: 10, flexGrow: 2 }}>
                            <InputLabel htmlFor="input-with-icon-adornment">{intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.NUMERO_TELEFONICO' })}</InputLabel>
                            <Input
                                value={usuario.telefono}
                                onChange={e => setUsuario({ ...usuario, telefono: e.target.value })}
                                onFocus={event => event.target.autocomplete = 'new-password'} // Hack para evitar autofill de Chrome
                                onKeyPress={telefono_onKeyPress}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <PhoneIcon />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <FormControl style={{ marginTop: 10, width: 120 }}>
                            <InputLabel htmlFor="input-with-icon-adornment">{intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.EXTENSION' })}</InputLabel>
                            <Input
                                type="number"
                                value={usuario.extension}
                                onChange={e => setUsuario({ ...usuario, extension: e.target.value })}
                                onFocus={event => event.target.autocomplete = 'new-password'} // Hack para evitar autofill de Chrome
                            />
                        </FormControl>
                    </Grid>
                    <Grid item style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <FormControl style={{ marginTop: 10, flexGrow: 1 }}>
                            <InputLabel htmlFor="input-with-icon-adornment">{intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.NUMERO_CELULAR' })}</InputLabel>
                            <Input
                                value={usuario.celular}
                                onChange={e => setUsuario({ ...usuario, celular: e.target.value })}
                                onFocus={event => event.target.autocomplete = 'new-password'} // Hack para evitar autofill de Chrome
                                onKeyPress={telefono_onKeyPress}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <PhoneAndroidIcon />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <FormControl style={{ marginTop: 10, width: 120 }}>
                            <InputLabel htmlFor="age-native-simple">{intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.ESTATUS' })}</InputLabel>
                            <Select
                                native
                                inputProps={{
                                    name: 'Estatus',
                                }}
                                value={usuario.status}
                                onChange={e => setUsuario({ ...usuario, status: e.target.value })}
                            >
                                <option value={"activo"}>{intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.OPC.ESTATUS_ACTIVO' })}</option>
                                <option value={"inactivo"}>{intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.OPC.ESTATUS_INACTIVO' })}</option>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <FormControl style={{ marginTop: 10, flexGrow: 1 }}>
                            <InputLabel htmlFor="input-with-icon-adornment">{intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.PERFIL' })}</InputLabel>
                            <Select
                                native
                                inputProps={{
                                    name: 'Perfil',
                                }}
                                value={usuario.perfil}
                                onChange={e => setUsuario({ ...usuario, perfil: e.target.value })}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <AccountBoxIcon />
                                    </InputAdornment>
                                }
                            >
                                {perfilesUsuario?.map((p, indx) => (
                                    <option key={indx}
                                        value={p.intUsuarioPerfil}
                                    >{p.strNombrePerfil}</option>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl style={{ marginTop: 10, width: 120 }}>
                            <InputLabel htmlFor="age-native-simple">{intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.ROL' })}</InputLabel>
                            <Select
                                native
                                inputProps={{
                                    name: 'Rol',
                                }}
                                value={usuario.rol}
                                onChange={e => setUsuario({ ...usuario, rol: e.target.value })}
                            >
                                <option value={"usuario"}>{intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.OPC.ROL_USUARIO' })}</option>
                                <option value={"admin"}>{intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.OPC.ROL_ADMINISTRADOR' })}</option>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                        <Button variant="outlined" style={{ width: '42.5%' }}
                            disabled={guardandoCambios}
                            onClick={() => {
                                fnCerrar()
                                setUsuario(edoInicialUsuario)
                            }}
                        >
                            {intl.formatMessage({ id: '_CANCELAR' })}
                        </Button>
                        <Button variant="outlined" style={{ width: '42.5%' }} color="primary"
                            disabled={errorCorreo || errorNombre || errorAPPat || guardandoCambios}
                            onClick={() => guardarCambiosUsuario()}
                        >
                            {intl.formatMessage({ id: '_GUARDAR' })}
                        </Button>
                    </Grid>
                    <Grid item style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10, height: 5 }}>
                        {guardandoCambios ? <LinearProgress style={{ flexGrow: 1 }} /> : <></>}
                    </Grid>
                </section>
            </Grid>
        </FWDModal>
    </>

}

const Usuario = injectIntl(_Usuario);

const CrudUsuarios = ({ intl, franquicias }) => {

    const { user, selectedClienteComercial } = useSelector(state => state.auth)

    const { varCliente, idGrupo } = franquicias;
    const [cargandoUsuarios, setCargandoUsuarios] = useState(true);
    const [usuarios, setUsuarios] = useState([]);
    const [cargandoBitGoogleUsuarios, setCargandoBitGoogleUsuarios] = useState([])

    const [perfilesUsuario, setPerfilesUsuario] = useState([]);

    const [verModalUsuario, setVerModalUsuario] = useState(false)
    const [eventoModalUsuario, setEventoModalUsuario] = useState()
    const [usuarioEditar, setUsuarioEditar] = useState(null)
    const [timestampModalUsuario, setTimestampModalUsuario] = useState(Date.now())

    const [seleccionadosBulk, setSeleccionadosBulk] = useState([]);
    const [bitSeleccionadosBulk, setBitSeleccionadosBulk] = useState(false);
    const [opcOperacionBulk, setOpcOperacionBulk] = useState("0")
    const [dialogoOpcBulkProps, setDialogoOpcBulkProps] = useState(FWDDialogoProps);
    const [dialogoOpcBulkVisible, setDialogoOpcBulkVisible] = useState(false)
    const [dialogoOpcBulkCargando, setDialogoOpcBulkCargando] = useState(false)

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const getUsuariosURL = () => process.env.REACT_APP_AWS_API_BASE_URL + `/portal/configuracion/seguridad/api/franquicias/${selectedClienteComercial}/usuarios`
    const getPerfilesUsuarioURL = (ID_GRUPO) => process.env.REACT_APP_AWS_API_BASE_URL + "/portal/configuracion/seguridad/api/perfiles-usuario?BIT_GLOBAL=1&ID_GRUPO=" + ID_GRUPO

    const getUsuarios = async () => {
        try {
            setCargandoUsuarios(true)
            const responseUsuarios = await axios.get(getUsuariosURL())
            const responsePerfilesUsuario = await axios.get(getPerfilesUsuarioURL(idGrupo));

            const usuarios = responseUsuarios.data.usuarios;
            const indxUsuarioActual = usuarios?.findIndex(x => x.EMAIL === user.username);
            usuarios.splice(indxUsuarioActual, 1);
            setSeleccionadosBulk(Array(usuarios.length).fill(false))
            setUsuarios(usuarios);
            setCargandoBitGoogleUsuarios(new Array(usuarios.length).fill(false))
            setPerfilesUsuario(responsePerfilesUsuario.data.perfiles)
        }
        catch (error) {
            console.log('ERROR | getUsuarios ', error)
        }
        finally {
            setCargandoUsuarios(false)
        }
    }

    useEffect(() => {
        setOpcOperacionBulk("0")
    }, [seleccionadosBulk])

    useEffect(() => {
        if (varCliente) getUsuarios()
    }, [varCliente, selectedClienteComercial])

    const setupModalCrearUsuario = () => {
        setEventoModalUsuario('Crear')
        setUsuarioEditar(null)
        setTimestampModalUsuario(Date.now())
        setVerModalUsuario(true)
    }

    const setupModalEditarUsuario = (usuario) => {
        setEventoModalUsuario('Editar')
        setUsuarioEditar({ ...usuario })
        setTimestampModalUsuario(Date.now())
        setVerModalUsuario(true)
    }

    const setupDialogoOperacionesBulk = (operacion, listaBitsPorIndx) => {
        const config = { ...FWDDialogoProps }
        config.txtTitulo = "Operaciones"
        config.txtMensaje = "¿Estás seguro de " + operacion + " los usuarios seleccionados?"
        config.inputValido = "confirmar"
        config.fnOk = () => new Promise(async (resolve, reject) => {

            try {
                setDialogoOpcBulkCargando(true);
                const usuariosSeleccionados = listaBitsPorIndx
                    .map((bit, indx) => bit ? usuarios[indx]['ID_USUARIO'] : null)
                    .filter(x => x !== null)

                await axios.patch(getUsuariosURL(), {
                    accion: operacion,
                    usuarios: usuariosSeleccionados
                })

                enqueueSnackbar('Usuarios actualizados', {
                    variant: 'success',
                    autoHideDuration: 1500
                })

                getUsuarios()
            }
            catch (error) {
                console.log('ERROR | setupDialogoOperacionesBulk', error);
                enqueueSnackbar('Error al actualizar usuarios', {
                    variant: 'error',
                    onClick: () => closeSnackbar()
                })
                reject();
            }
            finally {
                setBitSeleccionadosBulk(false);
                setDialogoOpcBulkVisible(false);
                setDialogoOpcBulkCargando(false);
                resolve()
            }

        })
        config.fnNoOk = () => new Promise((resolve, reject) => {

            setDialogoOpcBulkVisible(false);
            resolve();
        })
        setDialogoOpcBulkProps(config);
        setDialogoOpcBulkVisible(true);
    }

    const actualizarBitGoogleUsr = async (strUsuario, bitGoogleAuth, indexUsr) => {
        // https://92v9zvrmx3.execute-api.us-east-1.amazonaws.com/dev/portal/configuracion/seguridad/api/usuarios/set-bit-google-auth
        const bitUsuarios = cargandoBitGoogleUsuarios
        try {
            bitUsuarios[indexUsr] = true
            setCargandoBitGoogleUsuarios([...bitUsuarios])
            const result = await axios.post(process.env.REACT_APP_AWS_API_BASE_URL + '/portal/configuracion/seguridad/api/usuarios/set-bit-google-auth', {
                strUsuario,
                bitGoogleAuth
            })

            const usuariosTabla = usuarios
            usuariosTabla[indexUsr].BIT_GOOGLE = bitGoogleAuth
            setUsuarios([...usuariosTabla])
        }
        catch (error) {
            console.log('errorActualizarBitGoogleUsr ', error)
        }
        finally {
            bitUsuarios[indexUsr] = false
            setCargandoBitGoogleUsuarios([...bitUsuarios])
        }
    }

    const obtenerGoogleOtpUsuario = (strUsuario) => {
        // https://92v9zvrmx3.execute-api.us-east-1.amazonaws.com/dev/portal/configuracion/seguridad/api/usuarios/obtener-google-otp
        return new Promise(async (resolve, reject) => {
            try {
                const apiURL = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/configuracion/seguridad/api/usuarios/obtener-google-otp'
                const { data } = await axios.get(apiURL + '?strUsuario=' + strUsuario)
                resolve(data)
            }
            catch (error) {
                console.log('obtenerGoogleOtpUsuarioError', error)
                reject(error)
            }
        })
    }

    const [popOver, setPopOver] = useState({ anchor: null, usuario: null })

    const PopUsuario = (props) => {

        const { usuario, anchor, indexUsuario } = props
        const [codigoQR, setCodigoQR] = useState(null)
        const [creandoQR, setCreandoQR] = useState(true)
        const [datFecha, setDatFecha] = useState()

        const WHSIZE = 228

        const [cargandoRegenerarGoogleAuth, setCargandoRegenerarGoogleAuth] = useState(false)
        const regenerarGoogleAuth = async (strUsuario) => {
            // https://92v9zvrmx3.execute-api.us-east-1.amazonaws.com/dev/portal/configuracion/seguridad/api/usuarios/set-bit-google-auth
            try {
                setCargandoRegenerarGoogleAuth(true)
                const bitGoogleAuth = true
                const bitResetGoogleAuth = true
                const result = await axios.post(process.env.REACT_APP_AWS_API_BASE_URL + '/portal/configuracion/seguridad/api/usuarios/set-bit-google-auth', {
                    strUsuario,
                    bitGoogleAuth,
                    bitResetGoogleAuth
                })

                console.log('regenerarGoogleAuth ', result.data)
                getQR(result.data)

            }
            catch (error) {
                console.log('errorRegenerarGoogleAuth ', error)
            }
            finally {
                setCargandoRegenerarGoogleAuth(false)
            }
        }


        const getQR = async (user) => {
            try {
                const strOtp = user?.strHashOtpUrl
                const qr = await getQRCode(strOtp)
                setCodigoQR(qr)
                setDatFecha(user?.datFechaActivacionGoogleAuthenticator)
            }
            // En caso de resultar error, es debido a que no es una OTP_URL válida de Google
            catch (error) { }
            finally {
                setCreandoQR(false)
            }

        }

        useEffect(() => {
            getQR(usuario)
        }, [])

        if (anchor == null) {
            console.log('no anchor ?')
            return <></>
        }
        else return <Popover
            open={Boolean(anchor)}
            anchorEl={anchor}
            onClose={() => {
                setPopOver({ anchor: null, usuario: null })
            }}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
        >
            <section style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ margin: "15px 15px 0 15px", display: 'flex', justifyContent: 'space-between' }}>
                    <section>
                        {usuario?.datFechaActivacionGoogleAuthenticator
                            ? <Typography variant="overline">Sincronizado el {datFecha}</Typography>
                            : <Typography variant="overline">El usuario no ha sincronizado</Typography>
                        }
                    </section>
                    <CloseIcon className="cursor-hover" onClick={() => setPopOver({ anchor: null, usuario: null })} />
                </div>
                {creandoQR
                    ? <div style={{ width: WHSIZE, height: WHSIZE, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="overline">
                            <CircularProgress />
                        </Typography>
                    </div>
                    :
                    codigoQR ? <img src={codigoQR} style={{ width: WHSIZE, height: WHSIZE }} />
                        : <div style={{ width: WHSIZE, height: WHSIZE, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography variant="overline">El usuario no ha generado </Typography>
                            <Typography variant="overline">un código QR </Typography>
                        </div>
                }



                <div style={{ margin: "0 15px 15px 15px", display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant="outlined" color="primary" style={{ width: '45%' }}
                        disabled={cargandoRegenerarGoogleAuth}
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            regenerarGoogleAuth(usuario?.strUsuario)
                        }}
                    >Regenerar</Button>
                    <Button variant="outlined" color="secondary" style={{ width: '45%' }}
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            actualizarBitGoogleUsr(usuario?.strUsuario, false, indexUsuario)
                            setPopOver({ anchor: null, usuario: null })
                        }}
                    >Desactivar</Button>
                </div>
            </section>
        </Popover>
    }

    return <Grid container style={{ padding: 10 }}>
        <FWDDialogo {...dialogoOpcBulkProps} visible={dialogoOpcBulkVisible} cargando={dialogoOpcBulkCargando} />
        <Usuario
            visible={verModalUsuario}
            fnCerrar={() => setVerModalUsuario(false)}
            fnSuccess={() => getUsuarios()}
            evento={eventoModalUsuario}
            perfilesUsuario={perfilesUsuario}
            varCliente={selectedClienteComercial}
            usuarioEditar={usuarioEditar}
            timestamp={timestampModalUsuario}
        />
        <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '100%' }}>
            <section style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <IconButton aria-label="refresh"
                    color="secondary"
                    onClick={() => getUsuarios()}
                    disabled={cargandoUsuarios}
                >
                    <RefreshIcon />
                </IconButton>
                <FormControl>
                    <InputLabel htmlFor="age-native-simple">Operación</InputLabel>
                    <Select
                        value={opcOperacionBulk}
                        disabled={cargandoUsuarios || !bitSeleccionadosBulk}
                        native
                        onChange={(e) => {
                            const operacion = e.target.value;
                            setOpcOperacionBulk(operacion);
                            setupDialogoOperacionesBulk(operacion, seleccionadosBulk);
                        }}
                    >
                        <option aria-label="None" value="0" />
                        <option value="desactivar">Desactivar</option>
                        <option value="activar">Activar</option>
                        <option value="recuperar">Recuperar</option>
                        <option value="eliminar">Eliminar</option>
                    </Select>
                </FormControl>
            </section>
            <TableContainer>
                <Table size="small" aria-label="usuarios">
                    <TableHead>
                        <TableRow>
                            <TableCell component="th" align="center">{intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.MATRICULA' })}</TableCell>
                            <TableCell component="th" align="left">{intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.NOMBRE' })}</TableCell>
                            <TableCell component="th" align="left">{intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.CORREO_ELECTRONICO' })}</TableCell>
                            <TableCell component="th" align="center">{intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.FECHA_ALTA' })}</TableCell>
                            <TableCell component="th" align="center">{intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.FECHA_ACTIVIDAD' })}</TableCell>
                            <TableCell component="th" align="center">{intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.ESTATUS' })}</TableCell>
                            <TableCell component="th" align="center">{intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.ROL' })}</TableCell>
                            <TableCell component="th" align="center">{intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.TXT.PERFIL' })}</TableCell>
                            <TableCell component="th" align="center">
                                <IconButton aria-label="refresh"
                                    disabled={cargandoUsuarios}
                                    onClick={() => setupModalCrearUsuario()}
                                >
                                    <PersonAddIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell component="th">
                                {/* <Checkbox
                                checked={bitSeleccionadosBulk}
                                disabled={cargandoUsuarios}
                                onChange={(e) => {
                                    const val = e.target.checked;
                                    const sel = Array(seleccionadosBulk.length).fill(val)
                                    setSeleccionadosBulk(sel)
                                    setBitSeleccionadosBulk(val);
                                }}
                            /> */}
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img src="/svgs/GoogleAuthenticatorSvg.svg" width="25px" height="auto" />
                                </div>
                            </TableCell>
                            <TableCell component="th">
                                <Checkbox
                                    checked={bitSeleccionadosBulk}
                                    disabled={cargandoUsuarios}
                                    onChange={(e) => {
                                        const val = e.target.checked;
                                        const sel = Array(seleccionadosBulk.length).fill(val)
                                        setSeleccionadosBulk(sel)
                                        setBitSeleccionadosBulk(val);
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {cargandoUsuarios || usuarios?.length === 0
                            ?
                            <TableRow>
                                <TableCell colSpan={11}>
                                    <section style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        {cargandoUsuarios
                                            ?
                                            <>
                                                <Typography variant="overline">cargando</Typography>
                                                <LinearProgress style={{ width: '100%' }} />
                                            </>
                                            :
                                            <Typography variant="overline">{intl.formatMessage({ id: 'CONFIGURACIONES.SEGURIDAD.MSG.SIN_USUARIOS' })}</Typography>
                                        }
                                    </section>
                                </TableCell>
                            </TableRow>
                            :
                            usuarios?.map((u, uindx) => (
                                <TableRow key={uindx} hover>
                                    <TableCell align="center">{u.MATRICULA || 'Sin asignar'}</TableCell>
                                    <TableCell align="left">{u.NOMBRE_COMPLETO}</TableCell>
                                    <TableCell align="left">{u.EMAIL}</TableCell>
                                    <TableCell align="center">{u.FECHA_ALTA}</TableCell>
                                    <TableCell align="center">{u.FECHA_ULTIMO_LOGIN || 'Sin ingresar'}</TableCell>
                                    <TableCell align="center">{u.STATUS}</TableCell>
                                    <TableCell align="center">{u.ROL}</TableCell>
                                    <TableCell align="center">{u.PERFIL || 'Sin asignar'}</TableCell>
                                    <TableCell align="center">
                                        <IconButton aria-label="refresh"
                                            disabled={cargandoUsuarios}
                                            onClick={() => setupModalEditarUsuario(u)}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        <section style={{ display: 'flex', justifyContent: 'center' }}>
                                            <IconButton aria-label="refresh"
                                                disabled={cargandoBitGoogleUsuarios[uindx]}
                                                onClick={async (e) => {
                                                    e.persist()
                                                    if (!u.BIT_GOOGLE) {
                                                        // ACTIVAR GOOGLE AUTH
                                                        actualizarBitGoogleUsr(u.ID_USUARIO, true, uindx)
                                                    }
                                                    else {
                                                        try {
                                                            if (e?.target) {
                                                                // Abrir PopOver
                                                                const usuario = await obtenerGoogleOtpUsuario(u?.ID_USUARIO)
                                                                usuario.strUsuario = u?.ID_USUARIO
                                                                setPopOver({ anchor: e.target, usuario, indexUsuario: uindx })
                                                            }
                                                        }
                                                        catch (error) {
                                                            console.log('Error...', error)
                                                        }
                                                    }
                                                }}
                                            >
                                                {u.BIT_GOOGLE ? <LockIcon color="primary" /> : <LockOpenIcon />}
                                            </IconButton>
                                        </section>
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox
                                            disabled={cargandoUsuarios}
                                            checked={seleccionadosBulk[uindx]}
                                            onChange={() => {
                                                const bit = !seleccionadosBulk[uindx];
                                                const sel = [...seleccionadosBulk];
                                                sel[uindx] = bit;
                                                setSeleccionadosBulk(sel)
                                                const totalSeleccionados = sel.filter(Boolean).length;
                                                setBitSeleccionadosBulk(totalSeleccionados > 0)
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <PopUsuario {...popOver} />
        </section>
    </Grid>
}

export default injectIntl(CrudUsuarios)

