import React from 'react'
import { injectIntl } from "react-intl";
import { Typography, Paper, Divider, Grid } from '@material-ui/core';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import RoomIcon from '@material-ui/icons/Room';

const FRANQUICIAS_FWD = {
    MX: 1,
    US: 2
}

function VentaServicio({ intl, venta, markerColors = null }) {

    if (!venta) return <></>;

    const {
        dblPedidoDetCliente,
        dblProducto,
        strProducto,
        dblPedido,
        strNumeroServicio,
        dblPesoPedido,
        strUnidadMedida,
        strNombreCorto,
        strClaveSAT,
        strFranquicia,
        strRazonSocial,
        strRFC,
        strOrigen,
        strDestino,
        intCiudadOrigen,
        intCiudadDestino,
        intTipoVehiculo,
        strTipoVehiculo,
        strTipoVehiculoIngles,
        intMoneda,
        strMoneda,
        dblPrecioUnitario,
        intIVA,
        dblTasa,
        intRetIVA,
        dblIVA,
        dblRetIVA,
        dblTotal,
        intFranquicia,
        strFacturas,
        bitValidarOrigen,
        bitValidarDestino,
        bitTipoVehiculo,
        strDireccionOrigen,
        strDireccionDestino
    } = venta;

    if (markerColors === null) {
        markerColors = {
            green: '#587D71',
            red: '#902D41',
            blue: '#2E5EAA'
        }
    }

    const DefaultRender = () => (
        <Grid item container direction="column" xs={12}>

            <Timeline align="left" style={{ marginBottom: '0px', paddingBottom: '0px' }}>

                <TimelineItem style={{ alignItems: 'center', minHeight: '0' }}>
                    <TimelineOppositeContent style={{ display: 'none' }}>
                        <Typography variant="body2" color="textSecondary"></Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot style={{ background: markerColors.blue }}>
                            <LocalShippingIcon />
                        </TimelineDot>

                    </TimelineSeparator>
                    <TimelineContent style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '0px' }}>
                        <Typography style={{ fontWeight: 'bold' }}>{strProducto}</Typography>
                        <Typography style={{ fontWeight: 'bold' }}>{strTipoVehiculo || 'No aplica'}</Typography>
                    </TimelineContent>
                </TimelineItem>

            </Timeline>
            <Grid item container direction="row">
                <Grid item xs={6}>

                    <Timeline align="left" style={{ paddingTop: '0px' }}>

                        <TimelineItem style={{ alignItems: 'center', minHeight: '0' }}>
                            <TimelineOppositeContent style={{ display: 'none' }}>
                                <Typography variant="body2" color="textSecondary"></Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot style={{ background: markerColors.green }}>
                                    <RoomIcon />
                                </TimelineDot>

                            </TimelineSeparator>
                            <TimelineContent>
                                <Grid item container>
                                    <Grid item sm={12}>
                                        <Typography variant="subtitle1">{strOrigen || 'No aplica'}</Typography>
                                    </Grid>
                                    {strDireccionOrigen 
                                        ? <Grid item sm={12}>
                                            <Typography variant="subtitle2">{strDireccionOrigen}</Typography>
                                        </Grid>
                                        : <></>
                                    }
                                </Grid>
                            </TimelineContent>
                        </TimelineItem>

                        <TimelineItem style={{ alignItems: 'center', minHeight: '0' }}>
                            <TimelineOppositeContent style={{ display: 'none' }}>
                                <Typography variant="body2" color="textSecondary"></Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot style={{ background: markerColors.red }}>
                                    <RoomIcon />
                                </TimelineDot>
                            </TimelineSeparator>
                            <TimelineContent>
                            <Grid item container>
                                    <Grid item sm={12}>
                                        <Typography variant="subtitle1">{strDestino || 'No aplica'}</Typography>
                                    </Grid>
                                    {strDireccionDestino 
                                        ? <Grid item sm={12}>
                                            <Typography variant="subtitle2">{strDireccionDestino}</Typography>
                                        </Grid>
                                        : <></>
                                    }
                                </Grid>
                            </TimelineContent>
                        </TimelineItem>

                    </Timeline>

                </Grid>

                <Grid item xs={6} style={{ padding: '20px 0' }} container direction="row">
                    <Divider orientation="vertical" />
                    <section style={{ padding: '0 15px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', flexGrow: '1' }}>
                        {
                            intFranquicia === FRANQUICIAS_FWD.MX
                                ?
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography>{intl.formatMessage({ id: 'SERVICIOS.VENTA.TXT.SUBTOTAL' })}</Typography>
                                    <Typography>{`$${dblPrecioUnitario.toLocaleString('en-US', { minimumFractionDigits: 2 })} ${strMoneda}`}</Typography>
                                </div>
                                : <></>
                        }
                        {
                            intFranquicia === FRANQUICIAS_FWD.MX
                                ?
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography>{intl.formatMessage({ id: 'SERVICIOS.VENTA.TXT.IVA' })}</Typography>
                                    <Typography>{`$${dblIVA.toLocaleString('en-US', { minimumFractionDigits: 2 })} ${strMoneda}`}</Typography>
                                </div>
                                : <></>
                        }
                        {
                            dblRetIVA && intFranquicia === FRANQUICIAS_FWD.MX
                                ? <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography>{intl.formatMessage({ id: 'SERVICIOS.VENTA.TXT.RET_IVA' })}</Typography>
                                    <Typography>{`$${dblRetIVA.toLocaleString('en-US', { minimumFractionDigits: 2 })} ${strMoneda}`}</Typography>
                                </div>
                                : <></>
                        }
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography>{intl.formatMessage({ id: 'SERVICIOS.VENTA.TXT.TOTAL' })}</Typography>
                            <Typography>{`$${dblTotal.toLocaleString('en-US', { minimumFractionDigits: 2 })}`} {strMoneda}</Typography>
                        </div>
                    </section>
                </Grid>
            </Grid>
        </Grid>
    )

    const MiniRender = () => (
        <Grid item container direction="column" xs={12}>
            <Grid item container direction="row">
                <Grid item xs={6}>
                    <Timeline align="left">
                        <TimelineItem style={{ alignItems: 'center', minHeight: '0' }}>
                            <TimelineOppositeContent style={{ display: 'none' }}>
                                <Typography variant="body2" color="textSecondary"></Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot style={{ background: markerColors.blue }}>
                                    <LocalShippingIcon />
                                </TimelineDot>

                            </TimelineSeparator>
                            <TimelineContent>
                                <Typography style={{ fontWeight: 'bold' }}>{strProducto}</Typography>
                            </TimelineContent>
                        </TimelineItem>
                    </Timeline>
                </Grid>

                <Grid item xs={6} style={{ padding: '20px 0' }} container direction="row">
                    <Divider orientation="vertical" />
                    <section style={{ padding: '0 15px', display: 'flex', flexDirection: 'column', justifyContent: intFranquicia === FRANQUICIAS_FWD.MX ? 'flex-end' : 'center', flexGrow: '1' }}>

                        {
                            intFranquicia === FRANQUICIAS_FWD.MX
                                ?
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography>{intl.formatMessage({ id: 'SERVICIOS.VENTA.TXT.SUBTOTAL' })}</Typography>
                                    <Typography>{`$${dblPrecioUnitario.toLocaleString('en-US', { minimumFractionDigits: 2 })} ${strMoneda}`}</Typography>
                                </div>
                                : <></>
                        }
                        {
                            intFranquicia === FRANQUICIAS_FWD.MX
                                ?
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography>{intl.formatMessage({ id: 'SERVICIOS.VENTA.TXT.IVA' })}</Typography>
                                    <Typography>{`$${dblIVA.toLocaleString('en-US', { minimumFractionDigits: 2 })} ${strMoneda}`}</Typography>
                                </div>
                                : <></>
                        }
                        {dblRetIVA && intFranquicia === FRANQUICIAS_FWD.MX
                            ? <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography>{intl.formatMessage({ id: 'SERVICIOS.VENTA.TXT.RET_IVA' })}</Typography>
                                <Typography>{`$${dblRetIVA.toLocaleString('en-US', { minimumFractionDigits: 2 })} ${strMoneda}`}</Typography>
                            </div>
                            : <></>
                        }
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography>{intl.formatMessage({ id: 'SERVICIOS.VENTA.TXT.TOTAL' })}</Typography>
                            <Typography>{`$${dblTotal.toLocaleString('en-US', { minimumFractionDigits: 2 })} ${strMoneda}`}</Typography>
                        </div>
                    </section>
                </Grid>
            </Grid>
        </Grid>
    )

    return <>
        <Paper elevation={3}>
            {bitValidarOrigen && bitValidarDestino
                ? <DefaultRender />
                : <MiniRender />
            }
        </Paper>
    </>
}


export default injectIntl(VentaServicio);