/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useRef } from "react";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_helpers";
import CajonNotificaciones from './CajonNotificaciones';
import { useSelector } from 'react-redux';
import { LogoFwd } from 'app/componentes/dashboard/LoaderDashboard'
import BlankProfilePic from 'app/../imagenes/user_fwd.png'

export function QuickUser({ id = 'kt_quick_user' }) {
  const history = useHistory();

  const { session, selectedClienteComercial } = useSelector(state => state.auth);

  const logoutClick = () => {
    const toggle = document.getElementById(`${id}_toggle`);
    if (toggle) {
      toggle.click();
    }
    history.push("/logout");
  };


  const closePanelRef = useRef()

  const openProfileCLickEvent = e => {
    e.preventDefault()
    history.push('/perfil-usuario')
    closePanelRef.current.click()
  }

  const RenderUserInfo = () => (
    <div className="d-flex flex-column mt-5">
      {/* <div className="d-flex flex-column align-items-center justify-content-center mt-5"> */}
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-5" style={{ textAlign: "end" }}>
              <div className="symbol symbol-100">
                <img src={BlankProfilePic} />
                <i className="symbol-badge bg-success" />
              </div>
            </div>
            <div className="col-7" style={{ textAlign: "left" }}>
              <a href="#" className="font-weight-bold font-size-h5 text-dark-75">
                {session?.datosUsuario?.strNombreCompleto}
              </a>
              <p className="text-muted">
                {session?.datosUsuario?.strNickname}
              </p>
              <button className="btn btn-light-warning btn-bold" onClick={openProfileCLickEvent}>Editar Perfil <i className="fa fa-cogs" aria-hidden="true"></i></button>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column align-items-left mt-5">
        <div className="navi mt-2">
          <a href="#" className="navi-item">
            <span className="navi-link p-0 pb-2">
              <span className="navi-icon mr-1">
                <span className="svg-icon-lg svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Communication/Mail-notification.svg")}
                  ></SVG>
                </span>
              </span>
              <span className="navi-text text-muted">
                {session?.datosUsuario?.strEmail}
              </span>
            </span>
          </a>
        </div>
      </div>
      <button className="btn btn-lg btn-block btn-light-primary btn-bold mt-10" onClick={logoutClick}>Cerrar sesión</button>
    </div>

  )

  const RenderSeparator = ({ mt = 8, mb = 5 }) => (<div className={`separator separator-dashed mt-${mt} mb-${mb}`} />)

  // Se vuelve componente... se deja de ejemplo, se debe parametrizar conforme necesidades
  const RenderItem = () => (<>

    <div className="navi navi-spacer-x-0 p-0">

      <a className="navi-item" onClick={e => {
        e.preventDefault()
        // history.push('/perfil-usuario')
      }}>
        <div className="navi-link">
          <div className="symbol symbol-40 bg-light mr-3">
            <div className="symbol-label">
              <span className="svg-icon svg-icon-md svg-icon-success">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/General/Notification2.svg"
                  )}
                ></SVG>
              </span>
            </div>
          </div>
          <div className="navi-text">
            <div className="font-weight-bold">Mi perfil</div>
            <div className="text-muted">
              Configura tu perfil de usuario
              {/* <span className="label label-light-danger label-inline font-weight-bold">
          update
        </span> */}
            </div>
          </div>
        </div>
      </a>

    </div>

  </>)


  return (<div id={id} className="offcanvas offcanvas-right offcanvas p-10">

    <div className="offcanvas-header d-flex align-items-center justify-content-end pb-5">
      {/* <h3 className="font-weight-bold m-0">
      User Profile
        <small className="text-muted font-size-sm ml-2">12 messages</small>
    </h3> */}
      <a
        href="#"
        className="btn btn-xs btn-icon btn-light btn-hover-primary"
        id={`${id}_close`}
        ref={closePanelRef}
      >
        <i className="ki ki-close icon-xs text-muted" />
      </a>
    </div>

    <div
      className="offcanvas-content pr-5 mr-n5"
    >

      <RenderUserInfo />

      {/* <RenderSeparator />

      <RenderItem /> */}
    </div>
  </div>);
}

{/* <div className="separator separator-dashed my-7"></div> */ }
{/* <a href="/user/profile" className="navi-item">
          <div className="navi-link">
            <div className="symbol symbol-40 bg-light mr-3">
              <div className="symbol-label">
              <span className="svg-icon svg-icon-md svg-icon-warning">
                <SVG
                    src={toAbsoluteUrl(
                        "/media/svg/icons/Shopping/Chart-bar1.svg"
                    )}
                ></SVG>
              </span>
              </div>
            </div>
            <div className="navi-text">
              <div className="font-weight-bold">My Messages</div>
              <div className="text-muted">Inbox and tasks</div>
            </div>
          </div>
        </a>

        <a href="/user/profile" className="navi-item">
          <div className="navi-link">
            <div className="symbol symbol-40 bg-light mr-3">
              <div className="symbol-label">
              <span className="svg-icon svg-icon-md svg-icon-danger">
                <SVG
                    src={toAbsoluteUrl(
                        "/media/svg/icons/Files/Selected-file.svg"
                    )}
                ></SVG>
              </span>
              </div>
            </div>
            <div className="navi-text">
              <div className="font-weight-bold">My Activities</div>
              <div className="text-muted">Logs and notifications</div>
            </div>
          </div>
        </a>

        <a href="/user/profile" className="navi-item">
          <div className="navi-link">
            <div className="symbol symbol-40 bg-light mr-3">
              <div className="symbol-label">
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                    src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Mail-opened.svg"
                    )}
                ></SVG>
              </span>
              </div>
            </div>
            <div className="navi-text">
              <div className="font-weight-bold">My Tasks</div>
              <div className="text-muted">latest tasks and projects</div>
            </div>
          </div>
        </a> */}