import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { call, put, takeLatest, select } from "redux-saga/effects";
// import { getUserByToken } from "./authCrud";
import { Auth } from 'aws-amplify'
import axios from 'axios';

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API"
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  session: undefined,
  selectedClienteComercial: undefined, // Agrega esta propiedad
};

export const reducer = persistReducer(
  { storage, key: "v709-demo1-auth", whitelist: ["user", "authToken", "session", "selectedClienteComercial"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        Auth.signOut();
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user, session, selectedClienteComercial } = action.payload;
        return { ...state, user, session, selectedClienteComercial };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: authToken => ({ type: actionTypes.Login, payload: { authToken } }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: (user, session, selectedClienteComercial) => ({
    type: actionTypes.UserLoaded,
    payload: { user, session, selectedClienteComercial },
  })
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    try {

      const callUserSession = async () => {
        return await axios.get(`${process.env.REACT_APP_AWS_API_BASE_URL}/auth/sesion`);
      };
      const session = yield call(callUserSession);

      const callAuthenticatedUser = async () => {
        return await Auth.currentAuthenticatedUser();
      };
      const data = yield call(callAuthenticatedUser);
      const selectedClienteComercial = yield select((state) => state.auth.selectedClienteComercial);

      yield put(actions.fulfillUser(data, session.data, selectedClienteComercial));
    }
    catch (error) { }
  });
}
