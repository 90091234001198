import React, { useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Popover, ClickAwayListener, colors } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(1),
    },
}));

const gris = 'rgb(87, 117, 144)';
const amarillo = 'rgb(249, 199, 79)';
const verde = 'rgb(0, 227, 150)';
const rojo = 'rgb(255, 50, 74)';

function PopoverIndicadorPorFacturar(props) {

    const { intl, jsonDocumentosRequeridos, dblPedido } = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [color, setColor] = useState(gris);
    let arrayDocumentos = jsonDocumentosRequeridos ? JSON.parse(jsonDocumentosRequeridos) : [];

    const handlePopoverOpen = (event) => {
        if (anchorEl == null) {
            setAnchorEl(event.currentTarget);
        } else {
            setAnchorEl(null);
        }
    };

    const handlePopoverClose = (event) => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (arrayDocumentos.length == 0) {
            setColor(gris);
        } else {
            let filterDocumentosPorSubir = arrayDocumentos.filter((item) => !item.idDocumento.trim());
            if(filterDocumentosPorSubir.length == 0) {
                setColor(verde);
            } else if(filterDocumentosPorSubir.length == arrayDocumentos.length) {
                setColor(rojo);
            } else {
                setColor(amarillo);
            }
        }
    }, [jsonDocumentosRequeridos]);

    const open = Boolean(anchorEl);
    const id = `popover-documentos-pendientes-${dblPedido}`;

    return <div style={{ width: '100%', height: '100%', textAlign: 'center', position: 'relative', display: (color == gris ? "none" : "") }} aria-describedby={id}>
        <div style={{ width: '25px', height: '25px', backgroundColor: color, margin: 'auto', borderRadius: '50%' }} aria-describedby={id} onClick={handlePopoverOpen}></div>
            <Popover
                id={id}

                classes={{ paper: classes.paper, }}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                transformOrigin={{ vertical: 'top', horizontal: 'left', }}
                // onClick={handlePopoverClose}
            >
                <Grid container spacing={1} style={{ width: '300px' }} >
                    <Grid item ><Typography >{intl.formatMessage({ id: 'SERVICIOS.INDICADOR_DOCUMENTOS_POR_SUBIR.TITULO' })}</Typography></Grid>
                    <Grid container item spacing={1} style={{ paddingLeft: '15px' }} >
                        <Grid item ><div style={{ width: '20px', height: '20px', backgroundColor: rojo, borderRadius: '50%' }}></div></Grid>
                        <Grid item ><Typography >{intl.formatMessage({ id: 'SERVICIOS.INDICADOR_DOCUMENTOS_POR_SUBIR.PENDIENTE' })}</Typography></Grid>
                    </Grid>

                    <Grid container item spacing={1} style={{ paddingLeft: '15px' }} >
                        <Grid item ><div style={{ width: '20px', height: '20px', backgroundColor: amarillo, borderRadius: '50%' }}></div></Grid>
                        <Grid item ><Typography >{intl.formatMessage({ id: 'SERVICIOS.INDICADOR_DOCUMENTOS_POR_SUBIR.SUBIDA_PARCIAL' })}</Typography></Grid>
                    </Grid>

                    <Grid container item spacing={1} style={{ paddingLeft: '15px' }} >
                        <Grid item ><div style={{ width: '20px', height: '20px', backgroundColor: verde, borderRadius: '50%' }} ></div></Grid>
                        <Grid item ><Typography >{intl.formatMessage({ id: 'SERVICIOS.INDICADOR_DOCUMENTOS_POR_SUBIR.SUBIDA_TOTAL' })}</Typography></Grid>
                    </Grid>
                </Grid>
            </Popover>
    </div>
}

export default injectIntl(PopoverIndicadorPorFacturar);