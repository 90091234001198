import { useHtmlClassService } from '_metronic/layout/_core/MetronicLayout'


export const useWidthBreakpoints = () => {
    const uiService = useHtmlClassService();
    return uiService.config.js.breakpoints;
}

export const useColorScheme = () => {
    const uiService = useHtmlClassService();
    const selectedTheme = localStorage.getItem('FWDselectedUITheme') || process.env.REACT_APP_METRONIC_DEFAULT_THEME;
    return uiService.config.js.colors.theme[selectedTheme];
}

export const markerColors = () => {
    return {
        green: '#587D71',
        red: '#902D41',
        blue: '#2E5EAA',
        disabled: '#EBEBE4'
    }
}
