import React, { useState, memo, useEffect } from 'react'
import { compose } from 'redux'
import { Grid, makeStyles, Typography, Tooltip } from '@material-ui/core'
import { Tabs, Tab, Divider, Link, Fade, Hidden, CircularProgress, Paper, Box, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Skeleton from '@material-ui/lab/Skeleton';
import { injectIntl } from "react-intl";
import { useSnackbar } from 'notistack';
import axios from 'axios';

//ICONS
import MapIcon from '@material-ui/icons/Map';
import RoomIcon from '@material-ui/icons/Room';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PaymentIcon from '@material-ui/icons/Payment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';

//COMPONENTES
import FWDModal from 'app/componentes/FWDModal';
import Mapa from 'app/componentes/servicios/MapaServicios'
import loadTheme from 'app/helpers/loadTheme';
import VentaServicio from 'app/componentes/servicios/VentaServicio';
import DocumentoServicio from 'app/componentes/servicios/DocumentoServicio';
import DocumentoPorSubir from 'app/componentes/servicios/DocumentoPorSubir';
import ViajeServicio from 'app/componentes/servicios/ViajeServicio';
import TarjetaDescripcion from 'app/componentes/servicios/TarjetaDescripcion';
import ModalFactura from 'app/componentes/ModalFactura'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import TimelineSeguimiento from 'app/componentes/servicios/TimelineSeguimiento';

const useAccordionStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

const dataCuentasEspejo = [
    createData('Test', 'http://fwd.proveedores.test/123123123asldjasldjaslj1231csasdkaiqwek00012', 'Activo'),
];

function createData(proveedor, enlace, estado) {
    return { proveedor, enlace, estado };
}

function FWDModalServicio(props) {


    const UIColors = loadTheme();

    const { intl, bitOpen, dblPedido, fnClose, serviciosFilter } = props;

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [expanded, setExpanded] = useState('panel-detalle');

    const [zoomMapa] = useState(5);

    const [tabValue, setTabValue] = useState(0);

    const [cargandoDetalleServicio, setCargandoDetalleServicio] = useState(true);
    const [detalleServicio, setDetalleServicio] = useState(null);
    const [listaServicios, setListaServicios] = useState(null);
    const [listaBitCargandoAcuses, setlistaBitCargandoAcuses] = useState([])
    const [listaBitCargandoOtros, setListaBitCargandoOtros] = useState([])
    const [detalleCuentasEspejo, setDetalleCuentasEspejo] = useState([])
    const [facturaSeleccionada, setFacturaSeleccionada] = useState({ open: false, dblFactura: 0 });
    const [copiedUrl, setCopiedUrl] = useState(null);

    const accordionClasses = useAccordionStyles();

    useEffect(() => {
        if (dblPedido && serviciosFilter && Array.isArray(serviciosFilter)) {
            const servicioFiltrado = serviciosFilter.find(servicio => servicio.dblPedido === dblPedido);
            if (servicioFiltrado) {
                setDetalleCuentasEspejo(servicioFiltrado);
            } else {
                console.error('Servicio no encontrado.');
            }
        } else {
            // console.error('serviciosFilter no est� definido o no es un array v�lido.');
        }
    }, [dblPedido, serviciosFilter]);

    const handleCopyUrl = (url) => {
        // Copiar el contenido al portapapeles
        navigator.clipboard.writeText(url).then(() => {
            setCopiedUrl(url);
        });
    };

    const fetchDetalleServicio = async () => {
        try {
            setTabValue(0);
            setCargandoDetalleServicio(true);
            await syncDetalleServicio();
            const responseSeguimiento = await axios.get(`${process.env.REACT_APP_AWS_API_BASE_URL}/portal/dashboard/api/rastreos/servicios/${dblPedido}`)

        } catch (error) {
            enqueueSnackbar(intl.formatMessage({ id: "SERVICIOS.CONSULTA.ERROR.ERROR_AL_OBTENER_DETALLE_SERVICIO" }), {
                variant: 'error',
                persist: true,
                onClick: () => closeSnackbar()
            })
            console.error('Error fetch detalle servicios: ', error);
        }
        finally {
            setCargandoDetalleServicio(false);
        }
    }

    const syncDetalleServicio = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_AWS_API_BASE_URL}/portal/servicios/consultar/api/detalleservicio/${dblPedido}`)
                setDetalleServicio(response.data)
                setlistaBitCargandoAcuses(Array(response.data?.documentos?.length).fill(false));
                resolve(true);
            } catch (error) {
                reject(error);
            }
        });
    }

    const existeMasDeUnaVentaPorDenominacion = (detalleServicio) => {
        let masDeUnaVenta = false;
        if (!detalleServicio?.venta?.length) return masDeUnaVenta;

        const monedas = detalleServicio?.venta?.map(v => v.strMoneda).filter((val, indx, self) => self.indexOf(val) === indx);
        for (let moneda of monedas) {
            masDeUnaVenta = detalleServicio?.venta.filter(x => x.strMoneda === moneda).length > 1
            if (masDeUnaVenta) break;
        };

        return masDeUnaVenta;
    }

    let reloadDataMapa = (data) => {
        let listaServicios_aux = data || [...listaServicios];
        setListaServicios(listaServicios_aux);
    }

    const DetalleServicio = ({ height, servicioFiltrado }) => (
        <Grid item style={{
            display: 'flex', flexDirection: 'column',
            overflowY: 'scroll', height,
            marginBottom: detalleServicio?.venta?.length > 1 && detalleServicio?.seguimiento?.length ? 'auto' : '0'
        }}>

            <Accordion expanded={expanded === 'panel-detalle'}
                style={{
                    width: '100%', height: expanded !== 'panel-detalle' ? 'fit-content' : 'auto',
                    marginBottom: existeMasDeUnaVentaPorDenominacion(detalleServicio) || detalleServicio?.seguimiento?.length ? 'auto' : '0',
                    flexGrow: expanded !== 'panel-detalle' ? '0' : '1'
                }}
                onClick={() => { setExpanded('panel-detalle') }}
            >
                {expanded === 'panel-detalle'
                    ? <Fade in={expanded === 'panel-detalle'}>
                        <AccordionSummary style={{ justifyContent: 'space-between' }} className='cursor-default'>
                            <Typography className={accordionClasses.heading}>Detalle</Typography>
                        </AccordionSummary>
                    </Fade>
                    : <Fade in={expanded === 'panel-rutas'}>
                        <AccordionSummary style={{ justifyContent: 'flex-end' }}>
                            <section style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                <Link href="#detalle" onClick={e => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    setExpanded('panel-detalle')
                                }}>{expanded === 'panel-detalle' ? 'Ver seguimiento' : 'Ocultar seguimiento'}</Link>
                            </section>
                        </AccordionSummary>
                    </Fade>
                }
                <AccordionDetails>
                    <Grid container direction="column">
                        <Grid item style={{ margin: '5px 10px 5px 5px', width: "100%" }}>
                            <TarjetaDescripcion descripcion={detalleServicio?.descripcion} />
                        </Grid>
                        {detalleServicio?.venta?.map((venta, indx) => (
                            <Grid item
                                key={indx}
                                style={{ margin: '5px 10px 5px 5px', width: "100%" }}>
                                <VentaServicio venta={venta} />
                            </Grid>
                        ))}
                        <Grid item style={{ margin: '5px 10px 5px 5px', width: "100%" }}>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography style={{ fontWeight: 'bold' }}>Link's de rastreo</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TableContainer component={Paper}>
                                        <Table size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ width: '90%' }}>Enlace</TableCell>
                                                    <TableCell style={{ width: '5%' }}></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {detalleCuentasEspejo?.cuentasEspejo?.length > 0 ? (
                                                    detalleCuentasEspejo.cuentasEspejo.map((data) => (
                                                        <TableRow key={data.tokenRastreo}>
                                                            <TableCell style={{ color: '#1976d2' }}>
                                                                <Link href={data.urlRastreo} target="_blank" rel="noopener noreferrer">
                                                                    {data.urlRastreo}
                                                                </Link>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Tooltip
                                                                    title={
                                                                        copiedUrl === data.urlRastreo ? 'Enlace Copiado' : 'Copiar Enlace'
                                                                    }
                                                                >
                                                                    <Button
                                                                        type="button"
                                                                        variant="outlined"
                                                                        style={{ borderColor: 'white' }}
                                                                        color="primary"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            handleCopyUrl(data.urlRastreo);
                                                                        }}
                                                                    >
                                                                        <span>
                                                                            <LinkIcon style={{ color: '#02060c' }}></LinkIcon>
                                                                        </span>
                                                                    </Button>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                ) : (
                                                    <TableRow>
                                                        <TableCell colSpan={3} align="center">
                                                            No se ha generado ninguna cuenta espejo .
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>

                </AccordionDetails>
            </Accordion>

            {/* TODO */}

            <Accordion expanded={expanded === 'panel-rutas'}
                style={{
                    width: '100%', height: expanded !== 'panel-rutas' ? 'fit-content' : 'auto',
                    flexGrow: expanded !== 'panel-rutas' ? '0' : '1',
                    display: existeMasDeUnaVentaPorDenominacion(detalleServicio) || detalleServicio?.seguimiento?.length ? 'block' : 'none'
                }}
                onClick={() => { if (detalleServicio?.seguimiento?.length) setExpanded('panel-rutas') }}
            >
                <Fade in={expanded === 'panel-detalle'}>
                    <AccordionSummary
                        className={detalleServicio?.seguimiento?.length ? '' : 'cursor-default'}
                        style={{
                            display: expanded === 'panel-rutas' ? 'none' : 'flex',
                            justifyContent: 'space-between'
                        }}>
                        {existeMasDeUnaVentaPorDenominacion(detalleServicio) ? <Typography variant="h4" className={accordionClasses.heading}>Total</Typography> : <></>}
                        <section style={{ display: 'flex', flexDirection: 'column', flexGrow: '1', alignItems: 'flex-end' }}>
                            {existeMasDeUnaVentaPorDenominacion(detalleServicio) ? detalleServicio?.venta?.map(v => v.strMoneda).filter((val, indx, self) => self.indexOf(val) === indx).map(mon => (
                                <Typography key={mon} className={accordionClasses.heading}>${detalleServicio?.venta?.filter(x => x.strMoneda === mon).map(x => x.dblTotal).reduce((a, b) => a + b).toLocaleString('en-US', { minimumFractionDigits: 2 })} {mon}</Typography>
                            )) : <></>}
                            {detalleServicio?.seguimiento?.length > 0
                                ?
                                <Link href="#seguimiento" onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setExpanded('panel-rutas')
                                }}>{expanded === 'panel-detalle' ? 'Ver seguimiento' : 'Ocultar seguimiento'}</Link>
                                : <></>
                            }
                        </section>
                    </AccordionSummary>
                </Fade>
                <AccordionDetails
                // style={{ display: 'flex', background: 'red' }}
                >
                    <TimelineSeguimiento seguimiento={detalleServicio?.seguimiento} />
                </AccordionDetails>
            </Accordion>

        </Grid >
    )

    const RenderTabs = ({ offset = 1 }) => {

        let selectedTab = <></>;
        switch (tabValue) {
            // Este tab aparece solo en modo resposivo
            case (0 - offset):
                selectedTab = <DetalleServicio height="calc(80vh - 102px)" />
                break;

            case (1 - offset):
                selectedTab = <Grid item style={{ position: 'relative', width: '100%', height: 'calc(80vh - 108px)' }}>
                    <Mapa mapaStyle={{ width: 'calc(100% - 5px)', height: '100%' }}
                        listaServicios={listaServicios}
                        dblPedidoSeleccionado={dblPedido}
                        centrarMapa={true}
                        zoom={zoomMapa}
                        handlePeticionRecargarListaServicios={reloadDataMapa}
                    />
                </Grid>
                break;

            case (2 - offset):
                selectedTab = <Grid item container
                    justify={detalleServicio?.viaje?.length === 0 ? 'center' : 'flex-start'}
                    alignContent={detalleServicio?.viaje?.length === 0 ? 'center' : 'flex-start'}
                    style={{
                        height: 'calc(80vh - 108px)',
                        overflowY: detalleServicio?.viaje?.length === 0
                            ? 'unset'
                            : 'scroll'
                    }}>

                    {detalleServicio?.viaje?.map((viaje, indx) => (
                        <ViajeServicio key={indx} viaje={viaje} indice={indx} total={detalleServicio?.viaje?.length} />
                    ))}

                </Grid>
                break;

            case (3 - offset):
                selectedTab = <Grid item container
                    justify={detalleServicio?.documentos?.length === 0 ? 'center' : 'flex-start'}
                    alignContent={detalleServicio?.documentos?.length === 0 ? 'center' : 'flex-start'}
                    style={{
                        height: 'calc(80vh - 108px)',
                        overflowY: detalleServicio?.documentos?.length === 0
                            ? 'unset'
                            : 'scroll'
                    }}>

                    {detalleServicio?.documentosRequeridos.filter(item => !item.idDocumento)?.length > 0
                        ? <Grid item container xs={12} style={{ padding: '15px' }}>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                <Typography variant="overline">Documentos pendientes de registrar</Typography>
                                <Divider style={{ flexGrow: '1', marginLeft: '5px', transform: 'translateY(-7px)' }} />
                            </Grid>
                            <Grid item container spacing={2}>
                                {detalleServicio.documentosRequeridos.filter(item => !item.idDocumento).map((archivo, indx) => (
                                    <DocumentoPorSubir key={indx} documento={archivo} dblPedido={dblPedido} fnOnRegistrado={() => { syncDetalleServicio() }} fnOnClick={() => {

                                        // fnClose()
                                    }} />
                                ))}
                            </Grid>
                        </Grid>
                        : <></>
                    }


                    {detalleServicio?.documentos?.length > 0
                        ? <>
                            {detalleServicio?.documentos.filter(doclen => doclen.tipoDocumento === 'FA').length > 0
                                ? <Grid item container xs={12} style={{ padding: '15px' }}>
                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                        <Typography variant="overline">Facturas</Typography>
                                        <Divider style={{ flexGrow: '1', marginLeft: '5px', transform: 'translateY(-7px)' }} />
                                    </Grid>
                                    {detalleServicio?.documentos?.filter(doc => doc.tipoDocumento === 'FA')
                                        .map((archivo, indx) => (
                                            <DocumentoServicio key={indx} documento={archivo} fnOnClick={() => {
                                                setFacturaSeleccionada({ open: true, dblFactura: archivo.folio })
                                                // fnClose()
                                            }} />
                                        ))}
                                </Grid>
                                : <></>}

                            {detalleServicio?.documentos.filter(doclen => doclen.tipoDocumento === 'AF' || doclen.tipoDocumento === 'AD').length > 0
                                ? <Grid item container xs={12} style={{ padding: '15px' }}>
                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                        <Typography variant="overline">Acuses</Typography>
                                        <Divider style={{ flexGrow: '1', marginLeft: '5px', transform: 'translateY(-7px)' }} />
                                    </Grid>
                                    {detalleServicio?.documentos?.filter(doc => doc.tipoDocumento === 'AF' || doc.tipoDocumento === 'AD')
                                        .map((archivo, indx) => (
                                            <DocumentoServicio key={indx} documento={archivo} cargando={listaBitCargandoAcuses[indx]}
                                                fnOnClick={async () => {
                                                    let copiaListaBit = [...listaBitCargandoAcuses];
                                                    copiaListaBit[indx] = true;
                                                    setlistaBitCargandoAcuses(copiaListaBit);
                                                    try {

                                                        const apiUrl = process.env.REACT_APP_AWS_API_BASE_URL + `/portal/servicios/consultar/api/acuses/${archivo.archivo}/digital`
                                                        const { data } = await axios.get(apiUrl);
                                                        window.open(data, "Popup", "location,status,scrollbars,resizable,width=800, height=800");
                                                        // const link = document.createElement('a');
                                                        // link.target = "_blank";
                                                        // link.href = data;
                                                        // document.body.appendChild(link);
                                                        // link.click();
                                                        // document.body.removeChild(link);
                                                    }
                                                    catch (error) {
                                                        console.log('ERROR | onClickAFAD ', error);
                                                        if (error.response) {
                                                            switch (error.response.status) {

                                                                case 404:
                                                                    enqueueSnackbar(intl.formatMessage({ id: "_ARCHIVO_NO_ENCONTRADO" }, {
                                                                        archivo: archivo.archivo
                                                                    }), {
                                                                        variant: 'error',
                                                                        persist: false,
                                                                        onClick: () => closeSnackbar()
                                                                    })
                                                                    break;
                                                                default:
                                                                    enqueueSnackbar(intl.formatMessage({ id: "_ARCHIVO_ERROR" }, {
                                                                        archivo: archivo.archivo
                                                                    }), {
                                                                        variant: 'error',
                                                                        persist: false,
                                                                        onClick: () => closeSnackbar()
                                                                    })
                                                                    break;
                                                            }
                                                        }
                                                    }
                                                    finally {
                                                        copiaListaBit = [...copiaListaBit];
                                                        copiaListaBit[indx] = false;
                                                        setlistaBitCargandoAcuses(copiaListaBit);
                                                    }
                                                }}
                                            />
                                        ))}
                                </Grid>
                                : <></>}

                            {detalleServicio?.documentos.filter(doclen => doclen.tipoDocumento !== 'AF' && doclen.tipoDocumento !== 'AD' && doclen.tipoDocumento !== 'FA').length > 0
                                ? <Grid item container xs={12} style={{ padding: '15px' }}>
                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                        <Typography variant="overline">Otros</Typography>
                                        <Divider style={{ flexGrow: '1', marginLeft: '5px', transform: 'translateY(-7px)' }} />
                                    </Grid>
                                    {detalleServicio?.documentos?.filter(doc => doc.tipoDocumento !== 'AF' && doc.tipoDocumento !== 'AD' && doc.tipoDocumento !== 'FA')
                                        .map((archivo, indx) => (
                                            <DocumentoServicio key={indx} documento={archivo} cargando={listaBitCargandoOtros[indx]}
                                                fnOnClick={async () => {
                                                    let copiaListaBit = [...listaBitCargandoOtros];
                                                    copiaListaBit[indx] = true;
                                                    setListaBitCargandoOtros(copiaListaBit);
                                                    try {
                                                        const apiUrl = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/servicios/' + dblPedido + '/archivos-s3/' + archivo.archivo
                                                        const { data } = await axios.get(apiUrl)
                                                        window.open(data, "Popup", "location,status,scrollbars,resizable,width=800, height=800");
                                                    }
                                                    catch (error) {
                                                        console.log('ERROR | onClickOTROS ', error)
                                                        if (error.response) {
                                                            switch (error.response.status) {

                                                                case 404:
                                                                    enqueueSnackbar(intl.formatMessage({ id: "_ARCHIVO_NO_ENCONTRADO" }, {
                                                                        archivo: archivo.archivo
                                                                    }), {
                                                                        variant: 'error',
                                                                        persist: false,
                                                                        onClick: () => closeSnackbar()
                                                                    })
                                                                    break;
                                                                default:
                                                                    enqueueSnackbar(intl.formatMessage({ id: "_ARCHIVO_ERROR" }, {
                                                                        archivo: archivo.archivo
                                                                    }), {
                                                                        variant: 'error',
                                                                        persist: false,
                                                                        onClick: () => closeSnackbar()
                                                                    })
                                                                    break;
                                                            }
                                                        }
                                                    }
                                                    finally {
                                                        let copiaListaBit = [...copiaListaBit];
                                                        copiaListaBit[indx] = false;
                                                        setListaBitCargandoOtros(copiaListaBit);
                                                    }

                                                }} />
                                        ))}
                                </Grid>
                                : <></>}

                        </> : <Typography variant="subtitle2">No cuenta con documentos</Typography>
                    }
                </Grid>
                break;
            default:
                break;
        }
        return selectedTab;
    }

    return <>
        <ModalFactura open={facturaSeleccionada.open} dblFactura={facturaSeleccionada.dblFactura} onClose={() => { setFacturaSeleccionada({ open: false, dblFactura: 0 }); }}  ></ModalFactura>
        <FWDModal open={bitOpen}
            Titulo={() => <Grid style={{ width: '100%' }}>
                {!cargandoDetalleServicio
                    ? <Grid item container justify="space-between">
                        <section style={{ display: 'flex' }}>
                            <Tooltip title="Número de servicio">
                                <Typography variant="h5">Servicio {detalleServicio?.descripcion?.strNumeroServicio}</Typography>
                            </Tooltip>
                            {detalleServicio?.descripcion?.strNumeroReferencia
                                ?
                                <Tooltip title="Número de referencia">
                                    <Typography variant="h5">&nbsp;/&nbsp;{detalleServicio?.descripcion?.strNumeroReferencia}</Typography>
                                </Tooltip>
                                :
                                <></>
                            }
                            {detalleServicio?.descripcion?.strNumeroEconomico
                                ?
                                <Tooltip title="Número económico">
                                    <Typography variant="h5">&nbsp;&nbsp;/&nbsp;&nbsp;No. econ&oacute;mico&nbsp;{detalleServicio?.descripcion?.strNumeroEconomico}</Typography>
                                </Tooltip>
                                : <></>}

                        </section>
                        <section style={{ display: 'flex' }}>
                            <Typography variant="h5">{detalleServicio?.descripcion?.strEstatusPedido}</Typography>
                        </section>
                    </Grid>
                    : <Skeleton animation="wave" variant="rect" width="100%" height="19px" />
                }
            </Grid>}
            fnOnRendered={fetchDetalleServicio}
            fnCloseModal={() => {
                fnClose();
                setExpanded('panel-detalle');
            }}
        >
            {/* Seccion izquierda (descripcion de servicio) */}
            <Hidden smDown>
                <Grid container direction="column" item sm={false} md={6}>

                    {!cargandoDetalleServicio
                        ? <DetalleServicio height="calc(80vh - 62px)" />
                        : <Grid item container direction="column" style={{ height: 'calc(80vh - 108px)', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress />
                        </Grid>

                    }

                </Grid>
            </Hidden>

            {/* Seccion derecha (tabs) */}
            <Grid container item sm={12} md={6} alignContent="flex-start" style={{ marginLeft: '5px' }}>

                <Grid container direction="column">

                    {!cargandoDetalleServicio
                        ?
                        <>
                            <Hidden mdUp>
                                <Tabs
                                    value={tabValue}
                                    onChange={(_, val) => setTabValue(val)}
                                    variant="fullWidth"
                                    aria-label="tabs mapa archivos"
                                >
                                    <Tab style={{ minWidth: 50 }} icon={<PaymentIcon style={{ color: UIColors.secondary }} />} aria-label="details" />
                                    <Tab style={{ minWidth: 50 }} icon={<MapIcon style={{ color: UIColors.secondary }} />} aria-label="map" />
                                    <Tab style={{ minWidth: 50 }} icon={<RoomIcon style={{ color: UIColors.secondary }} />} aria-label="travel" />
                                    <Tab style={{ minWidth: 50 }} icon={<AttachFileIcon style={{ color: UIColors.secondary }} />} aria-label="files" />
                                </Tabs>
                            </Hidden>
                            <Hidden smDown>
                                <Tabs
                                    value={tabValue}
                                    onChange={(_, val) => setTabValue(val)}
                                    variant="fullWidth"
                                    aria-label="tabs mapa archivos"
                                >
                                    <Tab style={{ minWidth: 50 }} icon={<MapIcon style={{ color: UIColors.secondary }} />} aria-label="map" />
                                    <Tab style={{ minWidth: 50 }} icon={<RoomIcon style={{ color: UIColors.secondary }} />} aria-label="travel" />
                                    <Tab style={{ minWidth: 50 }} icon={<AttachFileIcon style={{ color: UIColors.secondary }} />} aria-label="files" />
                                </Tabs>
                            </Hidden>
                        </>
                        : <Skeleton animation="wave" variant="rect" width="calc(100% - 5px)" height="48px" />
                    }


                    <Grid item style={{ flexGrow: '1' }}>

                        {cargandoDetalleServicio
                            ? <Grid item style={{ position: 'relative', width: '100%', height: 'calc(80vh - 108px)' }}>
                                <Skeleton animation="wave" variant="rect" width="calc(100% - 5px)" height="100%" />
                            </Grid>
                            : <>
                                <Hidden mdUp>
                                    <RenderTabs offset={0} />
                                </Hidden>
                                <Hidden smDown>
                                    <RenderTabs offset={1} />
                                </Hidden>
                            </>
                        }
                    </Grid>

                </Grid>
            </Grid>
        </FWDModal>
    </>
}

export default memo(injectIntl(FWDModalServicio), (prev, next) => prev.dblPedido === next.dblPedido);
