import React, {useState} from 'react'
import { injectIntl } from 'react-intl'
import { Autocomplete } from '@material-ui/lab'
import { debounce } from 'lodash'
import { 
  TextField, 
  Typography, 
  LinearProgress
} from '@material-ui/core'
import axios from 'axios'

const AutocompleteSolicitudes = ({ 
  intl, 
  lblAutocomplete = 'ORIGEN',
  valueForm = '',
  setValueForm = null,
  requiredForm = false,
  setRequiredForm = null,
  setIdForm = null,
  getDirecciones = null,
  setFormSolicitud = null,
  formSolicitud = [],
  setErrorStep = null,
  setMensajeError = null,
  cargandoPagina = false
  }) => {
  // const lstResponse = [];
  const [cargandoAutocomplete, setCargandoAutocomplete] = useState(false);
  const [contentResponse, setContentResponse] = useState([]);

  const fetchAutocompleteAPI = async (busqueda) => {
    setCargandoAutocomplete(true);
    busqueda = busqueda.trim();
    
    let api_url = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/servicios/solicitudes/api/autocompletecatalogos';
    api_url += '?strCatalogo='+lblAutocomplete?.toLowerCase()?.trim()+'&strBusqueda=' + busqueda || '';
    try {
      const response = await axios.get(api_url);
      const lstResponse = response.data.catalogo;
      setContentResponse(lstResponse);
    }
    catch (error) {
      console.log('error', error);
    }
    finally {
      setCargandoAutocomplete(false);
    }
  }

  const debounceFetchAutocompleteAPI = debounce(fetchAutocompleteAPI, 1000);

  return <>
    <Autocomplete
      freeSolo={true}
      autoHighlight={true}
      disabled={cargandoPagina}
      autoComplete={false}
      label={intl.formatMessage({ id: `AUTOCOMPLETE.TXT.${lblAutocomplete?.toUpperCase()}` })}
      loading={cargandoAutocomplete}
      clearOnEscape={true}
      disableClearable={true}
      inputValue={valueForm}
      options={contentResponse}
      getOptionLabel={(catalogo) => catalogo.TITLE}
      noOptionsText={intl.formatMessage({ id: 'AUTOCOMPLETE.TXT.SIN_OPCIONES' })}
      loadingText={
        <section style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="overline">
                {intl.formatMessage({ id: '_CARGANDO' })}
            </Typography>
          <LinearProgress />
        </section>
      }
      renderInput={(params) => <TextField error={requiredForm} {...params}
          label={intl.formatMessage({ id: `AUTOCOMPLETE.TXT.${lblAutocomplete?.toUpperCase()}` })}
          onFocus={event => event.target.autocomplete = 'new-password'} // Hack para evitar autofill de Chrome
          onClick={event => {
            event.target.autocomplete = 'new-password';
            event.preventDefault();
            event.stopPropagation();
          }}
          value={valueForm}
          onChange={(event) => {
            event.preventDefault();
            event.stopPropagation();
            debounceFetchAutocompleteAPI(event.target.value);
            setValueForm(event.target.value);
            if((event.target.value).trim() == ''){
              setRequiredForm(true);
              setErrorStep(-1);
              setMensajeError('');
            }
          }}
        />
      }
      onChange={
        (event, value) => {
          // console.log('Selecciono', event, value);
          if(value){
            let valorAutocomplete = null;
            let solicitudTemp = {...formSolicitud};
            switch (lblAutocomplete?.toLowerCase()) {
              case 'origen':
                valorAutocomplete = value.ID;
                solicitudTemp.varOrigen = value.ID;
              break;
              case 'destino':
                valorAutocomplete = value.ID;
                solicitudTemp.varDestino = value.ID;
              break;
              case 'vehiculo':
                valorAutocomplete = value.ID;  
                solicitudTemp.varVehiculo = value.ID;
              break;
              default:
                valorAutocomplete = '';
            }
            setIdForm(valorAutocomplete);
            setValueForm(value.TITLE);
            // setDisableForm(true);
            setContentResponse([]);
            setRequiredForm(false);
            // console.log(solicitudTemp);
            setFormSolicitud(solicitudTemp);
            setErrorStep(-1);
            setMensajeError('');
            if(lblAutocomplete?.toLowerCase() == 'origen' || lblAutocomplete?.toLowerCase() == 'destino'){
              getDirecciones(lblAutocomplete?.toLowerCase(), valorAutocomplete, true);
            }
          }
        }
      }
    />
  </>
}

export default injectIntl(AutocompleteSolicitudes);