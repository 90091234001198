import clasificarEventoRuta from 'app/componentes/servicios/funcionesMapa/ClasificarEventoRuta';

const RUTA_ORIGEN = 1;
const RUTA_DESTINO = 2;
const RUTA_DESPUES_DESTINO = 3;

export default function calcularEventosRuta(servicio) {

    //Separamos los eventos que son aptos para pintarlos en el mapa
    //Y los guardamos en arrayEventosRuta
    servicio.arrayUnidades.forEach(function (unidad) {
        unidad.arrayEventosRuta = [];

        (unidad.arrayEventos).forEach(function (evento) {
            clasificarEventoRuta(servicio, unidad, evento);
        });
    });

    clasificarRutaPorSeccion(servicio);
    return servicio;
}

function clasificarRutaPorSeccion(servicio) {
    (servicio.arrayUnidades).forEach((unidad) => {
        unidad.arraySecciones = [];
        var ultimoIndex = 0;
        //Generamos una ruta con los eventos que se recibieron antes de llegar al origen
        if (unidad.eventosMasCercanos !== undefined && unidad.eventosMasCercanos.origen !== undefined) {
            if (unidad.eventosMasCercanos.origen.distancia < .700) {
                let index = unidad.eventosMasCercanos.origen.index;
                if ((unidad.arrayEventosRuta.length - 1) > index)
                    index++;

                let seccion = {
                    intSeccion: RUTA_ORIGEN,
                    strSeccion: "En ruta a origen",
                    arrayEventos: unidad.arrayEventosRuta.slice(ultimoIndex, index),
                    strNumeroPlaca: unidad.strNumeroPlaca,
                    dblPedido: servicio.dblPedido,
                    strColor: servicio.strColor
                };
                //A�adimos la seccion en ruta al origen
                unidad.arraySecciones.push(seccion);
                ultimoIndex = unidad.eventosMasCercanos.origen.index;
            }
        }

        //Generamos una ruta con los eventos que se recibieron entre origen y destino
        if (unidad.eventosMasCercanos !== undefined && unidad.eventosMasCercanos.destino !== undefined) {

            if (ultimoIndex < unidad.eventosMasCercanos.destino.index && unidad.eventosMasCercanos.destino.distancia < .700) {
                let index = unidad.eventosMasCercanos.destino.index;
                if ((unidad.arrayEventosRuta.length - 1) >= index)
                    index++;

                let seccion = {
                    intSeccion: RUTA_DESTINO,
                    strSeccion: "En ruta a destino",
                    arrayEventos: unidad.arrayEventosRuta.slice(ultimoIndex, index),
                    strNumeroPlaca: unidad.strNumeroPlaca,
                    dblPedido: servicio.dblPedido,
                    strColor: servicio.strColor
                };
                //A�adimos la seccion de origen a destino
                unidad.arraySecciones.push(seccion);
                ultimoIndex = unidad.eventosMasCercanos.destino.index;
                //A�adimos la seccion de todos los eventos que se recibieron despues de llegar al destino
                seccion = {
                    intSeccion: RUTA_DESPUES_DESTINO,
                    strSeccion: "Fuera de ruta",
                    arrayEventos: unidad.arrayEventosRuta.slice(ultimoIndex, unidad.arrayEventosRuta.length),
                    strNumeroPlaca: unidad.strNumeroPlaca,
                    dblPedido: servicio.dblPedido,
                    strColor: servicio.strColor
                };
                unidad.arraySecciones.push(seccion);
            } else {
                // A�adimos todos los eventos despues del origen
                let seccion = {
                    intSeccion: RUTA_DESTINO,
                    strSeccion: "En ruta a destino",
                    arrayEventos: unidad.arrayEventosRuta.slice(ultimoIndex, unidad.arrayEventosRuta.length),
                    strNumeroPlaca: unidad.strNumeroPlaca,
                    dblPedido: servicio.dblPedido,
                    strColor: servicio.strColor
                };
                unidad.arraySecciones.push(seccion);
            }
        }

        if (unidad.arraySecciones.length === 0) {
            let seccion = {
                intSeccion: RUTA_DESTINO,
                strSeccion: "En ruta a destino",
                arrayEventos: unidad.arrayEventosRuta.slice(ultimoIndex, unidad.arrayEventosRuta.length),
                strNumeroPlaca: unidad.strNumeroPlaca,
                dblPedido: servicio.dblPedido,
                strColor: servicio.strColor
            };
            unidad.arraySecciones.push(seccion);
        }
    });
}