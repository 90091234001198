/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { useSelector } from 'react-redux'
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import * as Icons from '@material-ui/icons/'

export function AsideMenuList({ layoutProps }) {

  const { session } = useSelector(state => state.auth);

  // const rootApp = document.getElementById('root');
  // const splashScreen = document.getElementById('splash-screen');

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  const [fwdRoutes, setFwdRoutes] = useState([]);
  useEffect(() => {

    const fetchFwdRoutes = async () => {
      try {
        setFwdRoutes(session.userNavigation);
      }
      catch (error) {
        console.error('session error: ', error)
      }
      finally {
        // rootApp.classList.add('hidden');
        // splashScreen.classList.remove('hidden');
      }

    }

    fetchFwdRoutes();
  }, []);

  const IconoPortal = (props) => {
    const {strIconoPortal} = props;
    // console.log('>>',strIconoPortal);
    return React.createElement(Icons[strIconoPortal])
  }

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/* begin::section */}
        {/* <li className="menu-section ">
          <h4 className="menu-text">Components</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li> */}
        {/* end:: section */}
        {fwdRoutes.map((modulo, index) => {

          return (
            /*begin::1 Level*/
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                modulo.strPathMenu, true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
              key={modulo.intModulo}
            >
              <NavLink className="menu-link menu-toggle" to={modulo.strPathMenu}>

                <span className="svg-icon menu-icon">
                  {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} /> */}
                  <IconoPortal strIconoPortal={modulo.strIconoPortal} key={index}/>
                </span>

                <span className="menu-text">{modulo.strModulo}</span>
                {modulo.opciones.length ? <i className="menu-arrow" /> : <></>}
              </NavLink>

              {modulo.opciones.length ?
                <div className="menu-submenu ">
                  <i className="menu-arrow" />
                  <ul className="menu-subnav">
                    <li className="menu-item  menu-item-parent" aria-haspopup="true">
                      <span className="menu-link">
                        <span className="menu-text">{modulo.strModulo}</span>
                      </span>
                    </li>

                    {/* Inputs */}
                    {/*begin::2 Level*/}
                    {modulo.opciones.map(opcion_lvl1 => {
                      return (
                        <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            opcion_lvl1.strUrl, true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                          key={opcion_lvl1.intOpcionMenu}
                        >
                          <NavLink className="menu-link menu-toggle" to={opcion_lvl1.strUrl}>
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">{opcion_lvl1.strOpcionMenuPortal}</span>
                            {opcion_lvl1.opciones.length ? <i className="menu-arrow" /> : <></>}
                          </NavLink>
                          {opcion_lvl1.opciones.length ?
                            <div className="menu-submenu ">
                              <i className="menu-arrow" />
                              <ul className="menu-subnav">
                                {/*begin::3 Level*/}
                                {opcion_lvl1.opciones.map(opcion_lvl2 => {
                                  return (
                                    <li
                                      className={`menu-item  ${getMenuItemActive(
                                        opcion_lvl2.strUrl
                                      )}`}
                                      aria-haspopup="true"
                                      key={opcion_lvl2.intOpcionMenu}
                                    >
                                      <NavLink className="menu-link"
                                        to={opcion_lvl2.strUrl}>
                                        <i className="menu-bullet menu-bullet-dot">
                                          <span />
                                        </i>
                                        <span className="menu-text">{opcion_lvl2.strOpcionMenuPortal}</span>
                                      </NavLink>
                                    </li>
                                  )
                                })}
                                {/*end::3 Level*/}
                              </ul>
                            </div> : <></>}
                        </li>
                      )
                    })}
                    {/*end::2 Level*/}
                  </ul>
                </div>
                : <></>}
            </li>
          )
          /*end::1 Level*/
        })}
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
