import React, { useState, useContext, useEffect } from "react";
import { useSnackbar } from "notistack";
import { injectIntl, FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { Avatar, Typography, Paper, Divider, Fade } from "@material-ui/core";
import RoomIcon from "@material-ui/icons/Room";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { markerColors } from "app/helpers/UIHelper";
import CrearDireccionCliente from "app/componentes/servicios/CrearDireccionCliente";
import { FWDDialogo } from "app/componentes/FWDDialogo";
import axios from "axios";
import { DireccionesClienteContext } from "app/contextos/servicios";

const _markerColors = markerColors();

function DireccionCliente({
  intl,
  direccion,
  mostrarNombreFiscal = false,
  onClick = () => { },
  // direccionSeleccionada = null,
}) {
  const [elevation, setElevation] = useState(3);
  const [editIconStyle, setEditIconStyle] = useState({
    background: "inherit",
    color: _markerColors.blue,
  });
  const [deleteIconStyle, setDeleteIconStyle] = useState({
    background: "inherit",
    color: _markerColors.red,
  });

  const [bitDialogo, setBitDialogo] = useState(false);
  const [propsDialogo, setPropsDialogo] = useState({});
  const [bitEliminandoDireccion, setBitEliminandoDireccion] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { data, dispatchData } = useContext(DireccionesClienteContext);

  const history = useHistory();
  const {
    dblClienteDireccionesEntrega,
    varCliente,
    strNombreComercial,
    strNombreFiscal,
    strCalle,
    strNumero,
    strColoniaEntrega,
    strCPEntrega,
    strCiudad,
    strLugarEntrega,
    dblLatitudDireccion,
    dblLongitudDireccion,
    datFechaEliminacion,
  } = direccion;

  if (!direccion) return <> </>;

  const callbackFnOk_EliminarDireccion = async () => {
    try {
      setBitEliminandoDireccion(true)
      let apiUrl =
        process.env.REACT_APP_AWS_API_BASE_URL +
        "/portal/servicios/direcciones/api/eliminardireccion";
      apiUrl += `?varCliente=${varCliente}&dblClienteDireccionesEntrega=${dblClienteDireccionesEntrega}`;

      await axios.delete(apiUrl);
      setBitDialogo(false);

      const index = data.direcciones.findIndex(
        (x) =>
          x.dblClienteDireccionesEntrega ===
          dblClienteDireccionesEntrega
      );

      let direcciones = data.direcciones;
      direcciones.splice(index, 1);
      if (data) {
        dispatchData({ type: "direcciones.set", payload: direcciones });
        dispatchData({ type: "direccionesFiltradas.reset" });
        dispatchData({ type: 'timestampActualizado.set' })
      }

      if ("scrollRestoration" in history)
        history.scrollRestoration = "manual";
      window.scrollTo(0, 0);

      enqueueSnackbar(intl.FormattedMessage({ id: 'SERVICIOS.DIRECCION.MSG.DIRECCION_ELIMINADA' }),
        {
          preventDuplicate: true,
          autoHideDuration: 1500,
          variant: "success",
        });
    }
    catch (error) {
      setPropsDialogo({
        ...propsDialogo,
        txtMensaje:
          "Ha sucedido un error, intentar nuevamente?",
      });
    }
    finally {
      setBitEliminandoDireccion(false)
    }
  }

  return (
    <>
      <FWDDialogo
        visible={bitDialogo}
        cargando={bitEliminandoDireccion}
        fnNoOk={() => setBitDialogo(false)}
        {...propsDialogo}
      />
      <Fade in>
        <Paper
          elevation={elevation}
          className="cursor-hover"
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px",
            marginBottom: "5px",
            border: data.direccionSeleccionada === dblClienteDireccionesEntrega
              || data.direccionEditable === dblClienteDireccionesEntrega
              ? `3px solid ${_markerColors.blue}`
              : "",
          }}
          onClick={onClick}
          onMouseOver={() => setElevation(7)}
          onMouseOut={() => setElevation(3)}
        >
          {!(data.direccionEditable === dblClienteDireccionesEntrega) ? (
            <>
              <section
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "5px",
                }}
              >
                <section
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                    }}
                  >

                    {strLugarEntrega}
                  </Typography>
                  {mostrarNombreFiscal ? (
                    <Typography
                      style={{
                        fontStyle: "italic",
                      }}
                    >

                      {strNombreFiscal}
                    </Typography>
                  ) : (
                      <> </>
                    )}
                </section>
                <Divider
                  style={{
                    width: "100%",
                    margin: "5px auto",
                  }}
                />
              </section>
              <section
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <section
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <RoomIcon htmlColor={_markerColors.blue} />
                  <section
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingLeft: "10px",
                    }}
                  >
                    {strCalle || strNumero ? (
                      <Typography variant="body1">

                        {strCalle ? `Calle ${strCalle}` : ""}
                        {strNumero ? `#${strNumero}` : ""}
                      </Typography>
                    ) : (
                        <> </>
                      )}
                    {strColoniaEntrega || strCPEntrega ? (
                      <Typography variant="body1">

                        {strColoniaEntrega
                          ? `Colonia ${strColoniaEntrega}, `
                          : ""}
                        {strCPEntrega ? `CP. ${strCPEntrega}` : ""}
                      </Typography>
                    ) : (
                        <> </>
                      )}
                    {strCiudad ? (
                      <Typography variant="body2">
                        {strCiudad}
                      </Typography>
                    ) : (
                        <> </>
                      )}
                  </section>
                </section>
                <section
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Avatar
                    style={{
                      marginRight: "5px",
                      ...editIconStyle,
                    }}
                    onMouseOver={() =>
                      setEditIconStyle({
                        background: _markerColors.blue,
                        color: "#ffffff",
                      })
                    }
                    onMouseOut={() =>
                      setEditIconStyle({
                        background: "inherit",
                        color: _markerColors.blue,
                      })
                    }
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      dispatchData({ type: 'coordenadas.set', payload: { dblLatitudDireccion, dblLongitudDireccion } })
                      dispatchData({ type: 'direccionSeleccionada.set', payload: dblClienteDireccionesEntrega })
                      dispatchData({ type: 'direccionEditable.set', payload: dblClienteDireccionesEntrega })
                      setEditIconStyle({ background: "inherit", color: _markerColors.blue })
                      dispatchData({ type: 'timestampClickActualizarEliminarDireccion.set' })
                      dispatchData({ type: 'markerEditable.true' })
                    }}
                  >
                    <EditIcon style={editIconStyle} />
                  </Avatar>
                  <Avatar
                    style={deleteIconStyle}
                    onMouseOver={() =>
                      setDeleteIconStyle({
                        background: _markerColors.red,
                        color: "#ffffff",
                      })
                    }
                    onMouseOut={() =>
                      setDeleteIconStyle({
                        background: "inherit",
                        color: _markerColors.red,
                      })
                    }
                    onClick={() => {
                      //  https://92v9zvrmx3.execute-api.us-east-1.amazonaws.com/dev/portal/servicios/direcciones/api/eliminardireccion
                      setPropsDialogo({
                        txtTitulo: intl.formatMessage({ id: 'SERVICIOS.DIRECCION.TXT.CONFIRMAR_ELIMINAR_DIRECCION' }),
                        txtMensaje: intl.formatMessage({ id: 'SERVICIOS.DIRECCION.MSG.CONFIRMAR_ELIMINAR_DIRECCION' }),
                        fnOk: callbackFnOk_EliminarDireccion
                      });
                      setBitDialogo(true);
                      dispatchData({ type: 'timestampClickActualizarEliminarDireccion.set' })
                    }}
                  >
                    <DeleteIcon style={deleteIconStyle} />
                  </Avatar>
                </section>
              </section>
            </>
          ) : (
              <CrearDireccionCliente
                fnCancelar={() => {
                  dispatchData({ type: 'direccionEditable.set', payload: null })
                  dispatchData({ type: 'direccionSeleccionada.set', payload: null })
                }}
                varCliente={varCliente}
                bitEditar={data.direccionEditable === dblClienteDireccionesEntrega}
                direccion={direccion}
              />
            )}
        </Paper>
      </Fade>
    </>
  );
}

export default injectIntl(DireccionCliente);
