import React, {useState, useEffect, Fragment} from 'react';
import { useSelector } from "react-redux"
import { createSelector } from 'reselect'
import { withSnackbar } from 'notistack';
import {
    Paper, Grid, makeStyles, withStyles,Tooltip,Button,
    Table, TableContainer, TableHead, TableBody, TableRow, TableCell,
    Tabs, Tab, Typography, Box, CircularProgress, LinearProgress
} from '@material-ui/core';

import logo  from '../../../imagenes/logo.png';

const selectNumOfDoneTodos = createSelector(
    state => state.todos,
    todos => todos.filter(todo => todo.isDone).length
)
const estilosNotificaciones = makeStyles((theme) => ({
    success: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: '#fff!important',
        backgroundColor: '#43a047!important',
        minHeight:'60px', 
        display: 'flex', 
        borderRadius: '4px',
        overflow: 'hidden',
    },
    danger: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: '#fff!important',
        backgroundColor: '#d32f2f!important',
        minHeight:'60px', 
        display: 'flex', 
        borderRadius: '4px',
        overflow: 'hidden',
    },
    info: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: '#fff!important',
        backgroundColor: '#2196f3!important',
        minHeight:'60px', 
        display: 'flex', 
        borderRadius: '4px',
        overflow: 'hidden',
    },
    warning: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: '#fff!important',
        backgroundColor: '#ff9800!important',
        minHeight:'60px', 
        display: 'flex', 
        borderRadius: '4px',
        overflow: 'hidden',
    },
    otro: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: '#fff!important',
        minHeight:'60px', 
        display: 'flex', 
        borderRadius: '4px',
        overflow: 'hidden',
    }

}));

const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
    notificacion: {
        
    },
}));

function WebSocketPortalCliente(props) {
    
    const urlWebSocket = 'wss://v28u68x6m0.execute-api.us-east-1.amazonaws.com/produccion';
    const { session, selectedClienteComercial } = useSelector(state => state.auth);
    const txtUsuario = session.username;
    const arrayCliente = session ? session.listaVarClientes.map((cliente) => {return `varCliente=${cliente.strVarClienteOrigen}`;}) : [];
    const [txtVista, set_txtVista] = useState(null);
    // const [callback_handleOnMessage, set_callback_handleOnMessage] = useState(null);
    // const [dataToSend, set_dataToSend] = useState(null);
    const [connection, set_connection] = useState(null);
    const [arrayNotificaciones, set_arrayNotificaciones] = useState(null);

    useEffect(() => {
        set_txtVista(props.txtVista);
        if(txtVista === props.txtVista) {
            conectarWebSocket();
        }
    }, [props.txtVista, txtVista, selectedClienteComercial]);

    var conectarWebSocket = async () => {

        cerrarWebSocket()
        .then(() => {
            return new Promise ((resolve, reject) => {
                try {
                    console.log("[conectarWebSocket]", txtVista);


                    if(arrayCliente.length < 1) {
                        reject({mensaje: "Usuario sin franquicias asignadas"});
                    } else {
                        let _connection = new WebSocket(urlWebSocket + "?strUsuario=" + txtUsuario + "&strVista=" + txtVista + "&" + "varCliente=" + selectedClienteComercial)
                        _connection.onopen = handleOnConnect;
                        _connection.onclose = handleOnDisconnect;
                        _connection.onmessage = handleOnMessage;
                        _connection.onerror = handleOnError;
                        set_connection(_connection);
                        resolve(true);
                    }
                } catch (error) {
                    reject({error: error, mensaje: "Error al iniciar websocket"});
                }
            });
            
        })
        .catch((error) => {
            console.log("[conectarWebSocket][catch]", error);
        });
    }

    var handleOnConnect = (e) => {
        console.log("[handleOnConnect]", txtVista);
    }

    var handleOnMessage = (message) => {
        console.log("[handleOnMessage]", txtVista);
        /* estructura del mensaje a recibir
        {
            action: '',
            data: {}
        }*/
        let dataJSON = message.data;
        let data = JSON.parse(dataJSON);

        switch(data.action) {
            case 'notificacion' :
                mostrarNotificacion(data.data);
            break;

            default: 

            break;
        }
    }

    var handleOnDisconnect = (event) => {
        console.log("[handleOnDisconnect]", txtVista);

        if(!connection || connection?.readyState === WebSocket.CLOSED || event?.wasClean) {
            conectarWebSocket();
        }
    }

    var handleOnError = (error) => {
        console.log("[handleOnError]", txtVista);
    }

    var cerrarWebSocket = () => {
        return new Promise ((resolve, reject) => {
            try {
                if(connection) {
                    if(connection.readyState !== WebSocket.CLOSED) {
                        console.log("[cerrarWebSocket]");
                        connection.onclose = (e) => {
                            resolve(true);
                        };
                        connection.close();
                    }
                } else {
                    resolve(true);
                }
            } catch (error) {
                reject(false);
            }
        });
    }
    const notificacion_estilo = estilosNotificaciones();

    var mostrarNotificacion = (data) => {
        console.log("[mostrarNotificacion]", data);

        const {titulo, cuerpo, estilo, icono} = data;
        let estiloNotificacion = notificacion_estilo.otro;
        let colorPersonalizado =  {backgroundColor: (data?.color != undefined ? data.color : "#2196f3")};
        const contenidoNotificacion = (key, message) => 
        (<Grid container style={{width: '450px'}}>
            <Grid item xs={12}>
                <Paper className={estiloNotificacion} style={colorPersonalizado}>
                    <Grid container style={{minHeight: '100%'}}>
                        <Grid 
                            item xs={2} 
                            style={{
                                alignItems: 'center', 
                                verticalAlign: 'middle', 
                                width: '100%',
                                minHeight: 'calc(100% - 4px)', 
                                position: 'relative'
                            }}>
                            <img alt="logo" style={{ width: '75px', height: 'auto', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}} src={icono} />
                        </Grid>
                        <Grid item container direction='column' xs={10} style={{textAlign: 'left', paddingLeft: '10px'}}>
                            <Grid item>
                                <Typography variant="subtitle1">{titulo}
                                    <Button style={{float: 'right', color: '#FFF', padding: 0}} onClick={() => { props.closeSnackbar(key) }}>
                                        X
                                    </Button>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle2" dangerouslySetInnerHTML={{__html: cuerpo}}>
                                    
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>    
        </Grid>);

    
        props.enqueueSnackbar("", {
            autoHideDuration: 7000,
            disableWindowBlurListener: true,
            preventDuplicate: true,
            anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
            content: contenidoNotificacion,/*<Grid container style={{width: '450px'}}>
            <Grid item xs={12}>
                <Paper className={notificacion_estilo.info}>
                    <Grid container style={{minHeight: '100%'}}>
                        <Grid 
                            item xs={2} 
                            style={{
                                alignItems: 'center', 
                                verticalAlign: 'middle', 
                                width: '100%',
                                minHeight: 'calc(100% - 4px)', 
                                position: 'relative'
                            }}>
                            <img alt="logo" style={{ width: '75px', height: 'auto', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}} src={logo} />
                        </Grid>
                        <Grid item container direction='column' xs={10} style={{textAlign: 'left', paddingLeft: '10px'}}>
                            <Grid item>
                                <Typography variant="h6">Titulo</Typography>
                            </Grid>
                            <Grid item>
                                Cuerpo<br></br>
                                Cuerpo<br></br>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>    
        </Grid>,
        action*/
        });
    }
    
    return ( 
        <></>
    );
}
 
export default withSnackbar(WebSocketPortalCliente);