import React, { useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { TableHead, TableRow, TableCell } from '@material-ui/core'

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';

//? Modelo de cabecera
export function FWDCabecera(id, value, props = undefined, ordenamiento = true) {
    this.id = id
    this.value = value
    this.props = props
    this.ordenamiento = ordenamiento
}

const ESTADOS = {
    ASC: 'ASC',
    DESC: 'DESC',
    NULL: null
}

export const FWDOrderHelper = (cabeceras = []) => {
    if (!Array.isArray(cabeceras)) throw { codigo: 'CABECERA_IS_NOT_ARRAY', mensaje: 'El parámetro debe ser un array' }
    return cabeceras?.map(x => `${x.id} ${x.ordenamiento}`).join() || ''
}

//? Componente principal
function _FWDTableHead(props) {

    const { cabeceras, fnOnClick, fnSetOrdenamiento, disabled = false, reset } = props

    const [ordenamientos, setOrdenamientos] = useState(Array(cabeceras.length).fill(null))


    const setSiguienteEstado = (indx) => {
        const aux = ordenamientos
        const estadoActual = ordenamientos[indx];
        if (estadoActual === ESTADOS.NULL) {
            aux[indx] = ESTADOS.ASC
            setOrdenamientos([...aux])
        }
        else if (estadoActual === ESTADOS.ASC) {
            aux[indx] = ESTADOS.DESC
            setOrdenamientos([...aux])
        }
        else if (estadoActual === ESTADOS.DESC) {
            aux[indx] = ESTADOS.NULL
            setOrdenamientos([...aux])
        }

        //* Ejecución de función callback
        const parametrosOrdenamiento = cabeceras.map((x, k) => ({
            id: x.id,
            ordenamiento: aux[k]
        })).filter(x => x.ordenamiento !== ESTADOS.NULL)

        if (fnOnClick)
            fnOnClick(parametrosOrdenamiento)

        if (fnSetOrdenamiento)
            fnSetOrdenamiento(parametrosOrdenamiento)
    }

    const RenderEstado = ({ indx }) => {
        let el;
        if (ordenamientos[indx] === ESTADOS.NULL)
            el = cabeceras[indx].ordenamiento ? <UnfoldMoreIcon /> : ''
        else if (ordenamientos[indx] === ESTADOS.ASC)
            el = <KeyboardArrowUpIcon />
        else if (ordenamientos[indx] === ESTADOS.DESC)
            el = <KeyboardArrowDownIcon />
        else el = <></>
        return el
    }

    useEffect(() => {
        if (reset) {
            setOrdenamientos(Array(cabeceras.length).fill(null))
            if (fnSetOrdenamiento) fnSetOrdenamiento([])
        }
    }, [reset])

    return <TableHead>
        <TableRow>
            {cabeceras?.map((x, k) => <TableCell className={x?.ordenamiento && !disabled ? "cursor-hover" : ""} key={k} {...x?.props} onClick={() => {
                if (x?.ordenamiento && !disabled) setSiguienteEstado(k)
            }}>
                {x.value} <RenderEstado indx={k} />
            </TableCell>)}
        </TableRow>
    </TableHead>

}

const FWDTableHead = injectIntl(_FWDTableHead)
export { FWDTableHead }
