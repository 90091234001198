import React, { useState, useEffect } from 'react'
import {
    Grid,
    Card,
    CardContent,
    Modal,
    Backdrop,
    Fade,
    Paper,
    Typography,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import CloseIcon from '@material-ui/icons/Close';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import UnfoldMoreSharpIcon from '@material-ui/icons/UnfoldMoreSharp';
import ExpandLessSharpIcon from '@material-ui/icons/ExpandLessSharp';
import ExpandMoreSharpIcon from '@material-ui/icons/ExpandMoreSharp';

//Estilos
import useStyles from 'app/componentes/servicios/serviciosEstilos/serviciosEstilos';


function formatDateTime(date) {

    const originalDate = date;

    const months = [
        'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
        'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'
    ];

    const dateObject = new Date(originalDate);

    const day = dateObject.getUTCDate();
    const month = months[dateObject.getUTCMonth()];
    const year = dateObject.getUTCFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
}

const ModalDashboard = ({ open, onClose, title, items }) => {
    const classes = useStyles();
    const [orderBy, setOrderBy] = useState('fechaServicio');
    const [order, setOrder] = useState('asc');

    const handleRequestSort = (property) => {
        let newOrder = 'asc';

        if (property === orderBy && order === 'asc') {
            newOrder = 'desc';
        }

        setOrder(newOrder);
        setOrderBy(property);
    };

    const sortedItems = items.slice().sort((a, b) => {
        return order === 'asc' ? 1 : -1;
    });

    if (orderBy === 'folio') {
        sortedItems.sort((a, b) => {
            return order === 'asc' ? b.idPedido - a.idPedido : a.idPedido - b.idPedido;
        });
    }
    else if (orderBy === 'fechaServicio') {
        sortedItems.sort((a, b) => {
            return order === 'asc' ? new Date(b.fechaServicio) - new Date(a.fechaServicio) : new Date(a.fechaServicio) - new Date(b.fechaServicio);
        });
    }
    else {
        sortedItems.sort((a, b) => {
            const fieldA = a[orderBy].toUpperCase();
            const fieldB = b[orderBy].toUpperCase();
            return order === 'asc' ? fieldA.localeCompare(fieldB) : fieldB.localeCompare(fieldA);
        });
    };

    return (

        <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth="lg">
            <MuiDialogTitle style={{ backgroundColor: '#1c1c2b', color: 'white' }} id="customized-dialog-title" onClose={onClose}>
                <Typography >{title}</Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>
            <DialogContent dividers>
                <Paper>
                    <Grid container spacing={1}>
                        <Grid item md={12}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell onClick={() => handleRequestSort('folio')} align="right">
                                                FOLIO
                                                {orderBy === 'folio' ? (
                                                    order === 'asc' ? <ExpandLessSharpIcon /> : <ExpandMoreSharpIcon />
                                                ) : <UnfoldMoreSharpIcon />}
                                            </TableCell>
                                            <TableCell onClick={() => handleRequestSort('fechaServicio')} align="right">
                                                FECHA DE SERVICIO
                                                {orderBy === 'fechaServicio' ? (
                                                    order === 'asc' ? <ExpandLessSharpIcon /> : <ExpandMoreSharpIcon />
                                                ) : <UnfoldMoreSharpIcon />}
                                            </TableCell>
                                            <TableCell onClick={() => handleRequestSort('estatusPedido')} align="right">
                                                ESTATUS
                                                {orderBy === 'estatusPedido' ? (
                                                    order === 'asc' ? <ExpandLessSharpIcon /> : <ExpandMoreSharpIcon />
                                                ) : <UnfoldMoreSharpIcon />}
                                            </TableCell>
                                            <TableCell onClick={() => handleRequestSort('strOrigen')} align="right">
                                                ORIGEN
                                                {orderBy === 'strOrigen' ? (
                                                    order === 'asc' ? <ExpandLessSharpIcon /> : <ExpandMoreSharpIcon />
                                                ) : <UnfoldMoreSharpIcon />}
                                            </TableCell>
                                            <TableCell onClick={() => handleRequestSort('strDestino')} align="right">
                                                DESTINO
                                                {orderBy === 'strDestino' ? (
                                                    order === 'asc' ? <ExpandLessSharpIcon /> : <ExpandMoreSharpIcon />
                                                ) : <UnfoldMoreSharpIcon />}
                                            </TableCell>
                                            <TableCell onClick={() => handleRequestSort('tipoTransporte')} align="right">
                                                TIPO DE UNIDAD
                                                {orderBy === 'tipoTransporte' ? (
                                                    order === 'asc' ? <ExpandLessSharpIcon /> : <ExpandMoreSharpIcon />
                                                ) : <UnfoldMoreSharpIcon />}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sortedItems.map((row) => (
                                            <TableRow key={row.idPedido}>
                                                <TableCell style={{ fontWeight: 600 }} align="right">{row.folio}</TableCell>
                                                <TableCell align="right">{formatDateTime(row.fechaServicio)}</TableCell>
                                                <TableCell align="right">{row.estatusPedido}</TableCell>
                                                <TableCell style={{ width: 'auto' }} align="right">{row.strOrigen}</TableCell>
                                                <TableCell style={{ width: 'auto' }} align="right">{row.strDestino}</TableCell>
                                                <TableCell style={{ width: 'auto' }} align="right">{row.tipoTransporte}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Paper>
            </DialogContent>
        </Dialog>
    );
};

export default ModalDashboard;