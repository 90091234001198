import calcularDistancia from 'app/componentes/servicios/funcionesMapa/CalcularDistancia';

export default function clasificarEventoRuta(servicio, unidad, evento) {
    if (unidad.strNumeroPlaca !== evento.strNumeroPlaca) {
        console.log("[clasificarEvento] evento en ruta erronea", unidad, evento);
        return;
    }

    var ultimoEvento = unidad.arrayEventosRuta.length === 0 ? { dblLatitud: 0, dblLongitud: 0 } : unidad.arrayEventosRuta[unidad.arrayEventosRuta.length - 1];
    //Solo a�adimos eventos que tienen distancias de mas de 50 metros entre si y el ultimo evento de la ruta
    evento.distanciaEntrePuntos = calcularDistancia(ultimoEvento.dblLatitud, ultimoEvento.dblLongitud, evento.dblLatitud, evento.dblLongitud);

    if (evento.distanciaEntrePuntos > .050)
        unidad.arrayEventosRuta.push(evento);

    if (servicio.ubicacionOrigen != null || servicio.ubicacionDestino != null) {
        if (unidad.eventosMasCercanos === undefined)
            unidad.eventosMasCercanos = {
                origen: { distancia: null },
                destino: { distancia: null }
            };

        //Calculamos su distancia al origen
        if (servicio.ubicacionOrigen.lat !== "" && servicio.ubicacionOrigen.lng !== "") {
            evento.distanciaOrigen = calcularDistancia(servicio.ubicacionOrigen.lat, servicio.ubicacionOrigen.lng, evento.dblLatitud, evento.dblLongitud);

            if (evento.distanciaOrigen <= unidad.eventosMasCercanos.origen.distancia || unidad.eventosMasCercanos.origen.distancia == null) {
                unidad.eventosMasCercanos.origen = {
                    distancia: evento.distanciaOrigen,
                    evento: evento,
                    index: (unidad.arrayEventosRuta.length - 1)
                };
            }
        }

        //Calculamos su distancia al destino
        if (servicio.ubicacionDestino.lat !== "" && servicio.ubicacionDestino.lng !== "") {
            evento.distanciaDestino = calcularDistancia(servicio.ubicacionDestino.lat, servicio.ubicacionDestino.lng, evento.dblLatitud, evento.dblLongitud);

            if (evento.distanciaDestino <= unidad.eventosMasCercanos.destino.distancia || unidad.eventosMasCercanos.destino.distancia == null) {
                if (unidad.eventosMasCercanos.origen.index < (unidad.arrayEventosRuta.length - 1)) {
                    unidad.eventosMasCercanos.destino = {
                        distancia: evento.distanciaDestino,
                        evento: evento,
                        index: (unidad.arrayEventosRuta.length - 1)
                    };
                }
            }
        }
    }
}