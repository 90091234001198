import React, {Component} from 'react';
import Websocket from 'react-websocket';
 
class WebSocketSincronizadorFwdSistema extends Component {
 
    handleMessage = (data) => {
        let result = JSON.parse(data);
        if(this.props.hasOwnProperty('callBackMessage'))
            this.props.callBackMessage(result);
    }

    handleConnect = (e) => {
        console.log("WebSocket conectado", this.props.txtVista);
    }

    handleDisconnect = (e) => {
        console.log("WebSocket desconectado", this.props.txtVista);
    }
 
    render() {
        return ( 
            <div>
                { this.props.txtUsuario !== undefined && this.props.txtVista !== undefined
                    ?   <Websocket url={`wss://x1by8f7jcf.execute-api.us-east-2.amazonaws.com/prueba?txtUsuario=${this.props.txtUsuario}&txtVista=${this.props.txtVista}`}
                            onMessage={this.handleMessage}
                            onOpen={this.handleConnect}
                            onClose={this.handleDisconnect}  
                            reconnect={true}
                            debug={false}/>
                    : <></>
                }
            </div>
        );
    }
}
 
export default WebSocketSincronizadorFwdSistema;