// Imports de ley
import axios from 'axios'
import { injectIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import React, { useEffect, useState, useReducer, useContext, useLayoutEffect } from 'react'
import PopoverCotizacionAceptacionInterna from 'app/componentes/Tarifario/PopoverCotizacionAceptacionInterna'
//Imports a necesidades
import { useSnackbar } from 'notistack';
import AutocompleteTarifario from 'app/componentes/Tarifario/AutocompleteTarifario'
import {
  Grid,
  Card,
  Typography,
  CardContent,
  Input,
  FormControl,
  InputLabel,
  Paper,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  LinearProgress,
  InputAdornment,
  IconButton,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Zoom
} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns';
import locale_es from "date-fns/locale/es";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { SH_formatCurrency, SH_FindInString, SH_FindInStringDate } from 'app/helpers/stringHelper'
import { markerColors } from 'app/helpers/UIHelper'
import { debounce } from 'lodash'
import Skeleton from '@material-ui/lab/Skeleton'

//Iconos
import RoomIcon from '@material-ui/icons/Room'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import SearchIcon from '@material-ui/icons/Search'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import EventIcon from '@material-ui/icons/Event';
import SettingsIcon from '@material-ui/icons/Settings'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import ViewModuleIcon from '@material-ui/icons/ViewModule'
import ViewListIcon from '@material-ui/icons/ViewList'

import { FWDCabecera, FWDTableHead, FWDOrderHelper } from 'app/componentes/FWDTableHead'
import ScrollContainer from 'react-indiana-drag-scroll'
import { LoaderDashboardTables } from 'app/componentes/dashboard/LoaderDashboard'

const _TarjetaFiltroTarifario = ({
  intl, cargandoPagina = false,
  debounceFnFiltrarTarifario = null,
  intFiltrado = 0,
  intTotalRegistros = 0,
  setCargandoPagina = null,
  filtrosAvanzados = [],
  setFiltrosAvanzados = null,
  contenTable = true,
  setContentTable = null
}) => {
  const today = new Date();
  const markColor = markerColors();
  const [disabledBusquedaSimple, setDisabledBusquedaSimple] = useState(false);
  const [disabledBusquedaAvanzada, setDisabledBusquedaAvanzada] = useState(true);
  const [varBusquedaSimple, setValueBusquedaSimple] = useState('');
  //Filtros avanzados
  const [varFiltroMoneda, setValueFiltroMoneda] = useState('');
  const [disabledMoneda, setDisabledMoneda] = useState(false);

  const [varFiltroOrigen, setValueFiltroOrigen] = useState('');
  const [disabledOrigen, setDisabledOrigen] = useState(false);

  const [varFiltroDestino, setValueFiltroDestino] = useState('');
  const [disabledDestino, setDisabledDestino] = useState(false);

  const [varFiltroVehiculo, setValueFiltroVehiculo] = useState('');
  const [disabledVehiculo, setDisabledVehiculo] = useState(false);

  const [datFechaFin, setDatFechaFin] = useState(new Date());

  // Funci�n para formatear la fecha
  function formatearFecha(fechaOriginal) {
    const fecha = new Date(fechaOriginal);
    const dia = fecha.getDate().toString().padStart(2, '0');
    const mes = fecha.toLocaleString('en-US', { month: 'short' });
    const anio = fecha.getFullYear();
    const fechaFormt = `${dia}/${mes}/${anio}`;

    return fechaFormt;
  }

  const handleChangeDate = (event) => {
    // Formatear la fecha
    const fechaFormateada = formatearFecha(event);
    setDatFechaFin(fechaFormateada);
    setCargandoPagina(true, setValueBusquedaSimple(fechaFormateada, debounceFnFiltrarTarifario(fechaFormateada)));
  };

  return <>
    <Grid item md={12}>
      <Paper style={{ marginBottom: '10px', padding: '10px' }} elevation={3}>
        <section style={{ display: 'flex', alignItems: 'center' }}>
          <SearchIcon style={{ marginRight: '10px', color: markColor.blue }} />
          <section style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'flex-end' }}>
            <Grid container spacing={1}>
              <Grid item md={12}>
                <Accordion elevation={0}
                  onChange={
                    (event, expanded) => {
                      if (expanded && varBusquedaSimple?.length > 0) {
                        setCargandoPagina(true);
                        setValueBusquedaSimple('');
                        debounceFnFiltrarTarifario('');
                      }
                      // setDisabledBusquedaSimple(expanded);
                      setDisabledBusquedaAvanzada(!expanded);
                    }
                  }
                >
                  <AccordionSummary expandIcon={<SettingsIcon />}>
                    <Grid item md={12}>
                      <FormControl style={{ width: '100%', paddingRight: '5px' }} onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}>
                        <InputLabel>{intl.formatMessage({ id: 'SERVICIOS.TARIFARIO.TXT.FILTRAR_TARIFARIO' })}</InputLabel>
                        <Input
                          type='text'
                          disabled={cargandoPagina}
                          disabled={disabledBusquedaSimple}
                          onChange={
                            (event) => {
                              setCargandoPagina(true, setValueBusquedaSimple(event.target.value, debounceFnFiltrarTarifario(event.target.value)));
                            }
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              {
                                varBusquedaSimple?.length > 0
                                  ? <IconButton
                                    aria-label="clear filtro sencillo search"
                                    onClick={() => {
                                      setCargandoPagina(true);
                                      setValueBusquedaSimple('');
                                      debounceFnFiltrarTarifario('');
                                    }}
                                  >
                                    <HighlightOffIcon />
                                  </IconButton>
                                  : <></>
                              }
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Grid>

                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item md={3}>
                      <AutocompleteTarifario
                        disabledBusquedaAvanzada={disabledBusquedaAvanzada}
                        lblAutocomplete="origen"
                        disabledFiltro={disabledOrigen}
                        setDisableFiltro={setDisabledOrigen}
                        valueFiltro={varFiltroOrigen}
                        setValueFiltro={setValueFiltroOrigen}
                        setFiltrosAvanzados={setFiltrosAvanzados}
                        filtrosAvanzados={filtrosAvanzados}
                      ></AutocompleteTarifario>
                    </Grid>
                    <Grid item md={3}>
                      <AutocompleteTarifario
                        disabledBusquedaAvanzada={disabledBusquedaAvanzada}
                        lblAutocomplete="destino"
                        disabledFiltro={disabledDestino}
                        setDisableFiltro={setDisabledDestino}
                        valueFiltro={varFiltroDestino}
                        setValueFiltro={setValueFiltroDestino}
                        setFiltrosAvanzados={setFiltrosAvanzados}
                        filtrosAvanzados={filtrosAvanzados}
                      ></AutocompleteTarifario>
                    </Grid>
                    <Grid item md={3}>
                      <AutocompleteTarifario
                        disabledBusquedaAvanzada={disabledBusquedaAvanzada}
                        lblAutocomplete="vehiculo"
                        disabledFiltro={disabledVehiculo}
                        setDisableFiltro={setDisabledVehiculo}
                        valueFiltro={varFiltroVehiculo}
                        setValueFiltro={setValueFiltroVehiculo}
                        setFiltrosAvanzados={setFiltrosAvanzados}
                        filtrosAvanzados={filtrosAvanzados}
                      ></AutocompleteTarifario>
                    </Grid>
                    <Grid item md={1}>
                      <FormControl style={{ width: '100%', paddingRight: '5px' }}>
                        <InputLabel>{intl.formatMessage({ id: 'SERVICIOS.TARIFARIO.TXT.MONEDA' })}</InputLabel>
                        <Select
                          disabled={cargandoPagina}
                          disabled={disabledBusquedaAvanzada}
                          disabled={disabledMoneda}
                          value={varFiltroMoneda}
                          onChange={
                            (event, child) => {
                              // console.log('Evento select >> ', event, child);
                              setFiltrosAvanzados([...filtrosAvanzados, {
                                label: child.props.children,
                                background: '',
                                color: '',
                                params: 'moneda',
                                varMoneda: event.target.value,
                                varOrigen: '',
                                varDestino: '',
                                varVehiculo: ''
                              }]);
                              setValueFiltroMoneda(event.target.value);
                              setDisabledMoneda(true);
                            }
                          }
                        >
                          <MenuItem value="">{intl.formatMessage({ id: 'SERVICIOS.TARIFARIO.TXT.OPTION_NADA' })}</MenuItem>
                          <MenuItem value={1}>MXN</MenuItem>
                          <MenuItem value={2}>USD</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale_es}>
                      <Grid item md={2}>
                        <KeyboardDatePicker
                          autoOk={true}
                          disableToolbar
                          variant="inline"
                          format="dd/MMM/yyyy"
                          style={{ width: '100%' }}
                          id="date-picker-fin"
                          label={intl.formatMessage({ id: "ADMINISTRACION.ESTADO_CUENTA.FILTROS.FECHA_FIN" })}
                          value={datFechaFin}
                          onChange={handleChangeDate}
                          KeyboardButtonProps={{
                            'aria-label': 'Cambiar fecha',
                          }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item md={12}>
                {
                  (!cargandoPagina)
                    ?
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <div style={{ marginRight: '10px' }}>
                        {
                          (!contenTable)
                            ?
                            <Tooltip TransitionComponent={Zoom} title={intl.formatMessage({ id: 'SERVICIOS.TARIFARIO.TXT.VISTA_CONTENT_TABLA' })} disableFocusListener disableTouchListener placement="bottom">
                              <ViewListIcon style={{ color: '#757575', cursor: 'pointer' }} onClick={() => {
                                setContentTable(!contenTable);
                              }} />
                            </Tooltip>
                            :

                            <Tooltip TransitionComponent={Zoom} title={intl.formatMessage({ id: 'SERVICIOS.TARIFARIO.TXT.VISTA_CONTENT_TARJETA' })} disableFocusListener disableTouchListener placement="bottom">
                              <ViewModuleIcon style={{ color: '#757575', cursor: 'pointer' }} onClick={() => {
                                setContentTable(!contenTable);
                              }} />
                            </Tooltip>
                        }
                      </div>
                      <div style={{ flexGrow: '1' }}>
                        {
                          filtrosAvanzados.map((avanzado, indexAvanzado) => (
                            <Chip
                              key={indexAvanzado}
                              // style={{background: avanzado.background, color: avanzado.color}}
                              style={{ marginRight: '10px' }}
                              size="small"
                              label={avanzado.label}
                              onDelete={
                                () => {
                                  let filtrosTemp = [...filtrosAvanzados];
                                  switch (avanzado.params) {
                                    case 'moneda':
                                      filtrosTemp.splice(indexAvanzado, 1);
                                      setDisabledMoneda(false);
                                      setValueFiltroMoneda('');
                                      break;
                                    case 'origen':
                                      filtrosTemp.splice(indexAvanzado, 1);
                                      setDisabledOrigen(false);
                                      setValueFiltroOrigen('');
                                      break;
                                    case 'destino':
                                      filtrosTemp.splice(indexAvanzado, 1);
                                      setDisabledDestino(false);
                                      setValueFiltroDestino('');
                                      break;
                                    case 'vehiculo':
                                      filtrosTemp.splice(indexAvanzado, 1);
                                      setDisabledVehiculo(false);
                                      setValueFiltroVehiculo('');
                                      break;
                                  }
                                  // console.log(filtrosTemp);
                                  setValueBusquedaSimple('');
                                  setFiltrosAvanzados(filtrosTemp);
                                }
                              }
                            />
                          ))
                        }
                      </div>
                      <div>
                        <Typography style={{ marginTop: '5px' }} variant="overline">{intFiltrado} / {intTotalRegistros}</Typography>
                      </div>
                    </div>
                    : <LinearProgress style={{ width: '98%' }} />
                }
              </Grid>
            </Grid>
          </section>
        </section>
      </Paper>
    </Grid>
  </>
}

const TarjetaFiltroTarifario = injectIntl(_TarjetaFiltroTarifario);

const Tarifario = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { intl } = props;
  const markColor = {
    ...markerColors(),
    truck: '#272932'
  };
  const [tarifas, setTarifas] = useState([]);
  const { session, selectedClienteComercial } = useSelector(state => state.auth);
  const [cargandoPagina, setCargandoPagina] = useState(true);
  const [contenTable, setContentTable] = useState(true);
  const [skeletonTable, setSkeletonTable] = useState(Array(1).fill(true));
  const [listaVarClientes, setListaVarClientes] = useState([]);
  const [tarifasFiltradas, setTarifasFiltradas] = useState([]);
  const [filtrosAvanzados, setFiltrosAvanzados] = useState([]);

  const [resetOrdenamiento, setResetOrdenamiento] = useState(null)

  /** 
   * Estructura json busqueda avanzada
     {
      label: 'MXN',
      background: '',
      color: '',
      params: 'moneda',
      varMoneda: '',
      varOrigen: '',
      varDestino: '',
      varVehiculo: ''
    }
   */

  // Funci�n para formatear la fecha

  function formatearFecha(fechaOriginal) {
    const fecha = new Date(fechaOriginal);
    const dia = fecha.getDate().toString().padStart(2, '0'); // Agrega cero si es un solo d�gito
    const mes = fecha.toLocaleString('es-Es', { month: 'short' });
    const anio = fecha.getFullYear();
    const fechaFormt = `${dia}/${mes}/${anio}`;

    return fechaFormt;
  }

  const fnFiltrarTarifario = (filtro) => {

    // console.clear();
    // console.log('debounce tarifa');
    // if (!filtro) return dispatchData({ type: 'direccionesFiltradas.reset' })
    const _tarifaFiltradas = tarifas?.filter(x =>
      SH_FindInString(x.ORIGEN, filtro) ||
      SH_FindInString(x.DESTINO, filtro) ||
      SH_FindInString(x.VEHICULO, filtro) ||
      SH_FindInString(x.FOLIO_COTIZACION, filtro) ||
      SH_FindInString((x.TARIFA)?.toString(), filtro) ||
      SH_FindInString(x.MONEDA, filtro) ||
      SH_FindInString((x.FECHA_TARIFA)?.toString(), filtro)
    )

    setTarifasFiltradas(_tarifaFiltradas);
    // setCargandoPagina(false);
  }

  const debounceFnFiltrarTarifario = debounce(fnFiltrarTarifario, 750);

  const get_tarifario = async (_listaVarClientes, ordenamiento = []) => {
    try {
      setCargandoPagina(true);
      let apiURL = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/servicios/tarifario/api/consultar?';
      _listaVarClientes.map((element) => {
        apiURL += 'varCliente=' + selectedClienteComercial;
      })

      const strOrdenamiento = FWDOrderHelper(ordenamiento)
      if (strOrdenamiento.length) apiURL += '&ordenamiento=' + strOrdenamiento

      const response = await axios.get(apiURL);

      const registros = (response.data.tarifario).length;
      setSkeletonTable(Array(registros).fill(true));
      setTarifasFiltradas(response.data.tarifario);
      setTarifas(response.data.tarifario);
      // setCargandoPagina(false);
    } catch (err) {
      console.log(err);
    } finally {
      // setCargandoPagina(false);
    }
  }

  const get_tarifarioFiltroAvanzado = async (_filtroAvanzado, ordenamiento = []) => {
    // if(!cargandoPagina){
    try {
      setCargandoPagina(true);
      let apiURL = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/servicios/tarifario/api/consultar?';
      listaVarClientes.map((element) => {
        apiURL += 'varCliente=' + selectedClienteComercial;
      })
      _filtroAvanzado.map((element) => {
        switch (element.params) {
          case 'origen':
            apiURL += 'varOrigen=' + element.varOrigen + '&';
            break;
          case 'destino':
            apiURL += 'varDestino=' + element.varDestino + '&';
            break;
          case 'vehiculo':
            apiURL += 'varVehiculo=' + element.varVehiculo + '&';
            break;
          case 'moneda':
            apiURL += 'varMoneda=' + element.varMoneda + '&';
            break;
          case 'fecha':
            apiURL += 'varFecha=' + element.varFecha + '&';
            break;
        }
      })

      const strOrdenamiento = FWDOrderHelper(ordenamiento)
      if (strOrdenamiento.length) apiURL += '&ordenamiento=' + strOrdenamiento

      const response = await axios.get(apiURL);
      setTarifasFiltradas(response.data.tarifario);
      setTarifas(response.data.tarifario);
      const registros = (response.data.tarifario).length;
      setSkeletonTable(Array(registros).fill(true));
    } catch (err) {
      console.log(err);
    } finally {
      // setCargandoPagina(false);
    }
    // }
  }

  const handleClick_verDocumento = (filename) => {

    //setLoading_isOpen(true);
    axios.get(`${process.env.REACT_APP_AWS_API_BASE_URL}/portal/servicios/tarifario/api/cotizacion/documento/${filename}`)
      .then(response => {
        if (response?.response?.data) {
          let responseError = response.response.data;
          if (responseError) {
            enqueueSnackbar(responseError.error, {
              autoHideDuration: 7000,
              preventDuplicate: true,
              variant: 'error',
              onClick: () => closeSnackbar()
            });
            console.log(responseError);
          }
        }

        if (response.status === 200) {
          window.open(response.data, 'documentos', 'toolbar=0,scrollbars=1,location=0,statusbar=0,menubar=0,resizable=1,width=500,height=700');
        }
        // setLoading_isOpen(false);
      })
      .catch(error => {
        console.log("[handleClick_verDocumento][catch]", error);
        if (error) {
          enqueueSnackbar(intl.formatMessage({ id: "ADMINISTRACION.ESTADO_CUENTA.MENSAJE.ERROR_AL_DESCARGAR_DOCUMENTO" }), {
            autoHideDuration: 7000,
            preventDuplicate: true,
            variant: 'error',
            onClick: () => closeSnackbar()
          });
        }
        // setLoading_isOpen(false);
      });
  }

  const _sessionVarClientes = session.listaVarClientes;
  const _listaVarClientes = _sessionVarClientes.map(x => x.strVarClienteOrigen);
  
  useEffect(() => {
    setResetOrdenamiento(Date.now())
    if (filtrosAvanzados.length) {
      get_tarifarioFiltroAvanzado(filtrosAvanzados);
    }
    else {
      get_tarifario(_listaVarClientes);
    }
  }, [filtrosAvanzados, selectedClienteComercial])

  useEffect(() => {
    // console.log('Tamaño de tarifas >> ',tarifasFiltradas.length);
    setCargandoPagina(false);
  }, [tarifasFiltradas])

  useEffect(() => {
    setListaVarClientes(_listaVarClientes);
    get_tarifario(_listaVarClientes);
  }, [])

  return <>
    <Grid container spacing={2} alignContent="flex-start">
      <TarjetaFiltroTarifario
        contenTable={contenTable}
        cargandoPagina={cargandoPagina}
        setContentTable={setContentTable}
        filtrosAvanzados={filtrosAvanzados}
        intTotalRegistros={tarifas?.length}
        setCargandoPagina={setCargandoPagina}
        intFiltrado={tarifasFiltradas?.length}
        setFiltrosAvanzados={setFiltrosAvanzados}
        debounceFnFiltrarTarifario={debounceFnFiltrarTarifario}
      ></TarjetaFiltroTarifario>
      {
        (contenTable) //Visualizar en una tabla
          ?
          <Grid item md={12}>
            <TableContainer component={Paper}>
              <ScrollContainer className="scroll-container" hideScrollbars={false}>

                <Table size="small" aria-label="tabla tarifario">
                  <FWDTableHead
                    cabeceras={[
                      new FWDCabecera('', 'Cotización', null, false),
                      new FWDCabecera('origen', 'Origen'),
                      new FWDCabecera('destino', 'Destino'),
                      new FWDCabecera('vehiculo', 'Vehículo'),
                      new FWDCabecera('tarifa', 'Tarifa', { align: 'right' }),
                      new FWDCabecera('fecha', 'Fecha Tarifa', { align: 'right' })
                    ]}
                    fnOnClick={(ordenamiento) => {
                      if (filtrosAvanzados.length)
                        get_tarifarioFiltroAvanzado(filtrosAvanzados, ordenamiento)
                      else
                        get_tarifario(_listaVarClientes, ordenamiento)
                    }}
                    disabled={cargandoPagina}
                    reset={resetOrdenamiento}
                  />
                  <TableBody>
                    {
                      (cargandoPagina)
                        ?
                        skeletonTable.map((element, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row" colSpan={6}>
                              <LoaderDashboardTables />
                            </TableCell>
                          </TableRow>
                        ))
                        :
                        [
                          (tarifasFiltradas.length === 0)
                            ?
                            <TableRow key={10101}>
                              <TableCell colSpan={5} align="center">
                                {intl.formatMessage({ id: 'SERVICIOS.TARIFARIO.TXT.NO_RESULTADOS' })}
                              </TableCell>
                            </TableRow>
                            :
                            tarifasFiltradas.map((element, index) => (
                              <TableRow key={index} hover>
                                <TableCell component="th" scope="row">

                                  {(element.DETALLE_COTIZACION)
                                    ? (
                                      element.DOC_COT_EVIDENCIA_ACEPTACION
                                        ? <PopoverCotizacionAceptacionInterna idCot={element.DETALLE_COTIZACION} folioCot={element.FOLIO_COTIZACION} docCot={element.DOC_COT} docEvidencia={element.DOC_COT_EVIDENCIA_ACEPTACION} comentario={element.COMENTARIO_ACEPTACION} handleClick_verDocumento={handleClick_verDocumento} />
                                        : <button
                                          style={{ padding: 0, maxWidth: '150px', textAlign: 'left' }}
                                          className="btn btn-link btn-no-seleccionable"
                                          onClick={() => {
                                            handleClick_verDocumento(element.DOC_COT);
                                          }}>{element.FOLIO_COTIZACION}
                                        </button>
                                    )

                                    : '-'}
                                </TableCell>
                                <TableCell>{element.ORIGEN}</TableCell>
                                <TableCell>{element.DESTINO}</TableCell>
                                <TableCell>{element.VEHICULO}</TableCell>
                                <TableCell align="right">{SH_formatCurrency(element.TARIFA)} {element.MONEDA}</TableCell>
                                <TableCell align="right">{formatearFecha(element.FECHA_TARIFA)}</TableCell>
                              </TableRow>
                            ))
                        ]
                    }
                  </TableBody>
                </Table>
              </ScrollContainer>
            </TableContainer>
          </Grid>

          : //Visualizar en modo tarjetas
          [
            (tarifasFiltradas.length === 0)
              ?
              <Grid item md={3} key={20202}>
                <Card style={{ marginBottom: '10px' }} variant="outlined">
                  <CardContent style={{ paddingBottom: 16 }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <Typography style={{ color: markColor.blue, fontWeight: 700, marginLeft: 3 }}>
                        {intl.formatMessage({ id: 'SERVICIOS.TARIFARIO.TXT.NO_RESULTADOS' })}
                      </Typography>
                      <Typography style={{ color: markColor.blue, fontWeight: 700 }}>
                        -
                      </Typography>
                    </div>
                    <Tooltip TransitionComponent={Zoom} title={intl.formatMessage({ id: 'SERVICIOS.TARIFARIO.TXT.ORIGEN' })} placement="left" leaveDelay={500}>
                      <Typography color="textSecondary" noWrap>
                        <RoomIcon style={{ color: markColor.green }}></RoomIcon> -
                      </Typography>
                    </Tooltip>
                    <Tooltip TransitionComponent={Zoom} title={intl.formatMessage({ id: 'SERVICIOS.TARIFARIO.TXT.DESTINO' })} placement="left" leaveDelay={500}>
                      <Typography color="textSecondary" noWrap>
                        <RoomIcon style={{ color: markColor.red }}></RoomIcon> -
                      </Typography>
                    </Tooltip>
                    <Tooltip TransitionComponent={Zoom} title={'Fecha Tarifa'} placement="left" leaveDelay={500}>
                      <Typography color="textSecondary" noWrap>
                        <EventIcon style={{ color: markColor.truck }}></EventIcon> -
                      </Typography>
                    </Tooltip>
                  </CardContent>
                </Card>
              </Grid>
              :
              tarifasFiltradas.map((element, index) => (
                // <Prueba key={index} index={index} element={element} />
                <Grid item md={3} key={index}>
                  <Card style={{ marginBottom: '10px' }} variant="outlined">
                    <CardContent style={{ paddingBottom: 16 }}>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography style={{ color: markColor.blue, fontWeight: 700, marginLeft: 3 }}>
                          {(element.DETALLE_COTIZACION)
                            ? (
                              element.DOC_COT_EVIDENCIA_ACEPTACION
                                ? <PopoverCotizacionAceptacionInterna idCot={element.DETALLE_COTIZACION} folioCot={element.FOLIO_COTIZACION} docCot={element.DOC_COT} docEvidencia={element.DOC_COT_EVIDENCIA_ACEPTACION} comentario={element.COMENTARIO_ACEPTACION} handleClick_verDocumento={handleClick_verDocumento} />
                                : <button
                                  style={{ padding: 0, maxWidth: '150px', textAlign: 'left' }}
                                  className="btn btn-link btn-no-seleccionable"
                                  onClick={() => {
                                    handleClick_verDocumento(element.DOC_COT);
                                  }}>{element.FOLIO_COTIZACION}
                                </button>
                            )

                            : '-'}
                        </Typography>
                        <Typography style={{ color: markColor.blue, fontWeight: 700 }}>
                          {SH_formatCurrency(element.TARIFA)} {element.MONEDA}
                        </Typography>
                      </div>
                      <Tooltip TransitionComponent={Zoom} title={intl.formatMessage({ id: 'SERVICIOS.TARIFARIO.TXT.ORIGEN' })} placement="left" leaveDelay={500}>
                        <Typography color="textSecondary" noWrap>
                          <RoomIcon style={{ color: markColor.green }}></RoomIcon> {element.ORIGEN}
                        </Typography>
                      </Tooltip>
                      <Tooltip TransitionComponent={Zoom} title={intl.formatMessage({ id: 'SERVICIOS.TARIFARIO.TXT.DESTINO' })} placement="left" leaveDelay={500}>
                        <Typography color="textSecondary" noWrap>
                          <RoomIcon style={{ color: markColor.red }}></RoomIcon> {element.DESTINO}
                        </Typography>
                      </Tooltip>
                      <Tooltip TransitionComponent={Zoom} title={intl.formatMessage({ id: 'SERVICIOS.TARIFARIO.TXT.VEHICULO' })} placement="left" leaveDelay={500}>
                        <Typography color="textSecondary" noWrap>
                          <LocalShippingIcon style={{ color: markColor.truck }}></LocalShippingIcon> {element.VEHICULO}
                        </Typography>
                      </Tooltip>
                      <Tooltip TransitionComponent={Zoom} title={'Fecha Tarifa'} placement="left" leaveDelay={500}>
                        <Typography color="textSecondary" noWrap>
                          <EventIcon style={{ color: markColor.truck }}></EventIcon> {element.FECHA_TARIFA}
                        </Typography>
                      </Tooltip>
                      {
                        (listaVarClientes.length > 1)
                          ? <Typography color="textSecondary" noWrap>
                            <AccountCircleIcon style={{ color: markColor.blue }}></AccountCircleIcon> {element.CLIENTE}
                          </Typography>
                          : <></>
                      }
                    </CardContent>
                  </Card>
                </Grid>
              ))
          ]
      }
    </Grid>
  </>
}

export default injectIntl(Tarifario)