import React, { useState, useEffect, useLayoutEffect, useRef } from "react"
// import Card from '../../componentes/Card'
import MapaServicios from 'app/componentes/servicios/MapaServicios'
import axios from 'axios'
import { injectIntl } from "react-intl";
import './Index.css'
import { useSelector } from "react-redux"
import Skeleton from '@material-ui/lab/Skeleton';
import { Grid, Card, CardHeader, CardContent, CircularProgress, Typography, Zoom, FormControl, InputLabel, IconButton } from '@material-ui/core'
import { Button } from '@material-ui/core'
import { SH_formatCurrency } from 'app/helpers/stringHelper'
import RoomIcon from '@material-ui/icons/Room'

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// import TimelineSeguimiento from 'app/componentes/servicios/TimelineSeguimiento'
import TimelineSeguimientos from 'app/componentes/dashboard/TimelineSeguimientos'
import Necesidades from 'app/componentes/dashboard/Necesidades'
import SelectorAnio from 'app/componentes/dashboard/SelectorAnio'
import SelectorMes from 'app/componentes/dashboard/SelectorMes'
import { LoaderDashboard, LoaderPantallaVacia } from 'app/componentes/dashboard/LoaderDashboard'
import FormatoFecha from 'app/formatos/Fecha'
import FWDModal from 'app/componentes/FWDModal'
import ModalDashboard from "app/componentes/dashboard/ModalDashboard";
import Chart from 'react-apexcharts'

import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import ListSharpIcon from '@material-ui/icons/ListSharp';

const setupPieOptions = (labels = []) => ({
    labels,
    plotOptions: {
        pie: {
            startAngle: 0,
            expandOnClick: false,
            offsetX: 0,
            offsetY: 0,
            customScale: 1,
            dataLabels: {
                offset: 0,
                minAngleToShowLabel: 10
            },
        }
    },
    chart: {
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        }
    }
})

const setupOpcionesDonutChart = (denominacion = 'MXN') => ({
    colors: ['#DC3545', /*'#FFA800', */ '#28A745',],
    legend: {
        fontSize: '14px',
        fontFamily: 'Helvetica, Arial',
        fontWeight: 400,
        position: 'bottom',
        horizontalAlign: 'center',
        floating: false,
        inverseOrder: false,
        itemMargin: {
            vertical: 10
        },
        formatter: function (seriesName, opts) {
            return '<div class="legend-info">' +
                '<span>' + seriesName + '</span> <br/>' +
                '<span>' + SH_formatCurrency(opts.w.globals.series[opts.seriesIndex]) + ' ' + denominacion + '</span>' +
                '</div>'
        }
    },
    dataLabels: {
        enabled: false,
    },
    labels: ['Vencido', 'A tiempo', /*'Por facturar'*/],
    plotOptions: {
        pie: {
            startAngle: -90,
            endAngle: 90,
            // offsetY: 10,
            donut: {
                labels: {
                    show: true,
                    name: {
                        show: true,
                        fontSize: '22px',
                        fontFamily: 'Rubik',
                        color: '#dfsda',
                        offsetY: -10
                    },
                    value: {
                        show: true,
                        fontSize: '16px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        color: undefined,
                        offsetY: 16,
                        formatter: function (val) {
                            return SH_formatCurrency(val)
                        }
                    },
                    total: {
                        show: true,
                        showAlways: true,
                        label: denominacion,
                        fontSize: '1.1rem',
                        formatter: function (w) {
                            let total = w.globals.seriesTotals.reduce((a, b) => {
                                return a + b
                            }, 0)
                            total = SH_formatCurrency(total)
                            return total
                        }
                    }
                }
            }
        }
    },
    tooltip: {
        y: {
            formatter: v => SH_formatCurrency(v)
        }
    },
    chart: {
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        }
    }
})

const setupOptionsBarChart = (language = 'es') => ({
    chart: {
        type: 'bar',
        height: 350,
        toolbar: {
            show: false
        },
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        }
    },
    plotOptions: {
        bar: {
            horizontal: false
        }
    },
    dataLabels: {
        enabled: false
    },
    xaxis: {
        categories: language.toLowerCase() === 'es'
            ? ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sept', 'Oct', 'Nov', 'Dic']
            : ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dic'],
    }
})

var date = new Date();
let today = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

const fechaDashboardDefaultState = {
    mes: (today.getMonth() + 1),
    anio: today.getFullYear(),
};

const fechaRegistroClienteDefaultState = FormatoFecha(today);


function DashboardPage(props) {
    // Session
    const { intl } = props;
    const { session, selectedClienteComercial } = useSelector(state => state.auth);
    // Array de clientes

    let arrayCliente = session ? session.listaVarClientes.map((cliente) => `varCliente=${cliente.strVarClienteOrigen}`) : [];

    // Control de Media Queries
    const theme = useTheme()
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'), { defaultMatches: true })
    const isXlUp = useMediaQuery(theme.breakpoints.up('xl'), { defaultMatches: true })
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'), { defaultMatches: true })

    useLayoutEffect(() =>
        setChartTotalDestinos({ ...chartTotalDestinos, options: { ...chartTotalDestinos.options, legend: { show: !isSmDown } } })
        , [isSmDown])

    // Año actual y anterior
    const dt_now = new Date()
    const actual_year = dt_now.getFullYear()
    const past_year = actual_year - 1

    // Estados
    const [listaServicios, setListaServicios] = useState([]);
    const [dblPedidoSeleccionado, setDblPedidoSeleccionado] = useState(0);
    const [chartCuentasPagarTotalExistentes, setChartCuentasPagarTotalExistentes] = useState(0)
    const [totalDiffServiciosAnio, setTotalDiffServiciosAnio] = useState(0)
    const [mostrarPines, setMostrarPines] = useState(false)
    const [mostrarPinesBtn, setMostrarPinesBtn] = useState(false)
    const [fechaDashboard, setFechaDashboard] = useState(fechaDashboardDefaultState);
    const [fechaRegistroCliente, setFechaRegistroCliente] = useState(fechaRegistroClienteDefaultState);
    const [openDash, setOpenDash] = useState(false);
    const [dataCards, setDataCards] = useState([]);
    const [modalTitle, setModalTitle] = useState('');

    // Estados (indicadores)
    const [indicadorNecesidadesReportadasCubiertas, setIndicadorNecesidadesReportadasCubiertas] = useState({
        totalConfirmados: 0, totalEnTransito: 0, totalEntregados: 0
    })
    const handleOpenDash = (data, title) => {
        setOpenDash(true);
        setDataCards(data);
        setModalTitle(title)
    };

    const handleCloseDash = () => {
        setOpenDash(false);
    };
    // Estados (Charts)
    const [chartTotalDestinos, setChartTotalDestinos] = useState({
        options: setupPieOptions([]),
        series: [],
    });
    const [chartCuentasPagarFranquiciaMxMxn, setChartCuentasPagarFranquiciaMxMxn] = useState({
        options: setupOpcionesDonutChart('MXN'),
        series: Array(2).fill(0)
    })
    const [chartCuentasPagarFranquiciaMxUsd, setChartCuentasPagarFranquiciaMxUsd] = useState({
        options: setupOpcionesDonutChart('USD'),
        series: Array(2).fill(0)
    })
    const [chartCuentasPagarFranquiciaUsUsd, setChartCuentasPagarFranquiciaUsUsd] = useState({
        options: setupOpcionesDonutChart('USD'),
        series: Array(2).fill(0)
    })
    const [chartTotalServiciosPorMesEnAnio, setChartTotalServiciosPorMesEnAnio] = useState({

        series: [
            {
                data: Array(12).fill(0),
                name: actual_year
            },
            {
                data: Array(12).fill(0),
                name: past_year
            }
        ],
        options: setupOptionsBarChart('ES')

    })

    // Carga inicial de pagina
    const [bitCargandoPagina, setbitCargandoPagina] = useState(true)
    const [bitCargandoRastreoServicioGPS, setBitCargandoRastreoServicioGPS] = useState(true)
    const [bitCargandoCuentasPagar, setBitCargandoCuentasPagar] = useState(true)
    const [bitCargandoServiciosDiffAnual, setBitCargandoServiciosDiffAnual] = useState(true)
    const [bitCargandoDestinosAnio, setBitCargandoDestinosAnio] = useState(true)
    const [bitCargandoNecesidadesReportadasCubiertas, setBitCargandoNecesidadesReportadasCubiertas] = useState(true)
    const [bitCargandoInfoCards, setBitCargandoInfoCards] = useState(true)

    //Servicio activo Rastreo GPS > Visualizando ruta del servicio especifico
    const [bitServicioActivoRuta, setBitServicioActivoRuta] = useState(false)

    //Modal de necesidades
    const [modalActivo, setModalActivo] = useState(false)
    const [filtroDashboardTexto, setFiltroDashboardTexto] = useState('')

    // Refs
    const timeoutRef = useRef()

    // Promesas

    const fetchServiciosCardsDashboard = async () => {

        let api_url = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/dashboard/api/cards';
        api_url += `?varCliente=${selectedClienteComercial}&varMes=${fechaDashboard.mes}&varAnio=${fechaDashboard.anio}`;
        try {
            const response = await axios.get(api_url);
            setIndicadorNecesidadesReportadasCubiertas({
                totalConfirmados: response.data.confirmados,
                totalEnTransito: response.data.enTransito,
                totalEntregados: response.data.entregados
            })
            setBitCargandoInfoCards(false)
        }
        catch (error) {
            console.log('error', error);
        }
    }

    useEffect(() => {
        fetchServiciosCardsDashboard();
    }, [selectedClienteComercial, fechaDashboard.mes, fechaDashboard.anio]);

    const fetchRastreoServicioGPS = () => {
        return new Promise((resolve, reject) => {
            setBitCargandoRastreoServicioGPS(true)
            axios.get(`${process.env.REACT_APP_AWS_API_BASE_URL}/auth/sesion`)
                .then((response) => {
                    // axios.get(`${process.env.REACT_APP_AWS_API_BASE_URL}/portal/dashboard/api/rastreos/servicios/28392`)
                    axios.get(`${process.env.REACT_APP_AWS_API_BASE_URL}/portal/dashboard/api/rastreos/servicios?varCliente=${selectedClienteComercial}`)
                        .then(response => {
                            if (response.status === 200) {
                                resolve(response.data);
                            }
                            else {
                                throw { code: response.status }
                            }
                        })
                        .catch(error => {
                            console.log('fetchRastreoServicioGPSError ', error)
                            reject(error)
                        })
                        .finally(() => setBitCargandoRastreoServicioGPS(false))
                });
        })
    }

    const fetchCuentasPagar = () => {
        return new Promise(async (resolve, reject) => {
            let totalCuentasPagar = 0
            setBitCargandoCuentasPagar(true)
            try {

                const apiURL = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/dashboard/api/cuentas-pago?' + `varCliente=${selectedClienteComercial}`
                const response = await axios.get(apiURL)
                const responses = response.data;

                const datosCuenta = { existe: false, /*pendienteFacturar: 0,*/ aTiempo: 0, vencido: 0 }
                let cuentaFwdMxMxn = { ...datosCuenta }
                let cuentaFwdMxUsd = { ...datosCuenta }
                let cuentaFwdUsUsd = { ...datosCuenta }

                cuentaFwdMxMxn.existe = cuentaFwdMxMxn.existe || responses.cuentaFwdMxMxn.existe
                // cuentaFwdMxMxn.pendienteFacturar += responses.cuentaFwdMxMxn.pendienteFacturar
                cuentaFwdMxMxn.aTiempo += responses.cuentaFwdMxMxn.aTiempo
                cuentaFwdMxMxn.vencido += responses.cuentaFwdMxMxn.vencido

                cuentaFwdMxUsd.existe = cuentaFwdMxUsd.existe || responses.cuentaFwdMxUsd.existe
                // cuentaFwdMxUsd.pendienteFacturar += responses.cuentaFwdMxUsd.pendienteFacturar
                cuentaFwdMxUsd.aTiempo += responses.cuentaFwdMxUsd.aTiempo
                cuentaFwdMxUsd.vencido += responses.cuentaFwdMxUsd.vencido

                cuentaFwdUsUsd.existe = cuentaFwdUsUsd.existe || responses.cuentaFwdUsUsd.existe
                // cuentaFwdUsUsd.pendienteFacturar += responses.cuentaFwdUsUsd.pendienteFacturar
                cuentaFwdUsUsd.aTiempo += responses.cuentaFwdUsUsd.aTiempo
                cuentaFwdUsUsd.vencido += responses.cuentaFwdUsUsd.vencido

                const resolveCuentaFwdMxMxn = {
                    ...chartCuentasPagarFranquiciaMxMxn, series: [
                        cuentaFwdMxMxn.vencido, cuentaFwdMxMxn.aTiempo, /*cuentaFwdMxMxn.pendienteFacturar*/
                    ],
                    "$existe": cuentaFwdMxMxn.existe
                    // ...chartCuentasPagarFranquiciaUsUsd, series: [
                    //     cuentaFwdUsUsd.vencido, cuentaFwdUsUsd.aTiempo, /*cuentaFwdUsUsd.pendienteFacturar*/
                    // ],
                    // "$existe": cuentaFwdUsUsd.existe
                }
                // setChartCuentasPagarFranquiciaMxMxn()
                if (cuentaFwdMxMxn.existe) totalCuentasPagar++

                const resolveCuentaFwdMxUsd = {
                    ...chartCuentasPagarFranquiciaMxUsd, series: [
                        cuentaFwdMxUsd.vencido, cuentaFwdMxUsd.aTiempo, /*cuentaFwdMxUsd.pendienteFacturar*/
                    ],
                    "$existe": cuentaFwdMxUsd.existe
                    // ...chartCuentasPagarFranquiciaUsUsd, series: [
                    //     cuentaFwdUsUsd.vencido, cuentaFwdUsUsd.aTiempo, /*cuentaFwdUsUsd.pendienteFacturar*/
                    // ],
                    // "$existe": cuentaFwdUsUsd.existe
                }
                // setChartCuentasPagarFranquiciaMxUsd()
                if (cuentaFwdMxUsd.existe) totalCuentasPagar++

                const resolveCuentaFwdUsUsd = {
                    ...chartCuentasPagarFranquiciaUsUsd, series: [
                        cuentaFwdUsUsd.vencido, cuentaFwdUsUsd.aTiempo, /*cuentaFwdUsUsd.pendienteFacturar*/
                    ],
                    "$existe": cuentaFwdUsUsd.existe
                }
                // setChartCuentasPagarFranquiciaUsUsd()
                if (cuentaFwdUsUsd.existe) totalCuentasPagar++
                resolve({
                    resolveCuentaFwdMxMxn,
                    resolveCuentaFwdMxUsd,
                    resolveCuentaFwdUsUsd,
                    totalCuentasPagar
                })
            }
            catch (error) {
                console.log('fetchCuentasPagarError', error);
                reject(error)
            }
            finally {
                setBitCargandoCuentasPagar(false)
            }
        })
    }

    const fetchServiciosDiffAnual = () => {
        return new Promise(async (resolve, reject) => {
            setBitCargandoServiciosDiffAnual(true)
            try {
                const responses = await Promise.all(arrayCliente.map(async cte => {
                    const apiURL = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/dashboard/api/servicios-diff-anual?' + `varCliente=${selectedClienteComercial}` + '&anio=' + actual_year
                    const response = await axios.get(apiURL)
                    return response.data
                }))

                const conteoAnios = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0 }
                const totalDiffAnual = {
                    totalAnio: 0,
                    totalAnioAnterior: 0,
                    servicios: {
                        anioActual: { ...conteoAnios },
                        anioAnterior: { ...conteoAnios }
                    }
                }

                for (const res of responses) {
                    totalDiffAnual.totalAnio += res.totalAnio
                    totalDiffAnual.totalAnioAnterior += res.totalAnioAnterior
                    for (const nMes in totalDiffAnual.servicios.anioActual) {
                        totalDiffAnual.servicios.anioActual[nMes] += res.servicios.anioActual[nMes]
                        totalDiffAnual.servicios.anioAnterior[nMes] += res.servicios.anioAnterior[nMes]
                    }
                }

                const resolveChartTotalServicioPorMesEnAnio = {
                    ...chartTotalServiciosPorMesEnAnio, series: [
                        {
                            data: Object.values(totalDiffAnual.servicios.anioAnterior),
                            name: past_year
                        },
                        {
                            data: Object.values(totalDiffAnual.servicios.anioActual),
                            name: actual_year
                        },
                    ]
                }
                const resolveTotalDiffServiciosAnio = totalDiffAnual.totalAnio - totalDiffAnual.totalAnioAnterior
                resolve({
                    resolveChartTotalServicioPorMesEnAnio,
                    resolveTotalDiffServiciosAnio
                })
            }
            catch (error) {
                console.log('fetchServiciosDiffAnualError', error)
                reject(error)
            }
            finally {
                setBitCargandoServiciosDiffAnual(false)
            }
        })
    }

    const fetchDestinosAnio = () => {
        setBitCargandoDestinosAnio(true)
        return new Promise(async (resolve, reject) => {
            try {
                const topX = 5
                let apiUrl = `${process.env.REACT_APP_AWS_API_BASE_URL}/portal/dashboard/api/destinos-anio?`
                apiUrl += `varCliente=${selectedClienteComercial}&`
                apiUrl += '&anio=' + fechaDashboard.anio
                apiUrl += '&mes=' + fechaDashboard.mes
                apiUrl += '&topX=' + topX

                const response = await axios.get(apiUrl)
                const ciudades = response.data?.ciudades?.map(x => x.strCiudad)
                const totales = response.data?.ciudades?.map(x => x.total)

                // Si son menos de 10 ciudades ocultamos el conteo de "otros"
                if (response.data?.nTop < topX) {
                    ciudades.splice(-1, 1)
                    totales.splice(-1, 1)
                }

                const resolveChartTotalDestinos = { options: setupPieOptions(ciudades), series: totales, "$nTop": response.data?.nTop }
                resolve({ resolveChartTotalDestinos })
            }
            catch (error) {
                console.log('fetchDestinosAnioError', error)
                reject(error)
            }
            finally {
                setBitCargandoDestinosAnio(false)
            }
        })
    }

    const fetchNecesidadesReportadasCubiertas = () => {
        return new Promise(async (resolve, reject) => {
            setBitCargandoNecesidadesReportadasCubiertas(true)
            try {
                let apiURL = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/dashboard/api/necesidades-reportadas-cubiertas'
                apiURL += "?anio=" + fechaDashboard.anio
                apiURL += "&mes=" + fechaDashboard.mes
                apiURL += '&' + `varCliente=${selectedClienteComercial}`
                const result = await axios.get(apiURL)
                const resolveIndicadorNecesidadesReportadasCubiertas = result.data
                resolve({
                    resolveIndicadorNecesidadesReportadasCubiertas
                })
            }
            catch (error) {
                console.log('fetchNecesidadesReportadasCubierta', error)
                reject(error)
            }
            finally {
                setBitCargandoNecesidadesReportadasCubiertas(false)
            }
        })
    }

    const fetchFechaRegistroCliente = () => {
        return new Promise(async (resolve, reject) => {
            try {
                let apiURL = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/dashboard/api/fecha-registro-cliente'
                apiURL += '?' + `varCliente=${selectedClienteComercial}`
                const result = await axios.get(apiURL)
                if (result.data.length > 0) {
                    let strFechaAlta = result.data[0].datFechaAlta;
                    let datFechaAlta = new Date(strFechaAlta);
                    datFechaAlta = new Date(datFechaAlta.getTime() + datFechaAlta.getTimezoneOffset() * 60000)
                    setFechaRegistroCliente(FormatoFecha(datFechaAlta));
                }
                resolve(result.data);
            }
            catch (error) {
                reject(error)
            }
            finally {
            }
        })
    }

    // Sincronización inciial (al cargar la página)

    useEffect(() => {
        setbitCargandoPagina(true);

        Promise.all([
            fetchRastreoServicioGPS(), //p1
            fetchCuentasPagar(), //p2
            fetchServiciosDiffAnual(), //p3
            fetchDestinosAnio(), //p4
            // fetchNecesidadesReportadasCubiertas(), //p5
        ])
            .then(([p1, p2, p3, p4]) => {
                setListaServicios(p1)
                setChartCuentasPagarFranquiciaMxMxn(p2.resolveCuentaFwdMxMxn)
                setChartCuentasPagarFranquiciaMxUsd(p2.resolveCuentaFwdMxUsd)
                setChartCuentasPagarFranquiciaUsUsd(p2.resolveCuentaFwdUsUsd)
                setChartCuentasPagarTotalExistentes(p2.totalCuentasPagar)
                setChartTotalServiciosPorMesEnAnio(p3.resolveChartTotalServicioPorMesEnAnio)
                setTotalDiffServiciosAnio(p3.resolveTotalDiffServiciosAnio)
                setChartTotalDestinos(p4.resolveChartTotalDestinos)
                // setIndicadorNecesidadesReportadasCubiertas(p5.resolveIndicadorNecesidadesReportadasCubiertas)
            })
            .catch(error => {
                console.log('effect.promiseallfetchapierror ', error)
            })
            .finally(() => {
                // Necesario para que el widget de cuentas por pagar recalcule dimensiones (bug de libería)
                timeoutRef.current = setTimeout(() => window.dispatchEvent(new Event('resize')), 1000)
                setbitCargandoPagina(false)
            })

    }, [fechaDashboard, selectedClienteComercial]);


    // Al cerrar ventana destruimos el timeout
    window.addEventListener('beforeunload', () => clearTimeout(timeoutRef.current))

    useEffect(() => {
        setMostrarPinesBtn(dblPedidoSeleccionado > 0)
    }, [dblPedidoSeleccionado])


    // Control dinámico de estilos
    const [styleNecesidadesReportadas, setStyleNecesidadesReportadas] = useState({ fontSize: '1rem', color: '#3783e7' })
    const [styleNecesidadesCubiertas, setStyleNecesidadesCubiertas] = useState({ fontSize: '1rem', color: '#3783e7' })

    // Métodos
    let reloadData = (data) => {
        let listaServicios_aux = data ? data : listaServicios;
        setListaServicios([...listaServicios_aux]);
    }

    let handleClick_seleccionTarjeta = function (e) {
        //Desmarcamos a todas las tarjetas y unicamente volvemos a seleccionar como activa a la tarjeta que recibi� el click
        let tarjetaActiva = !e.currentTarget.classList.contains('active');
        document.getElementsByClassName('tarjeta-servicio').forEach((element) => {
            element.classList.remove('active');
            element.style.display = "block";
        });
        if (tarjetaActiva) {
            e.currentTarget.classList.add('active');
            document.getElementsByClassName('tarjeta-servicio').forEach((element) => {
                if (!element.classList.contains('active')) {
                    element.style.display = "none";
                }
            });
        }

        var dblPedido = e.currentTarget.getAttribute('idPedido');
        var bitActivo = tarjetaActiva;
        let indexServicio = -1;

        listaServicios.forEach((item, index) => {
            item.bitSeleccionado = Number(item.dblPedido) === Number(dblPedido);
            if (item.dblPedido === Number(dblPedido))
                indexServicio = index;

            if (bitActivo) {
                item.bitVisible = Number(item.dblPedido) === Number(dblPedido);
            } else {
                item.bitVisible = true;
            }
        });

        if (indexServicio >= 0 && bitActivo) {
            setDblPedidoSeleccionado(Number(dblPedido));
            setBitServicioActivoRuta(true);
        } else {
            setDblPedidoSeleccionado(0);
            setBitServicioActivoRuta(false);
        }
        setListaServicios([...listaServicios]);
    }

    //Retornar a todos los servicios en el rastreo de GPS
    const fn_retornarServiciosRastreo = () => {
        document.getElementsByClassName('tarjeta-servicio').forEach((element) => {
            element.classList.remove('active');
            element.style.display = "block";
        });
        setDblPedidoSeleccionado(0);
        setBitServicioActivoRuta(false);
    }

    // Render secciones
    const renderSeccionCuentasPagar = () => {
        let renderCuentas = []

        if (chartCuentasPagarFranquiciaMxMxn["$existe"])
            renderCuentas.push(
                <div style={{ width: '100%', textAlign: 'center' }} key={`cuenta-1`}>
                    <Typography variant="h6" color="primary">{intl.formatMessage({ id: 'APP.EMPRESAS.FWD_LOGISTICA' })}</Typography>
                    <Chart type="donut" width="100%" height="100%"
                        options={chartCuentasPagarFranquiciaMxMxn?.options}
                        series={chartCuentasPagarFranquiciaMxMxn?.series}
                    />
                </div>
            )

        if (chartCuentasPagarFranquiciaMxUsd["$existe"])
            renderCuentas.push(
                <div style={{ width: '100%', textAlign: 'center' }} key={`cuenta-2`}>
                    <Typography variant="h6" color="primary">{intl.formatMessage({ id: 'APP.EMPRESAS.FWD_LOGISTICA' })}</Typography>
                    <Chart type="donut" width="100%" height="100%"
                        options={chartCuentasPagarFranquiciaMxUsd?.options}
                        series={chartCuentasPagarFranquiciaMxUsd?.series}
                    />
                </div>
            )

        if (chartCuentasPagarFranquiciaUsUsd["$existe"])
            renderCuentas.push(
                <div style={{ width: '100%', textAlign: 'center' }} key={`cuenta-3`}>
                    <Typography variant="h6" color="primary">{intl.formatMessage({ id: 'APP.EMPRESAS.FWD_LOGISTICS' })}</Typography>
                    <Chart type="donut" width="100%" height="100%"
                        options={chartCuentasPagarFranquiciaUsUsd?.options}
                        series={chartCuentasPagarFranquiciaUsUsd?.series}
                    />
                </div>
            )

        return renderCuentas
    }

    //Formato fecha modal necesidades
    const fn_renombrarFiltro = () => {
        const monthNames = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
        setFiltroDashboardTexto(monthNames[fechaDashboard.mes - 1] + "/" + fechaDashboard.anio);
    }

    return <>
        <FWDModal
            open={modalActivo}
            fnCloseModal={() => {
                setModalActivo(false);
            }}
            Titulo={() => <Typography variant="h5">Necesidades {filtroDashboardTexto}</Typography>}
            width="75%"
            height="80%"
            overflow='auto'
        >
            <Necesidades
                anio={fechaDashboard.anio}
                mes={fechaDashboard.mes}
                cliente={arrayCliente[0]}
            />
        </FWDModal>
        {bitCargandoPagina ? (
            <Grid container spacing={2}>
                <Grid item container alignContent="flex-end" justify="flex-end" xs={12} spacing={2}>
                    <LoaderDashboard />
                </Grid>
            </Grid>
        ) : (
            <Grid container spacing={2}>
                <Grid item container alignContent="flex-end" justify="flex-end" xs={12} spacing={2}>
                    <Grid container item xs={12} sm={6} md={6} lg={3}>
                        <Grid item xs={6}>
                            <FormControl style={{ width: '100%' }}>
                                <InputLabel>{intl.formatMessage({ id: "DASHBOARD.INDEX.FILTRO_MES" })}</InputLabel>
                                <SelectorMes
                                    disabled={bitCargandoPagina}
                                    strFechaInicio={fechaRegistroCliente}
                                    value={fechaDashboard.mes}
                                    onChange={value => setFechaDashboard({ ...fechaDashboard, mes: value })} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl style={{ width: '100%' }}>
                                <InputLabel>{intl.formatMessage({ id: "DASHBOARD.INDEX.FILTRO_ANIO" })}</InputLabel>
                                <SelectorAnio
                                    disabled={bitCargandoPagina}
                                    strFechaInicio={fechaRegistroCliente}
                                    value={fechaDashboard.anio}
                                    onChange={value => setFechaDashboard({ ...fechaDashboard, anio: value })} />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    {/* <Grid item xs={12} sm={6} md={6} lg={3}>
                    <Card style={{ height: 150 }} onClick={() => { { fn_renombrarFiltro(); setModalActivo(true); } }} className="cursor-hover">
                        <CardHeader title={
                            <section style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography
                                    className="cursor-default"
                                    variant="h5"
                                >Necesidades</Typography>
                                <LocalShippingIcon style={{ fontSize: '2rem' }} />
                            </section>
                        } />
                        <CardContent>
                            {bitCargandoNecesidadesReportadasCubiertas
                                ?
                                <section style={{ display: 'flex', width: '100%', height: 60, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                    <CircularProgress color="secondary" />
                                </section>
                                :
                                <section style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <section style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography
                                            variant="overline"
                                            color="primary"
                                            className="cursor-default"
                                            style={styleNecesidadesReportadas}
                                            onMouseEnter={e => setStyleNecesidadesReportadas({ ...styleNecesidadesReportadas, fontSize: '1.1rem' })}
                                            onMouseLeave={e => setStyleNecesidadesReportadas({ ...styleNecesidadesReportadas, fontSize: '1rem' })}
                                        >
                                            <span style={{ fontWeight: 'bolder' }}>{indicadorNecesidadesReportadasCubiertas.totalReportadas}</span>
                                            &nbsp;<span>reportadas</span>
                                        </Typography>
                                        <Typography
                                            variant="overline"
                                            color="primary"
                                            className="cursor-default"
                                            style={styleNecesidadesCubiertas}
                                            onMouseEnter={e => setStyleNecesidadesCubiertas({ ...styleNecesidadesCubiertas, fontSize: '1.1rem' })}
                                            onMouseLeave={e => setStyleNecesidadesCubiertas({ ...styleNecesidadesCubiertas, fontSize: '1rem' })}
                                        >
                                            <span style={{ fontWeight: 'bolder' }}>{indicadorNecesidadesReportadasCubiertas.totalCubiertas}</span>
                                            &nbsp;<span>cubiertas</span>
                                        </Typography>
                                    </section>
                                    <Typography variant="h4" color="primary">{indicadorNecesidadesReportadasCubiertas.porcentaje} %</Typography>
                                </section>
                            }

                        </CardContent>
                    </Card>
                </Grid> */}
                    <ModalDashboard open={openDash} onClose={handleCloseDash} title={modalTitle} items={dataCards} />

                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <Card style={{ height: 150 }}>
                            <CardHeader
                                title="Confirmados"
                                action={indicadorNecesidadesReportadasCubiertas.totalConfirmados.length > 0 ?
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        style={{ marginTop: 10, borderRadius: 10, backgroundColor: '#1f23bc', color: 'white' }}
                                        onClick={() => handleOpenDash(indicadorNecesidadesReportadasCubiertas.totalConfirmados, 'Total Confirmados')}
                                        endIcon={<ListSharpIcon />}
                                    >
                                        Ver detalles
                                    </Button> : <Button
                                        variant="outlined"
                                        color="secondary"
                                        style={{ marginTop: 10, borderRadius: 10 }}
                                        disabled
                                        endIcon={<ListSharpIcon />}
                                    >
                                        Ver detalles
                                    </Button>
                                } />
                            <CardContent>
                                <section style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <PlaylistAddCheckIcon style={{ fontSize: '4.5rem' }} />
                                    {bitCargandoInfoCards
                                        ?
                                        <section style={{ display: 'flex', width: '100%', height: 60, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                            <CircularProgress color="secondary" />
                                        </section>
                                        :
                                        <Typography variant="h5" style={{ color: '#1f23bc' }}>{indicadorNecesidadesReportadasCubiertas.totalConfirmados.length ? 'Confirmados: ' + indicadorNecesidadesReportadasCubiertas.totalConfirmados.length : 'Sin Datos'}</Typography>
                                    }
                                </section>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <Card style={{ height: 150 }}>
                            <CardHeader
                                title="En Transito"
                                action={indicadorNecesidadesReportadasCubiertas.totalEnTransito.length > 0 ?
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => handleOpenDash(indicadorNecesidadesReportadasCubiertas.totalEnTransito, 'Total en Transito')}
                                        style={{ marginTop: 10, borderRadius: 10, backgroundColor: '#1f23bc', color: 'white' }}
                                        endIcon={<ListSharpIcon />}
                                    >
                                        Ver detalles
                                    </Button> : <Button
                                        variant="outlined"
                                        color="secondary"
                                        style={{ marginTop: 10, borderRadius: 10 }}
                                        disabled
                                        endIcon={<ListSharpIcon />}
                                    >
                                        Ver detalles
                                    </Button>
                                }
                            />
                            <CardContent>
                                <section style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <LocalShippingIcon style={{ fontSize: '4.5rem' }} />
                                    {bitCargandoInfoCards
                                        ?
                                        <section style={{ display: 'flex', width: '100%', height: 60, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                            <CircularProgress color="secondary" />
                                        </section>
                                        :
                                        <Typography variant="h5" style={{ color: '#1f23bc' }}>{indicadorNecesidadesReportadasCubiertas.totalEnTransito.length ? 'En Transito: ' + indicadorNecesidadesReportadasCubiertas.totalEnTransito.length : 'Sin Datos'}</Typography>
                                    }
                                </section>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <Card style={{ height: 150, borderBlockStartColor: '#1f23be' }}>
                            <CardHeader
                                title="Entregados"
                                action={indicadorNecesidadesReportadasCubiertas.totalEntregados.length > 0 ?
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => handleOpenDash(indicadorNecesidadesReportadasCubiertas.totalEntregados, 'Total Entregados')}
                                        style={{ marginTop: 10, borderRadius: 10, backgroundColor: '#1f23bc', color: 'white' }}
                                        endIcon={<ListSharpIcon />}
                                    >
                                        Ver detalles
                                    </Button> : <Button
                                        variant="outlined"
                                        color="secondary"
                                        style={{ marginTop: 10, borderRadius: 10 }}
                                        disabled
                                        endIcon={<ListSharpIcon />}
                                    >
                                        Ver detalles
                                    </Button>
                                }
                            />
                            <CardContent>
                                <section style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <AlarmOnIcon style={{ fontSize: '4.5rem' }} />
                                    {bitCargandoInfoCards
                                        ?
                                        <section style={{ display: 'flex', width: '100%', height: 60, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                            <CircularProgress color="secondary" />
                                        </section>
                                        :
                                        <Typography variant="h5" style={{ color: '#1f23bc' }}>{indicadorNecesidadesReportadasCubiertas.totalEntregados.length ? 'Entregados: ' + indicadorNecesidadesReportadasCubiertas.totalEntregados.length : 'Sin Datos'}</Typography>
                                    }
                                </section>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <Grid container item xs={12} sm={12} md={7} spacing={2} alignContent="stretch">
                    <Zoom in={true}>
                        <Grid item xs={12} xl={chartCuentasPagarTotalExistentes <= 2 ? 5 : 12}>
                            <Card>
                                <CardHeader
                                    //title="Servicios por mes 2019 - 2020" 
                                    title={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="h5">Cuentas por pagar</Typography>
                                        {/* <Typography variant="h6" color="primary">Fwd Logistics  </Typography> */}
                                    </div>}
                                />
                                <CardContent>
                                    {chartCuentasPagarTotalExistentes > 0
                                        ?
                                        <>
                                            <section style={{ height: 300, display: 'flex' }}>
                                                {renderSeccionCuentasPagar()}
                                            </section>
                                        </>
                                        : <>
                                            <section style={{ height: 300 }}>
                                                <LoaderPantallaVacia label="No hay datos" />
                                            </section>
                                        </>}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Zoom>

                    <Grid item xs={12} xl={bitCargandoPagina ? 12 : chartCuentasPagarTotalExistentes <= 2 ? 7 : 12}>
                        <Card>
                            <CardHeader title={`Top ${chartTotalDestinos["$nTop"] || 0} destinos`} />
                            <CardContent>
                                {chartTotalDestinos["$nTop"] != 0 ? (
                                    <>
                                        <section style={{ height: 300 }}>
                                            <Chart type="pie" width="100%" height="100%" options={chartTotalDestinos.options} series={chartTotalDestinos.series} />
                                        </section>
                                    </>

                                ) : (
                                    <section style={{ height: 300 }}>
                                        <LoaderPantallaVacia label="no hay datos" />
                                    </section>
                                )}

                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader
                                //title="Servicios por mes 2019 - 2020" 
                                title={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography variant="h5">Servicios por mes {past_year} - {actual_year}</Typography>
                                    {/* <Typography variant="h5" style={{ color: totalDiffServiciosAnio <= 0 ? '#DC3545' : '#28A745' }}>
                                    {totalDiffServiciosAnio <= 0 ? '' : '+'}{totalDiffServiciosAnio}
                                    {totalDiffServiciosAnio <= 0

                                        ? <ArrowDropDownIcon style={{ color: '#DC3545' }} />
                                        : <ArrowDropUpIcon style={{ color: '#28A745' }} />
                                    }

                                </Typography> */}
                                </div>}
                            />
                            <CardContent>
                                <section style={{ height: 200 }}>
                                    <Chart type="bar" width="100%" height="100%"
                                        options={chartTotalServiciosPorMesEnAnio.options}
                                        series={chartTotalServiciosPorMesEnAnio.series}
                                    />
                                </section>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                {/* Seteamos espaceado verificando si pantalla es arriba de breakpoint md, usando hooks de MUI */}
                <Grid container item xs={12} sm={12} md={5} spacing={isMdUp ? 0 : 2}>
                    <Grid item xs={12}>
                        <TimelineSeguimientos
                            height={chartCuentasPagarTotalExistentes <= 2
                                ? isXlUp ? 525 : 940
                                : 940
                            }
                            fechaDashboard={fechaDashboard}
                            clienteComercial={selectedClienteComercial}
                            arrayCliente={arrayCliente}
                        />
                    </Grid>
                </Grid>

                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader title={<section style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="h5">Rastreo GPS</Typography>
                                <div>
                                    {mostrarPinesBtn ?
                                        <Button
                                            variant="contained"
                                            disabled={listaServicios?.length === 0 || false}
                                            color="secondary"
                                            onClick={() => setMostrarPines(!mostrarPines)}
                                            startIcon={<RoomIcon />}
                                        >
                                            {mostrarPines ? 'Ocultar' : 'Mostrar'} pines
                                        </Button>
                                        : <></>
                                    }
                                </div>
                            </section>} />
                            <CardContent>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                    <section style={{
                                        width: '350px', height: '65vh', overflowY: 'scroll',
                                    }}>
                                        {bitCargandoRastreoServicioGPS
                                            ?
                                            <div style={{
                                                width: '100%', height: '100%',
                                                display: 'flex', justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                            >
                                                <CircularProgress color="secondary" />
                                            </div>
                                            :
                                            listaServicios?.length === 0
                                                ? <LoaderPantallaVacia label="No hay servicios activos" />
                                                :
                                                <>
                                                    {listaServicios.map(servicio => (<div key={servicio.dblPedido} className={`tarjeta-servicio`} id={`tarjeta-servicio-${servicio.dblPedido}`} style={{ borderLeft: `solid ${servicio.strColor} 3px` }} idpedido={servicio.dblPedido} onClick={handleClick_seleccionTarjeta} >
                                                        <div className="texto-12" style={{ paddingLeft: "25px" }} title="Click para abrir servicio">{servicio.strNumeroServicio}</div>
                                                        <p title="Unidad asignada" className="texto-8">
                                                            <i className="fa fa-truck" style={{ width: "25px" }}></i><strong>{intl.formatMessage({ id: "MAPA.LABEL.UNIDAD" })}:</strong> {servicio.strNumeroPlaca}
                                                        </p>
                                                        <p title="Origen" className="texto-8">
                                                            <i className="fas fa-map-marker-alt" style={{ width: "25px" }}></i><strong>{intl.formatMessage({ id: "MAPA.LABEL.ORIGEN" })}:</strong> <span>{servicio.strCiudadOrigen}</span>
                                                        </p>
                                                        <p title="Destino" className="texto-8">
                                                            <i className="fas fa-map-marker-alt" style={{ width: "25px" }}></i><strong>{intl.formatMessage({ id: "MAPA.LABEL.DESTINO" })}:</strong> <span>{servicio.strCiudadDestino}</span>
                                                        </p>
                                                        <p title="Fecha servicio" className="texto-8">
                                                            <i className="fa fa-calendar" style={{ width: "25px" }}></i><strong>{intl.formatMessage({ id: "MAPA.LABEL.FECHA_SERVICIO" })}:</strong> <span>{servicio.datFechaAlta}</span>
                                                        </p>
                                                    </div>)
                                                    )}
                                                    {
                                                        (bitServicioActivoRuta && listaServicios?.length > 1)
                                                            ?
                                                            <div style={{ width: '100%', textAlign: 'center', marginTop: '10px' }}>
                                                                <Button
                                                                    variant="contained"
                                                                    disabled={bitCargandoPagina}
                                                                    color="secondary"
                                                                    onClick={() => fn_retornarServiciosRastreo()}
                                                                    startIcon={<SettingsBackupRestoreIcon />}
                                                                >
                                                                    Mostrar todos los servicios
                                                                </Button>
                                                            </div>
                                                            : <></>
                                                    }
                                                </>
                                        }

                                    </section>
                                    <section style={{ position: 'relative', height: '65vh', width: 'calc(100% - 350px)' }}>
                                        {bitCargandoRastreoServicioGPS
                                            ?
                                            <Skeleton style={{ width: '100%', height: '100%', transform: 'translateY(-0px)' }} />
                                            :
                                            <MapaServicios
                                                mapaStyle={{ height: '65vh' }}
                                                handlePeticionRecargarListaServicios={reloadData}
                                                dblPedidoSeleccionado={dblPedidoSeleccionado}
                                                listaServicios={listaServicios}
                                                intl={intl}
                                                mostrarPins={mostrarPines}
                                            />
                                        }

                                    </section>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        )}

    </>
}

export default injectIntl(DashboardPage);