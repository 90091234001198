import React, { useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles';
import {Typography, Grid, Popover, ClickAwayListener, colors } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
  },
}));

const gris = 'rgb(87, 117, 144)';
const amarillo = 'rgb(249, 199, 79)';
const verde = 'rgb(0, 227, 150)';

function PopoverIndicadorPorFacturar(props) {

    const {intl, dblPorcentajePorFacturar, dblPedido} = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [color, setColor] = useState(gris);

    const handlePopoverOpen = (event) => {
        if(anchorEl == null) {
            setAnchorEl(event.currentTarget);
        } else {
            setAnchorEl(null);
        }
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if(Number(dblPorcentajePorFacturar) < 1) {
            setColor(verde);
        } else if(Number(dblPorcentajePorFacturar) < 100) {
            setColor(amarillo);
        } else {
            setColor(gris);
        }
    }, [dblPorcentajePorFacturar])

    const open = Boolean(anchorEl);
    const id = `popover-${dblPedido}`;
    
    return <div  style={{width: '100%', height:'100%', textAlign:'center', position: 'relative'}} aria-describedby={id} >
            <div  style={{width: '25px', height:'25px', backgroundColor: color, margin: 'auto', borderRadius: '50%'}} aria-describedby={id} onClick={handlePopoverOpen}></div>
            <Popover
                id={id}
                
                classes={{paper: classes.paper,}}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{vertical: 'bottom',horizontal: 'left',}}
                transformOrigin={{vertical: 'top',horizontal: 'left',}}
                // onClick={handlePopoverClose}
            >
                <Grid container spacing={1} style={{width:'250px'}} >
                    <Grid item ><Typography >{intl.formatMessage({ id: 'SERVICIOS.INDICADOR_PAGOS.TITULO_POR_FACTURAR' })}</Typography></Grid>
                    <Grid container item spacing={1} style={{paddingLeft:'15px'}} >
                        <Grid item ><div style={{width:'20px', height:'20px', backgroundColor: gris, borderRadius: '50%'}}></div></Grid>
                        <Grid item ><Typography >{intl.formatMessage({ id: 'SERVICIOS.INDICADOR_PAGOS.POR_FACTURAR' })}</Typography></Grid>
                    </Grid>

                    <Grid container item spacing={1} style={{paddingLeft:'15px'}} >
                        <Grid item ><div style={{width:'20px', height:'20px', backgroundColor: amarillo, borderRadius: '50%'}}></div></Grid>
                        <Grid item ><Typography >{intl.formatMessage({ id: 'SERVICIOS.INDICADOR_PAGOS.FACTURADO_PARCIAL' })}</Typography></Grid>
                    </Grid>

                    <Grid container item spacing={1} style={{paddingLeft:'15px'}} >
                        <Grid item ><div style={{width:'20px', height:'20px', backgroundColor: verde, borderRadius: '50%'}} ></div></Grid>
                        <Grid item ><Typography >{intl.formatMessage({ id: 'SERVICIOS.INDICADOR_PAGOS.FACTURADO_TOTAL' })}</Typography></Grid>
                    </Grid>
                </Grid>
            </Popover>
        </div>
}

export default injectIntl(PopoverIndicadorPorFacturar);