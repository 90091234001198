export default function setupAxios(axios, Auth) {

  axios.interceptors.request.use(
    async config => {

      const auth = await  Auth.currentAuthenticatedUser();
      const authToken = auth?.signInUserSession?.accessToken?.jwtToken

      if (authToken) {
        config.headers.Authorization = `${authToken}`;
      }

      //? Seteamos X-API-KEY para controlar trafico en AWS
      config.headers['x-api-key'] = process.env.REACT_APP_AWS_API_X_API_KEY;
      config.headers['x-payload'] = JSON.stringify({ ...config.data })

      return config;
    },
    err => Promise.reject(err)
  );

  axios.interceptors.response.use(response => {
    return response;
  }, error => {
    if (error.response?.status === 403) {
      window.location.replace('/logout');
    }
    throw error;
  });
}
