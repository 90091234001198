import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, Paper, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Typography } from '@material-ui/core'
import { FormControl, InputLabel, Input, InputAdornment, IconButton, makeStyles, TextField, Link, Grow } from '@material-ui/core'
import { LinearProgress, Hidden } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import { Select, MenuItem } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';
import Pagination from '@material-ui/lab/Pagination';
import { injectIntl, FormattedMessage } from "react-intl";
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import FWDModalServicio from 'app/componentes/FWDModalServicio'
import RoomIcon from "@material-ui/icons/Room";
import { markerColors } from 'app/helpers/UIHelper'
import moment from 'moment'
import { debounce } from 'lodash'
import PopoverIndicadorPagoServicio from 'app/componentes/servicios/PopoverIndicadorPagoServicio'
import PopoverIndicadorPorFacturar from 'app/componentes/servicios/PopoverIndicadorPorFacturar'
import PopoverIndicadorDocumentosPorSubir from 'app/componentes/servicios/PopoverIndicadorDocumentosPorSubir'
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import locale_es from "date-fns/locale/es";
import { LoaderDashboardTables } from 'app/componentes/dashboard/LoaderDashboard'

import { FWDCabecera, FWDTableHead, FWDOrderHelper } from 'app/componentes/FWDTableHead'
import ScrollContainer from 'react-indiana-drag-scroll'

const useInputStyles = makeStyles(theme => ({
    input: {
        textAlign: 'left'
    }
}))

const useTableRowStyles = makeStyles(theme => ({
    hover: {
        cursor: 'pointer'
    }
}));

const busquedaAvanzadaDefaultState = {
    origen: null,
    destino: null,
    estatus: '0',
    fechaInicio: moment().subtract(30, 'days').format(),
    fechaFin: moment().format(),
    varCliente: '0',
    filtroFechas: 'datCarga'
};

const catalogoFiltrosFechaDefaultState = [{ value: 'datCarga', text: "Fecha carga" }, { value: 'datDescarga', text: "Fecha descarga" }];

function ConsultarServicios(props) {
    const history = useHistory();

    const { intl } = props;
    const { session, selectedClienteComercial } = useSelector(state => state.auth);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [listaVarClientes, setListaVarClientes] = useState([]);
    const [cargandoServicios, setCargandoServicios] = useState(true);
    const [totalPagina, setTotalPagina] = useState(25);

    const [servicios, setServicios] = useState(new Array(totalPagina).fill(null));
    const [cuentasEspejo, setCuentasEspejo] = useState(new Array(totalPagina).fill(null));
    const [numeroPaginas, setNumeroPaginas] = useState(0);
    const [paginaActual, setPaginaActual] = useState(1);
    const [modalServicio, setModalServicio] = useState({ dblPedido: null, open: false })

    const [txtBusqueda, setTxtBusqueda] = useState('');
    const [bitBusquedaAvanzada, setBitBusquedaAvanzada] = useState(false)

    const [catalogoEstatusPedido, setCatalogoEstatusPedido] = useState([]);
    const [catalogoFiltrosFecha, setCatalogoFiltrosFecha] = useState(catalogoFiltrosFechaDefaultState);
    const [catalogoVarClientes, setCatalogoVarClientes] = useState([]);

    const [catalogoCiudadesOrigen, setCatalogoCiudadesOrigen] = useState([])
    const [cargandoCiudadOrigen, setCargandoCiudadOrigen] = useState(false);
    const [catalogoCiudadesDestino, setCatalogoCiudadesDestino] = useState([])
    const [cargandoCiudadDestino, setCargandoCiudadDestino] = useState(false)

    const [busquedaAvanzada, setBusquedaAvanzada] = useState(busquedaAvanzadaDefaultState)

    const [listaOrdenamiento, setListaOrdenamiento] = useState([])
    const [resetOrderTable, setResetOrderTable] = useState(null)

    const inputClasses = useInputStyles();
    const tableRowClasses = useTableRowStyles();

    // const UIMarkerColors = markerColors();

    const fetchServicios = async (_listaVarClientes, _pagina, _totalPagina, _filtro = false, _busquedaAvanzada = false, ordenamiento = []) => {

        try {
            setCargandoServicios(true);

            let apiUrl = `${process.env.REACT_APP_AWS_API_BASE_URL}/portal/servicios/consultar/api/listaservicios`;
            apiUrl += `?pagina=${encodeURIComponent(_pagina - 1)}&totalPagina=${encodeURIComponent(_totalPagina)}`



            if (_filtro) {
                const filtros = txtBusqueda.split(',');
                filtros.forEach(x => apiUrl += `&filtro=${encodeURIComponent(x.trim())}`)
            }

            if (_busquedaAvanzada) {

                apiUrl += '&ba_bitBusquedaAvanzada=1'

                if (!!busquedaAvanzada.origen)
                    apiUrl += '&ba_intCiudadOrigen=' + busquedaAvanzada.origen.intCiudad

                if (!!busquedaAvanzada.destino)
                    apiUrl += '&ba_intCiudadDestino=' + busquedaAvanzada.destino.intCiudad

                if (busquedaAvanzada.estatus !== '0')
                    apiUrl += '&ba_intEstatus=' + busquedaAvanzada.estatus

                if (!!busquedaAvanzada.filtroFechas)
                    apiUrl += '&ba_strTipoFiltroFecha=' + busquedaAvanzada.filtroFechas

                if (!!busquedaAvanzada.fechaInicio)
                    apiUrl += '&ba_datFechaInicio=' + busquedaAvanzada.fechaInicio?.substring(0, 10)

                if (!!busquedaAvanzada.fechaFin)
                    apiUrl += '&ba_datFechaFin=' + busquedaAvanzada.fechaFin?.substring(0, 10)

                if (busquedaAvanzada.varCliente === '0')
                    // _listaVarClientes.forEach(varCliente => apiUrl += `&varCliente=${varCliente}`)
                    apiUrl += `&varCliente=${selectedClienteComercial}`
                else
                    apiUrl += `&varCliente=${busquedaAvanzada.varCliente}`
            }
            else {
                apiUrl += `&varCliente=${selectedClienteComercial}`
            }

            const strOrdenamiento = FWDOrderHelper(ordenamiento)

            if (strOrdenamiento.length) apiUrl += '&ordenamiento=' + strOrdenamiento

            const response = await axios.get(apiUrl)
            if (response.data) {
                let listaServicios = response.data.servicios.map((servicio) => {
                    servicio.porcentajesPago = JSON.parse(servicio.jsonPorcentajes);
                    servicio.cuentasEspejo = JSON.parse(servicio.jsonCuentasEspejo);
                    return servicio;
                });

                setServicios(listaServicios);

                setNumeroPaginas(Math.ceil(response.data.totalServicios / _totalPagina));
            }
            else {
                setServicios([]);
                setNumeroPaginas(0);
            }
        }
        catch (error) {
            enqueueSnackbar(intl.formatMessage({ id: "SERVICIOS.CONSULTA.ERROR.ERROR_AL_OBTENER_SERVICIOS" }), {
                variant: 'error',
                persist: true,
                onClick: () => closeSnackbar()
            })
            console.error('Error fetch servicios: ', error);
        }
        finally {
            setCargandoServicios(false);
        }

    }

    const fetchCatalogoEstatusPedido = async () => {
        try {
            let apiUrl = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/servicios/consultar/api/catalogo-estatus-pedido'
            const response = await axios.get(apiUrl)
            setCatalogoEstatusPedido(response.data.estatus)
        }
        catch (error) {
            console.log('ERROR | fetchCatalogoEstatusPedido ', error);
        }
    }

    const fetchCatalogoCiudades = async (cual = 'O', strCiudad) => {
        if (cual === 'O')
            setCargandoCiudadOrigen(true)
        if (cual === 'D')
            setCargandoCiudadDestino(true);

        strCiudad = strCiudad.trim();
        let api_url = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/servicios/consultar/api/catalogo-ciudades';
        api_url += '?strCiudad=' + strCiudad || '';

        try {
            const response = await axios.get(api_url);
            const ciudades = response.data.ciudades;
            if (cual === 'O')
                setCatalogoCiudadesOrigen(ciudades);
            if (cual === 'D')
                setCatalogoCiudadesDestino(ciudades)
        }
        catch (error) {
            console.log('error', error);
        }
        finally {
            if (cual === 'O')
                setCargandoCiudadOrigen(false)
            if (cual === 'D')
                setCargandoCiudadDestino(false);
        }
    }

    const handle_OnItemClick_openModal = (event, dblPedido) => {
        if (event.target)
            if (event.target.classList.contains("no-abrir-modal"))
                return;

        setModalServicio({ open: true, dblPedido: dblPedido })
    };

    const debounceFetchCatalogoCiudades = debounce(fetchCatalogoCiudades, 750);

    useEffect(() => {
        const _sessionVarClientes = session.listaVarClientes;
        setCatalogoVarClientes(_sessionVarClientes);
        const _listaVarClientes = _sessionVarClientes.map(x => x.strVarClienteOrigen);
        setListaVarClientes(_listaVarClientes);
        fetchServicios(_listaVarClientes, paginaActual, totalPagina);
        fetchCatalogoEstatusPedido()
    }, [selectedClienteComercial])

    useEffect(() => {
        if (bitBusquedaAvanzada)
            fetchServicios(listaVarClientes, 1, totalPagina, true, true)
    }, [JSON.stringify(busquedaAvanzada)])

    const colspanTablaServicios = listaVarClientes.length > 1 ? 12 : 11

    return <>
        <FWDModalServicio serviciosFilter={servicios} bitOpen={modalServicio.open} dblPedido={modalServicio.dblPedido}
            fnClose={() => { setModalServicio({ open: false, dblPedido: null }) }}
        />

        <Grid container direction="column" xl={12} justify="center" style={{ height: '100%', display: 'initial' }}>

            <Grid item component={Paper} elevation={4} container direction="row" justify="space-between" alignItems="center"
                style={{ padding: '15px 0', position: 'sticky', top: '70px', marginBottom: '25px', zIndex: 2 }}>

                <Grid item container lg={6} style={{ padding: '0 15px' }}>
                    {/* <TextField id="filled-search" label="Search field" type="search" fullWidth={true} endAd/> */}
                    <FormControl style={{ width: 'calc(100% - 50px)' }}>
                        <InputLabel disabled={cargandoServicios}>{intl.formatMessage({ id: 'SERVICIOS.CONSULTA.TXT.BUSCAR_SERVICIOS' })}</InputLabel>
                        <Input
                            disabled={cargandoServicios}
                            classes={inputClasses}
                            type='text'
                            value={txtBusqueda}
                            onChange={event => setTxtBusqueda(event.target.value)}
                            onKeyUp={event => {
                                if (event.key.toLowerCase() === 'enter') {
                                    setPaginaActual(1);
                                    setResetOrderTable(Date.now())
                                    fetchServicios(listaVarClientes, 1, totalPagina, true)
                                }
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    {txtBusqueda.length > 0
                                        ? <IconButton
                                            disabled={cargandoServicios}
                                            aria-label="search services"
                                            onClick={(event) => {
                                                setTxtBusqueda('');
                                                setPaginaActual(1);
                                                setResetOrderTable(Date.now())
                                                fetchServicios(listaVarClientes, 1, totalPagina)
                                                setBitBusquedaAvanzada(false)
                                            }}
                                        >
                                            <HighlightOffIcon />
                                        </IconButton>
                                        : <></>}
                                    <IconButton
                                        disabled={cargandoServicios}
                                        aria-label="search services"
                                        onClick={(event) => {
                                            setPaginaActual(1);
                                            setResetOrderTable(Date.now())
                                            fetchServicios(listaVarClientes, 1, totalPagina, true)
                                        }}
                                    // onMouseDown={handleMouseDownPassword}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl style={{ width: '50px' }}>
                        <InputLabel>Total</InputLabel>
                        <Select
                            disabled={cargandoServicios}
                            classes={inputClasses}
                            value={totalPagina}
                            onChange={event => {
                                setPaginaActual(1);
                                setTotalPagina(event.target.value)
                                fetchServicios(listaVarClientes, 1, event.target.value, txtBusqueda.length > 0, bitBusquedaAvanzada, listaOrdenamiento)
                            }}
                        >
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                            <MenuItem value={250}>250</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item lg={6} style={{ padding: '15px' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                        <Pagination count={numeroPaginas} variant="outlined"
                            disabled={cargandoServicios}
                            page={paginaActual}
                            onChange={(event, value) => {
                                setPaginaActual(value);
                                fetchServicios(listaVarClientes, value, totalPagina, txtBusqueda.length > 0, bitBusquedaAvanzada, listaOrdenamiento)
                                // document.getElementById('root').scrollIntoView({ behavior: 'smooth', block: 'start' });
                                // This prevents the page from scrolling down to where it was previously.
                                if ('scrollRestoration' in history) {
                                    history.scrollRestoration = 'manual';
                                }
                                // This is needed if the user scrolls down during page load and you want to make sure the page is scrolled to the top once it's fully loaded. This has Cross-browser support.
                                window.scrollTo(0, 0);
                            }}
                        />
                    </div>
                </Grid>

                {bitBusquedaAvanzada
                    ?
                    <Grid item lg={6} style={{ padding: "0 15px", display: 'flex', flexDirection: 'column' }}>
                        <Link
                            disabled={cargandoServicios}
                            onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                setBitBusquedaAvanzada(false)
                                setBusquedaAvanzada(busquedaAvanzadaDefaultState)
                                setResetOrderTable(Date.now())
                                fetchServicios(listaVarClientes, 1, totalPagina, true)
                            }}>
                            <Typography color="error" href="/servicios/consultar" variant="overline">{intl.formatMessage({ id: 'SERVICIOS.CONSULTA.BOTON.CANCELAR_BUSQUEDA_AVANZADA' })}</Typography>
                        </Link>
                    </Grid>
                    :
                    <Grid item lg={6} style={{ padding: "0 15px" }}>
                        <Link
                            disabled={cargandoServicios}
                            onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                // This prevents the page from scrolling down to where it was previously.
                                if ('scrollRestoration' in history) {
                                    history.scrollRestoration = 'manual';
                                }
                                // This is needed if the user scrolls down during page load and you want to make sure the page is scrolled to the top once it's fully loaded. This has Cross-browser support.
                                window.scrollTo(0, 0);
                                setBitBusquedaAvanzada(true)
                                setResetOrderTable(Date.now())
                                fetchServicios(listaVarClientes, 1, totalPagina, true, true);
                            }}>
                            <Typography color="primary" variant="overline">{intl.formatMessage({ id: 'SERVICIOS.CONSULTA.BOTON.BUSQUEDA_AVANZADA' })}</Typography>
                        </Link>
                    </Grid>}
            </Grid>
            {bitBusquedaAvanzada
                ?
                <Grow in>
                    <Grid item component={Paper} style={{ padding: '15px', marginBottom: 15, display: 'flex', flexDirection: 'column' }}>
                        <Hidden mdDown>
                            <section style={{ display: 'flex', width: '100%' }}>
                                <div style={{ display: 'flex', width: 'calc(100% - 400px)' }}>

                                    <Autocomplete
                                        autoSelect
                                        autoComplete
                                        label="Origen"
                                        style={{ width: '50%' }}
                                        loading={cargandoCiudadOrigen}
                                        disabled={cargandoServicios}
                                        loadingText={
                                            <section style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography variant="overline">
                                                    {intl.formatMessage({ id: '_CARGANDO' })}
                                                </Typography>
                                                <LinearProgress />
                                            </section>
                                        }
                                        options={catalogoCiudadesOrigen}
                                        getOptionLabel={(origen) => origen.strCiudad}
                                        noOptionsText="Sin opciones"
                                        renderInput={(params) => <TextField {...params}
                                            label="Origen"
                                            onFocus={event => event.target.autocomplete = 'new-password'} // Hack para evitar autofill de Chrome
                                            onClick={event => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                            }}
                                            onChange={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                debounceFetchCatalogoCiudades('O', event.target.value)
                                            }}
                                        />
                                        }
                                        onChange={(event, ciudad) => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            setBusquedaAvanzada({ ...busquedaAvanzada, origen: ciudad })
                                            return event;
                                        }}
                                    />


                                    <Autocomplete
                                        autoSelect
                                        autoComplete
                                        label="Destino"
                                        style={{ width: '50%' }}
                                        loading={cargandoCiudadDestino}
                                        disabled={cargandoServicios}
                                        loadingText={
                                            <section style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography variant="overline">
                                                    {intl.formatMessage({ id: '_CARGANDO' })}
                                                </Typography>
                                                <LinearProgress />
                                            </section>
                                        }
                                        options={catalogoCiudadesDestino}
                                        getOptionLabel={(destino) => destino.strCiudad}
                                        noOptionsText="Sin opciones"
                                        renderInput={(params) => <TextField {...params}
                                            label="Destino"
                                            onFocus={event => event.target.autocomplete = 'new-password'} // Hack para evitar autofill de Chrome
                                            onClick={event => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                            }}
                                            onChange={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                debounceFetchCatalogoCiudades('D', event.target.value)
                                            }}
                                        />
                                        }
                                        onChange={(event, ciudad) => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            setBusquedaAvanzada({ ...busquedaAvanzada, destino: ciudad })
                                            return event;
                                        }}
                                    />
                                </div>

                                <div style={{ width: 250 }}>
                                    <FormControl fullWidth disabled={cargandoServicios}>
                                        <InputLabel>Estatus</InputLabel>
                                        <Select native
                                            value={busquedaAvanzada.estatus}
                                            onChange={e => setBusquedaAvanzada({ ...busquedaAvanzada, estatus: e.target.value })}
                                        >
                                            <option value="0">Todos</option>
                                            {catalogoEstatusPedido.map((x, k) => <option key={k} value={x.intEstatusPedido}>{x.strEstatusPedido}</option>)}
                                        </Select>
                                    </FormControl>
                                </div>

                                <div style={{ width: 250 }}>
                                    <FormControl fullWidth disabled={cargandoServicios}>
                                        <InputLabel>Filtro fecha</InputLabel>
                                        <Select native
                                            value={busquedaAvanzada.filtroFechas}
                                            onChange={e => setBusquedaAvanzada({ ...busquedaAvanzada, filtroFechas: e.target.value })}
                                        >
                                            {catalogoFiltrosFecha.map((x, k) => <option key={k} value={x.value}>{x.text}</option>)}
                                        </Select>
                                    </FormControl>
                                </div>

                                <div style={{ width: 125 }}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale_es}>
                                        <Grid container direction="row">
                                            <DatePicker
                                                disabled={cargandoServicios}
                                                disableToolbar
                                                autoOk
                                                variant="inline"
                                                format="dd/MMM/yyyy"
                                                label="Fecha inicio"
                                                style={{ width: 125 }}
                                                value={busquedaAvanzada.fechaInicio}
                                                onChange={date => setBusquedaAvanzada({ ...busquedaAvanzada, fechaInicio: moment(date).format() })}
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </div>

                                <div style={{ width: 125 }}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale_es}>
                                        <Grid container direction="row">
                                            <DatePicker
                                                disabled={cargandoServicios}
                                                disableToolbar
                                                autoOk
                                                variant="inline"
                                                format="dd/MMM/yyyy"
                                                label="Fecha fin"
                                                style={{ width: 125 }}
                                                value={busquedaAvanzada.fechaFin}
                                                onChange={date => setBusquedaAvanzada({ ...busquedaAvanzada, fechaFin: moment(date).format() })}
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </div>
                            </section>
                        </Hidden>
                        <Hidden mdUp>
                            <section style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', width: '100%' }}>

                                    <Autocomplete
                                        autoSelect
                                        autoComplete
                                        label="Origen"
                                        style={{ width: '50%' }}
                                        loading={cargandoCiudadOrigen}
                                        disabled={cargandoServicios}
                                        loadingText={
                                            <section style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography variant="overline">
                                                    {intl.formatMessage({ id: '_CARGANDO' })}
                                                </Typography>
                                                <LinearProgress />
                                            </section>
                                        }
                                        options={catalogoCiudadesOrigen}
                                        getOptionLabel={(origen) => origen.strCiudad}
                                        noOptionsText="Sin opciones"
                                        renderInput={(params) => <TextField {...params}
                                            label="Origen"
                                            onFocus={event => event.target.autocomplete = 'new-password'} // Hack para evitar autofill de Chrome
                                            onClick={event => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                            }}
                                            onChange={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                debounceFetchCatalogoCiudades('O', event.target.value)
                                            }}
                                        />
                                        }
                                        onChange={(event, ciudad) => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            setBusquedaAvanzada({ ...busquedaAvanzada, origen: ciudad })
                                            return event;
                                        }}
                                    />


                                    <Autocomplete
                                        autoSelect
                                        autoComplete
                                        label="Destino"
                                        style={{ width: '50%' }}
                                        loading={cargandoCiudadDestino}
                                        disabled={cargandoServicios}
                                        loadingText={
                                            <section style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography variant="overline">
                                                    {intl.formatMessage({ id: '_CARGANDO' })}
                                                </Typography>
                                                <LinearProgress />
                                            </section>
                                        }
                                        options={catalogoCiudadesDestino}
                                        getOptionLabel={(destino) => destino.strCiudad}
                                        noOptionsText="Sin opciones"
                                        renderInput={(params) => <TextField {...params}
                                            label="Destino"
                                            onFocus={event => event.target.autocomplete = 'new-password'} // Hack para evitar autofill de Chrome
                                            onClick={event => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                            }}
                                            onChange={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                debounceFetchCatalogoCiudades('D', event.target.value)
                                            }}
                                        />
                                        }
                                        onChange={(event, ciudad) => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            setBusquedaAvanzada({ ...busquedaAvanzada, destino: ciudad })
                                            return event;
                                        }}
                                    />
                                </div>

                                <div style={{ width: '100%', display: 'flex' }}>
                                    <div style={{ width: '50%' }}>
                                        <FormControl fullWidth disabled={cargandoServicios}>
                                            <InputLabel>Estatus</InputLabel>
                                            <Select native
                                                value={busquedaAvanzada.estatus}
                                                onChange={e => setBusquedaAvanzada({ ...busquedaAvanzada, estatus: e.target.value })}
                                            >
                                                <option value="0">Todos</option>
                                                {catalogoEstatusPedido.map((x, k) => <option key={k} value={x.intEstatusPedido}>{x.strEstatusPedido}</option>)}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div style={{ width: '25%' }}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale_es}>
                                            <Grid container direction="row" style={{ width: '100%' }}>
                                                <DatePicker
                                                    disabled={cargandoServicios}
                                                    disableToolbar
                                                    disableFuture
                                                    autoOk
                                                    variant="inline"
                                                    format="dd/MMM/yyyy"
                                                    label="Fecha inicio"
                                                    value={busquedaAvanzada.fechaInicio}
                                                    onChange={date => setBusquedaAvanzada({ ...busquedaAvanzada, fechaInicio: moment(date).format() })}
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div style={{ width: '25%' }}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale_es}>
                                            <Grid container direction="row" style={{ width: '100%' }}>
                                                <DatePicker
                                                    disabled={cargandoServicios}
                                                    disableToolbar
                                                    disableFuture
                                                    autoOk
                                                    variant="inline"
                                                    format="dd/MMM/yyyy"
                                                    label="Fecha fin"
                                                    value={busquedaAvanzada.fechaFin}
                                                    onChange={date => setBusquedaAvanzada({ ...busquedaAvanzada, fechaFin: moment(date).format() })}
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                            </section>
                        </Hidden>
                        {catalogoVarClientes.length > 1
                            ?
                            <section>
                                <FormControl fullWidth disabled={cargandoServicios}>
                                    <InputLabel>Empresa</InputLabel>
                                    <Select native
                                        value={busquedaAvanzada.varCliente}
                                        onChange={e => setBusquedaAvanzada({ ...busquedaAvanzada, varCliente: e.target.value })}
                                    >
                                        <option value="0">Todas</option>
                                        {catalogoVarClientes.map((x, k) => <option key={k} value={x.strVarClienteOrigen}>{x.strRazonSocial}</option>)}
                                    </Select>
                                </FormControl>
                            </section>
                            : <></>}
                    </Grid>
                </Grow>
                :
                <></>
            }
            <Grid item>
                <TableContainer component={Paper}>
                    <ScrollContainer className="scroll-container" hideScrollbars={false}>
                        <Table>
                            <FWDTableHead
                                cabeceras={[
                                    new FWDCabecera('noServicio', intl.formatMessage({ id: 'SERVICIOS.CONSULTA.CABECERAS_RESULTADOS.NUMERO_SERVICIO' }), { align: 'center', width: 150, style: { minWidth: 150 } }),
                                    new FWDCabecera('noReferencia', intl.formatMessage({ id: 'SERVICIOS.CONSULTA.CABECERAS_RESULTADOS.NUMERO_REFERENCIA' }), { align: 'center', width: 120, style: { minWidth: 120 } }),
                                    new FWDCabecera('noEconomico', intl.formatMessage({ id: 'SERVICIOS.CONSULTA.CABECERAS_RESULTADOS.NUMERO_ECONOMICO' }), { align: 'center', width: 150, style: { minWidth: 150 } }),
                                    ...[listaVarClientes.length > 1 ? new FWDCabecera('nombreComercial', intl.formatMessage({ id: 'SERVICIOS.CONSULTA.CABECERAS_RESULTADOS.NOMBRE_COMERCIAL' }), { align: 'left', style: { minWidth: 120 } }) : null].filter(x => x !== null),
                                    new FWDCabecera('rutaOrigen', intl.formatMessage({ id: 'SERVICIOS.CONSULTA.CABECERAS_RESULTADOS.RUTA_ORIGEN' }), { align: 'left', style: { minWidth: 200 } }),
                                    new FWDCabecera('datOrigen', intl.formatMessage({ id: 'SERVICIOS.CONSULTA.CABECERAS_RESULTADOS.FECHA_HORA_ORIGEN' }), { align: 'left', width: 150, style: { minWidth: 150 } }),
                                    new FWDCabecera('rutaDestino', intl.formatMessage({ id: 'SERVICIOS.CONSULTA.CABECERAS_RESULTADOS.RUTA_DESTINO' }), { align: 'left', style: { minWidth: 200 } }),
                                    new FWDCabecera('datDestino', intl.formatMessage({ id: 'SERVICIOS.CONSULTA.CABECERAS_RESULTADOS.FECHA_HORA_DESTINO' }), { align: 'left', width: 175, style: { minWidth: 175 } }),
                                    new FWDCabecera('estatus', intl.formatMessage({ id: 'SERVICIOS.CONSULTA.CABECERAS_RESULTADOS.ESTATUS' }), { align: 'left', width: 150, style: { minWidth: 150 } }),
                                    new FWDCabecera('', intl.formatMessage({ id: 'SERVICIOS.CONSULTA.CABECERAS_RESULTADOS.INDICADOR_DOCUMENTOS_POR_SUBIR' }), { align: 'center', width: 120, title: intl.formatMessage({ id: 'SERVICIOS.CONSULTA.CABECERAS_RESULTADOS.TITLE.INDICADOR_DOCUMENTOS_POR_SUBIR' }) }, false),
                                    new FWDCabecera('', intl.formatMessage({ id: 'SERVICIOS.CONSULTA.CABECERAS_RESULTADOS.PORCENTAJE_POR_FACTURAR' }), { align: 'center', width: 120 }, false),
                                    new FWDCabecera('', intl.formatMessage({ id: 'SERVICIOS.CONSULTA.CABECERAS_RESULTADOS.ESTATUS_PAGO' }), { align: 'center', width: 120 }, false)
                                ]}
                                fnOnClick={(ordenamiento) => fetchServicios(listaVarClientes, paginaActual, totalPagina, txtBusqueda.length > 0, bitBusquedaAvanzada, ordenamiento)}
                                fnSetOrdenamiento={setListaOrdenamiento}
                                disabled={cargandoServicios}
                                reset={resetOrderTable}
                            />
                            <TableBody>
                                {cargandoServicios ? (
                                    <TableRow>
                                        <TableCell scope="row" colSpan={colspanTablaServicios}>
                                            <LoaderDashboardTables />
                                        </TableCell>
                                    </TableRow>
                                )
                                    : servicios.length ? servicios.map(servicio => (
                                        <TableRow key={servicio.dblPedido} hover={true} classes={tableRowClasses}>
                                            <TableCell align="center" onClick={(event) => { handle_OnItemClick_openModal(event, servicio.dblPedido) }}>{servicio.strNumeroServicio}</TableCell>
                                            <TableCell align="center" onClick={(event) => { handle_OnItemClick_openModal(event, servicio.dblPedido) }}>{servicio.strNumeroReferencia}</TableCell>
                                            <TableCell align="center" onClick={(event) => { handle_OnItemClick_openModal(event, servicio.dblPedido) }}>{servicio.strNoEconomico}</TableCell>
                                            {listaVarClientes.length > 1
                                                ? <TableCell align="left" onClick={(event) => { handle_OnItemClick_openModal(event, servicio.dblPedido) }}>{servicio.strNombreComercial}</TableCell>
                                                : <></>
                                            }
                                            <TableCell align="left" onClick={(event) => { handle_OnItemClick_openModal(event, servicio.dblPedido) }}>{servicio.strRutaOrigen}</TableCell>
                                            <TableCell align="left" onClick={(event) => { handle_OnItemClick_openModal(event, servicio.dblPedido) }}>{servicio.datFechaHoraOrigen}</TableCell>
                                            <TableCell align="left" onClick={(event) => { handle_OnItemClick_openModal(event, servicio.dblPedido) }}>{servicio.strRutaDestino}</TableCell>
                                            <TableCell align="left" onClick={(event) => { handle_OnItemClick_openModal(event, servicio.dblPedido) }}>{servicio.datFechaHoraDestino}</TableCell>
                                            <TableCell align="left" onClick={(event) => { handle_OnItemClick_openModal(event, servicio.dblPedido) }}>{servicio.strEstatusPedido}</TableCell>
                                            <TableCell align="center" style={{ width: '115px' }} className="no-abrir-modal"><PopoverIndicadorDocumentosPorSubir {...servicio} /></TableCell>
                                            <TableCell align="center" style={{ width: '115px' }} className="no-abrir-modal"><PopoverIndicadorPorFacturar {...servicio.porcentajesPago} /></TableCell>
                                            <TableCell align="center" style={{ width: '115px' }} className="no-abrir-modal"><PopoverIndicadorPagoServicio {...servicio.porcentajesPago} /></TableCell>
                                        </TableRow>
                                    )) : <TableRow>
                                        <TableCell colSpan={colspanTablaServicios}>
                                            <section style={{ display: 'flex', justifyContent: 'center' }}>
                                                <FormattedMessage id="SERVICIOS.CONSULTA.TXT.SIN_REGISTROS" />
                                            </section>
                                        </TableCell>
                                    </TableRow>}
                            </TableBody>
                        </Table>
                    </ScrollContainer>
                </TableContainer>
            </Grid>
        </Grid>
    </>

}

export default injectIntl(ConsultarServicios);