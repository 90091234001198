/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { UserProfileDropdown } from "./dropdowns/UserProfileDropdown";

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export function QuickUserToggler() {
  const { user } = useSelector(state => state.auth);

  // Control de Media Queries
  const theme = useTheme()
  // const isMdUp = useMediaQuery(theme.breakpoints.up('md'), { defaultMatches: true })
  // const isXlUp = useMediaQuery(theme.breakpoints.up('xl'), { defaultMatches: true })
  // const isSmDown = useMediaQuery(theme.breakpoints.down('sm'), { defaultMatches: true })

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
    };
  }, [uiService]);

  // console.log(user);

  return (<>
    {layoutProps.offcanvas && (<OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id="quick-user-tooltip">Perfil</Tooltip>}
    >
      <div className="topbar-item">
        <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
          id="kt_quick_user_toggle">
          <>

            {/* <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span> */}
            <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
              {user.attributes ? user.attributes['email'] : ''}
            </span>
            {/* {isSmDown
              ?
              < span className="symbol symbol-35 symbol-light-success">
                <span className="symbol-label font-size-h5 font-weight-bold">{user.attributes['custom:username']}</span>
              </span>

              : <></>} */}
          </>
        </div>
      </div>
    </OverlayTrigger>)
    }

    {/* { !layoutProps.offcanvas && (<UserProfileDropdown />)} */}
  </>
  );
}
