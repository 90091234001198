import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import { Auth } from 'aws-amplify'
import { useIdleTimer } from 'react-idle-timer'
import moment from 'moment'

export default function App({ store, persistor, basename }) {
  const PLA_IDLE = Number(process.env.REACT_APP_FWD_PORTAL_LAST_ACTIVE_IDLE_MINS || 30)
  const PLA_TIMEOUT = Number(process.env.REACT_APP_FWD_PORTAL_LAST_ACTIVE_IDLE_TIMEOUT || 60000)
  const PLA_DEBOUNCE = Number(process.env.REACT_APP_FWD_PORTAL_LAST_ACTIVE_IDLE_DEBOUNCE || 500)

  const handleOnIdle = event => {
    const dtLastActive = getLastActiveTime()
    localStorage.setItem('fwd-portal-last-active', dtLastActive)
  }

  const handleOnActive = async event => {
    const mtnow = moment(Date.now())
    const mtlac = moment(Number(localStorage.getItem('fwd-portal-last-active')))
    const totalIdleMins = mtnow.diff(mtlac, 'minutes')

    const userSession = await Auth.currentUserInfo()

    console.log('on Active!!')

    if ((totalIdleMins > PLA_IDLE || userSession === null) && !window.location.pathname.toLowerCase().includes('/auth'))
      window.location.replace('/logout')
  }

  const { getLastActiveTime } = useIdleTimer({
    timeout: PLA_TIMEOUT,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: PLA_DEBOUNCE
  })

  window.addEventListener('beforeunload', handleOnIdle)

  return (
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                {/* Render routes with provided `Layout`. */}
                <Routes />
              </I18nProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
    /* Provide Redux store */
  );
}
