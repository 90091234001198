import React, { useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { FileIcon, defaultStyles } from 'react-file-icon';

import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux'
import axios from 'axios'

import { TextField, Input, Grid, Card, Typography, CardContent, Paper, Tooltip, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'; //Modals

import { Skeleton, Autocomplete } from '@material-ui/lab';
import { debounce } from 'lodash';
import ModalsComponent from 'app/componentes/uiFwdElements/ModalsComponent';
import { LoaderDashboardTables, LoaderPantallaVacia } from 'app/componentes/dashboard/LoaderDashboard'
import { useSnackbar } from 'notistack';
import Loading from 'app/componentes/Loading';

//Iconos
import SearchIcon from '@material-ui/icons/Search';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DescriptionIcon from '@material-ui/icons/Description';
import CloseIcon from '@material-ui/icons/Close';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AddCircleOutlineIIcon from '@material-ui/icons/AddCircleOutline';
//Estilos
import useStyles from 'app/componentes/servicios/serviciosEstilos/serviciosEstilos';

const fetchConsultarCotizaciones = async (idCliente) => {

    let api_url = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/servicios/solicitudes/api/consultarcotizaciones';
    api_url += `?varCliente=${idCliente}`;
    try {
        const response = await axios.get(api_url);

        const lstResponse = response.data.cotizaciones;
        console.log("lstResponse", lstResponse)
        return lstResponse;
    }
    catch (error) {
        console.log('error', error);
    }
}

function formatDateTime(date) {

    const originalDate = date;

    const months = [
        'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
        'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'
    ];

    const dateObject = new Date(originalDate);

    const day = dateObject.getUTCDate();
    const month = months[dateObject.getUTCMonth()];
    const year = dateObject.getUTCFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
}

const columns = [
    { id: 'folio', label: 'Folio' },
    { id: 'cliente', label: 'Cliente' },
    { id: 'fecha', label: 'Fecha de Cotización' },
    { id: 'producto', label: 'Tipo de Mercancía' },
    { id: 'descripcion', label: 'Descripción' },
    { id: 'ejecutivo', label: 'Ejecutivo' },
    { id: 'estatus', label: 'Estatus' },
    { id: '', label: 'Detalle' },
];

const ConsultarCotizaciones = ({ }) => {
    const classes = useStyles();
    const docStyles = defaultStyles;
    docStyles['xml'] = { type: 'document', glyphColor: '#000000', labelColor: '#000000' };

    const { session, selectedClienteComercial } = useSelector(state => state.auth);
    const [cotizaciones, setCotizaciones] = useState([]);
    const idCliente = selectedClienteComercial;
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('fecha');
    const [loading, setLoading] = useState(true);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);

    const [uniqueProducts, setUniqueProducts] = useState([]);
    const [uniqueStatus, setUniqueStatus] = useState([]);
    const [uniqueEjecutivos, setUniqueEjecutivos] = useState([]);
    const [loading_isOpen, setLoading_isOpen] = useState(false);
    //Filtros
    const [filterStatus, setFilterStatus] = useState('');
    const [filterProduct, setFilterProduct] = useState('');
    const [filterEjecutivo, setFilterEjecutivo] = useState('');
    const [searchText, setSearchText] = useState('');
    const uniqueSet = new Set();
    const uniqueArray = [];
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleSort = (columnId) => {
        const isAsc = orderBy === columnId && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(columnId);
    };

    //Sortear datos
    const sortedCotizaciones = [...cotizaciones].sort((a, b) => {
        const isAsc = order === 'asc';

        switch (orderBy) {
            case 'folio':
                return isAsc
                    ? a.idCotVenta - b.idCotVenta
                    : b.idCotVenta - a.idCotVenta;
            case 'cliente':
                return isAsc
                    ? a.cliente.localeCompare(b.cliente)
                    : b.cliente.localeCompare(a.cliente);
            case 'producto':
                return isAsc
                    ? a.productoTransportar.localeCompare(b.productoTransportar)
                    : b.productoTransportar.localeCompare(a.productoTransportar);
            case 'descripcion':
                return isAsc
                    ? a.descTransportar.localeCompare(b.descTransportar)
                    : b.descTransportar.localeCompare(a.descTransportar);
            case 'fecha':
                return isAsc ? new Date(a.fechaRegistro) - new Date(b.fechaRegistro) : new Date(b.fechaRegistro) - new Date(a.fechaRegistro);
            case 'ejecutivo':
                return isAsc
                    ? a.nombreCompleto.localeCompare(b.nombreCompleto)
                    : b.nombreCompleto.localeCompare(a.nombreCompleto);
            case 'estatus':
                return isAsc
                    ? a.estatusCotizacion.localeCompare(b.estatusCotizacion)
                    : b.estatusCotizacion.localeCompare(a.estatusCotizacion);
            default:
                return 0;
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await fetchConsultarCotizaciones(idCliente);
                setCotizaciones(response);

                const productSet = new Set(response.map(item => item.productoTransportar));
                const statusSet = new Set(response.map(item => item.estatusCotizacion));
                const ejecutivoSet = new Set(response.map(item => item.nombreCompleto));

                setUniqueProducts(Array.from(productSet));
                setUniqueStatus(Array.from(statusSet));
                setUniqueEjecutivos(Array.from(ejecutivoSet));

                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [selectedClienteComercial]);

    const handleStatusChange = (event) => {
        setFilterStatus(event.target.value);
    };

    const handleProductChange = (event) => {
        setFilterProduct(event.target.value);
    };

    const handleEjecutivoChange = (event) => {
        setFilterEjecutivo(event.target.value);
    };

    const handleSearchChange = debounce((text) => {
        setSearchText(text);
    }, 300);

    const filteredCotizaciones = (sortedCotizaciones || []).filter((row) => {
        return (
            (filterStatus === '' || row.estatusCotizacion.includes(filterStatus)) &&
            (filterProduct === '' || row.productoTransportar.includes(filterProduct)) &&
            (filterEjecutivo === '' || row.nombreCompleto.includes(filterEjecutivo))
        );
    });

    //Abrir modal con datos
    const handleOpenDialog = (rowData) => {
        setSelectedRowData(rowData);
        setDialogOpen(true);
    };

    const handleClick_verDocumento = (filename) => {

        setLoading_isOpen(true);
        axios.get(`${process.env.REACT_APP_AWS_API_BASE_URL}/portal/servicios/tarifario/api/cotizacion/documento/${filename}`)
            .then(response => {
                if (response?.response?.data) {
                    let responseError = response.response.data;
                    if (responseError) {
                        enqueueSnackbar(responseError.error, {
                            autoHideDuration: 7000,
                            preventDuplicate: true,
                            variant: 'error',
                            onClick: () => closeSnackbar()
                        });
                        console.log(responseError);
                    }
                }

                if (response.status === 200) {
                    window.open(response.data, 'documentos', 'toolbar=0,scrollbars=1,location=0,statusbar=0,menubar=0,resizable=1,width=500,height=700');
                }
                setLoading_isOpen(false);
            })
            .catch(error => {
                console.log("[handleClick_verDocumento][catch]", error);
                if (error) {
                    enqueueSnackbar("error al descargar el documento", {
                        autoHideDuration: 7000,
                        preventDuplicate: true,
                        variant: 'error',
                        onClick: () => closeSnackbar()
                    });
                }
                setLoading_isOpen(false);
            });
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid item md={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={4}>
                                    <FormControl fullWidth>
                                        <InputLabel>Tipo de Mercancía</InputLabel>
                                        <Select value={filterProduct} onChange={handleProductChange}>
                                            <MenuItem value="">Todos</MenuItem>
                                            {uniqueProducts.map((item, index) => (
                                                <MenuItem key={index} value={item}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControl fullWidth>
                                        <InputLabel>Estatus</InputLabel>
                                        <Select value={filterStatus} onChange={handleStatusChange}>
                                            <MenuItem value="">Todos</MenuItem>
                                            {uniqueStatus.map((item, index) => (
                                                <MenuItem key={index} value={item}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControl fullWidth>
                                        <InputLabel>Ejecutivo</InputLabel>
                                        <Select value={filterEjecutivo} onChange={handleEjecutivoChange}>
                                            <MenuItem value="">Todos</MenuItem>
                                            {uniqueEjecutivos.map((item, index) => (
                                                <MenuItem key={index} value={item}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </CardContent>

                    </Card>
                    <Card className={classes.cardTable}>
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item md={12}>
                                    <div style={{ justifyContent: 'flex-end', padding: 5, alignItems: 'flex-end', textAlign: 'right' }}>
                                        <Button endIcon={<AddCircleOutlineIIcon />} space={1} variant="contained" color="secondary" component={Link} to="/servicios/crear-solicitud"> Nueva Cotización </Button>
                                    </div>
                                </Grid>
                            </Grid>
                            <TableContainer style={{ marginTop: 6 }} component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <TableCell key={column.id}>
                                                    <TableSortLabel
                                                        active={orderBy === column.id}
                                                        direction={orderBy === column.id ? order : 'asc'}
                                                        onClick={() => handleSort(column.id)}
                                                    >
                                                        {column.label}
                                                    </TableSortLabel>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {loading ? (
                                            <TableRow>
                                                <TableCell colSpan={columns.length} spacing={2}>
                                                    {/* <Skeleton variant="rect" height={50} />
                                                    <Skeleton variant="rect" height={50} />
                                                    <Skeleton variant="rect" height={50} />
                                                    <Skeleton variant="rect" height={50} />
                                                    <Skeleton variant="rect" height={50} /> */}
                                                    <LoaderDashboardTables />
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            filteredCotizaciones.map((row, index) => (
                                                <>
                                                    <TableRow key={index}>
                                                        <TableCell style={{ fontWeight: 'bold' }}>{'COT-' + row.idCotVenta + '-' + row.intVersion}</TableCell>
                                                        <TableCell>{row.cliente}</TableCell>
                                                        <TableCell>{formatDateTime(row.fechaRegistro)}</TableCell>
                                                        <TableCell>{row.productoTransportar}</TableCell>
                                                        <TableCell>{row.descTransportar}</TableCell>
                                                        <TableCell>{row.nombreCompleto}</TableCell>
                                                        <TableCell>{row.estatusCotizacion}</TableCell>
                                                        <TableCell>
                                                            {/* Verifica si strArchivoCotizacion no está vacío */}
                                                            {row?.strArchivoCotizacion == '' ? (
                                                                <IconButton
                                                                    aria-label="detailArchivo"
                                                                    style={{ color: "#9e9e9e" }}
                                                                    disabled={row?.strArchivoCotizacion == ''}
                                                                >
                                                                    <DescriptionIcon />
                                                                </IconButton>
                                                            ) : (<IconButton
                                                                aria-label="detailArchivo"
                                                                style={{ color: "red" }}
                                                                onClick={() => handleOpenDialog(row)}
                                                            >
                                                                <DescriptionIcon />
                                                            </IconButton>)}
                                                        </TableCell>
                                                    </TableRow>

                                                </>
                                            ))
                                        )}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {/* Modal con datos de la tabla */}
                            <Dialog
                                open={dialogOpen}
                                onClose={() => setDialogOpen(false)}
                                fullWidth
                                maxWidth="sm"
                            >
                                <DialogTitle className={classes.modalHeaderServicios}>
                                    Detalles de la Cotización
                                    <IconButton aria-label="close" className={classes.closeButton} onClick={() => setDialogOpen(false)}>
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    {selectedRowData && (
                                        <>
                                            {/* // Aquí puedes mostrar los detalles de la fila seleccionada */}
                                            <Card>
                                                <CardContent>
                                                    {loading_isOpen ? (<LoaderDashboardTables label="Visualizando documento" />) : (
                                                        <Grid container spacing={2}>
                                                        <Grid item={6}>
                                                            <Typography variant='h6'>Datos</Typography>
                                                            <Typography>Folio: {selectedRowData.idCotVenta}</Typography>
                                                            <Typography>Cliente: {selectedRowData.cliente}</Typography>
                                                            <Typography>Producto a Transportar: {selectedRowData.productoTransportar}</Typography>
                                                        </Grid>
                                                        <Grid item={6}>
                                                            <Typography variant='h6'>Descargar Cotización</Typography>
                                                            <Tooltip title={'Visualizar PDF'}>
                                                                <button className="btn btn-link btn-disabled btn-no-seleccionable"  onClick={() => handleClick_verDocumento(selectedRowData.strArchivoCotizacion)}>
                                                                    <div style={{ width: '40px', display: 'inline-block' }}>
                                                                        <FileIcon extension={'pdf'} {...docStyles['pdf']} />
                                                                    </div>
                                                                </button>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                    )}
                                                
                                                </CardContent>
                                            </Card>
                                        </>
                                    )}
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setDialogOpen(false)} color="primary">
                                        Cerrar
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

export default ConsultarCotizaciones