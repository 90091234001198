import React, { useState, useContext, useLayoutEffect, useEffect, useRef } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import RoomIcon from '@material-ui/icons/Room';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { TextField, Select, MenuItem, Typography } from '@material-ui/core'
import { FormControl, InputLabel, Avatar, LinearProgress } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { digitos09_onKeyPress } from 'app/helpers/inputEventListeners'

import axios from 'axios'
import { debounce } from 'lodash'
import Geocode from 'react-geocode'

import { markerColors } from 'app/helpers/UIHelper'

import { DireccionesClienteContext } from 'app/contextos/servicios'

const UIMarkerColors = markerColors();
// Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
Geocode.setApiKey('AIzaSyBV2WKwb027MjV36ynLx5A14GVuxonKhlQ');
Geocode.setRegion('mx');

const CrearDireccionCliente = ({
    intl,
    fnCancelar = () => { },
    bitEditar = false,
    varCliente = '',
    direccion = {
        dblClienteDireccionesEntrega: null,
        strLugarEntrega: '',
        strCalle: '',
        strNumero: '',
        strColoniaEntrega: '',
        strCPEntrega: '',
        intCiudad: null,
        strCiudad: '',
        timestampActualizado: Date.now()
    }
}) => {

    // console.log('direccion???', direccion);

    const [checkIconStyle, setCheckIconStyle] = useState({ background: 'inherit', color: UIMarkerColors.green })
    const [clearIconStyle, setClearIconStyle] = useState({ background: 'inherit', color: UIMarkerColors.red });

    const [catalogoCiudades, setCatalogoCiudades] = useState([]);
    const [direccionCliente, setDireccionCliente] = useState(direccion);

    const [guardandoCambios, setGuardandoCambios] = useState(false);
    const [cargandoCiudades, setCargandoCiudades] = useState(false);
    const [direccionValida, setDireccionValida] = useState(false);

    const [hashCliente, setHashCliente] = useState(varCliente);

    const { session, selectedClienteComercial } = useSelector(state => state.auth);
    const [listaClientes] = useState(session.listaVarClientes);

    const { data, dispatchData } = useContext(DireccionesClienteContext);

    const { enqueueSnackbar } = useSnackbar();

    const history = useHistory();

    // Si solo existe una franquicia en el grupo de clientes, se define por default para
    // poder crear y editar direcciones
    useLayoutEffect(() => {
        if (listaClientes.length === 1)
            setHashCliente(listaClientes[0].strVarClienteOrigen)
    }, []);

    const fetchCiudadesFiltro = async (strCiudad) => {
        setCargandoCiudades(true)
        strCiudad = strCiudad.trim();
        let api_url = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/servicios/direcciones/api/catalogociudades';
        api_url += '?strCiudad=' + strCiudad || '';

        try {
            const response = await axios.get(api_url);
            const ciudades = response.data.ciudades;
            setCatalogoCiudades(ciudades);
        }
        catch (error) {
            console.log('error', error);
        }
        finally {
            setCargandoCiudades(false)
        }
    }

    const debounceFetchCiudadeFiltro = debounce(fetchCiudadesFiltro, 750);

    const setGeocode = async () => {
        let { strCalle, strNumero, strColoniaEntrega, strCPEntrega, strCiudad, strLugarEntrega } = direccionCliente;
        strCalle = strCalle?.trim();
        strNumero = strNumero?.trim();
        strColoniaEntrega = strColoniaEntrega?.trim()
        strCPEntrega = strCPEntrega?.trim();
        strCiudad = strCiudad?.trim();
        strLugarEntrega = strLugarEntrega?.trim();

        try {
            const geocode = await Geocode.fromAddress(`${strCalle} ${strNumero} ${strColoniaEntrega} ${strCPEntrega} ${strCiudad} ${strLugarEntrega}`);
            const location = geocode?.results[0]?.geometry.location;
            const coordenadas = {
                dblLatitudDireccion: location.lat,
                dblLongitudDireccion: location.lng
            }
            dispatchData({ type: 'coordenadas.set', payload: coordenadas })
        }
        catch (error) {
            dispatchData({ type: 'coordenadas.set', payload: { dblLatitudDireccion: null, dblLongitudDireccion: null } })
            console.log('ERROR | setGeocode ', error)
        };
    }

    // Comparamos representacion en string del objeto de direccion cliente para saber si hubo cambios
    useEffect(() => {
        if (direccion.timestampActualizado !== direccionCliente.timestampActualizado)
            setGeocode();
    }, [direccionCliente.timestampActualizado]);

    const validarSetDireccionEntrega = () => {
        const { strLugarEntrega, strCalle, strCiudad, intCiudad } = direccionCliente;

        const direccionValida = strLugarEntrega?.length
            && strCalle?.length
            && (strCiudad?.length || intCiudad)
            && hashCliente?.length;

        setDireccionValida(direccionValida);
    }

    useEffect(() => {
        validarSetDireccionEntrega()
    }, [JSON.stringify(direccionCliente)])

    const guardarDireccionCliente = async () => {
        const { coordenadas, direcciones } = data;
        const payload = { ...direccionCliente, dblLatitudDireccion: coordenadas.lat, dblLongitudDireccion: coordenadas.lng };
        const api_url = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/servicios/direcciones/api/nuevadireccion?varCliente=' + selectedClienteComercial;
        try {
            setGuardandoCambios(true);
            const response = await axios.post(api_url, payload);
            if ('scrollRestoration' in history) history.scrollRestoration = 'manual';
            window.scrollTo(0, 0);
            dispatchData({ type: 'direcciones.set', payload: [response.data.direccion, ...direcciones] })
            dispatchData({ type: 'direccionesFiltradas.reset' })
            dispatchData({ type: 'timestampActualizado.set' })
            enqueueSnackbar(intl.formatMessage({ id: 'SERVICIOS.DIRECCION.MSG.DIRECCION_GUARDADA' }),
                {
                    preventDuplicate: true,
                    autoHideDuration: 1500,
                    variant: 'success',
                })
            fnCancelar(); // Cerramos
        }
        catch (error) {
            console.log('ERROR | guardarDireccionCliente ', error);
        }
        finally {
            setGuardandoCambios(false);
            dispatchData({ type: 'coordenadas.set', payload: { dblLatitudDireccion: null, dblLongitudDireccion: null } });
        }
    }

    const actualizarDireccionCliente = async () => {
        // https://92v9zvrmx3.execute-api.us-east-1.amazonaws.com/dev/portal/servicios/direcciones/api/actualizardireccion
        let { coordenadas, direcciones } = data;
        const payload = { ...direccionCliente, dblLatitudDireccion: coordenadas.lat, dblLongitudDireccion: coordenadas.lng };
        let api_url = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/servicios/direcciones/api/actualizardireccion';
        api_url += `?varCliente=${selectedClienteComercial}&dblClienteDireccionesEntrega=${direccionCliente.dblClienteDireccionesEntrega}`;

        console.log("actualizarDireccionCliente ", payload)

        try {
            setGuardandoCambios(true);
            const response = await axios.post(api_url, payload);
            const index = direcciones.findIndex(x => x.dblClienteDireccionesEntrega === direccionCliente.dblClienteDireccionesEntrega);
            direcciones.splice(index, 1);
            dispatchData({ type: 'direcciones.set', payload: [response.data.direccion, ...direcciones] })
            dispatchData({ type: 'direccionesFiltradas.reset' })
            dispatchData({ type: 'timestampActualizado.set' })
            enqueueSnackbar(intl.formatMessage({ id: 'SERVICIOS.DIRECCION.MSG.DIRECCION_ACTUALIZADA' }), {
                preventDuplicate: true,
                autoHideDuration: 1500,
                variant: 'success',
            })
            fnCancelar(); // Cerramos
        }
        catch (error) {
            console.log('ERROR | guardarDireccionCliente ', error);
        }
        finally {
            setGuardandoCambios(false);
            dispatchData({ type: 'coordenadas.set', payload: { dblLatitudDireccion: null, dblLongitudDireccion: null } });
        }
    }

    // const history = useHistory();

    return <section style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginBottom: '5px' }}>

        <RoomIcon htmlColor={UIMarkerColors.green} />

        <section style={{ display: 'flex', flexDirection: 'column', paddingLeft: '10px', flexGrow: '1' }} >

            <section style={{ display: 'flex' }}>
                <div style={{ width: listaClientes?.length > 1 ? '70%' : '100%' }}>
                    <TextField
                        label={intl.formatMessage({ id: 'SERVICIOS.DIRECCION.TXT.LUGAR_DE_ENTREGA' })}
                        style={{ width: '100%' }}
                        disabled={guardandoCambios}
                        value={direccionCliente.strLugarEntrega}
                        error={!direccionCliente.strLugarEntrega.length}
                        onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                        }}
                        onChange={event => {
                            setDireccionCliente({ ...direccionCliente, strLugarEntrega: event.target.value || '', timestampActualizado: Date.now() })
                        }}
                        onKeyUp={(event) => {
                            if (event.key === 'Escape')
                                fnCancelar()
                        }}
                        onFocus={() => {
                            // setDireccionCliente({ ...direccionCliente, timestampActualizado: Date.now() })
                            if (!bitEditar) {
                                dispatchData({ type: 'direccionSeleccionada.set', payload: null })
                            }
                        }}
                    />
                </div>
                {listaClientes?.length > 1
                    ?
                    <div style={{ width: '30%' }}>
                        <FormControl style={{ width: '100%' }}>
                            <InputLabel>
                                <FormattedMessage id="SERVICIOS.DIRECCION.TXT.EMPRESA" />
                            </InputLabel>
                            <Select
                                disabled={guardandoCambios}
                                value={hashCliente}
                                error={!hashCliente}
                                onClick={event => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                }}
                                onChange={(event) => {
                                    setHashCliente(event.target.value)
                                }}
                                onKeyUp={(event) => {
                                    if (event.key === 'Escape')
                                        fnCancelar()
                                }}
                                onFocus={() => {
                                    // setDireccionCliente({ ...direccionCliente, timestampActualizado: Date.now() })
                                    if (!bitEditar) {
                                        dispatchData({ type: 'direccionSeleccionada.set', payload: null })
                                    }
                                }}
                            >
                                {listaClientes.map(x => <MenuItem key={x.strVarClienteOrigen} value={x.strVarClienteOrigen}>{x.strRazonSocial}</MenuItem>)}
                            </Select>
                        </FormControl>

                    </div>
                    :
                    <></>
                }
            </section>

            <section style={{ display: 'flex' }}>
                <div style={{ display: 'flex', width: '70%' }}>
                    <TextField
                        label={intl.formatMessage({ id: 'SERVICIOS.DIRECCION.TXT.CALLE' })}
                        style={{ flexGrow: '1' }}
                        value={direccionCliente.strCalle}
                        disabled={guardandoCambios}
                        error={!direccionCliente.strCalle.length}
                        onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                        }}
                        onChange={event => {
                            setDireccionCliente({ ...direccionCliente, strCalle: event.target.value || '', timestampActualizado: Date.now() })
                        }}
                        onKeyUp={(event) => {
                            if (event.key === 'Escape')
                                fnCancelar()
                        }}
                        onFocus={() => {
                            // setDireccionCliente({ ...direccionCliente, timestampActualizado: Date.now() })
                            if (!bitEditar) {
                                dispatchData({ type: 'direccionSeleccionada.set', payload: null })
                            }
                        }}
                    />
                </div>

                <section style={{ display: 'flex', flexDirection: 'column', width: '30%' }} >
                    <TextField
                        label={intl.formatMessage({ id: 'SERVICIOS.DIRECCION.TXT.NUMERO' })}
                        style={{ flexGrow: '1' }}
                        value={direccionCliente.strNumero}
                        disabled={guardandoCambios}
                        onKeyPress={digitos09_onKeyPress}
                        onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                        }}
                        onChange={event => {
                            setDireccionCliente({ ...direccionCliente, strNumero: event.target.value || '', timestampActualizado: Date.now() })
                        }}
                        onKeyUp={(event) => {
                            if (event.key === 'Escape')
                                fnCancelar()
                        }}
                        onFocus={() => {
                            // setDireccionCliente({ ...direccionCliente, timestampActualizado: Date.now() })
                            if (!bitEditar) {
                                dispatchData({ type: 'direccionSeleccionada.set', payload: null })
                            }
                        }}
                    />
                </section>
            </section>

            <section style={{ display: 'flex', flexGrow: '1' }}>
                <div style={{ display: 'flex', width: '70%' }}>
                    <TextField
                        label={intl.formatMessage({ id: 'SERVICIOS.DIRECCION.TXT.COLONIA' })}
                        style={{ flexGrow: '1' }}
                        value={direccionCliente.strColoniaEntrega}
                        disabled={guardandoCambios}
                        onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                        }}
                        onChange={event => {
                            setDireccionCliente({ ...direccionCliente, strColoniaEntrega: event.target.value || '', timestampActualizado: Date.now() })
                        }}
                        onKeyUp={(event) => {
                            if (event.key === 'Escape')
                                fnCancelar()
                        }}
                        onFocus={() => {
                            // setDireccionCliente({ ...direccionCliente, timestampActualizado: Date.now() })
                            if (!bitEditar) {
                                dispatchData({ type: 'direccionSeleccionada.set', payload: null })
                            }
                        }}
                    />
                </div>
                <div style={{ display: 'flex', width: '30%' }}>
                    <TextField
                        label={intl.formatMessage({ id: 'SERVICIOS.DIRECCION.TXT.CODIGO_POSTAL' })}
                        value={direccionCliente.strCPEntrega}
                        disabled={guardandoCambios}
                        onKeyPress={digitos09_onKeyPress}
                        onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                        }}
                        onChange={event => {
                            setDireccionCliente({ ...direccionCliente, strCPEntrega: event.target.value || '', timestampActualizado: Date.now() })
                        }}
                        onKeyUp={(event) => {
                            if (event.key === 'Escape')
                                fnCancelar()
                        }}
                        onFocus={() => {
                            // setDireccionCliente({ ...direccionCliente, timestampActualizado: Date.now() })
                            if (!bitEditar) {
                                dispatchData({ type: 'direccionSeleccionada.set', payload: null })
                            }
                        }}
                    />
                </div>
            </section>

            <section style={{ display: 'flex', flexGrow: '1' }}>
                <div style={{ display: 'flex', width: '70%' }}>
                    <Autocomplete
                        freeSolo={true}
                        autoHighlight={true}
                        value={direccionCliente.strCiudad}
                        loading={cargandoCiudades}
                        loadingText={<section style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="overline">
                                {intl.formatMessage({ id: '_CARGANDO' })}
                            </Typography>
                            <LinearProgress />
                        </section>}
                        disabled={guardandoCambios}
                        options={catalogoCiudades.map(ciudad => ciudad.strCiudad)}
                        style={{ width: '100%' }}
                        onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                        }}
                        onChange={(event, value) => {
                            event.preventDefault();
                            event.stopPropagation();
                            const ciudad = catalogoCiudades.find(x => x.strCiudad === value);
                            setDireccionCliente({ ...direccionCliente, intCiudad: ciudad?.intCiudad || null, strCiudad: ciudad?.strCiudad || '', timestampActualizado: Date.now() })
                            return event;
                        }}
                        onInputChange={event => {
                            if (event) {
                                event.preventDefault()
                                event.stopPropagation();
                            }
                        }}
                        onHighlightChange={event => {
                            if (event) {
                                event.preventDefault();
                                event.stopPropagation();
                            }
                        }}
                        onClose={(event, value) => {
                            if (event) {
                                event.preventDefault();
                                event.stopPropagation();
                                // Nos aseguramos de enviar la ciudad sin importar que no existiera un resultado
                                // si no se ha seleccionado desde el combobox
                                if (value !== 'select-option')
                                    setDireccionCliente({ ...direccionCliente, strCiudad: event.target.value, timestampActualizado: Date.now() })
                            }
                        }}
                        onKeyUp={(event) => {
                            if (event.key === 'Escape')
                                fnCancelar()
                        }}
                        onFocus={() => {
                            // setDireccionCliente({ ...direccionCliente, timestampActualizado: Date.now() })
                            if (!bitEditar) {
                                dispatchData({ type: 'direccionSeleccionada.set', payload: null })
                            }
                        }}

                        renderInput={
                            (params) => <TextField {...params}
                                label={intl.formatMessage({ id: 'SERVICIOS.DIRECCION.TXT.CIUDAD' })}
                                error={!direccionCliente.intCiudad || !direccionCliente.strCiudad.length}
                                onFocus={event => event.target.autocomplete = 'new-password'} // Hack para evitar autofill de Chrome
                                onClick={event => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                }}
                                onChange={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    debounceFetchCiudadeFiltro(event.target.value)
                                }}
                            />

                        }
                    />
                </div>
                <div style={{ display: 'flex', width: '30%', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                    <Avatar className="cursor-hover" style={{ marginRight: '5px', ...checkIconStyle }}
                        onMouseOver={() => {
                            if (guardandoCambios || !direccionValida)
                                setCheckIconStyle({ background: '#ffffff', color: UIMarkerColors.green })
                            else
                                setCheckIconStyle({ background: UIMarkerColors.green, color: '#ffffff' })
                        }}
                        onMouseOut={() => {
                            setCheckIconStyle({ background: 'inherit', color: UIMarkerColors.green })
                        }}
                        disabled={guardandoCambios || !direccionValida}
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            if (direccionValida) {
                                setCheckIconStyle({ background: '#ffffff', color: UIMarkerColors.green })
                                dispatchData({ type: 'markerEditable.false' })
                                if (!guardandoCambios) {
                                    if (!bitEditar) guardarDireccionCliente();
                                    else actualizarDireccionCliente();
                                }
                            }
                            else {
                                enqueueSnackbar(intl.formatMessage({ id: '_CAMPOS_REQUERIDOS' }),
                                    {
                                        preventDuplicate: true,
                                        autoHideDuration: 2500,
                                        variant: 'warning',
                                    })
                            }
                        }}
                    >
                        <CheckIcon style={checkIconStyle} />
                    </Avatar>
                    <Avatar className="cursor-hover" style={clearIconStyle}
                        onMouseOver={() => {
                            if (guardandoCambios)
                                setClearIconStyle({ background: '#ffffff', color: UIMarkerColors.red })
                            else
                                setClearIconStyle({ background: UIMarkerColors.red, color: '#ffffff' })
                        }}
                        onMouseOut={() => {
                            setClearIconStyle({ background: 'inherit', color: UIMarkerColors.red })
                        }}
                        disabled={guardandoCambios}
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            dispatchData({ type: 'markerEditable.false' })
                            if (!guardandoCambios) {
                                fnCancelar()
                                dispatchData({ type: 'coordenadas.set', payload: { dblLatitudDireccion: null, dblLongitudDireccion: null } });
                            }
                        }}
                    >
                        <ClearIcon style={clearIconStyle} />
                    </Avatar>

                </div>
            </section>

        </section>

    </section>
}

export default injectIntl(CrearDireccionCliente);