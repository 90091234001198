const RUTA_ORIGEN = 1;
const RUTA_DESTINO = 2;
const RUTA_DESPUES_DESTINO = 3;

export default function obtenerSeccion(unidad, intSeccion = RUTA_DESTINO) {
    var respuesta = null;
    if (unidad.arraySecciones !== undefined) {
        if (unidad.arraySecciones.length > 0) {
            //Devolvemos solo los puntos de la seccion
            (unidad.arraySecciones).forEach((seccion) => {
                if (seccion.intSeccion === intSeccion) {
                    respuesta = seccion;
                }
            });
        }
    }

    return respuesta;
}