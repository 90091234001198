import React from 'react'
import { injectIntl } from 'react-intl'
import { Paper, Typography } from '@material-ui/core'

import FWDLogoAzul from 'imagenes/LogoAzul.png'

function TarjetaDescripcion({ descripcion }) {

    if (!descripcion) return <></>;

    let {
        strProductoTransportar,
        dblPesoPedido,
        strUnidadMedidaCorto,
        strFranquicia,
    } = descripcion;

    const imgFranquicia = <img src={FWDLogoAzul} style={{
        height: '30px',
        width: 'auto'
    }} />

    strProductoTransportar = strProductoTransportar?.toLowerCase();
    strProductoTransportar = strProductoTransportar?.charAt(0).toUpperCase() + strProductoTransportar?.slice(1);

    return <Paper elevation={3} style={{ padding: '15px', display: 'flex', justifyContent: 'space-between' }}>
        <section style={{ display: 'flex', alignItems: 'center' }}>
            {imgFranquicia}
            <Typography variant="h6" style={{ paddingLeft: '10px' }}>{strFranquicia}</Typography>
        </section>
        <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>{strProductoTransportar}</Typography>
            <Typography variant="body2" style={{ fontStyle: 'italic' }}>{dblPesoPedido.toLocaleString()} {strUnidadMedidaCorto}</Typography>
        </section>
    </Paper>

}

export default injectIntl(TarjetaDescripcion)

