import React, { useMemo } from "react";
import objectPath from "object-path";
import { BrowserRouter as Router, Route, Link, useLocation } from "react-router-dom";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {Button} from '@material-ui/core'
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { SearchDropdown } from "../extras/dropdowns/search/SearchDropdown";
import { SelectCustomer } from "../extras/utilsUXElements/SelectCustomer";

import { UserNotificationsDropdown } from "../extras/dropdowns/UserNotificationsDropdown";
import { QuickActionsDropdown } from "../extras/dropdowns/QuickActionsDropdown";
import { MyCartDropdown } from "../extras/dropdowns/MyCartDropdown";
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { QuickUserToggler } from "../extras/QuiclUserToggler";

import LogoAzul from 'app/../imagenes/LogoAzul.png'

export function Topbar() {
  const uiService = useHtmlClassService();

  const location = useLocation();

  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  return (
    <div className="topbar" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <Link to="">
        <img src={LogoAzul} width={75} height={40} className="cursor-hover" />
      </Link>
      <section style={{ display: 'flex' }}>
        {/* {layoutProps.viewSearchDisplay && <SearchDropdown /> */}
        {(location.pathname != "/servicios/crear-solicitud") ? (
          <Button style={{marginTop: 5, height: 40, marginRight: 20, borderRadius: 10, backgroundColor: '#1f23bc', color: 'white'}} variant="contained" component={Link} to="/servicios/crear-solicitud">
            Cotizar
          </Button>
        ):<></>}

        

        {<SelectCustomer />} {/*Selector de clientes*/}

        {layoutProps.viewNotificationsDisplay && <UserNotificationsDropdown />}

        {/*layoutProps.viewQuickActionsDisplay && <QuickActionsDropdown />}

      {layoutProps.viewCartDisplay && <MyCartDropdown />}

      {layoutProps.viewQuickPanelDisplay && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="quick-panel-tooltip">Quick panel</Tooltip>}
        >
          <div
            className="topbar-item"
            data-toggle="tooltip"
            title="Quick panel"
            data-placement="right"
          >
            <div
              className="btn btn-icon btn-clean btn-lg mr-1"
              id="kt_quick_panel_toggle"
            >
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Layout/Layout-4-blocks.svg"
                  )}
                />
              </span>
            </div>
          </div>
        </OverlayTrigger>
      )} */}

        {/* {layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />} */}

        {layoutProps.viewUserDisplay && <QuickUserToggler />}
      </section>
    </div>
  );
}
