// Imports de ley
import React, { useEffect, useState, useReducer, useContext, useLayoutEffect } from 'react'
import { injectIntl } from 'react-intl'
import { debounce } from 'lodash'
import axios from 'axios'

//Imports a necesidades
import { 
  TextField, 
  Typography, 
  LinearProgress,
  Grid,
  CircularProgress,
  Button 
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import Geocode from 'react-geocode'
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { DireccionesClienteContext } from 'app/contextos/servicios';
import { digitos09_onKeyPress } from 'app/helpers/inputEventListeners'
import { DireccionesClienteModalContext } from 'app/contextos/servicios'

//Iconos
import { MarkunreadMailboxRounded } from '@material-ui/icons'
import SaveIcon from '@material-ui/icons/Save';

Geocode.setApiKey('AIzaSyBV2WKwb027MjV36ynLx5A14GVuxonKhlQ');
Geocode.setRegion('mx');

const INITIAL_STATE = {
  strLugarEntrega: '',
  strCalle: '',
  strNumero: '',
  strColoniaEntrega: '',
  strCPEntrega: '',
  intCiudad: null,
  strCiudad: '',
  dblLatitudDireccion: null,
  dblLongitudDireccion: null,
  varCliente: ''
};

const REDUCTOR = (state, action) => {
  switch (action.type) {
    case 'direcciones.set':
      return action.payload;
    case 'coordenadas.set':
      return {...state, dblLatitudDireccion: action.payload.dblLatitudDireccion, dblLongitudDireccion: action.payload.dblLongitudDireccion}
    case 'cliente.set':
      return {...state, varCliente: action.payload}
  }
}

const FWD_MAP_HEIGHT = "calc(45vh)";
const FWDMap = ({ google }) => {
  const { data, dispatchData } = useContext(DireccionesClienteModalContext);
  const coordenadasFWD = { lat: 25.658731, lng: -100.360774 };
  console.log(data);
  //https://stackoverflow.com/questions/51495060/google-maps-react-get-marker-position-on-drag-end
  const onMarkerDragEnd = (coord) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    dispatchData({ type: 'coordenadas.set', payload: { dblLatitudDireccion: lat, dblLongitudDireccion: lng } })
  }

  return <section style={{ position: 'relative', width: '100%', height: FWD_MAP_HEIGHT, border: (data.dblLatitudDireccion !== null && data.dblLongitudDireccion !== null) ? 'none' : '2px solid #d90404' }}>
    <Map
      google={google}
      center={(data.dblLatitudDireccion !== null && data.dblLongitudDireccion !== null)
        ? {lat : data?.dblLatitudDireccion, lng : data?.dblLongitudDireccion}
        : coordenadasFWD}
      initialCenter={coordenadasFWD}
      zoom={16}
    >
      {(data.dblLatitudDireccion !== null && data.dblLongitudDireccion !== null)
        ?
          <Marker
            draggable={true}
            position={{lat : data?.dblLatitudDireccion, lng : data?.dblLongitudDireccion}}
            name={'Current location'}
            onDragend={(t, map, coord) => onMarkerDragEnd(coord)}
          />
        :
          <></>
      }
    </Map>
  </section>
}

const FWDMapLoader = () => (
  <Grid style={{ display: 'flex', width: '100%', height: FWD_MAP_HEIGHT, justifyContent: 'center', alignItems: 'center' }}>
    <CircularProgress />
  </Grid>
)

const FWDMapWrapper = GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  LoadingContainer: FWDMapLoader
})(FWDMap);

const CrearDireccionClienteModal = ({
  intl,
  varCliente = '',
  setModalAbierto = null,
  setGuardarDireccion = null
}) => {

  const [guardandoCambios, setGuardandoCambios] = useState(false);
  const [cargandoCiudades, setCargandoCiudades] = useState(false);

  //Catalogo
  const [catalogoCiudades, setCatalogoCiudades] = useState([]);
  const fetchCiudadesFiltro = async (strCiudad) => {
    setCargandoCiudades(true)
    strCiudad = strCiudad.trim();
    let api_url = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/servicios/direcciones/api/catalogociudades';
    api_url += '?strCiudad=' + strCiudad || '';

    try {
      const response = await axios.get(api_url);
      const ciudades = response.data.ciudades;
      setCatalogoCiudades(ciudades);
    }
    catch (error) {
      console.log('error', error);
    }
    finally {
      setCargandoCiudades(false)
    }
  }

  const debounceFetchCiudadeFiltro = debounce(fetchCiudadesFiltro, 750);

  //Setear datos
  const setDatosDireccion = async (dato) => {
    dispatchData({
      type: 'direcciones.set',
      payload: dato
    });
    debounceSetGeocode(dato.strCalle, dato.strNumero, dato.strColoniaEntrega, dato.strCPEntrega, dato.strCiudad, dato.strLugarEntrega);
  }

  const debounceSetDatosDireccion = debounce(setDatosDireccion,750);

  //Buscar geocoding
  const setGeocode = async (strCalle, strNumero, strColoniaEntrega, strCPEntrega, strCiudad, strLugarEntrega) => {
    strCalle = strCalle?.trim();
    strNumero = strNumero?.trim();
    strColoniaEntrega = strColoniaEntrega?.trim()
    strCPEntrega = strCPEntrega?.trim();
    strCiudad = strCiudad?.trim();
    strLugarEntrega = strLugarEntrega?.trim();
    
    if(!strCalle || !strCiudad || !strLugarEntrega){
      return false;
    }
    // console.log(strCalle, strNumero, strColoniaEntrega, strCPEntrega, strCiudad, strLugarEntrega);
    try {
        const geocode = await Geocode.fromAddress(`${strCalle} ${strNumero} ${strColoniaEntrega} ${strCPEntrega} ${strCiudad} ${strLugarEntrega}`);
        const location = geocode?.results[0]?.geometry.location;
        const coordenadas = {
          dblLatitudDireccion: location.lat,
          dblLongitudDireccion: location.lng
        }
        dispatchData({ type: 'coordenadas.set', payload: coordenadas })
    }
    catch (error) {
        dispatchData({ type: 'coordenadas.set', payload: { dblLatitudDireccion: null, dblLongitudDireccion: null } })
        console.log('ERROR | setGeocode ', error)
    };
  }

  const debounceSetGeocode = debounce(setGeocode, 250);

  //Registrar
  const setDireccionEntrega = async () => {
    // console.log('Enviar >> ', data);
    const payload = data;
    ///portal/servicios/solicitudes/api/registrardirecciones
    const api_url = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/servicios/solicitudes/api/registrardirecciones?varCliente=' + data.varCliente;
    try {
      setGuardandoCambios(true);
      const response = await axios.post(api_url, payload);
      console.log('Response al guardar >> ', response);
      setGuardarDireccion(true);
      setModalAbierto(false);
    }
    catch (error) {
      console.log('ERROR | guardarDireccionCliente ', error);
    }
    finally {
      setGuardandoCambios(false);
    }
  }

  //Reductor
  const [data, dispatchData] = useReducer(REDUCTOR, INITIAL_STATE);

  useEffect(()=>{
    //Enviar varCliente
    dispatchData({
      type: 'cliente.set',
      payload: varCliente
    });
  },[]);

  return <>
    <DireccionesClienteModalContext.Provider value={{data, dispatchData}}>
      <Grid container spacing={2}>
        {/* Formulario */}
        <Grid item xs={12} md={6} style={{display: 'flex', alignItems:'center'}}>
          <section style={{ display: 'flex', flexDirection: 'column', paddingLeft: '10px', flexGrow: '1' }} >
            {/* Lugar */}
            <section style={{ display: 'flex' }}>
              <div style={{ width: '100%' }}>
                <TextField
                  label={intl.formatMessage({ id: 'SERVICIOS.DIRECCION.TXT.LUGAR_DE_ENTREGA' })}
                  style={{ width: '100%' }}
                  disabled={guardandoCambios}
                  // value={direccionClienteRegistrar.strLugarEntrega}
                  error={!data.strLugarEntrega.length}
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                  onChange={event => {
                    let direccionTemp = {...data};
                    direccionTemp.strLugarEntrega = event.target.value;
                    debounceSetDatosDireccion(direccionTemp);
                  }}
                />
              </div>
            </section>
                
            {/* Calle - Numero */}
            <section style={{ display: 'flex' }}>
              <div style={{ display: 'flex', width: '70%' }}>
                <TextField
                  label={intl.formatMessage({ id: 'SERVICIOS.DIRECCION.TXT.CALLE' })}
                  style={{ flexGrow: '1' }}
                  // value={direccionClienteRegistrar.strCalle}
                  disabled={guardandoCambios}
                  error={!data.strCalle.length}
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                  onChange={event => {
                    let direccionTemp = {...data};
                    direccionTemp.strCalle = event.target.value;
                    debounceSetDatosDireccion(direccionTemp);
                  }}
                />
              </div>

              <section style={{ display: 'flex', flexDirection: 'column', width: '30%' }} >
                <TextField
                  label={intl.formatMessage({ id: 'SERVICIOS.DIRECCION.TXT.NUMERO' })}
                  style={{ flexGrow: '1' }}
                  // value={direccionClienteRegistrar.strNumero}
                  disabled={guardandoCambios}
                  onKeyPress={digitos09_onKeyPress}
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                  onChange={event => {
                    let direccionTemp = {...data};
                    direccionTemp.strNumero = event.target.value;
                    debounceSetDatosDireccion(direccionTemp);
                  }}
                />
              </section>
            </section>
            
            {/* Colonia - Codigo postal */}
            <section style={{ display: 'flex', flexGrow: '1' }}>
              <div style={{ display: 'flex', width: '70%' }}>
                <TextField
                  label={intl.formatMessage({ id: 'SERVICIOS.DIRECCION.TXT.COLONIA' })}
                  style={{ flexGrow: '1' }}
                  // value={direccionClienteRegistrar.strColoniaEntrega}
                  disabled={guardandoCambios}
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                  onChange={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    let direccionTemp = {...data};
                    direccionTemp.strColoniaEntrega = event.target.value;
                    debounceSetDatosDireccion(direccionTemp);
                  }}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                  <TextField
                    label={intl.formatMessage({ id: 'SERVICIOS.DIRECCION.TXT.CODIGO_POSTAL' })}
                    // value={direccionClienteRegistrar.strCPEntrega}
                    disabled={guardandoCambios}
                    onKeyPress={digitos09_onKeyPress}
                    onClick={event => {
                      event.preventDefault();
                      event.stopPropagation();
                    }}
                    onChange={event => {
                      let direccionTemp = {...data};
                      direccionTemp.strCPEntrega = event.target.value;
                      debounceSetDatosDireccion(direccionTemp);
                    }}
                  />
                </div>
            </section>
            
            {/* Ciudad y boton de guardar */}
            <section style={{ display: 'flex', flexGrow: '1', alignItems:'flex-end' }}>
              <div style={{ display: 'flex', width: '70%' }}>
                <Autocomplete
                  freeSolo={true}
                  autoHighlight={true}
                  // value={direccionClienteRegistrar.strCiudad}
                  loading={cargandoCiudades}
                  loadingText={<section style={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="overline">
                          {intl.formatMessage({ id: '_CARGANDO' })}
                      </Typography>
                      <LinearProgress />
                  </section>}
                  disabled={guardandoCambios}
                  options={catalogoCiudades.map(ciudad => ciudad.strCiudad)}
                  style={{ width: '100%' }}
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                  
                  onChange={(event, value) => {
                    event.preventDefault();
                    event.stopPropagation();
                    const ciudad = catalogoCiudades.find(x => x.strCiudad === value);
                    let direccionTemp = {...data};
                    direccionTemp.intCiudad = ciudad?.intCiudad;
                    direccionTemp.strCiudad = ciudad?.strCiudad;
                    debounceSetDatosDireccion(direccionTemp);
                    // return event;
                  }}

                  onInputChange={event => {
                    if (event) {
                      event.preventDefault()
                      event.stopPropagation();
                    }
                  }}

                  onHighlightChange={event => {
                    if (event) {
                      event.preventDefault();
                      event.stopPropagation();
                    }
                  }}

                  renderInput={
                    (params) => <TextField {...params}
                      label={intl.formatMessage({ id: 'SERVICIOS.DIRECCION.TXT.CIUDAD' })}
                      error={!data.strCiudad?.length}
                      onFocus={event => event.target.autocomplete = 'new-password'} // Hack para evitar autofill de Chrome
                      onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                      }}
                      onChange={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        debounceFetchCiudadeFiltro(event.target.value)
                      }}
                    />
                  }
                />
              </div>
              <section style={{width: '30%', justifyContent: 'center', display: 'flex'}}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  startIcon={<SaveIcon />}
                  disabled={
                    !data.strLugarEntrega ||
                    !data.strCalle || 
                    !data.intCiudad ||
                    !data.strCiudad ||
                    !data.dblLatitudDireccion ||
                    !data.dblLongitudDireccion ||
                    guardandoCambios
                  }
                  onClick={setDireccionEntrega}
                >
                  Registrar
                </Button>
              </section> 
            </section>

          </section>
        </Grid>
        {/* Mapa google */}
        <Grid item xs={12} md={6}>
          <FWDMapWrapper />
        </Grid>
      </Grid>
    </DireccionesClienteModalContext.Provider>
  </>;
}

export default injectIntl(CrearDireccionClienteModal);