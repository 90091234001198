import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import FWDModal from 'app/componentes/FWDModal'
import { Grid, Typography, Link } from '@material-ui/core'
import axios from 'axios'
import { useSelector } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RoomIcon from '@material-ui/icons/Room'
import GpsFixedIcon from '@material-ui/icons/GpsFixed'
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

import formatoMoneda from 'app/formatos/Moneda'

import { markerColors } from 'app/helpers/UIHelper'

import FWDModalServicio from 'app/componentes/FWDModalServicio'

// https://92v9zvrmx3.execute-api.us-east-1.amazonaws.com/dev/portal/servicios/consultar-solicitudes/api/detalle-solicitud
const BASE_URL = process.env.REACT_APP_AWS_API_BASE_URL
const CHECK_CIRCLE_STYLE = { color: '#1bc5bd', marginRigth: 5 }
const ROW_STYLE = { display: 'flex', alignItems: 'flex-start', marginBottom: '1.1rem' }
const ROW_TEXT_STYLE = { paddingLeft: 5 }

const FWDModalSolicitud = (props) => {

    const { dblServicioCliente, open, fnClose } = props

    const coloresMarcadores = markerColors()

    const { session, actualizarDireccionCliente } = useSelector(state => state.auth);
    const listaVarClientes = session.listaVarClientes.map(x => x.strVarClienteOrigen);

    const [cargandoDetalle, setCargandoDetalle] = useState(true)
    const [detalleSolicitud, setDetalleSolicitud] = useState({})

    const [modalServicio, setModalServicio] = useState({ dblPedido: null, open: false })

    const fetchDetalleSolicitud = async () => {
        try {
            setCargandoDetalle(true)
            const apiURL = BASE_URL + '/portal/servicios/consultar-solicitudes/api/detalle-solicitud?varCliente=' +
            actualizarDireccionCliente +
                '&dblServicioCliente=' + dblServicioCliente

            const result = await axios.get(apiURL)
            setDetalleSolicitud(result.data)
            // setDetalleSolicitud({
            //     "detalle": {
            //         "bitHazmat": 1,
            //         "bitTrasbordo": 1,
            //         "datFechaCargaRaw": "...",
            //         "datFechaDescargaRaw": "...",
            //         "datFechaCarga": "14/ene/2021",
            //         "datFechaDescarga": "15/ene/2021",
            //         "datFechaRegistro": "2021-01-14T13:27:47.000Z",
            //         "dblPrecioUnitario": 37000,
            //         "dblServicioCliente": 29290,
            //         "intCantidad": 1,
            //         "intNumeroUN": "0000",
            //         "intTipoProductoTransportar": 3,
            //         "strCPCarga": null,
            //         "strCPDescarga": "78045",
            //         "strCalleCarga": "Eje Oriente Poniente Lote 1 Mz 7",
            //         "strCalleDescarga": "Free Trade",
            //         "strCiudadDestino": "Laredo, Texas, Estados Unidos",
            //         "strCiudadDestinoIngles": "Laredo, Texas, USA",
            //         "strCiudadOrigen": "Tizayuca, Hidalgo, México",
            //         "strCiudadOrigenIngles": "Tizayuca, Hidalgo, Mexico",
            //         "strColoniaCarga": "Zona Industrial",
            //         "strColoniaDescarga": null,
            //         "strComentarios": "Esto es una nota, omitir.",
            //         "strMoneda": "MXN",
            //         "strNombreCorto": "Ton",
            //         "strNumeroCarga": null,
            //         "strNumeroDescarga": "4117",
            //         "strProducto": "Terrestre",
            //         "strProductoDescripcion": "Flete Terrestre",
            //         "strProductoTransportar": "Prueba",
            //         "strReferencia": null,
            //         "strTipoCarga": "En vivo",
            //         "strTipoDescarga": "Drop & Hook",
            //         "strTipoProducto": "Transporte",
            //         "strTipoProductoTransportar": "Materia Prima",
            //         "strTipoProductoTransportarIngles": " Raw material",
            //         "strTipoVehiculo": "Caja Seca 53 pies",
            //         "strTipoVehiculoIngles": "53 ft Dry Van",
            //         "strUnidadMedida": "Toneladas"
            //     },
            //     "paradas": [
            //         {
            //             "bitHazmat": 1,
            //             "bitTrasbordo": 1,
            //             "datFechaCarga": "14/ene/2021",
            //             "datFechaCargaRaw": "...",
            //             "datFechaDescargaRaw": "...",
            //             "datFechaDescarga": null,
            //             "datFechaRegistro": "2021-01-14T13:27:47.000Z",
            //             "dblPrecioUnitario": 0,
            //             "dblServicioCliente": 29290,
            //             "intCantidad": 1,
            //             "intNumeroUN": "0000",
            //             "intTipoProductoTransportar": 3,
            //             "strCPCarga": "46219",
            //             "strCPDescarga": null,
            //             "strCalleCarga": "English Ave",
            //             "strCalleDescarga": null,
            //             "strCiudadDestino": null,
            //             "strCiudadDestinoIngles": null,
            //             "strCiudadOrigen": "Indianápolis, Indiana, Estados Unidos",
            //             "strCiudadOrigenIngles": "Indianápolis, Indiana, USA",
            //             "strColoniaCarga": null,
            //             "strColoniaDescarga": null,
            //             "strComentarios": "Esto es una nota, omitir.",
            //             "strMoneda": "MXN",
            //             "strNombreCorto": "Ton",
            //             "strNumeroCarga": "7025",
            //             "strNumeroDescarga": null,
            //             "strProducto": "Reparto",
            //             "strProductoDescripcion": "Flete de Reparto",
            //             "strProductoTransportar": "Prueba",
            //             "strReferencia": null,
            //             "strTipoCarga": "En vivo",
            //             "strTipoDescarga": "Drop & Hook",
            //             "strTipoProducto": "Transporte",
            //             "strTipoProductoTransportar": "Materia Prima",
            //             "strTipoProductoTransportarIngles": " Raw material",
            //             "strTipoVehiculo": null,
            //             "strTipoVehiculoIngles": null,
            //             "strUnidadMedida": "Toneladas"
            //         },
            //         {
            //             "bitHazmat": 1,
            //             "bitTrasbordo": 1,
            //             "datFechaCarga": "15/ene/2021",
            //             "datFechaDescarga": null,
            //             "datFechaCargaRaw": "...",
            //             "datFechaDescargaRaw": "...",
            //             "datFechaRegistro": "2021-01-14T13:27:47.000Z",
            //             "dblPrecioUnitario": 0,
            //             "dblServicioCliente": 29290,
            //             "intCantidad": 1,
            //             "intNumeroUN": "0000",
            //             "intTipoProductoTransportar": 3,
            //             "strCPCarga": "43015",
            //             "strCPDescarga": null,
            //             "strCalleCarga": "Nutter Farms Lane",
            //             "strCalleDescarga": null,
            //             "strCiudadDestino": null,
            //             "strCiudadDestinoIngles": null,
            //             "strCiudadOrigen": "Delaware, Ohio, Estados Unidos",
            //             "strCiudadOrigenIngles": "Delaware, Ohio, USA",
            //             "strColoniaCarga": null,
            //             "strColoniaDescarga": null,
            //             "strComentarios": "Esto es una nota, omitir.",
            //             "strMoneda": "MXN",
            //             "strNombreCorto": "Ton",
            //             "strNumeroCarga": "2000",
            //             "strNumeroDescarga": null,
            //             "strProducto": "Recolección",
            //             "strProductoDescripcion": "Flete de Recolección",
            //             "strProductoTransportar": "Prueba",
            //             "strReferencia": null,
            //             "strTipoCarga": "En vivo",
            //             "strTipoDescarga": "Drop & Hook",
            //             "strTipoProducto": "Transporte",
            //             "strTipoProductoTransportar": "Materia Prima",
            //             "strTipoProductoTransportarIngles": " Raw material",
            //             "strTipoVehiculo": null,
            //             "strTipoVehiculoIngles": null,
            //             "strUnidadMedida": "Toneladas"
            //         }
            //     ],
            //     "totalSolicitudes": 7
            // })
        }
        catch (error) {
            console.log('fetchDetalleSolicitudError ', error)
        }
        finally {
            setCargandoDetalle(false)
        }
    }

    const construyeDireccionCarga = (x) => {
        let direccion = ''
        direccion += x?.strCalleCarga || ''
        direccion += x?.strNumeroCarga ? ` #${x?.strNumeroCarga}` : ''
        direccion += x?.strColoniaCarga ? `, Col. ${x?.strColoniaCarga}` : ''
        direccion += x?.strCPCarga ? `, C.P. ${x?.strCPCarga}` : ''
        return direccion
    }

    const construyeDireccionDescarga = (x) => {
        let direccion = ''
        direccion += x?.strCalleDescarga || ''
        direccion += x?.strNumeroDescarga ? ` #${x?.strNumeroDescarga}` : ''
        direccion += x?.strColoniaDescarga ? `, Col. ${x?.strColoniaDescarga}` : ''
        direccion += x?.strCPDescarga ? `, C.P. ${x?.strCPDescarga}` : ''
        return direccion
    }

    useEffect(() => {

        if (dblServicioCliente !== null)
            fetchDetalleSolicitud()

    }, [dblServicioCliente, actualizarDireccionCliente])

    const HEIGHT_MODAL_SOLICITUD = "620px"

    return <>
        <FWDModal
            width="820px"
            height={HEIGHT_MODAL_SOLICITUD}
            open={open}
            Titulo={() => <Grid style={{ width: '100%' }}>
                <Typography variant="h5">Solicitud de servicio</Typography>
                {/* <Typography variant="h5">Solicitud {dblServicioCliente}</Typography> */}
            </Grid>}
            fnCloseModal={() => fnClose()}
        >
            <Grid container item sm={12} alignContent="flex-start" style={{ margin: '0 5' }}>

                {
                    cargandoDetalle
                        ? <section style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress color="secondary" />
                        </section>
                        : <section style={{ width: '100%', height: `calc(${HEIGHT_MODAL_SOLICITUD} - 50px)`, overflowY: 'scroll', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: 10 }}>

                            {
                                detalleSolicitud?.detalle?.strReferencia
                                    ? <div style={ROW_STYLE}>
                                        <CheckCircleIcon style={CHECK_CIRCLE_STYLE} />
                                        <Typography style={ROW_TEXT_STYLE} variant="body1">No. Referencia: <b>{detalleSolicitud?.detalle?.strReferencia}</b></Typography>
                                    </div>
                                    : <></>
                            }

                            <div style={ROW_STYLE}>
                                <CheckCircleIcon style={CHECK_CIRCLE_STYLE} />
                                <Typography style={ROW_TEXT_STYLE} variant="body1">Se solicita{detalleSolicitud?.totalSolicitudes > 1 ? 'n' : ''}&nbsp;<b>{detalleSolicitud?.totalSolicitudes}</b>&nbsp;servicio{detalleSolicitud?.totalSolicitudes > 1 ? 's' : ''}&nbsp;
                            {detalleSolicitud?.detalle?.bitHazmat || detalleSolicitud?.detalle?.bitTrasbordo ? (<b>{detalleSolicitud?.detalle?.bitTrasbordo ? 'con trasbordo' : ''}&nbsp;{detalleSolicitud?.detalle?.bitHazmat ? 'y hazmat' : ''}</b>) : <></>}</Typography>
                            </div>
                            <div style={ROW_STYLE}>
                                <CheckCircleIcon style={CHECK_CIRCLE_STYLE} />
                                <Typography style={ROW_TEXT_STYLE} variant="body1">Queda{detalleSolicitud?.totalPendientes > 1 ? 'n' : ''}&nbsp;<b>{detalleSolicitud?.totalPendientes}</b>&nbsp;solicitud{detalleSolicitud?.totalPendientes > 1 ? 'es' : ''}&nbsp;pendiente{detalleSolicitud?.totalPendientes > 1 ? 's' : ''}</Typography>
                            </div>
                            <div style={ROW_STYLE}>
                                <CheckCircleIcon style={CHECK_CIRCLE_STYLE} />
                                <Typography style={ROW_TEXT_STYLE} variant="body1">Para transportar <b>{detalleSolicitud?.detalle?.strTipoProductoTransportar} - {detalleSolicitud?.detalle?.strProductoTransportar}</b> de <b>{detalleSolicitud?.detalle?.intCantidad} {detalleSolicitud?.detalle?.strUnidadMedida}</b></Typography>
                            </div>
                            <div style={ROW_STYLE}>
                                <CheckCircleIcon style={CHECK_CIRCLE_STYLE} />
                                <Typography style={ROW_TEXT_STYLE} variant="body1">Con carga <b>{detalleSolicitud?.detalle?.strTipoCarga}</b> y descarga <b>{detalleSolicitud?.detalle?.strTipoDescarga}</b></Typography>
                            </div>
                            <div style={ROW_STYLE}>
                                <CheckCircleIcon style={CHECK_CIRCLE_STYLE} />
                                <section style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography style={ROW_TEXT_STYLE} variant="body1">Notas: </Typography>
                                    <Typography variant="body1" style={{ paddingLeft: 7 }}><b>{detalleSolicitud?.detalle?.strComentarios}</b></Typography>
                                </section>
                            </div>
                            <section style={{ display: 'flex', flexDirection: 'column', marginTop: 25 }}>
                                <div style={ROW_STYLE}>
                                    <RoomIcon style={{ color: coloresMarcadores.green }} />
                                    <Typography style={ROW_TEXT_STYLE} variant="body1">De&nbsp;<b>{detalleSolicitud?.detalle?.strCiudadOrigen}&nbsp;</b>&nbsp;<span style={{ fontStyle: 'italic' }}>{construyeDireccionCarga(detalleSolicitud?.detalle)}</span>&nbsp;{detalleSolicitud?.detalle?.datFechaCargaRaw ? (<b>&nbsp;( {detalleSolicitud?.detalle?.datFechaCarga} a las {detalleSolicitud?.detalle?.datHoraCarga} hrs )</b>) : <></>}</Typography>
                                </div>
                                <div style={ROW_STYLE}>
                                    <RoomIcon style={{ color: coloresMarcadores.red }} />
                                    <Typography style={ROW_TEXT_STYLE} variant="body1">A&nbsp;<b>{detalleSolicitud?.detalle?.strCiudadDestino}&nbsp;</b>&nbsp;<span style={{ fontStyle: 'italic' }}>{construyeDireccionDescarga(detalleSolicitud?.detalle)}</span>&nbsp;{detalleSolicitud?.detalle?.datFechaDescargaRaw ? (<b>&nbsp;( {detalleSolicitud?.detalle?.datFechaDescarga} a las {detalleSolicitud?.detalle?.datHoraDescarga} hrs )</b>) : <></>}</Typography>
                                </div>
                                <div style={ROW_STYLE}>
                                    <LocalShippingIcon style={{ color: coloresMarcadores.blue }} />
                                    <Typography style={ROW_TEXT_STYLE} variant="body1">En&nbsp;<b>{detalleSolicitud?.detalle?.strTipoVehiculo}</b>&nbsp;a&nbsp;<b>$ {formatoMoneda(detalleSolicitud?.detalle?.dblPrecioUnitario)}&nbsp;{detalleSolicitud?.detalle?.strMoneda}</b></Typography>
                                </div>
                            </section>
                            <section style={{ display: 'flex', flexDirection: 'column', marginTop: 25 }}>
                                {detalleSolicitud?.paradas?.map((x, k) => {
                                    if (x.strCiudadOrigen || x.strCiudadDestino) return (
                                        <div style={ROW_STYLE} key={k}>
                                            <GpsFixedIcon style={{ color: coloresMarcadores.blue }} />
                                            <Typography style={ROW_TEXT_STYLE} variant="body1">{x.strProducto} en <b>{x.strCiudadOrigen}</b>{x.strCiudadDestino ? ' a' : ''}{x.strCiudadDestino ? <b>&nbsp;{x.strCiudadDestino}</b> : <></>}&nbsp;<span style={{ fontStyle: 'italic' }}>{construyeDireccionCarga(x)}</span>&nbsp;&nbsp;{x?.datFechaCargaRaw ? (<b>&nbsp;( {x?.datFechaCarga} a las {x?.datHoraCarga} hrs )</b>) : <></>}</Typography>
                                        </div>
                                    )
                                })}
                            </section>
                            {detalleSolicitud?.solicitudesConvertidas?.length
                                ?
                                <section style={{ display: 'flex', flexDirection: 'column', marginTop: 25, marginBottom: 25 }}>
                                    <div style={{ ...ROW_STYLE, marginBottom: 0 }}>
                                        <CheckCircleIcon style={CHECK_CIRCLE_STYLE} />
                                        <Typography style={ROW_TEXT_STYLE} variant="body1">Solicitudes atendidas</Typography>
                                    </div>
                                    {detalleSolicitud?.solicitudesConvertidas?.map((x, k) => (
                                        <span key={k} style={{ paddingLeft: 25 }}>
                                            <Link color="secondary" component="p" className="cursor-pointer" onClick={() => {
                                                setModalServicio({ open: true, dblPedido: x.dblPedido })
                                            }}>{x.strFolioServicio}{x.strNoReferencia ? `/${x.strNoReferencia}` : ''}</Link>
                                        </span>
                                    ))}
                                </section>
                                : <></>
                            }
                        </section>
                }

            </Grid>
        </FWDModal>
        <FWDModalServicio bitOpen={modalServicio.open} dblPedido={modalServicio.dblPedido}
            fnClose={() => { setModalServicio({ open: false, dblPedido: null }) }}
        />
    </>
}

export default injectIntl(FWDModalSolicitud)