import React, { Suspense, lazy, useEffect, useState } from "react";
import { Redirect, Switch, useLocation } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { useSelector } from 'react-redux'
import DashboardPage from "./pages/dashboard/Index.js";
import ConsultarServicios from "./pages/servicios/ConsultarServicios";
import ConsultarEstadoCuenta from "./pages/administracion/consultarEstadoCuenta/ConsultarEstadoCuenta";
import ConsultarPagos from "./pages/administracion/consultarPagos/ConsultarPagos";
import DireccionesCliente from 'app/pages/servicios/DireccionesCliente';
import WebSocketPortalCliente from "app/componentes/webSockets/WebSocketPortalCliente";
import Tarifario from 'app/pages/servicios/Tarifario';
import Solicitudes from 'app/pages/servicios/Solicitudes';
import CrearSolicitud from 'app/pages/servicios/CrearSolicitud';
import ConsultarCotizaciones from "./pages/servicios/ConsultarCotizaciones";
import ConsultaSolicitudes from 'app/pages/servicios/ConsultaSolicitudes'
import EditarInformacionUsuario from 'app/pages/usuario/EditarInformacion'

import { Demo1Dashboard } from 'app/../_metronic/_partials/dashboards/Demo1Dashboard'

// Rutas de configuracion (solo administrador)
import Seguridad from 'app/pages/configuraciones/Seguridad';
import PerfilesUsuario from 'app/pages/configuraciones/PerfilesUsuario'

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  const location = useLocation();
  const [ruta, set_ruta] = useState(useLocation());

  const { session } = useSelector(state => state.auth);
  const { userNavigation } = session

  useEffect(() => {
    set_ruta(location);
    // console.log('Location changed', location);
  }, [location, ruta]);

  
  let redirectPermiso = '/error/error-v1'

  if (userNavigation.length !== 0) {
    const permisoDashboard = userNavigation?.find(x => x.strPathMenu === '/dashboard')
    if (permisoDashboard)
      redirectPermiso = '/dashboard'
    else {
      if (userNavigation[0].opciones.length !== 0)
        redirectPermiso = userNavigation[0].opciones[0].strUrl
    }
  }



  return (
    <Suspense fallback={<LayoutSplashScreen />}>

      <WebSocketPortalCliente txtVista={encodeURIComponent(ruta.pathname)} />

      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to={redirectPermiso} />
        }
        {/* Inicio */}
        <ContentRoute path="/dashboard" component={DashboardPage} />

        {/* Configuracion usuario */}
        <ContentRoute path="/perfil-usuario" component={EditarInformacionUsuario} />

        {/* Servicios */}
        <ContentRoute path="/servicios/consultar" component={ConsultarServicios} />
        <ContentRoute path="/servicios/direcciones" component={DireccionesCliente} />
        <ContentRoute path="/servicios/tarifario" component={Tarifario} />
        <ContentRoute path="/servicios/solicitudes" component={Solicitudes} />
        <ContentRoute path="/servicios/crear-solicitud" component={CrearSolicitud}/>
        <ContentRoute path="/servicios/consultar-cotizaciones" component={ConsultarCotizaciones}/>
        <ContentRoute path="/servicios/consultar-solicitudes" component={ConsultaSolicitudes} />

        {/** Administraci�n */}
        <ContentRoute path="/administracion/estado-de-cuenta" component={ConsultarEstadoCuenta} />
        <ContentRoute path="/administracion/pagos" component={ConsultarPagos} />

        {/* Configuraciones (Solo usuario administrador) */}
        <ContentRoute path="/config/seguridad" component={Seguridad} />
        <ContentRoute path="/config/perfiles-usuario" component={PerfilesUsuario} />

        {
          process.env.NODE_ENV === 'development'
            ?
            <>
              <ContentRoute path="/builder" component={BuilderPage} />
              <ContentRoute path="/demodashboard/1" component={Demo1Dashboard} />
            </>
            : <></>
        }
        < Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
