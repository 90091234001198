import React from 'react'
import { injectIntl } from 'react-intl'
import { Grid, Typography, Divider } from '@material-ui/core'
import GpsFixed from '@material-ui/icons/GpsFixed'
import RoomIcon from '@material-ui/icons/Room';

function ViajeServicio({ intl, viaje, indice = 0, total = 1, markerColors = null }) {

    if (!viaje) return <></>;

    const {
        strDireccionOrigen,
        strCiudadOrigen,
        strEstadoOrigen,
        strPaisOrigen,
        datFechaHoraOrigen,
        strLugarRecogerOrigen,
        dblLatitudOrigen,
        dblLongitudOrigen,
        strCalleOrigen,
        strNumeroOrigen,
        strColoniaOrigen,
        strCPOrigen,
        strDireccionDestino,
        strCiudadDestino,
        strEstadoDestino,
        strPaisDestino,
        datFechaHoraDestino,
        strLugarEntregaDestino,
        dblLatitudDestino,
        dblLongitudDestino,
        strCalleDestino,
        strNumeroDestino,
        strColoniaDestino,
        strCPDestino,
        datFechaHoraRealEntrega,
        strTipoVehiculo,
        strTipoVehiculoIngles
    } = viaje;

    if (markerColors === null) {
        markerColors = {
            green: '#587D71',
            red: '#902D41',
            blue: '#2E5EAA'
        }
    }

    const IconoIzq = indice === 0
        ? <RoomIcon style={{ color: markerColors.green, marginRight: '5px' }} />
        : <GpsFixed style={{ color: markerColors.blue, marginRight: '5px' }} />

    const IconoDer = indice + 1 === total
        ? <RoomIcon style={{ color: markerColors.red, marginLeft: '5px' }} />
        : <GpsFixed style={{ color: markerColors.blue, marginLeft: '5px' }} />

    let fechaHoraOrigen = datFechaHoraOrigen || 'Sin definir';
    let fechaHoraDestino = datFechaHoraRealEntrega || datFechaHoraDestino || 'Sin definir';

    if (datFechaHoraOrigen) fechaHoraOrigen += ' hrs';
    if (datFechaHoraRealEntrega || datFechaHoraDestino) fechaHoraDestino += ' hrs';


    return <Grid item container direction="column" style={{ padding: '15px' }}>
        <Grid item style={{ display: 'flex', alignItems: 'flex-end' }}>
            {IconoIzq}
            <Typography variant="overline">{fechaHoraOrigen}</Typography>
            <Divider style={{ flexGrow: '1', margin: '0 5px', transform: 'translateY(-7px)' }} />
            <Typography variant="overline">{fechaHoraDestino}</Typography>
            {IconoDer}
        </Grid>
        <Grid item style={{ display: 'flex', justifyContent: 'space-between' }}>
            <section style={{ width: 'calc(50% - 2.5px)', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body1">{strCiudadOrigen || 'Sin definir'}</Typography>
                <Typography variant="subtitle1">{strEstadoOrigen}, {strPaisOrigen}</Typography>
                {indice === 0
                    ?
                    <Typography variant="caption">
                        {strCalleOrigen
                            ?
                            `${strCalleOrigen}${strNumeroOrigen ? ` #${strNumeroOrigen}` : ``}${strColoniaOrigen ? `, ${strColoniaOrigen}` : ``}${strCPOrigen ? ` C.P. ${strCPOrigen}` : ``}`
                            : ''
                        }
                    </Typography>
                    : <></>
                }
            </section>
            <section style={{ width: 'calc(50% - 2.5px)', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <Typography variant="body1">{strCiudadDestino || 'Sin definir'}</Typography>
                <Typography variant="subtitle1">{strEstadoDestino}, {strPaisDestino}</Typography>
                {indice + 1 === total
                    ?
                    <Typography variant="caption" style={{ textAlign: 'right' }}>
                        {strCalleDestino
                            ?
                            `${strCalleDestino}${strNumeroDestino ? ` #${strNumeroDestino}` : ``}${strColoniaDestino ? `, ${strColoniaDestino}` : ``}${strCPDestino ? ` C.P. ${strCPDestino}` : ``}`
                            : ''
                        }
                    </Typography>
                    :
                    <></>
                }
            </section>
        </Grid>
    </Grid>
}

export default injectIntl(ViajeServicio);

