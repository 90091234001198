import React from 'react'
import axios from 'axios'
import { injectIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles';
import {Typography, Grid, Popover, ClickAwayListener } from '@material-ui/core';

import { FileIcon, defaultStyles } from 'react-file-icon';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
  },
}));

function PopoverCotizacionAceptacionInterna(props) {

    const {intl, idCot, folioCot, comentario, docCot, docEvidencia, handleClick_verDocumento} = props;
    const classes = useStyles();
    const docStyles = defaultStyles;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        if(anchorEl == null) {
            setAnchorEl(event.currentTarget);
        } else {
            setAnchorEl(null);
        }
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = `popover-${idCot}`;
    
    return <ClickAwayListener onClickAway={handlePopoverClose} >
        <div aria-describedby={id}>

            <button
                aria-describedby={id}
                style={{ padding: 0, maxWidth: '150px', textAlign: 'left' }}
                className="btn btn-link" onClick={(event) => {handlePopoverOpen(event)}}>
                    {folioCot}
            </button>
            <Popover
                id={id}
                classes={{paper: classes.paper,}}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{vertical: 'bottom',horizontal: 'left',}}
                transformOrigin={{vertical: 'top',horizontal: 'left',}}
                onClick={handlePopoverClose}>
                <Grid container spacing={1} style={{width:'250px'}} >
                    <Grid item sm={12} style={{paddingBottom: '20px'}}>
                        <Typography variant="h6">{intl.formatMessage({ id: 'SERVICIOS.POPUP.INFO_COTIZACION_ACEPTADA.TITULO' })}</Typography>
                    </Grid>

                    <Grid item container sm={12} style={{paddingBottom: '20px'}}>
                        <Grid item sm={12}>
                            <Typography variant="subtitle1">{intl.formatMessage({ id: 'SERVICIOS.POPUP.INFO_COTIZACION_ACEPTADA.COMENTARIO_ACEPTACION' })}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography variant="subtitle2">{comentario}</Typography>
                        </Grid>
                    </Grid>

                    <Grid item sm={12}>
                        <button className="btn btn-link btn-no-seleccionable" onClick={() => {handleClick_verDocumento(docEvidencia)}} style={{padding: 0}}>
                            <div style={{width: '20px', display: 'inline-block', marginRight: '15px'}}> 
                                <FileIcon/>
                            </div>
                            {intl.formatMessage({ id: 'SERVICIOS.POPUP.INFO_COTIZACION_ACEPTADA.BOTON.DESCARGAR_EVIDENCIA' })}
                        </button>
                    </Grid>
                    
                    <Grid item sm={12}>
                        <button className="btn btn-link btn-no-seleccionable" onClick={() => {handleClick_verDocumento(docCot)}} style={{padding: 0}}>
                            <div style={{width: '20px', display: 'inline-block', marginRight: '15px'}}> 
                                <FileIcon extension={'pdf'} {...docStyles['pdf']}/>
                            </div>
                            {intl.formatMessage({ id: 'SERVICIOS.POPUP.INFO_COTIZACION_ACEPTADA.BOTON.DESCARGAR_DOC_COT' })}
                        </button>
                    </Grid>


                    
                </Grid>
            </Popover>
        </div>
    </ClickAwayListener>
}

export default injectIntl(PopoverCotizacionAceptacionInterna);