/*global google*/
import React, { useState, useEffect, useRef } from 'react'
import { Map, GoogleApiWrapper, Marker, InfoWindow, Polyline } from 'google-maps-react';
import SincronizadorMapa from 'app/componentes/webSockets/WebSocketSincronizadorFwdSistema'
import { injectIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import CalcularEventosRuta from 'app/componentes/servicios/funcionesMapa/CalcularEventosRuta'
import GenerarPolylines from 'app/componentes/servicios/funcionesMapa/GenerarPolylines'
import obtenerSeccion from 'app/componentes/servicios/funcionesMapa/ObtenerSeccionUnidad'
import moment from 'moment'
import 'moment/locale/es';
import formatoFechaHora from 'app/formatos/FechaHora';
import clasificarEventoRuta from 'app/componentes/servicios/funcionesMapa/ClasificarEventoRuta';
import { LinearProgress } from '@material-ui/core'

const RUTA_DESTINO = 2;
const infoWindowMarkerDefaultState = { visible: false, marker: null, content: null };
const initialCenter = { lat: 25.658731, lng: -100.360774 };

function MapaServicios(props) {
    const { intl, mapaStyle, zoom, mostrarPins = false } = props;

    const [infoWindowMarker, setInfoWindowMarker] = useState(infoWindowMarkerDefaultState);

    const [map, setMap] = useState(null);
    const [bitCentrarMapa, setBitCentrarMapa] = useState(null);

    const [listaServicios, setListaServicios] = useState(null);
    const [listaUnidades, setListaUnidades] = useState(null);
    const [dblPedidoSeleccionado, setDblPedidoSeleccionado] = useState(0);

    const [pins, setPins] = useState([])

    const [servicioSeleccionado, setServicioSeleccionado] = useState(null);
    const [polylines, setPolylines] = useState(null);
    const [ubicacionOrigen, setUbicacionOrigen] = useState(null);
    const [ubicacionDestino, setUbicacionDestino] = useState(null);
    const [listaSeguimientos, setListaSeguimientos] = useState(null);

    const [bitCargando, setBitCargando] = useState(false);

    useEffect(() => {
        setBitCentrarMapa(props.centrarMapa);
    }, [bitCentrarMapa, props.centrarMapa]);

    useEffect(() => {
        setListaServicios(props.listaServicios);
        // console.log("listaServicios >>> ", listaServicios);
        if(listaServicios == props.listaServicios) {
            setServicio_dblPedidoSeleccionado();
        }

    }, [listaServicios, props.listaServicios]);

    useEffect(() => {
        if (Number(props.dblPedidoSeleccionado) === dblPedidoSeleccionado) {
            setServicio_dblPedidoSeleccionado();
        }
        setDblPedidoSeleccionado(Number(props.dblPedidoSeleccionado));
    }, [dblPedidoSeleccionado, props.dblPedidoSeleccionado]);

    useEffect(() => {
        setUbicacionOrigen(servicioSeleccionado ? servicioSeleccionado.ubicacionOrigen : null);
        setUbicacionDestino(servicioSeleccionado ? servicioSeleccionado.ubicacionDestino : null);
        if(servicioSeleccionado) {
            generarRutaServicio();
        }
        
        if(map) {
            var bounds = new google.maps.LatLngBounds();
            if(servicioSeleccionado) {
                let hasBounds = false;
                if(servicioSeleccionado.ubicacionOrigen?.lat != undefined && servicioSeleccionado?.ubicacionOrigen.lat != null){
                    bounds.extend(new google.maps.LatLng(Number(servicioSeleccionado.ubicacionOrigen.lat), Number(servicioSeleccionado.ubicacionOrigen.lng)));
                    hasBounds = true;
                }
                if(servicioSeleccionado.ubicacionDestino?.lat != undefined && servicioSeleccionado?.ubicacionDestino.lat != null){
                    bounds.extend(new google.maps.LatLng(Number(servicioSeleccionado.ubicacionDestino.lat), Number(servicioSeleccionado.ubicacionDestino.lng)));
                    hasBounds = true;
                }
                if(hasBounds) {
                    map.fitBounds(bounds);
                }
                
            } else {
                if(listaUnidades) {
                    listaUnidades.forEach((unidad) => {
                        if(unidad.dblLatitud && unidad.dblLongitud){
                            bounds.extend(new google.maps.LatLng(Number(unidad.dblLatitud), Number(unidad.dblLongitud)));
                        }
                    });
                }

                map.fitBounds(bounds);
            }
        }
    }, [servicioSeleccionado]);

    const timerMostrarPinsMapa = useRef()
    useEffect(() => {

        if (mostrarPins && dblPedidoSeleccionado) {
            timerMostrarPinsMapa.current = setTimeout(() => mostrarPinsMapaEnPedidoSeleccionado(), 1000);
        }
        else {
            setPins([])
            if (timerMostrarPinsMapa) clearInterval(timerMostrarPinsMapa.current);
        }
    }, [dblPedidoSeleccionado, mostrarPins]);

    const setServicio_dblPedidoSeleccionado = () => {
        let _servicio = null;
        let listaUnidades_aux = listaUnidades ? listaUnidades : [];
        let polylines_aux = [];

        if(listaServicios) {
            listaServicios.forEach((servicio) => {
                if (Number(servicio.dblPedido) === dblPedidoSeleccionado) {
                    servicio.bitVisible = true;
                    servicio.bitPintarRuta = true;
                    _servicio = servicio;
                } else {
                    servicio.bitVisible = dblPedidoSeleccionado == 0;
                    servicio.bitPintarRuta = false;
                }

                servicio.arrayUnidades.forEach((unidad) => {
                    unidad.bitVisible = servicio.bitVisible;
                    unidad.bitPintarRuta = servicio.bitPintarRuta;
                });

                //Si no hay pedido seleccionado entonces mostramos a todas las unidades
                if (Number(servicio.dblPedido) === dblPedidoSeleccionado && dblPedidoSeleccionado != 0) {
                    listaUnidades_aux = [...servicio.arrayUnidades];
                    servicio.arrayUnidades.forEach((unidad) => {
                        var seccion = obtenerSeccion(unidad);
                        if(seccion) {
                            if(seccion.hasOwnProperty('polylines')) {
                                if(seccion.polylines.length > 0) {
                                    polylines_aux = [...polylines_aux, ...seccion.polylines];
                                }
                            }
                        }
                    });
                } else if(dblPedidoSeleccionado == 0){
                    
                    for (let indexUnidad = 0; indexUnidad < servicio.arrayUnidades.length; indexUnidad++) {
                        let unidad = servicio.arrayUnidades[indexUnidad];

                        if(!buscarUnidad(listaUnidades_aux, unidad.strNumeroPlaca)) {
                            listaUnidades_aux.push(unidad);
                        }
                    }
                }
            });
        }

        setServicioSeleccionado(_servicio);
        setListaUnidades(listaUnidades_aux);
        setInfoWindowMarker(infoWindowMarkerDefaultState);
        setPolylines(polylines_aux);

        if(_servicio?.arraySeguimientos) {
            setListaSeguimientos(_servicio.arraySeguimientos);
        } else {
            setListaSeguimientos(null);
        }
/*
        console.log("dblPedidoSeleccionado >>> ", dblPedidoSeleccionado);
        console.log("servicioSeleccionado >>> ", _servicio);
        console.log("listaServicios >>> ", listaServicios);
        console.log("ListaUnidades >>> ", listaUnidades_aux);
        console.log("polylines >>> ", polylines_aux);*/
    }

    const buscarUnidad = (listaUnidades, strNumeroPlaca) => {
        for (let index = 0; index < listaUnidades.length; index++) {
            const unidad = listaUnidades[index];

            if(unidad.strNumeroPlaca == strNumeroPlaca) {
                return unidad;
            }
        }
        return false;
    }

    const descargarEventosGPSUnidadSeleccionada = () => {
        return new Promise((resolve, reject) => {

            if(dblPedidoSeleccionado == 0) {
                reject({error: "No hay servicio seleccionado"});
            } else {

                if(servicioSeleccionado.bitEventosDescargados == true) {
                    resolve(servicioSeleccionado);
                } else {
                    
                    fetch(`${process.env.REACT_APP_AWS_API_FWD_TRACKING_URL}/rastreo/servicio`, 
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({ dblPedido: dblPedidoSeleccionado })
                        }
                    )
                    .then(responseJson => {
                        if (responseJson.status != 200 || !responseJson) {
                            throw { codigo: 'SinRespuestaException' }
                        }
                        return responseJson.json()
                    })
                    .then(arrayUnidadesEventos => {
                        // console.log("responseRastreo >>> ", arrayUnidadesEventos)
    
                        servicioSeleccionado.arrayUnidades.forEach((unidad) => {
                            for (const index in arrayUnidadesEventos) {
    
                                if (Number(arrayUnidadesEventos[index].dblPedido) === Number(unidad.dblPedido) && arrayUnidadesEventos[index].strNumeroPlaca === unidad.strNumeroPlaca) {
                                    unidad.arrayEventos = arrayUnidadesEventos[index].arrayEventos;
                                }
                            }
                        });
    
                        servicioSeleccionado.bitEventosDescargados = true;
    
                        resolve(servicioSeleccionado);
                    })
                    .catch(error => { 
                        console.log("fetchRastreoServicio: ", error)
                        reject({error});
                    })
                }
            }
        });
    }

    const generarRutaServicio = async () => {
        try {
            setBitCargando(true);
            let servicio = await descargarEventosGPSUnidadSeleccionada();
            // console.log("descargarEventosGPSUnidadSeleccionada >>> ", servicio);
            servicio = CalcularEventosRuta(servicio);
            // console.log("CalcularEventosRuta >>> ", servicio);
            servicio = await GenerarPolylines(servicio);
            // console.log("GenerarPolylines >>> ", servicio);
            setServicio_dblPedidoSeleccionado();
        } catch(error) {
            console.log("generarRutaServicio", error);
        }
        setBitCargando(false);
    }

    const abrirInfoWindowUnidad = (unidad, marker) => {
        setInfoWindowMarker({
            visible: true, marker: marker, content: (
                <div id="content"> <div className="mb-2"><strong>{unidad.strNumeroServicio}</strong></div>
                    <div id="bodyContent">
                        <p>
                            <strong>{intl.formatMessage({ id: "MAPA.LABEL.UNIDAD" })}:</strong> {unidad.strNumeroPlaca}<br />
                            <strong>{intl.formatMessage({ id: "MAPA.LABEL.ULTIMA_ACTUALIZACION" })}:</strong> {unidad.datFechaHoraAlta}<br />
                        </p>
                    </div>
                </div>
            )
        });
    }

    const mostrarPinsMapaEnPedidoSeleccionado = (diferenciaEnMinutos = 15, direccionRuta = RUTA_DESTINO) => {
        try {
            const unidades = servicioSeleccionado?.arrayUnidades?.map(x => x)

            if (!unidades) throw { FWDError: 'DatoNecesarioException' }

            let secciones = []
            for (const unidad of unidades) {

                // Unidad debe enviar bit de visibilidad y pintado
                if (!(unidad?.bitVisible && unidad?.bitPintarRuta))
                    break

                //  Obtenemos ruta hacia
                //  RUTA_ORIGEN  : Hacia Origen   (1)
                //  RUTA_DESTINO : Hacia Destino  (2)
                const seccion = unidad
                    ?.arraySecciones
                    ?.find(x => x.intSeccion === direccionRuta)

                //  Agregamos la seccion en la lista si se encontr� en el filtro anterior
                if (seccion)
                    secciones = [...secciones, seccion.arrayEventos]
            }

            let eventos = []
            if (secciones.length) {
                let ultimoDateTimeEvento = moment(secciones[0][0].datFechaHoraAlta);
                for (const seccion of secciones) {
                    for (const evento of seccion) {
                        const dtEvento = moment(evento.datFechaHoraAlta)
                        const diffMinutos = dtEvento.diff(ultimoDateTimeEvento, 'minutes')
                        if (diffMinutos > diferenciaEnMinutos) {
                            eventos.push(evento)
                            ultimoDateTimeEvento = dtEvento
                        }
                    }
                }
            }

            if (eventos.length > pins.length) {
                setPins(eventos)
            }
        }
        catch (error) {
            console.log('[mostrarPinsMapaEnServicioError] ', error)
        }
    }

    const onPinClick = (props, marker, e) => {

        const { datFechaHoraAlta } = props.pinData
        const fechaHora = moment(datFechaHoraAlta)
        fechaHora.locale('es')
        const fechaHoraCalendarizada = fechaHora.calendar()
        const fechaHoraCapitalizada = fechaHoraCalendarizada.charAt(0).toUpperCase() + fechaHoraCalendarizada.slice(1)
        setInfoWindowMarker({
            visible: true, marker: marker, content: (
                <div id="content"> <div className="mb-2"><strong>{fechaHoraCapitalizada + ' hrs'}</strong></div>
                    {/* <div id="bodyContent"><p></p></div> */}
                </div>
            )
        })
    }

    const handleClick_pinSeguimiento = (props, marker, e) => {
        const { datFechaHora, strSeguimientoCliente } = props.pinData;
        setInfoWindowMarker({
            visible: true, marker: marker, content: (
                <div id="content"> <div className="mb-2">
                    <strong>{datFechaHora}</strong></div>
                    <div id="bodyContent"><p>{strSeguimientoCliente}</p></div>
                </div>
            )
        })
    }

    const habilitarRastreoUnidad = (data) => {
        if (!listaServicios) return;

        listaServicios.forEach(servicio => {
            if (Number(servicio.dblPedido) === (data.dblPedido)) {
                servicio.arrayUnidades.forEach(unidad => {
                    if (unidad.strNumeroPlaca === data.strNumeroPlaca) {
                        console.log(`${data.dblPedido}][${data.strNumeroPlaca}] Status rastreo : ${data.rastreoActivo ? "activo" : "inactivo"}`);
                        unidad.bitPermiteSincronizacion = Number(data.rastreoActivo);
                    }
                });
            }
        });

        setServicio_dblPedidoSeleccionado();

    }

    const handlePrincipalSincronizaEvento = async (evento) => {
        console.log("[handlePrincipalSincronizaEvento]");
        if (evento.hasOwnProperty('tipo')) {
            switch (evento.tipo) {
                case "habilitarSeguimiento":
                    habilitarRastreoUnidad(evento);
                    break;
                default:
                    console.log("evento no controlado", evento);
            }
        } else if (evento.hasOwnProperty('dblLatitud')) {
            for (let indexServicio = 0; indexServicio < listaServicios.length; indexServicio++) {
                var servicio = listaServicios[indexServicio];

                if (Number(servicio.dblPedido) === Number(evento.strNumeroServicio)) {
                    for (let indexUnidad = 0; indexUnidad < servicio.arrayUnidades.length; indexUnidad++) {
                        var unidad = servicio.arrayUnidades[indexUnidad];
                        if (unidad.strNumeroPlaca === evento.strNumeroPlaca && Number(unidad.bitPermiteSincronizacion) === 1) {
                            console.log("Nuevo evento aceptado", evento.strNumeroPlaca);
                            unidad.dblLatitud = Number(evento.dblLatitud);
                            unidad.dblLongitud = Number(evento.dblLongitud);
                            let date = new Date(evento.datFechaHoraAlta);
                            unidad.datFechaHoraAlta = formatoFechaHora(date, true);

                            if (unidad.hasOwnProperty('arrayEventos')) {
                                unidad.arrayEventos.push(evento);

                                clasificarEventoRuta(servicio, unidad, evento);
                                var seccion = obtenerSeccion (unidad, RUTA_DESTINO);
                                if (seccion && evento.distanciaEntrePuntos > .050)
                                    seccion.arrayEventos.push(evento);

                    
                                if (Number(servicio.dblPedido) == dblPedidoSeleccionado){
                                    servicio = await GenerarPolylines(servicio);
                                }
                            }

                            setServicio_dblPedidoSeleccionado();
                        }
                    }
                }
            }
        }
    }

    

    return (
        <>

            { listaServicios?.map((servicio) => {
                    return <SincronizadorMapa key={servicio.dblPedido} txtUsuario="hugoViza" txtVista={`Mapa_${servicio.dblPedido}`} callBackMessage={handlePrincipalSincronizaEvento} />
                })
            }
            {bitCargando 
                ? <LinearProgress  />
                : <></>
            }

            <Map
                ref={(ref) => { setMap(ref ? ref.map : null) }}
                google={props.google}
                zoom={zoom || 5}
                style={mapaStyle || { width: '100%', height: "calc(100vh - 250px)" }}
                initialCenter={initialCenter}
                gestureHandling="greedy">
                
                {/* Pines de unidades */}
                {listaUnidades ? 
                    listaUnidades.map((unidad) => {
                        return unidad.dblLatitud && unidad.dblLongitud ? 
                            <Marker
                                visible={unidad.bitVisible}
                                key={`${unidad.strNumeroPlaca}`}
                                id={`unidad-${unidad.strNumeroPlaca}`}
                                position={{ lat: unidad.dblLatitud, lng: unidad.dblLongitud }}
                                icon={toAbsoluteUrl(`/media/markers/marker_truck_${unidad.strColor}.png`)}
                                onClick={(element_props, marker, e) => {abrirInfoWindowUnidad(unidad, marker);}} >
                            </Marker> : 
                            <></>
                    }) : <></>
                }

                { /* Linea de ruta */}
                {polylines?.map((polyline, index) => {
                        return <Polyline key={index}
                            path={polyline.path}
                            strokeColor={polyline.options.strokeColor}
                            strokeOpacity={polyline.options.strokeOpacity}
                            strokeWeight={polyline.options.strokeWeight} />
                    }) 
                }

                {pins?.map((pin, pindx) => (
                    <Marker
                        visible={true}
                        key={pindx}
                        pinData={pin}
                        position={{ lat: Number(pin.dblLatitud), lng: Number(pin.dblLongitud) }}
                        icon={toAbsoluteUrl(`/media/markers/pin_24.png`)}
                        onClick={onPinClick}>
                    </Marker>
                ))}


                {listaSeguimientos?.map((seguimiento, pinindexSeguimientodx) => (
                    <Marker
                        visible={true}
                        key={pinindexSeguimientodx}
                        pinData={seguimiento}
                        position={{ lat: Number(seguimiento.dblLatitud), lng: Number(seguimiento.dblLongitud) }}
                        onClick={handleClick_pinSeguimiento}>
                    </Marker>
                ))}

                { /* Origen*/}
                {ubicacionOrigen
                    ? <Marker
                        visible={true}
                        key="ubicacionOrigen"
                        id="ubicacionOrigen"
                        position={{ lat: Number(ubicacionOrigen.lat), lng: Number(ubicacionOrigen.lng) }}
                        label="Origen"
                        icon={toAbsoluteUrl(`/media/markers/marker_short_text_dark_green.png`)}
                        onClick={e => console.log("click en origen")} >
                    </Marker>
                    : <></>
                }
                { /* Destino*/}
                {ubicacionDestino
                    ? <Marker
                        visible={true}
                        key="ubicacionDestino"
                        id="ubicacionDestino"
                        position={{ lat: Number(ubicacionDestino.lat), lng: Number(ubicacionDestino.lng) }}
                        label="Destino"
                        icon={toAbsoluteUrl(`/media/markers/marker_short_text_dark_red.png`)}
                        onClick={e => console.log("click en destino")} >
                    </Marker>
                    : <></>
                }

                <InfoWindow
                    visible={infoWindowMarker.visible}
                    marker={infoWindowMarker.marker}
                    onClose={() => {setInfoWindowMarker(infoWindowMarkerDefaultState)}}>
                    <div id="infoWindow">
                        {infoWindowMarker.content}
                    </div>
                </InfoWindow>
            </Map>
        </>
    );
}


export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
})(injectIntl(MapaServicios));
