import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl'
import {
    TextField,
    CircularProgress,
    Typography,
    LinearProgress,
    MenuItem,
    FormControl,
    Select
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// Funci�n para realizar la solicitud a la API

const fetchAutocompleteAPI = async (busqueda, catalogo) => {
    const catalogos = catalogo.toLowerCase()?.trim()
    const busquedaLimpia = busqueda.replace(/[^a-zA-Z0-9]/g, '');

    let api_url = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/servicios/solicitudes/api/autocompletecatalogos';
    api_url += `?strCatalogo=${catalogos}&strBusqueda=${busquedaLimpia || ''}`;
    try {
        const response = await axios.get(api_url);
        const lstResponse = response.data.catalogo;

        return lstResponse;
    }
    catch (error) {
        console.log('error', error);
    }
}

const fetchAutocompleteAPISelect = async (catalogName, additionalFilter) => {

    let api_url = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/servicios/solicitudes/api/catalogos';
    api_url += `?strCatalogo=${catalogName}&strFiltro1=${additionalFilter}`;
    try {
        const response = await axios.get(api_url);
        const lstResponse = response.data.catalogos;
        return lstResponse;
    }
    catch (error) {
        console.log('error', error);
    }
}

//Autocomplete general para seleccionar

export const AutocompleteCatalogos = ({ catalogName, label, onItemSelected, editedFormItems, selectedValue, cleanAutocomplete }) => {
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorEmpty, setErrorEmpty] = useState(true);
    const [autocompleteValue, setAutocompleteValue] = useState(null);

    useEffect(() => {
        if (inputValue.trim() === '') {
            setOptions([]);
            return undefined;
        }

        let active = true;

        (async () => {
            setLoading(true);
            try {
                const response = await fetchAutocompleteAPI(inputValue, catalogName);
                if (active) {
                    setOptions(response);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        })();

        return () => {
            active = false;
        };
    }, [inputValue, catalogName]);

    useEffect(() => {
        if (cleanAutocomplete) {
            handleInputClean()
        }

    }, [cleanAutocomplete]);

    const handleItemSelected = (event, value) => {
        if (value) {
            onItemSelected(value); // Llama a la funci�n para manejar el valor seleccionado
            setAutocompleteValue(value);
        } else {
            onItemSelected(null); // Si se deselecciona, pasa null al estado
        }
    };

    const handleInputChange = (event, newInputValue) => {
        // Utiliza una expresi�n regular para permitir solo n�meros y letras
        const cleanValue = newInputValue.replace(/[^a-zA-Z0-9]/g, '');
        setErrorEmpty(false);
        setInputValue(cleanValue);
    };

    const handleInputClean = () => {
        setAutocompleteValue(null);
    };

    return (
        <Autocomplete
            options={options}
            getOptionLabel={(option) => option.TITLE}
            loading={loading}
            onInputChange={handleInputChange}
            onChange={handleItemSelected} // Maneja el evento de selecci�n
            value={autocompleteValue}
            noOptionsText={'Sin Opciones'}
            loadingText={
                <section style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="overline">
                        Cargando
                    </Typography>
                    <LinearProgress />
                </section>
            }
            renderInput={(params) => (
                <TextField
                    error={errorEmpty}
                    id="standard-error"
                    placeholder="Escribe para buscar..."
                    {...params}
                    label={label}
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? (
                                    <></>
                                ) : null}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};
export const AutocompleteSelectCatalogos = ({ catalogName, label, onItemSelected, editedFormItems, selectedValue }) => {

    const [inputValue, setInputValue] = useState('a');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorEmpty, setErrorEmpty] = useState(true);


    useEffect(() => {
        if (inputValue.trim() === '') {
            setOptions([]);
            return undefined;
        }

        let active = true;

        (async () => {
            setLoading(true);
            try {
                const response = await fetchAutocompleteAPI(inputValue, catalogName);
                if (active) {
                    setOptions(response);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        })();

        return () => {
            active = false;
        };
    }, [inputValue, catalogName]);

    const handleItemSelected = (event, value) => {
        if (value) {
            onItemSelected(value); // Llama a la funci�n para manejar el valor seleccionado
        } else {
            onItemSelected(null); // Si se deselecciona, pasa null al estado
        }
    };

    const handleInputChange = (event, newInputValue) => {
        // Utiliza una expresi�n regular para permitir solo n�meros y letras
        const cleanValue = newInputValue.replace(/[^a-zA-Z0-9]/g, '');
        setErrorEmpty(false);
        setInputValue(cleanValue);
    };

    return (
        <>

            <Autocomplete
                options={options}
                getOptionLabel={(option) => option.TITLE}
                loading={loading}
                onInputChange={handleInputChange}
                onChange={handleItemSelected} // Maneja el evento de selecci�n
                // selectedValue={selectedValue}
                renderInput={(params) => <TextField {...params} label={label} />}
            />
        </>

    );
};

//Autocomplete para rutas dinamicas
export const AutocompleteRutaCatalogos = ({ catalogName, label, onItemSelected, inputValueAutocomplete, error }) => {
    const [inputValue, setInputValue] = useState(inputValueAutocomplete);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [autocompleteValue, setAutocompleteValue] = useState(null);

    useEffect(() => {
        setInputValue(inputValueAutocomplete);
    }, [inputValueAutocomplete]);

    useEffect(() => {
        if (!inputValue) {
            // Si el inputValue es null o undefined, no realices la b�squeda.
            setOptions([]);
            return;
        }

        let active = true;

        (async () => {
            setLoading(true);
            try {
                const response = await fetchAutocompleteAPI(inputValue, catalogName);
                if (active) {
                    setOptions(response);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        })();

        return () => {
            active = false;
        };
    }, [inputValue, catalogName]);


    const handleItemSelected = (event, value) => {
        if (value) {
            onItemSelected(value);
            setAutocompleteValue(value);
        } else {
            onItemSelected(null);
        }
    };

    const handleInputChange = (event, newInputValue) => {
        // Actualiza el estado inputValue con el nuevo valor de entrada
        setInputValue(newInputValue);
    };

    return (
        <Autocomplete
            options={options}
            getOptionLabel={(option) => option.TITLE}
            loading={loading}
            value={autocompleteValue}
            onInputChange={handleInputChange} // Maneja el cambio de entrada
            onChange={handleItemSelected}
            renderInput={(params) => (
                <TextField
                    id="standard-error"
                    error={error}
                    placeholder="Escribe para buscar..."
                    {...params}
                    label={label}
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? (
                                    <CircularProgress
                                        variant="indeterminate"
                                        disableShrink
                                        size={24}
                                        thickness={4}
                                    />
                                ) : null}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};

export const AutocompleteVehiculosCatalogos = ({ catalogName, label, onItemSelected, cleanAutocomplete }) => {
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [errorEmpty, setErrorEmpty] = useState(true);

    const [autocompleteValue, setAutocompleteValue] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchAutocompleteAPISelect(catalogName, '');
                setOptions(response);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [catalogName]);

    useEffect(() => {
        if (cleanAutocomplete) {
            handleInputClean()
        }

    }, [cleanAutocomplete]);

    const handleItemSelected = (event, value) => {
        if (value) {
            onItemSelected(value);
            setAutocompleteValue(value);
        } else {
            onItemSelected(null);
        }
    };

    const handleInputChange = (event, newInputValue) => {
        const cleanValue = newInputValue?.replace(/[^a-zA-Z0-9]/g, '');
        setErrorEmpty(false);
        setInputValue(cleanValue);
    };

    const handleInputClean = () => {
        setAutocompleteValue(null);
    };

    return (
        <Autocomplete
            options={options}
            getOptionLabel={(option) => option.VALUE_TEXT}
            onInputChange={handleInputChange}
            onChange={handleItemSelected}
            value={autocompleteValue}
            renderInput={(params) => (
                <TextField
                    error={errorEmpty}
                    id="standard-error"
                    {...params}
                    label={label}
                />
            )}
        />
    );
};




