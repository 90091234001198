import React, { useState, useEffect } from "react"
import { makeStyles } from '@material-ui/core/styles';
import { InputLabel, MenuItem, FormHelperText, FormControl, Select, Typography } from '@material-ui/core'
import { useSelector, useDispatch } from "react-redux"
import { actions } from "app/modules/Auth/_redux/authRedux";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const SelectCustomer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { user, session, selectedClienteComercial } = useSelector(state => state.auth);
  const clientes = session.listaVarClientes;
  const [clienteComercial, setClientecomercial] = useState(clientes[0].strVarClienteOrigen);
  const handleChange = (event) => {
    const selectedClienteComercial = event.target.value;
    setClientecomercial(selectedClienteComercial);

    dispatch(actions.fulfillUser(user, session, event.target.value));
  };

  useEffect(() => {
    // Llama a handleChange para asignar el valor inicial de clienteComercial
    handleChange({ target: { value: clienteComercial } });
  }, []);

  return (
    <div style={{ marginTop: '-10px', marginRight: 20 }}>

      {(clientes.length != 1) ? (
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Nombre Comercial</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={clienteComercial}
            onChange={handleChange}
          >
            {clientes.map((option, index) => (
              <MenuItem key={index} value={option.strVarClienteOrigen}>
                {option.strFranquicia}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

      ) : (
        <>
          <Typography style={{ color: '#7d7d7d', marginTop: 10 }}>Nombre Comercial</Typography>
          <Typography style={{ color: 'black', fontSize: 15 }}>{clientes[0].strFranquicia}</Typography>
        </>
      )}

    </div>
  )
}