import React, { useEffect, useState, useRef } from 'react'
import { injectIntl } from 'react-intl'
import axios from 'axios'
import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TextField, InputAdornment } from '@material-ui/core'
import { Paper, Typography, LinearProgress, FormControl, InputLabel, Select, MenuItem, IconButton, Tooltip } from '@material-ui/core'
import formatoMoneda from 'app/formatos/Moneda'
import { Skeleton, Autocomplete } from '@material-ui/lab';
import { debounce } from 'lodash'
import SearchIcon from '@material-ui/icons/Search';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useSelector } from 'react-redux';
import FWDModalSolicitud from 'app/componentes/FWDModalSolicitud'
import { FWDCabecera, FWDTableHead, FWDOrderHelper } from 'app/componentes/FWDTableHead'
import { LoaderDashboardTables } from 'app/componentes/dashboard/LoaderDashboard'

// https://92v9zvrmx3.execute-api.us-east-1.amazonaws.com/dev/portal/servicios/consultar-solicitudes/api/consultar-solicitudes
// https://92v9zvrmx3.execute-api.us-east-1.amazonaws.com/dev/portal/servicios/consultar-solicitudes/api/catalogo-tipos-vehiculo
const BASE_URL = process.env.REACT_APP_AWS_API_BASE_URL

const CAT_MONEDAS = {
    MXN: 1,
    USD: 2
}

const ConsultaSolicitudes = (props) => {
    const { intl } = props
    const { session, selectedClienteComercial } = useSelector(state => state.auth);
    const listaVarClientes = session.listaVarClientes.map(x => x.strVarClienteOrigen);
    const [solicitudes, setSolicitudes] = useState(new Array(1).fill())
    const [catalogoCiudadesOrigen, setCatalogoCiudadesOrigen] = useState([])
    const [catalogoCiudadesDestino, setCatalogoCiudadesDestino] = useState([])
    const [catalogoTiposVehiculos, setCatalogosTiposVehiculos] = useState([])
    const [catalogoTiposMoneda] = useState([{ id: CAT_MONEDAS.MXN, valor: 'MXN' }, { id: CAT_MONEDAS.USD, valor: 'USD' }])
    const [catalogoEstatusSolicitudes] = useState([{ id: 'VIG', valor: 'Vigente' }, { id: 'VEN', valor: 'Vencido' }])

    const [cargandoSolicitudes, setCargandoSolicitudes] = useState(true)
    const [cargandoCiudadOrigen, setCargandoCiudadOrigen] = useState(false)
    const [cargandoCiudadDestino, setCargandoCiudadDestino] = useState(false)
    const [cargandoCatalogoTiposVehiculos, setCargandoCatalogoTiposVehiculos] = useState(true)

    const [filtrosBusqueda, setFiltrosBusqueda] = useState({ origen: null, destino: null, moneda: null, tipoVehiculo: null, estatus: 'VIG' })
    const [autocompleteValues, setAutocompleteValues] = useState({ origen: '', destino: '' })
    const [watcherBusqueda, setWatcherBusqueda] = useState(Date.now())
    const [modalSolicitud, setModalSolicitud] = useState({ open: false, dblServicioCliente: null })

    const [resetOrderTable, setResetOrderTable] = useState(null)

    const fetchCatalogoCiudades = async (cual = 'O', strCiudad) => {
        if (cual === 'O')
            setCargandoCiudadOrigen(true)
        if (cual === 'D')
            setCargandoCiudadDestino(true);

        strCiudad = strCiudad.trim();
        let api_url = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/servicios/consultar/api/catalogo-ciudades';
        api_url += '?strCiudad=' + strCiudad || '';

        try {
            const response = await axios.get(api_url);
            const ciudades = response.data.ciudades;
            if (cual === 'O')
                setCatalogoCiudadesOrigen(ciudades);
            if (cual === 'D')
                setCatalogoCiudadesDestino(ciudades)
        }
        catch (error) {
            console.log('error', error);
        }
        finally {
            if (cual === 'O')
                setCargandoCiudadOrigen(false)
            if (cual === 'D')
                setCargandoCiudadDestino(false);
        }
    }

    const fetchTipoVehiculos = async () => {
        try {
            setCargandoCatalogoTiposVehiculos(true)
            const response = await axios.get(BASE_URL + '/portal/servicios/consultar-solicitudes/api/catalogo-tipos-vehiculo')
            setCatalogosTiposVehiculos(response?.data?.tiposVehiculo || [])
        }
        catch (error) {
            console.log('fetchTipoVehiculosError', error)
            setCatalogosTiposVehiculos([])
        }
        finally {
            setCargandoCatalogoTiposVehiculos(false)
        }
    }

    const debounceFetchCatalogoCiudades = debounce(fetchCatalogoCiudades, 750);


    const fetchConsultarSolicitudes = async (ordenamientos = []) => {   
        try {
            setCargandoSolicitudes(true)

            const strOrdenamiento = FWDOrderHelper(ordenamientos)

            const filtros = {}
            if (filtrosBusqueda.origen) filtros.origen = filtrosBusqueda.origen
            if (filtrosBusqueda.destino) filtros.destino = filtrosBusqueda.destino
            if (filtrosBusqueda.estatus) filtros.estatus = filtrosBusqueda.estatus
            if (filtrosBusqueda.tipoVehiculo) filtros.tipoVehiculo = filtrosBusqueda.tipoVehiculo
            if (filtrosBusqueda.moneda) filtros.moneda = filtrosBusqueda.moneda

            const filtrosURL = new URLSearchParams(filtros)
            let apiURL = BASE_URL + `/portal/servicios/consultar-solicitudes/api/consultar-solicitudes?varCliente=${selectedClienteComercial}&${filtrosURL.toString()}`
            if (strOrdenamiento.length) apiURL += `&ordenamiento=${strOrdenamiento}`
            
            const response = await axios.get(apiURL)
            setSolicitudes(response?.data?.solicitudes || [])
        }
        catch (error) {
            console.log('fetchConsultarSolicitudesError', error)
            setSolicitudes([])
        }
        finally {
            setCargandoSolicitudes(false)
        }
    }

    useEffect(() => {
        fetchConsultarSolicitudes()
        fetchTipoVehiculos()
    }, [selectedClienteComercial])

    useEffect(() => {
        fetchConsultarSolicitudes()
    }, [watcherBusqueda, selectedClienteComercial])

    return <>
        <FWDModalSolicitud
            {...modalSolicitud}
            fnClose={() => setModalSolicitud({ dblServicioCliente: null, open: false })}
        />
        <Grid container alignContent="flex-start" style={{ display: 'initial' }}>
            <Grid item xs={12} style={{ marginBottom: 15, position: 'sticky', top: '70px', zIndex: 2 }}>
                <Paper style={{ padding: 15 }}>
                    <section style={{ display: 'flex' }}>
                        <Autocomplete
                            inputValue={autocompleteValues?.origen || ''}
                            autoSelect
                            autoComplete
                            label="Origen"
                            style={{ flexGrow: 1 }}
                            loading={cargandoCiudadOrigen}
                            disabled={cargandoSolicitudes}
                            loadingText={
                                <section style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant="overline">
                                        {intl.formatMessage({ id: '_CARGANDO' })}
                                    </Typography>
                                    <LinearProgress />
                                </section>
                            }
                            options={catalogoCiudadesOrigen}
                            getOptionLabel={(origen) => origen.strCiudad}
                            noOptionsText="Sin opciones"
                            renderInput={(params) => <TextField {...params}
                                label="Origen"
                                onFocus={event => event.target.autocomplete = 'new-password'} // Hack para evitar autofill de Chrome
                                onClick={event => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                }}
                                onChange={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    debounceFetchCatalogoCiudades('O', event.target.value)
                                    setAutocompleteValues({ ...autocompleteValues, origen: event.target.value })
                                }}
                            />
                            }
                            onChange={(event, ciudad) => {
                                event.preventDefault();
                                event.stopPropagation();
                                setFiltrosBusqueda({ ...filtrosBusqueda, origen: ciudad?.intCiudad })
                                setAutocompleteValues({ ...autocompleteValues, origen: ciudad?.strCiudad })
                                return event;
                            }}
                        />
                        <Autocomplete
                            inputValue={autocompleteValues?.destino || ''}
                            autoSelect
                            autoComplete
                            label="Destino"
                            style={{ flexGrow: 1 }}
                            loading={cargandoCiudadDestino}
                            disabled={cargandoSolicitudes}
                            loadingText={
                                <section style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant="overline">
                                        {intl.formatMessage({ id: '_CARGANDO' })}
                                    </Typography>
                                    <LinearProgress />
                                </section>
                            }
                            options={catalogoCiudadesDestino}
                            getOptionLabel={(destino) => destino.strCiudad}
                            noOptionsText="Sin opciones"
                            renderInput={(params) => <TextField {...params}
                                label="Destino"
                                onFocus={event => event.target.autocomplete = 'new-password'} // Hack para evitar autofill de Chrome
                                onClick={event => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                }}
                                onChange={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    debounceFetchCatalogoCiudades('D', event.target.value)
                                    setAutocompleteValues({ ...autocompleteValues, destino: event.target.value })
                                }}
                            />
                            }
                            onChange={(event, ciudad) => {
                                event.preventDefault();
                                event.stopPropagation();
                                setFiltrosBusqueda({ ...filtrosBusqueda, destino: ciudad?.intCiudad })
                                setAutocompleteValues({ ...autocompleteValues, destino: ciudad?.strCiudad })
                                return event;
                            }}
                        />
                        <FormControl style={{ width: 250 }}>
                            <InputLabel id="demo-simple-select-label">Tipos veh&iacute;culos</InputLabel>
                            <Select
                                disabled={cargandoCatalogoTiposVehiculos || cargandoSolicitudes}
                                value={filtrosBusqueda.tipoVehiculo || ''}
                                onChange={e => setFiltrosBusqueda({ ...filtrosBusqueda, tipoVehiculo: e.target.value })}
                            >
                                {catalogoTiposVehiculos.map((x, k) => <MenuItem key={k} value={x.intTipoVehiculo}>{x.strTipoVehiculo}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <FormControl style={{ width: 100 }}>
                            <InputLabel id="demo-simple-select-label">Moneda</InputLabel>
                            <Select
                                disabled={cargandoSolicitudes}
                                value={filtrosBusqueda.moneda || ''}
                                onChange={e => setFiltrosBusqueda({ ...filtrosBusqueda, moneda: e.target.value })}
                            >
                                {catalogoTiposMoneda.map((x, k) => <MenuItem key={k} value={x.id}>{x.valor}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <FormControl style={{ width: 100 }}>
                            <InputLabel id="demo-simple-select-label">Estatus</InputLabel>
                            <Select
                                disabled={cargandoSolicitudes}
                                value={filtrosBusqueda.estatus}
                                onChange={e => setFiltrosBusqueda({ ...filtrosBusqueda, estatus: e.target.value }, setResetOrderTable(Date.now(), setWatcherBusqueda(Date.now())))}
                            >
                                {catalogoEstatusSolicitudes.map((x, k) => <MenuItem key={k} value={x.id}>{x.valor}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <Tooltip title="Borrar filtros">
                            <IconButton aria-label="borrar filtros" component="span"
                                disabled={cargandoSolicitudes}
                                onClick={() => {
                                    setAutocompleteValues({ origen: '', destino: '' })
                                    setFiltrosBusqueda({ ...filtrosBusqueda, origen: null, destino: null, moneda: null, tipoVehiculo: null }, setResetOrderTable(Date.now(), setWatcherBusqueda(Date.now())))
                                }}
                            >
                                <HighlightOffIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Buscar solicitudes">
                            <IconButton color="secondary" aria-label="buscar solicitudes" component="span"
                                disabled={cargandoSolicitudes}
                                onClick={() => setResetOrderTable(Date.now(), fetchConsultarSolicitudes())}
                            >
                                <SearchIcon />
                            </IconButton>
                        </Tooltip>
                    </section>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table>
                        <FWDTableHead
                            cabeceras={[
                                new FWDCabecera('fechaCarga', 'Fecha de carga', { width: 145 }),
                                new FWDCabecera('solicitados', 'Solicitados', { width: 125 }),
                                new FWDCabecera('pendientes', 'Pendientes', { width: 125 }),
                                new FWDCabecera('servicio', 'Servicio', { width: 180 }),
                                new FWDCabecera('vehiculo', 'Vehículo', { width: 180 }),
                                new FWDCabecera('origen', 'Origen'),
                                new FWDCabecera('destino', 'Destino'),
                                new FWDCabecera('tarifa', 'Tarifa', { width: 200, align: 'right' }, false)
                            ]}
                            fnOnClick={fetchConsultarSolicitudes}
                            disabled={cargandoSolicitudes}
                            reset={resetOrderTable}
                        />
                        <TableBody>
                            {
                                !cargandoSolicitudes ?
                                    solicitudes?.length
                                        ? solicitudes?.map((sol, k) => (
                                            <TableRow hover className="cursor-pointer" key={k} onClick={() => setModalSolicitud({ dblServicioCliente: sol.dblServicioCliente.split(',')[0], open: true })}>
                                                <TableCell align="center">{sol.datFechaServicioFormato}</TableCell>
                                                <TableCell align="center">{sol.intCantidadSolicitados}</TableCell>
                                                <TableCell align="center">{sol.intCantidadPendientes}</TableCell>
                                                <TableCell>{sol.strProducto}</TableCell>
                                                <TableCell>{sol.strTipoVehiculo}</TableCell>
                                                <TableCell>{sol.strOrigen}</TableCell>
                                                <TableCell>{sol.strDestino}</TableCell>
                                                {/* <TableCell align="right">{sol.intMoneda === CAT_MONEDAS.MXN ? `$${formatoMoneda(sol.dblVentaMXN)} MXN` : `$${formatoMoneda(sol.dblVentaUSD)} USD`}</TableCell> */}
                                                <TableCell align="right">
                                                    {sol.dblVentaMXN
                                                        ? <span>${formatoMoneda(sol.dblVentaMXN)} MXN</span> : <></>}
                                                    {sol.cantidadMonedas > 1
                                                        ? <br /> : <></>}
                                                    {sol.dblVentaUSD
                                                        ? <span>${formatoMoneda(sol.dblVentaUSD)} USD</span> : <></>}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                        : <TableRow>
                                            <TableCell colSpan="7" style={{ textAlign: 'center' }}>No hay solicitudes&nbsp;{filtrosBusqueda.estatus === 'VIG' ? 'vigentes' : 'vencidas'}</TableCell>
                                        </TableRow>
                                    :
                                    (<TableRow >
                                        <TableCell colSpan="8">
                                            <LoaderDashboardTables/>
                                        </TableCell>
                                    </TableRow>)
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    </>
}

export default injectIntl(ConsultaSolicitudes)

