import React, { useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import {Select, MenuItem } from '@material-ui/core';

function SelectorAnio(props) {

    const {intl, onChange, strFechaInicio, value, disabled} = props;

    const [strFechaInicioState, setStrFechaInicioState] = useState(null);
    const [arrayAnios, setArrayAnios] = useState(null);

    today = new Date();
    let today = new Date(Date.now());

    useEffect(() => {
        if(strFechaInicioState == strFechaInicio) {
            fn_generarListaAnios();
        }
        setStrFechaInicioState(strFechaInicio);

    }, [strFechaInicio, strFechaInicioState] )

    const fn_generarListaAnios = () => {
        let date = new Date(strFechaInicio);
        let dateInicio = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        let anioInicio = dateInicio.getFullYear();
        let anioFin = today.getFullYear();

        let _arrayAnios = [];

        for (let anio = anioInicio; anio <= anioFin; anio++) {
            _arrayAnios.push(anio);
        }

        setArrayAnios(_arrayAnios);
    }

    const handleOnChangeSelector = (event) => {
        if(onChange) {
            onChange(event.target.value);
        }
    }
    return <Select
        disabled={disabled}
        value={arrayAnios ? value : ""}
        onChange={handleOnChangeSelector}>
            {
                arrayAnios?.map(anio => <MenuItem key={anio} value={anio}>{anio}</MenuItem>)
            }
        </Select>
}

export default injectIntl(SelectorAnio);