import React, { useEffect, useState, useReducer, useContext, useLayoutEffect } from 'react'
import { injectIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { Grid, FormControl, InputLabel, Input, InputAdornment, IconButton, Paper, Avatar, Typography } from '@material-ui/core'
import { CircularProgress, Hidden } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SearchIcon from '@material-ui/icons/Search';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import { useSelector } from 'react-redux';
import axios from 'axios'
import { debounce } from 'lodash'
import DireccionCliente from 'app/componentes/servicios/DireccionCliente';
import { markerColors, useWidthBreakpoints } from 'app/helpers/UIHelper'
import { SH_FindInString } from 'app/helpers/stringHelper'
import CrearDireccionCliente from 'app/componentes/servicios/CrearDireccionCliente';
import { DireccionesClienteContext } from 'app/contextos/servicios';
import { LoaderDashboardTables } from 'app/componentes/dashboard/LoaderDashboard'


const UIMarkerColors = markerColors();

//  Reductor usado para compartir datos entre componentes. Se separ� el INPUT de Filtro/Busqueda
//  por que causaba re-renderizaci�n del componente, se opt� por crear un reductor que comparte el 
//  estado de todos los componentes usados en esta p�gina.
const INITIAL_STATE = {
    direcciones: [],
    direccionesFiltradas: [],
    coordenadas: { lat: null, lng: null },
    direccionSeleccionada: null,
    direccionEditable: null,
    timestampActualizado: Date.now(),  // Ultima actualizacion de lista direcciones
    timestampClickActualizarEliminarDireccion: Date.now(), // Ultimo click de actualizar/eliminar direccion
    markerEditable: false // Controlar el estado editable del marcador
};

const REDUCTOR = (state, action) => {

    switch (action.type) {
        case 'direcciones.set':
            return {
                ...state,
                direcciones: action.payload,
                direccionesFiltradas: action.payload
            }

        case 'direccionesFiltradas.reset':
            return { ...state, direccionesFiltradas: state.direcciones }

        case 'direccionesFiltradas.set':
            return { ...state, direccionesFiltradas: action.payload }

        case 'coordenadas.set':
            const { dblLatitudDireccion, dblLongitudDireccion } = action.payload;
            const coordenadas = {
                lat: dblLatitudDireccion === null ? null : Number(dblLatitudDireccion),
                lng: dblLongitudDireccion === null ? null : Number(dblLongitudDireccion)
            };
            return { ...state, coordenadas }

        case 'direccionSeleccionada.set':
            return { ...state, direccionSeleccionada: action.payload, direccionEditable: null }

        case 'direccionEditable.set':
            return { ...state, direccionEditable: action.payload }

        case 'timestampActualizado.set':
            return { ...state, timestampActualizado: Date.now() }

        case 'timestampClickActualizarEliminarDireccion.set':
            return { ...state, timestampClickActualizarEliminarDireccion: Date.now() }

        case 'markerEditable.true':
            return { ...state, markerEditable: true }

        case 'markerEditable.false':
            return { ...state, markerEditable: false }
    }
}

//  Componente MAPA separado para evitar efecto negativo en rendimiento de render del componente pagina (DireccionesCliente)
const FWD_MAP_HEIGHT = "calc(100vh - 110px)";
const FWDMap = ({ google }) => {

    const { data, dispatchData } = useContext(DireccionesClienteContext);
    const coordenadasFWD = { lat: 25.658731, lng: -100.360774 };

    //https://stackoverflow.com/questions/51495060/google-maps-react-get-marker-position-on-drag-end
    const onMarkerDragEnd = (coord) => {
        const { latLng } = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();
        dispatchData({ type: 'coordenadas.set', payload: { dblLatitudDireccion: lat, dblLongitudDireccion: lng } })
    }

    return <section style={{ position: 'relative', width: '100%', height: FWD_MAP_HEIGHT }}>
        <Map
            google={google}
            center={data?.coordenadas.lat !== null
                && data?.coordenadas.lng !== null
                ? data?.coordenadas
                : coordenadasFWD}
            initialCenter={coordenadasFWD}
            zoom={16}
        >
            {data?.coordenadas.lat !== null
                && data?.coordenadas.lng !== null
                ?
                <Marker
                    // onClick={this.onMarkerClick}
                    // icon={<RoomIcon htmlColor={UIMarkerColors.blue} />} NO FUNCIONAN ICONO MUI
                    draggable={data?.markerEditable}
                    position={data?.coordenadas}
                    name={'Current location'}
                    onDragend={(t, map, coord) => onMarkerDragEnd(coord)}
                />
                :
                <></>
            }

            {/* <InfoWindow
                onClose={this.onInfoWindowClose}
            >
                <div>
                    <h1>foo</h1>
                </div>
            </InfoWindow> */}
        </Map>
    </section>
}

const FWDMapLoader = () => (
    <Grid style={{ display: 'flex', width: '100%', height: FWD_MAP_HEIGHT, justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
    </Grid>
)

const FWDMapWrapper = GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    LoadingContainer: FWDMapLoader
})(FWDMap);

const _TarjetaFiltroCreateUpdate = ({ intl, cargandoPagina = false }) => {
    const { data, dispatchData } = useContext(DireccionesClienteContext);
    const [txtFiltro, setTxtFiltro] = useState('');
    const [addLocationIconStyle, setAddLocationIconStyle] = useState({ background: 'inherit', color: UIMarkerColors.green })

    const [crearDireccion, setCrearDireccion] = useState(false)

    const history = useHistory();

    // Observador de ultima actualizacion de lista de direcciones
    useEffect(() => {
        handleSetTxtFiltro('');
    }, [data?.timestampActualizado])

    // Observador del ultimo evento de editar/eliminar una direccion
    // util para evitar evitar crear y editar direcciones al mismo tiempo
    useEffect(() => {
        setCrearDireccion(false);
    }, [data?.timestampClickActualizarEliminarDireccion])

    const fnFiltrarDirecciones = (filtro) => {
        if (!filtro) return dispatchData({ type: 'direccionesFiltradas.reset' })

        const _direccionesFiltradas = data?.direcciones?.filter(x =>
            SH_FindInString(x.strNombreFiscal, filtro) ||
            SH_FindInString(x.strCalle, filtro) ||
            SH_FindInString(x.strNumero, filtro) ||
            SH_FindInString(x.strColoniaEntrega, filtro) ||
            SH_FindInString(x.strCPEntrega, filtro) ||
            SH_FindInString(x.strCiudad, filtro) ||
            SH_FindInString(x.strEstado, filtro) ||
            SH_FindInString(x.strPais, filtro) ||
            SH_FindInString(x.strLugarEntrega, filtro)
        )

        dispatchData({ type: 'direccionesFiltradas.set', payload: _direccionesFiltradas })
    }

    const debounceFnFiltrarDirecciones = debounce(fnFiltrarDirecciones, 750);

    const handleSetTxtFiltro = (value) => {
        setTxtFiltro(value)
        if ('scrollRestoration' in history) history.scrollRestoration = 'manual';
        window.scrollTo(0, 0);
        debounceFnFiltrarDirecciones(value);
    }

    return <Paper elevation={4}
        style={{
            marginBottom: '15px', padding: '10px', position: 'sticky', top: '90px', zIndex: '2',
            display: 'flex', flexDirection: 'column'
        }}
    >
        {!crearDireccion
            ?
            <section style={{ display: 'flex', alignItems: 'center' }}>
                <SearchIcon style={{ marginRight: '10px', color: UIMarkerColors.blue }} />
                <section style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'flex-end' }}>
                    <FormControl style={{ width: '100%', paddingRight: '5px' }}>
                        <InputLabel disabled={false}>{intl.formatMessage({ id: 'SERVICIOS.DIRECCION.TXT.FILTRAR_DIRECCIONES' })}</InputLabel>
                        <Input
                            disabled={cargandoPagina}
                            // classes={inputClasses}
                            type='text'
                            value={txtFiltro}
                            onChange={event => handleSetTxtFiltro(event.target.value)}
                            onFocus={() => dispatchData({ type: 'direccionSeleccionada.set', payload: null })}
                            endAdornment={
                                <InputAdornment position="end">
                                    {txtFiltro.length > 0
                                        ? <IconButton
                                            disabled={false}
                                            aria-label="clear direction search"
                                            onClick={() => {
                                                handleSetTxtFiltro('');
                                            }}
                                        >
                                            <HighlightOffIcon />
                                        </IconButton>
                                        : <></>}
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <Typography style={{ marginTop: '5px' }} variant="overline">{data?.direccionesFiltradas?.length} / {data?.direcciones?.length}</Typography>
                </section>
                <Avatar style={addLocationIconStyle} className="cursor-hover"
                    onMouseOver={() => {
                        if (cargandoPagina)
                            setAddLocationIconStyle({ color: UIMarkerColors.green, background: "#ffffff" })
                        else
                            setAddLocationIconStyle({ color: '#FFFFFF', background: UIMarkerColors.green })
                    }}
                    onMouseOut={() => setAddLocationIconStyle({ background: 'inherit', color: UIMarkerColors.green })}
                    onClick={() => {
                        if (!cargandoPagina) {
                            setCrearDireccion(true)
                            if ('scrollRestoration' in history) history.scrollRestoration = 'manual';
                            window.scrollTo(0, 0);
                            dispatchData({ type: 'direccionSeleccionada.set', payload: null })
                            dispatchData({ type: 'coordenadas.set', payload: { dblLatitudDireccion: null, dblLongitudDireccion: null } });
                            dispatchData({ type: 'markerEditable.true' })
                        }
                    }}
                >
                    <AddLocationIcon style={addLocationIconStyle} />
                </Avatar>
            </section>
            : <CrearDireccionCliente
                fnCancelar={() => setCrearDireccion(false)}
            />
        }
    </Paper>
}

const TarjetaFiltroCreateUpdate = injectIntl(_TarjetaFiltroCreateUpdate);

const DireccionesCliente = () => {
    const { session, selectedClienteComercial } = useSelector(state => state.auth);

    const [data, dispatchData] = useReducer(REDUCTOR, INITIAL_STATE);

    const [listaVarClientes] = selectedClienteComercial;

    const [cargandoPagina, setCargandoPagina] = useState(false);

    const [marginTopGridDirecciones, setMarginTopGridDirecciones] = useState(0);

    const UIBreakpoints = useWidthBreakpoints();


    useEffect(() => {
        const fetchDirecciones = async () => {
            try {
                setCargandoPagina(true);
                const queryParams = '?varCliente=' + selectedClienteComercial;
                const fetchUrl = process.env.REACT_APP_AWS_API_BASE_URL + '/portal/servicios/direcciones/api/listadirecciones' + queryParams;
                const response = await axios.get(fetchUrl);
                dispatchData({ type: 'direcciones.set', payload: response.data })
            }
            catch (error) {
                console.log('error | DireccionesCliente.fetchDirecciones => ', error);
            }
            finally {
                setCargandoPagina(false);
            }
        }
        fetchDirecciones();
    }, [selectedClienteComercial]);

    useLayoutEffect(() => {
        const handleResize = () => {
            if (document.body.clientWidth < UIBreakpoints.sm + 375) setMarginTopGridDirecciones(30);
            else setMarginTopGridDirecciones(0)
        }
        window.addEventListener('resize', handleResize);
        handleResize();
    }, [])

    return <>
        <DireccionesClienteContext.Provider value={{ data, dispatchData }}>
            <Grid container>
                <Grid item container direction="column" sm={6} style={{ display: 'initial' }}>
                    <TarjetaFiltroCreateUpdate
                        cargandoPagina={cargandoPagina} />
                    {
                        cargandoPagina
                            ?
                            <section style={{ width: '100%', height: 'calc(100vh - 220px)', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                <LoaderDashboardTables />
                            </section>
                            :
                            <Grid item style={{ marginTop: marginTopGridDirecciones }}>
                                {data?.direccionesFiltradas?.map((dir, indx) => (
                                    <DireccionCliente key={indx} direccion={dir}
                                        mostrarNombreFiscal={selectedClienteComercial}
                                        onClick={() => {
                                            if (data.direccionSeleccionada !== dir.dblClienteDireccionesEntrega) {
                                                dispatchData({ type: 'direccionSeleccionada.set', payload: dir.dblClienteDireccionesEntrega })
                                                dispatchData({ type: 'coordenadas.set', payload: dir })
                                            }
                                        }}
                                    />
                                ))}
                            </Grid>
                    }
                </Grid>
                {cargandoPagina
                    ?
                    <Grid item sm={6} style={{ paddingLeft: '15px', height: FWD_MAP_HEIGHT }}>
                        {/* El mega hack de translateY(-0px) para que el Skeleton tome el 100% de height ... */}
                        <Skeleton style={{ width: '100%', height: '100%', transform: 'translateY(-0px)' }} />
                    </Grid>
                    :
                    <Hidden xsDown>
                        <Grid item container sm={6} style={{ display: 'initial', paddingLeft: '15px' }}>
                            <Grid style={{ position: 'sticky', top: '90px' }}>
                                <FWDMapWrapper />
                            </Grid>
                        </Grid>
                    </Hidden>
                }
            </Grid>
        </DireccionesClienteContext.Provider>
    </>
}

export default injectIntl(DireccionesCliente);