import React from 'react';
import { NumericFormat } from 'react-number-format';
import TextField from '@material-ui/core/TextField';

const NumberFormatMasks = ({ value, onChange, label, prefix, error }) => {
    return (
        <NumericFormat
            error={error}
            customInput={TextField}
            thousandSeparator={true}
            prefix={prefix}
            label={label}
            style={{ width: '100%' }}
            value={value}
            onValueChange={(values) => onChange(values.value)}
        />
    );
};

export default NumberFormatMasks;