/*global google*/
import { GoogleApiWrapper } from 'google-maps-react';
import obtenerSeccion from 'app/componentes/servicios/funcionesMapa/ObtenerSeccionUnidad'
import { injectIntl } from "react-intl";

const RUTA_ORIGEN = 1;
const RUTA_DESTINO = 2;
const RUTA_DESPUES_DESTINO = 3;

export default function generarPolylines (servicio, intSeccion = RUTA_DESTINO) {

    return new Promise(async (resolve, reject) => {
        try {
            if (servicio.arrayUnidades.length === 0) {
                reject({error: `El servicio ${servicio.strNumeroPlaca} aun no cuenta con un rastreo activo`});
            } else {
                for (let indexUnidad = 0; indexUnidad < servicio.arrayUnidades.length; indexUnidad++) {
                    var unidad = servicio.arrayUnidades[indexUnidad];

                    if (unidad.arrayEventosRuta === undefined) {
                        console.log(`[generarRuta][${unidad.strNumeroPlaca}] No tiene eventos de ruta`, unidad);
                    } else {
                        var seccion = obtenerSeccion(unidad, intSeccion);

                        if (!seccion) {
                            console.log(`[generarRuta][${unidad.strNumeroPlaca}] No se encontr� seccion por pintar`);
                        } else {
                            //Solo vamos a pintar los fragmentos que est�n pendientes de ser pintados
                            if (seccion.indexUltimoEventoRuta === undefined)
                                seccion.indexUltimoEventoRuta = 0;
        
                            await generarFragmentosDeRuta(seccion);
                        }
                    }
                }

                resolve(servicio);
            }
        } catch(error) {
            console.log("[generarPolylines][catch]", error);
            resolve(servicio);
        }
    });
}

function generarFragmentosDeRuta(seccion) {

    return new Promise(async (resolve, reject) => { 
        try {
            if (seccion.indexUltimoEventoRuta < (seccion.arrayEventos.length - 1)) {
                //Dividimos la ruta en fragmentos de 25 piezas (23 waypoints + origen + destino)
                for (var i = seccion.indexUltimoEventoRuta, max = 25 - 1; i < seccion.arrayEventos.length; i = i + max) {

                    var waypts = [];
                    var waypointsArray = seccion.arrayEventos.slice(i, i + max + 1)
                    seccion.indexUltimoEventoRuta += (waypointsArray.length - 1);
                    //Creamos los waypoints del fragmento
                    for (var j = 1; j < waypointsArray.length - 1; j++) {
                        var puntoActual = { lat: waypointsArray[j].dblLatitud, lng: waypointsArray[j].dblLongitud };
            
                        if (!isNaN(puntoActual.lat) && !isNaN(puntoActual.lng)) {
                            waypts.push({
                                location: puntoActual,
                                stopover: false
                            });
                        }
                    }
            
                    var origen = new google.maps.LatLng(waypointsArray[0].dblLatitud, waypointsArray[0].dblLongitud);
                    var destino = new google.maps.LatLng(waypointsArray[waypointsArray.length - 1].dblLatitud, waypointsArray[waypointsArray.length - 1].dblLongitud);
                    
                    await requestDirectionService(seccion, origen, destino, waypts);
                }
                resolve(seccion);
            } else {
                resolve(seccion);
            }
        } catch(error) {
            console.log("[generarFragmentosDeRuta]", error);
            resolve(seccion);
        }
    });
}


function requestDirectionService(seccion, origen, destino, waypts) {
    return new Promise(async (resolve, reject) => { 
        try {

            let directionsService = new google.maps.DirectionsService();

            directionsService.route(
                {
                    origin: origen,
                    destination: destino,
                    waypoints: waypts,
                    optimizeWaypoints: false,
                    travelMode: 'DRIVING'
                },
                function (response, status) {

                    if (status === 'OK') {
                        var polylineOptions = {
                            strokeColor: seccion.strColor,
                            strokeOpacity: .5,
                            strokeWeight: 5
                        };
        
                        if (seccion.polylines === undefined)
                            seccion.polylines = [];
        
                            directionsToPolylines(response, seccion, polylineOptions);
                            
                            resolve(seccion);

                    } else {
                        if (status === "ZERO_RESULTS") {
                            setTimeout(() => {
                                if(waypts.length == 0) {
                                    resolve(seccion);
                                } else {
                                    requestDirectionService(seccion, origen, destino, [])
                                    .then((responseSeccion) => {
                                        resolve(responseSeccion);
                                    })
                                    .catch((error) => {
                                        console.log("[requestDirectionService][catch]", error);
                                        resolve(seccion);
                                    });
                                }
                            }, 1000);
                        } else {
                            
                            setTimeout(() => {
                                console.log("[dt_renderizarRuta] Reintentando pintar fragmento ");
                                
                                requestDirectionService(seccion, origen, destino, waypts)
                                .then((responseSeccion) => {
                                    resolve(responseSeccion);
                                })
                                .catch((error) => {
                                    console.log("[requestDirectionService][catch]", error);
                                    resolve(seccion);
                                });
                            }, 1000);
                        }
                    }
                });

        } catch(_error) {
            console.log("[requestDirectionService]", _error);
        }

    });
}

function directionsToPolylines(response, seccion, polylineOptions) {
    var legs = response.routes[0].legs;
    for (let i = 0; i < legs.length; i++) {
        var steps = legs[i].steps;
        for (let j = 0; j < steps.length; j++) {
            seccion.polylines.push({ path: steps[j].path, options: polylineOptions });
        }
    }
}
/*
export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
})(injectIntl(generarPolylines));*/