import React, { useMemo, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { injectIntl } from 'react-intl'
import { Grid, makeStyles, Typography, Tooltip } from '@material-ui/core'
import Fade from '@material-ui/core/Fade'
import CircularProgress from '@material-ui/core/CircularProgress'
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: 'rgb(249, 199, 79)',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    marginBottom: '20px'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};
function DocumentoPorSubir({ documento, dblPedido, fnOnRegistrado }) {

    const { fechaRegistro, idDocumento, nombreDocumento, tipoDocumento, idTipoDocumento } = documento;
    const { session } = useSelector(state => state.auth);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [cargando, setCargando] = useState(null);

    let validarDocumento = async (files) => {
        if (files.length == 1) {
            let file = files[0];
            let fileName = file.name;
            let fileExtension = fileName.split('.').pop();
            const arrayVarCliente = session?.listaVarClientes?.map(cliente => `varCliente=${cliente.strVarClienteOrigen}`);
            const {strUsuario, username} = session;

            try {

                // generamos link de subida del documento
                const response = await axios.get(`${process.env.REACT_APP_AWS_API_BASE_URL}/portal/servicios/${dblPedido}/api/documentos/generar-url?extension=${fileExtension}`);
                if(!response.data.presignedUrl) throw "No se pudo generar documento";
                
                // subimos el documento
                const responseS3 = await fetch(response.data.presignedUrl, {
                    method: 'PUT',
                    body: file
                });
                if (responseS3.status !== 200) throw "No se pudo subir documento";

                // guardamos la relacion del documento y servicio
                let documentoInfo = {
                    archivo: response.data.fileName.split(`${dblPedido}/`).pop(),
                    fileName,
                    extension: fileExtension,
                    filetype: file.type,
                    idTipoDocumento
                };
                const responseSave = await axios.post(`${process.env.REACT_APP_AWS_API_BASE_URL}/portal/servicios/${dblPedido}/api/documentos?varUsuario=${strUsuario}&userName=${username}&${arrayVarCliente.join('&')}`, documentoInfo);

                enqueueSnackbar("Documento registrado", {
                    variant: 'success',
                    persist: true,
                    onClick: () => closeSnackbar()
                });
                setTimeout(() => {
                    closeSnackbar()
                }, 2000);

                if(fnOnRegistrado) fnOnRegistrado();

            } catch (error) {
                acceptedFiles.length = 0
                acceptedFiles.splice(0, acceptedFiles.length)
                inputRef.current.value = ''
                setCargando(acceptedFiles.length > 0);
                enqueueSnackbar("Ha surgido un error al guardar documento" + error, {
                    variant: 'error',
                    persist: true,
                    onClick: () => closeSnackbar()
                });
                setTimeout(() => {
                    closeSnackbar()
                }, 2000);
            }
        }
    }

    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        draggedFiles,
        isDragActive,
        isDragAccept,
        isDragReject,
        inputRef } = useDropzone({ accept: 'image/*, application/pdf', maxFiles: 1, onDrop: validarDocumento });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    useEffect(() => {
        setCargando(false);
    }, [])

    useEffect(() => {
        setCargando(acceptedFiles.length > 0);
    }, [acceptedFiles])

    console.log("draggedFiles>>",draggedFiles);

    return (
        <Grid item xs={6}>
            <Fade
                in={cargando}
                style={{ transitionDelay: cargando ? '800ms' : '0ms', }}
                unmountOnExit>
                <Grid
                    container
                    direction="column"
                    style={{ justifyContent: "center" }}
                    alignItems="center"
                    spacing={1}>
                    <CircularProgress />
                    <h6>Guardando documento {tipoDocumento}</h6>
                    <label>¡Por favor no cierre la ventana!</label>
                </Grid>
            </Fade>
            <Fade
                in={!cargando}
            ><div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <p>Arrastre y suelte el documento <b>{tipoDocumento}</b> aquí,<br />o haga clic para buscar el documento</p>
                    {isDragAccept && (<p style={{ color: 'green' }}>Documento permitido</p>)}
                    {fileRejections?.length > 1 && (<p style={{ color: 'red' }}>Solo se permite 1 documento</p>)}
                    {isDragReject && draggedFiles.length <= 1 && (<p style={{ color: 'red' }}>Extensión de documento no permitida, se requiere PDF o IMAGEN</p>)}
                    {isDragReject && draggedFiles.length > 1 && (<p style={{ color: 'red' }}>Solo se permite 1 documento</p>)}
                </div></Fade>
        </Grid>
    );
}

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export default injectIntl(DocumentoPorSubir);
