import React, { useState, useEffect } from "react"
import { injectIntl } from "react-intl";
import { Modal, Grid, CircularProgress, Typography, makeStyles} from '@material-ui/core';

function Loading({ mensaje, open, fnCloseModal, elevation = 2,
    fnOnRendered = () => { return new Promise(resolve => resolve()) },
    fnOnClose = () => { return new Promise(resolve => resolve()) }
}) {
    const [strMensaje, setStrMensaje] = useState();

    useEffect(() => {
        setStrMensaje(mensaje);
    }, [strMensaje, mensaje]);
    // Inspired by the former Facebook spinners.
    const useStylesFacebook = makeStyles((theme) => ({
        root: {
            position: 'relative',
        },
        bottom: {
            color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        top: {
            color: '#1a90ff',
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
        },
        circle: {
            strokeLinecap: 'round',
        },
    }));
  
    function FacebookCircularProgress(props) {
        const classes = useStylesFacebook();
      
        return (
          <div className={classes.root}>
            <CircularProgress
              variant="determinate"
              className={classes.bottom}
              size={40}
              thickness={4}
              {...props}
              value={100}
            />
            <CircularProgress
              variant="indeterminate"
              disableShrink
              className={classes.top}
              classes={{
                circle: classes.circle,
              }}
              size={40}
              thickness={4}
              {...props}
            />
          </div>
        );
    }

    return <>
        <Modal
            open={open}
            onRendered={async () => { await fnOnRendered() }}
            onClose={async () => { await fnOnClose() }}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: 0 }}
            onEscapeKeyDown={() => fnCloseModal()}
        >
            <Grid container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={3}
                style={{border: 0}}>
                    <Grid item xs={12}>
                        <FacebookCircularProgress/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" component="div" style={{color: 'white'}}>
                            {strMensaje}
                        </Typography>
                    </Grid>
            </Grid>
        </Modal>
    </>
}

export default injectIntl(Loading);